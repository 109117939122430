import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  FormControlLabel,
  FormHelperText,
  Link,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { ErrorOption, SubmitHandler, useForm } from "react-hook-form";
import { BackendTypeServer, GCPCredentials } from "../../../domain/people";
import {
  useDeleteGCPCredentialsMutation,
  useGCPCredentials,
  useUpdateGCPCredentialsMutation,
} from "../../../crud/setup/hooks";
import { LoadingButton } from "@mui/lab";
import {
  StepProps,
  StepperContext,
} from "../../../shared-components/StepperContext";
import { ConfirmationDialog } from "./AWSRegionalInfraForm";
import { validateServiceAccountEmail } from "../UpdateOptions/GCP/SetCredentials";
import { parseServiceCreds } from "../UpdateOptions/GCP/CredentialsFileInput";
import { camelizeKeys } from "../../../apiUtils";
import { ExpandMore, UploadFile } from "@mui/icons-material";
import { CodeBlock } from "../../../shared-components/CodeBlock";
import { Urls } from "../../../domain/urls";
import { useIsAccountAdmin } from "../../../crud/account/hooks";
import { GCPSteps } from "./const";
import { analytics } from "../../../analytics";

type GCPCredentialsFormProps = {
  disabled?: boolean;
  workspaceSlug: string;
};

const DEFAULT_VALUES: GCPCredentials = {
  credentials: undefined,
  instanceServiceAccount: "",
};

export const GCPCredentialsStep = ({
  index,
  workspaceSlug,
}: StepProps): React.ReactElement => {
  const { data: current } = useGCPCredentials(workspaceSlug);
  const navigate = useNavigate();
  const {
    state: { completedSteps },
    dispatch,
  } = useContext(StepperContext);
  useEffect(() => {
    if (current) {
      dispatch({ type: "STEP_COMPLETED", payload: index });
      navigate(`/${Urls.Setup}/${Urls.Infrastructure}`);
    }
  }, [current, dispatch, index, navigate]);
  const isDisabled = !completedSteps.includes(GCPSteps.BEFORE);
  const [styleStyle, setSetupStyle] = useState<"simple" | "custom">("simple");
  return (
    <Stack sx={{ maxWidth: "750px" }} spacing={2}>
      <Typography>
        <p>
          {
            "If you choose the simple (easiest) option, the Coiled CLI will configure a VPC in your account. We'll explain each step and ask permission while you run the CLI."
          }
        </p>
        <p>
          If you prefer, you can configure Coiled to use a custom network
          configuration (e.g. pre-existing VPC).
        </p>
        <RadioGroup value={styleStyle} name="radio-buttons-group">
          <FormControlLabel
            value="simple"
            control={<Radio />}
            label="Default network configuration (recommended)"
            onChange={() => {
              setSetupStyle("simple");
              analytics.track("setup-gcp-style-selected", { style: "simple" });
            }}
          />
          <FormControlLabel
            value="custom"
            control={<Radio />}
            label="Custom network configuration"
            onChange={() => {
              setSetupStyle("custom");
              analytics.track("setup-gcp-style-selected", { style: "custom" });
            }}
          />
        </RadioGroup>
      </Typography>

      {styleStyle === "simple" && (
        <Stack spacing={2}>
          <Typography variant="h6">
            Run these commands to set up Coiled for Google Cloud:
          </Typography>
          <CodeBlock
            isTerminal
            snippet={`pip install coiled
coiled setup gcp`}
          />
        </Stack>
      )}
      {styleStyle === "custom" && (
        <Stack spacing={2}>
          <Typography variant="h6">Custom Network Configuration:</Typography>
          <Typography>Run these commands to get started:</Typography>
          <CodeBlock
            isTerminal
            snippet={`pip install coiled
coiled setup gcp --manual-final-setup`}
          />
          <Typography>
            ... and then come back here to add your new service account.
          </Typography>
          <GCPCredentialsForm
            disabled={isDisabled}
            workspaceSlug={workspaceSlug}
          />
        </Stack>
      )}
    </Stack>
  );
};

export const GCPCredentialsForm = ({
  disabled,
  workspaceSlug,
}: GCPCredentialsFormProps): React.ReactElement => {
  const { data: current, isLoading } = useGCPCredentials(workspaceSlug);
  const isAdmin = useIsAccountAdmin(workspaceSlug);
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { isDirty, errors, dirtyFields },
  } = useForm<GCPCredentials>({
    defaultValues: DEFAULT_VALUES,
  });

  const submitCreds = useUpdateGCPCredentialsMutation(workspaceSlug);
  const deleteCreds = useDeleteGCPCredentialsMutation(workspaceSlug);
  useEffect(() => {
    reset(current);
  }, [current, reset]);

  const onSubmit: SubmitHandler<GCPCredentials> = (data, e) => {
    e?.preventDefault();

    submitCreds.mutate(
      {
        instanceServiceAccount: data.instanceServiceAccount,
        ...((dirtyFields.credentials && { ...data }) || {}),
      },
      {
        onSuccess: () => {
          reset(current, { keepDirty: false });
          analytics.track("setup-credentials-added", {
            provider: BackendTypeServer.GCP_HOSTED,
          });
        },
        onError: () => {
          analytics.track("setup-credentials-error", {
            provider: BackendTypeServer.GCP_HOSTED,
          });
        },
      },
    );
  };
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [uploadError, setUploadError] = useState<ErrorOption | undefined>(
    undefined,
  );
  const formHasValues = !!watch("credentials");
  const instanceServiceAccount = watch("instanceServiceAccount");
  return (
    <>
      <ConfirmationDialog
        title="Delete Credentials"
        onClose={(success) => {
          if (success) {
            deleteCreds.mutate();
          }
          setDeleteConfirmation(false);
        }}
        open={deleteConfirmation}
      >
        This will immediately delete your credentials, running clusters will not
        be stopped, and Coiled will not be able to interact with your account
        anymore. Are you sure you want to continue?
      </ConfirmationDialog>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading && (
          <>
            <Skeleton sx={{ height: "50px" }} />
            <Skeleton sx={{ height: "50px" }} />
            <Skeleton sx={{ height: "50px" }} />
          </>
        )}
        {current && (
          <Alert severity="success">
            This workspace is connected to a Google Cloud project!
          </Alert>
        )}
        {!isLoading && (
          <Stack spacing={2} alignItems={"flex-start"}>
            <List sx={{ width: "75%", paddingTop: "0px" }}>
              {!current && (
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        {"Service Account Credentials "}
                        <Link
                          href="https://docs.coiled.io/user_guide/setup/gcp/manual.html#create-a-cluster-service-account"
                          target="_blank"
                        >
                          (details)
                        </Link>
                        {" :"}
                      </>
                    }
                  />
                  <Stack spacing={1}>
                    <Button
                      startIcon={<UploadFile />}
                      component="label"
                      sx={{ maxWidth: "350px" }}
                      disabled={!isAdmin || disabled}
                    >
                      Select File
                      <input
                        type="file"
                        accept=".json"
                        hidden
                        onChange={async (
                          e: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          if (!e.target.files) return;
                          const result = await parseServiceCreds(
                            e.target.files[0],
                          );
                          if (result.error) {
                            setUploadError(result.error);
                          } else {
                            setUploadError(undefined);
                            reset(
                              {
                                credentials: camelizeKeys(result.result),
                                instanceServiceAccount,
                              },
                              {
                                keepDefaultValues: true,
                              },
                            );
                          }
                        }}
                      />
                    </Button>
                    <FormHelperText error={!!uploadError}>
                      {uploadError?.message}
                    </FormHelperText>
                  </Stack>
                </ListItem>
              )}
              <ListItem>
                <Accordion sx={{ width: "100%" }} disabled={!formHasValues}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    Credential Details
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      <ListItem>
                        <TextField
                          size="small"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          label="Type"
                          fullWidth
                          {...register("credentials.type")}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          size="small"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          label="Client Id"
                          fullWidth
                          error={!!errors.credentials?.clientId}
                          helperText={errors.credentials?.clientId?.message}
                          {...register("credentials.clientId")}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          size="small"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          label="Project Id"
                          fullWidth
                          error={!!errors.credentials?.projectId}
                          helperText={errors.credentials?.projectId?.message}
                          {...register("credentials.projectId")}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          size="small"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          label="Private Key Id"
                          fullWidth
                          error={!!errors.credentials?.privateKeyId}
                          helperText={errors.credentials?.privateKeyId?.message}
                          {...register("credentials.privateKeyId")}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          size="small"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          label="Private Key"
                          fullWidth
                          error={!!errors.credentials?.privateKey}
                          helperText={errors.credentials?.privateKey?.message}
                          {...register("credentials.privateKey")}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          size="small"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          label="Auth Uri"
                          fullWidth
                          error={!!errors.credentials?.authUri}
                          helperText={errors.credentials?.authUri?.message}
                          {...register("credentials.authUri")}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          size="small"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          label="Token Uri"
                          error={!!errors.credentials?.tokenUri}
                          helperText={errors.credentials?.tokenUri?.message}
                          fullWidth
                          {...register("credentials.tokenUri")}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          size="small"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          label="Client Email"
                          fullWidth
                          error={!!errors.credentials?.clientEmail}
                          helperText={errors.credentials?.clientEmail?.message}
                          {...register("credentials.clientEmail")}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          size="small"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          label="Auth Provider x509 Cert Url"
                          fullWidth
                          error={!!errors.credentials?.authProviderX509CertUrl}
                          helperText={
                            errors.credentials?.authProviderX509CertUrl?.message
                          }
                          {...register("credentials.authProviderX509CertUrl")}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          size="small"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          label="Client X509 Cert Url"
                          fullWidth
                          error={!!errors.credentials?.clientX509CertUrl}
                          helperText={
                            errors.credentials?.clientX509CertUrl?.message
                          }
                          {...register("credentials.clientX509CertUrl")}
                        />
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
              </ListItem>
              <ListItem>
                <TextField
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  placeholder="example: compute@developer.gserviceaccount.com"
                  label="Data access service account email (optional)"
                  disabled={!isAdmin}
                  fullWidth
                  error={!!errors.instanceServiceAccount}
                  helperText={errors.instanceServiceAccount?.message}
                  {...register("instanceServiceAccount", {
                    required: false,
                    validate: (value) =>
                      validateServiceAccountEmail(value) ||
                      "Service account email doesn't seem to be valid.",
                  })}
                />
              </ListItem>
              <ListItem>
                <Stack direction="row" spacing={2}>
                  {current && (
                    <LoadingButton
                      variant="outlined"
                      color="error"
                      disabled={!isAdmin || disabled}
                      loading={deleteCreds.isLoading || isLoading || disabled}
                      onClick={() => {
                        setDeleteConfirmation(true);
                      }}
                    >
                      Delete
                    </LoadingButton>
                  )}
                  <LoadingButton
                    type="submit"
                    disabled={
                      !isAdmin ||
                      !isDirty ||
                      isLoading ||
                      submitCreds.isLoading ||
                      disabled
                    }
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </ListItem>
            </List>
          </Stack>
        )}
      </form>
    </>
  );
};
