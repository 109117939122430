import React, { useEffect } from "react";

import { Stack, Alert, CardContent, Typography } from "@mui/material";
import {
  useGCPGlobalSetup,
  useGCPRegionalInfra,
} from "../../../crud/setup/hooks";
import { OnboardingSuccessDialog } from "./AWSInfrastructure";
import { GCPGlobalInfraForm } from "./GCPGlobalInfraForm";
import { GCPRegionsForm } from "./GCPRegionsForm";
import { useOnboarding } from "./hooks";
import { analytics } from "../../../analytics";

export const GCPInfrastructureForm = ({
  workspaceSlug,
}: {
  workspaceSlug: string;
}): React.ReactElement => {
  const [onboarding, setOnboarding] = useOnboarding();
  const [shownDialog, setShownDialog] = React.useState(false);
  const { data: gcpGlobalInfra } = useGCPGlobalSetup(workspaceSlug);
  const { data: gcpRegionalInfra } = useGCPRegionalInfra(workspaceSlug);
  const isComplete =
    gcpGlobalInfra?.component?.state === "created" &&
    gcpRegionalInfra?.regions &&
    gcpRegionalInfra?.regions.length > 0 &&
    gcpRegionalInfra?.regions.every(
      (region) => region.component?.state === "created",
    );
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (isComplete && onboarding && !shownDialog) {
      setShownDialog(true);
      setTimeout(() => setOpen(true), 1000);
      setOnboarding(false);
      analytics.track("ui-victory-screen", {
        backend: "vm_gcp",
      });
    }
  }, [isComplete, onboarding, shownDialog, setOnboarding, analytics]);
  return (
    <>
      <OnboardingSuccessDialog open={open} onClose={() => setOpen(false)} />
      {onboarding && (
        <Alert
          severity={isComplete ? "success" : "warning"}
          sx={{ borderRadius: 0 }}
        >
          {isComplete && <Typography>{"You're all set!"}</Typography>}
          {!isComplete &&
            gcpRegionalInfra &&
            gcpRegionalInfra?.regions.length > 0 && (
              <Typography>
                {
                  "We're just setting up some things, this should take less than a minute."
                }
              </Typography>
            )}
          {!isComplete &&
            gcpGlobalInfra &&
            gcpRegionalInfra &&
            gcpRegionalInfra?.regions.length === 0 && (
              <Typography>{"Add a region to get started."}</Typography>
            )}
          {!isComplete && !gcpGlobalInfra && (
            <Typography>{"Create global infra to get started."}</Typography>
          )}
        </Alert>
      )}

      <CardContent>
        <Stack spacing={2}>
          <Typography>
            Coiled maintains some resources in your GCP account. You can manage
            it all from here.
          </Typography>
          <GCPGlobalInfraForm
            workspaceSlug={workspaceSlug}
            allowUnmanaged={
              gcpRegionalInfra?.regions && gcpRegionalInfra.regions.length === 0
            }
          />
          <GCPRegionsForm
            workspaceSlug={workspaceSlug}
            disabled={!gcpGlobalInfra}
            managed={!!gcpGlobalInfra?.managed}
          />
        </Stack>
      </CardContent>
    </>
  );
};
