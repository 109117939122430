import React from "react";
import { useForm, Path } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Box,
} from "@mui/material";
import {
  useCompleteInteractionFlagMutation,
  useInteractionFlag,
} from "../../crud/interactions/hooks";
import { InteractionFlagNames } from "../../crud/interactions/types";
import { HookSelect } from "../HookSelect";
import { HookCheck } from "../HookCheck";
import { parseError } from "../../apiUtils";
import { analytics } from "../../analytics";

interface UserSourceFormInput {
  source: string;
  useCase: string;
  academicUse: boolean;
  otherSource?: string;
  moreInformation?: string;
}

const SOURCES = [
  "Search Engine",
  "Friend or Colleague",
  "Social Media",
  "Dask Docs (dask.org)",
  "Event",
  "YouTube",
  "Podcast",
  "Other",
];

const USE_CASES = [
  "DataFrames (e.g., tabular data, ETL)",
  "ML (e.g., feature engineering)",
  "GPUs",
  "Array (e.g., geospatial, finance, etc.)",
  "General parallelism",
  "Other",
];

export const UserSourceDialog = (): React.ReactElement | null => {
  const hasSeenDialog = useInteractionFlag(
    InteractionFlagNames.HasSeenUserSourceDialog,
  );
  const completeHasSeenDialogMutation = useCompleteInteractionFlagMutation(
    InteractionFlagNames.HasSeenUserSourceDialog,
  );

  const { register, handleSubmit, watch, control } =
    useForm<UserSourceFormInput>({
      mode: "all",
      defaultValues: {
        source: "",
        useCase: "",
        academicUse: false,
        otherSource: "",
        moreInformation: "",
      },
    });

  const source = watch("source");

  const onSubmit = async (data: UserSourceFormInput) => {
    analytics.track("user-source", {
      source: data.source,
      otherSource: data.otherSource,
      moreInformation: data.moreInformation,
      useCase: data.useCase,
      academicUse: data.academicUse,
    });

    handleClose();
  };

  const handleClose = async () => {
    // Mark INTERACTION_FLAG as completed will close
    await completeHasSeenDialogMutation.mutate({});
  };

  if (hasSeenDialog.isLoading || hasSeenDialog.data?.completed) {
    return null;
  }

  const getAdditionalField = (
    value: string,
    otherName: Path<UserSourceFormInput>,
    moreName: Path<UserSourceFormInput> | null,
  ) => {
    switch (value) {
      case "":
        return null;
      case "Other":
        return (
          <TextField
            label="Please specify"
            variant="outlined"
            {...register(otherName)}
          />
        );
      default:
        return moreName ? (
          <TextField
            label="More information"
            variant="outlined"
            {...register(moreName)}
          />
        ) : (
          <></>
        );
    }
  };

  return (
    <Dialog open>
      <DialogTitle sx={{ p: 3 }}>Welcome to Coiled!</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ width: 400 }}>
            <Stack spacing={2}>
              <Typography>How did you learn about us?</Typography>
              <HookSelect control={control} name="source">
                {SOURCES.map((s) => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </HookSelect>
              {getAdditionalField(source, "otherSource", "moreInformation")}

              <Typography>
                What’s your use case for Coiled?{" "}
                <Typography sx={{ fontWeight: "light", display: "inline" }}>
                  (select all that apply)
                </Typography>
              </Typography>
              <HookSelect
                control={control}
                name="useCase"
                multiple
                renderValue={(selected) => (
                  <>
                    {Array.isArray(selected) ? selected.join(", ") : selected}
                  </>
                )}
              >
                {USE_CASES.map((s) => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </HookSelect>
              <HookCheck
                control={control}
                name="academicUse"
                label="Academic Use"
              />
            </Stack>
          </Box>

          {completeHasSeenDialogMutation.isError && (
            <Typography color="error">
              {parseError(completeHasSeenDialogMutation.error)}
            </Typography>
          )}
          <DialogActions sx={{ mt: 3, p: 0 }}>
            <Button variant="secondary" type="button" onClick={handleClose}>
              Skip
            </Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
