import {
  Alert,
  AlertTitle,
  CardContent,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Link,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  useAWSGlobalSetup,
  useDeleteAWSGlobalSetup,
  useRequestAWSGlobalSetup,
} from "../../../crud/setup/hooks";
import { AWSGlobalInfraSchema } from "../../../crud/setup/types";

import { Delete, PolicyTwoTone } from "@mui/icons-material";
import { ExpandableCard } from "../../../shared-components/Cards";
import { LoadingButton } from "@mui/lab";
import { ComponentIcon } from "./StateIcon";
import { ConfirmationDialog } from "./AWSRegionalInfraForm";
import { useOnboarding } from "./hooks";
import { BackendTypeServer } from "../../../domain/people";
import { useIsAccountAdmin } from "../../../crud/account/hooks";
import { analytics } from "../../../analytics";

const DEFAULT_VALUES: AWSGlobalInfraSchema = {
  id: 1,
  instanceProfile: undefined,
  role: undefined,
  policy: undefined,
  component: {
    coiledManaged: true,
    state: "",
    reason: "",
  },
};

export const AWSGlobalInfraForm = ({
  workspaceSlug,
}: {
  workspaceSlug: string;
}): React.ReactElement => {
  const {
    data: currentValues,
    isLoading,
    isSuccess,
  } = useAWSGlobalSetup(workspaceSlug);
  const isAdmin = useIsAccountAdmin(workspaceSlug);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const previousGlobalInfraState = useRef(currentValues?.component?.state);
  const requestSetup = useRequestAWSGlobalSetup(workspaceSlug);
  const delSetup = useDeleteAWSGlobalSetup(workspaceSlug);
  const [editMode, setEditMode] = useState(true);
  const { reset, handleSubmit } = useForm<AWSGlobalInfraSchema>({
    defaultValues: DEFAULT_VALUES,
  });
  const [onboarding] = useOnboarding();

  useEffect(() => {
    if (currentValues) {
      reset(currentValues);
    } else reset(DEFAULT_VALUES);
    if (currentValues?.component?.state !== previousGlobalInfraState.current) {
      previousGlobalInfraState.current = currentValues?.component?.state;
      if (onboarding && currentValues?.component?.state === "created") {
        // they successfully created the global infra
        // close the form after a short delay
        setTimeout(() => setEditMode(false), 200);
        analytics.track("setup-global-infra-created", {
          provider: BackendTypeServer.AWS_HOSTED,
        });
      }
    }
  }, [currentValues, onboarding, reset, analytics]);

  const onSubmit: SubmitHandler<AWSGlobalInfraSchema> = (data, e) => {
    requestSetup.mutate(data, {
      onSuccess: () =>
        analytics.track("setup-global-infra-requested", {
          provider: BackendTypeServer.AWS_HOSTED,
        }),
      onError: () =>
        analytics.track("setup-global-infra-request-error", {
          provider: BackendTypeServer.AWS_HOSTED,
        }),
    });
    e?.preventDefault();
    e?.stopPropagation();
  };

  const iamRoleName = currentValues?.role?.arn
    ? currentValues?.role?.arn.split("/")[1]
    : "";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableCard
        loading={isLoading}
        title={
          <Stack
            direction="row"
            spacing={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              {isSuccess && (
                <ComponentIcon
                  coiledComponent={currentValues?.component}
                  confetti
                  animateIn
                />
              )}
              <span>Global Infrastructure</span>
            </Stack>
            {!isLoading && !currentValues && (
              <LoadingButton
                type="submit"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                loading={requestSetup.isLoading}
                disabled={!isAdmin}
              >
                Create
              </LoadingButton>
            )}
            {!isLoading && currentValues && (
              <IconButton
                sx={{ marginLeft: "auto" }}
                disabled={delSetup.isLoading || !isAdmin}
                onClick={(e) => {
                  setDeleteConfirmation(true);
                  e.stopPropagation();
                }}
              >
                <Tooltip title="Remove global infrastructure">
                  <Delete />
                </Tooltip>
              </IconButton>
            )}
          </Stack>
        }
        isOpen={editMode}
        handleClick={() => setEditMode(!editMode)}
      >
        <ConfirmationDialog
          title={"Delete global infrastructure?"}
          open={deleteConfirmation}
          onClose={(success) => {
            if (success) {
              delSetup.mutate({});
            }
            setDeleteConfirmation(false);
          }}
        >
          Are you sure you want to delete the global infrastructure? This will
          means Coiled to be unable to create new clusters.
        </ConfirmationDialog>
        <CardContent>
          <Stack spacing={2}>
            {currentValues?.component?.state === "error" && (
              <Alert severity="error" sx={{ whiteSpace: "pre-line" }}>
                <AlertTitle>Error </AlertTitle>
                {currentValues?.component?.reason}
              </Alert>
            )}
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <ComponentIcon coiledComponent={currentValues?.role?.component} />
              <TextField
                label="Cluster IAM Role"
                value={currentValues?.role?.arn || ""}
                disabled
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder="Will be created automatically"
                sx={{ maxWidth: "500px" }}
                size="small"
              />
              {iamRoleName && (
                <Tooltip title={"See IAM Role in AWS Console"}>
                  <Link
                    href={`https://console.aws.amazon.com/iam/home#/roles/details/${iamRoleName}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PolicyTwoTone fontSize="small" />
                  </Link>
                </Tooltip>
              )}
            </Stack>
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <ComponentIcon
                coiledComponent={currentValues?.policy?.component}
              />
              <TextField
                label={"Cluster IAM Role Policy"}
                value={currentValues?.policy?.arn || ""}
                disabled
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder="Will be created automatically"
                size="small"
                sx={{ maxWidth: "500px" }}
              />
            </Stack>
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <ComponentIcon
                coiledComponent={currentValues?.instanceProfile?.component}
              />
              <TextField
                label={"Instance Profile"}
                value={currentValues?.instanceProfile?.arn || ""}
                disabled
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder="Will be created automatically"
                size="small"
                sx={{ maxWidth: "500px" }}
              />
            </Stack>
          </Stack>
        </CardContent>
      </ExpandableCard>
    </form>
  );
};
