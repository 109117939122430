import React from "react";
import { StyledAlert } from "./StyledAlert";
import { useUserOrganizationMemberships } from "../../crud/user/hooks";
import { BannerLevel } from "../../crud/banners/types";
import ReactMarkdown from "react-markdown";
import { formatDistanceStrict } from "date-fns";

const daysUntilDisplay = (date: Date): string => {
  return formatDistanceStrict(date, new Date(), { unit: "day" });
};

export const TrialExpirationAlert = (): React.ReactElement | null => {
  const { data: memberships } = useUserOrganizationMemberships();

  const earliestExpiration =
    memberships
      ?.map((item) => item.displayedProTrialExpiration)
      .map((date) => (date ? new Date(date) : null))
      .filter(
        (date): date is Date => date instanceof Date && !isNaN(date.getTime()),
      )
      .sort((a, b) => a.getTime() - b.getTime())[0] || null;

  const daysLeftDisplay = earliestExpiration
    ? daysUntilDisplay(earliestExpiration)
    : NaN;
  const notExpired = earliestExpiration && new Date() < earliestExpiration;

  return earliestExpiration && notExpired ? (
    <StyledAlert
      key="trial-expiration-banner"
      severity={BannerLevel.Info}
      title="Trial Expiration"
      message={
        <ReactMarkdown>{`Your trial of Coiled Professional ends in **${daysLeftDisplay}**.`}</ReactMarkdown>
      }
    />
  ) : null;
};
