import React, { useContext, useState } from "react";
import { Grid, Stack, Card } from "@mui/material";
import { useIsOrgAdmin } from "../../crud/account/hooks";
import { OrganizationSelector } from "../../shared-components/OrganizationSelector";
import { OrganizationScopeSchema } from "../../api-client";
import { useOrganization } from "../../crud/organizations/hooks";
import { SelectSomething } from "../../shared-components/SelectSomething";
import { UserContext } from "../../crud/user/context";
import { SelectOrgTags } from "../../shared-components/SelectOrgTags";
import { Dashboard } from "./Dashboard";
import { BannerLevel } from "../../crud/banners/types";
import { StyledAlert } from "../../shared-components/Alerts/StyledAlert";
import { useInteractionFlag } from "../../crud/interactions/hooks";
import { InteractionFlagNames } from "../../crud/interactions/types";

export const Billing = (): React.ReactElement => {
  const {
    user: { isStaff: isStaff },
  } = useContext(UserContext);

  const [org, setOrg] = useState<OrganizationScopeSchema | undefined>(
    undefined,
  );
  const { data: organization, isSuccess: organizationLoaded } = useOrganization(
    org?.id,
  );
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const isOrgAdmin = useIsOrgAdmin(org?.id);

  const hasAccessToSetupWorkspace = useInteractionFlag(
    InteractionFlagNames.AccessToAccountWithCompletedSetup,
  );

  return (
    <Stack spacing={2}>
      {hasAccessToSetupWorkspace.data?.completed === false && (
        <StyledAlert
          key={"coiled-is-free-banner"}
          severity={BannerLevel.Info}
          title="Coiled Free: No Payment Necessary"
          message="Coiled comes with 200 CPU-hours of usage free every month, or 10,000 CPU-hours if you connect to your own cloud account."
        />
      )}
      <div style={{ marginLeft: "auto" }}>
        <OrganizationSelector onChange={setOrg} />
      </div>
      {(isOrgAdmin || isStaff) && organizationLoaded && (
        <Grid item xs="auto" md="auto">
          <Dashboard
            key={`dashboard-${organization.id}-${startDate?.toDateString()}-${endDate?.toDateString()}`}
            organizationId={organization.id}
            startDate={startDate}
            endDate={endDate}
            onBillingPeriodChange={(
              newStartDate: Date,
              newEndDate: Date,
            ): void => {
              setStartDate(newStartDate);
              setEndDate(newEndDate);
            }}
          />
          {isStaff && (
            <Card sx={{ marginTop: ".5rem" }}>
              <SelectOrgTags
                key={organization.id}
                organization={organization}
              />
            </Card>
          )}
        </Grid>
      )}

      {!org && (
        <SelectSomething message="Select an organization to view billing information." />
      )}
      {org && !isOrgAdmin && !isStaff && (
        <SelectSomething
          message={`You must be an admin to view billing information for ${org.name}.`}
        />
      )}
    </Stack>
  );
};
