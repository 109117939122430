import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import ky from "ky";
import { REDIRECT_URI_PARAM } from "./constants";
import { getDjangoCSRFCookie, REDIRECT_COOKIE } from "../cookieUtils";
import { ColumnDiv } from "./components";
import { AuthenticationContext } from "../crud/auth/context";
import { useCookie } from "../utils/hooks";
import { useMutation } from "react-query";
import { analytics } from "../analytics";

type RawResponse = {
  key: string;
  existing?: boolean;
};
type TokenResponse = { token: string; existing?: boolean };

const getTokenForWorkOS = async (code: string): Promise<TokenResponse> => {
  const spec = (await ky
    .get(`/api/v2/workos/success`, {
      searchParams: { code },
      headers: { "X-CSRFToken": getDjangoCSRFCookie() || "" },
    })
    .json()) as RawResponse;
  return {
    token: spec.key,
    existing: spec.existing,
  };
};

// this is copied from HandleProviderResponse, with (mostly irrelevant) error handling removed
export const HandleWorkOSLogin = (): React.ReactElement => {
  const [redirectCookie, setRedirectCookie] = useCookie(REDIRECT_COOKIE);
  const { setToken } = useContext(AuthenticationContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get("code") || "/";
  const { mutate, isIdle } = useMutation(
    "login",
    (c: string) => getTokenForWorkOS(code), // hit BE to log in user and get coiledkey token
    {
      onSuccess: (data) => {
        setToken(data.token);
        const redirectURI =
          redirectCookie || searchParams.get(REDIRECT_URI_PARAM) || "/";
        setRedirectCookie(undefined);
        analytics.track(`login-success`, { provider: "workos" });
        navigate(redirectURI, { replace: true });
        return;
      },
    },
  );
  useEffect(() => {
    if (isIdle && code) {
      mutate(code);
    }
  }, [
    isIdle,
    mutate,
    code,
    setToken,
    setRedirectCookie,
    navigate,
    redirectCookie,
    searchParams,
  ]);

  return (
    <Container maxWidth="xs">
      <ColumnDiv>
        <Typography>Logging you in...</Typography>
        <CircularProgress />
      </ColumnDiv>
    </Container>
  );
};
