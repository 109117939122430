import React, { useContext } from "react";

import { AWSInfrastructureForm } from "./AWSInfrastructure";
import { Alert, Link } from "@mui/material";
import { GCPInfrastructureForm } from "./GCPInfrastructure";
import { Link as RouterLink } from "react-router-dom";
import { Urls } from "../../../domain/urls";
import { BackendTypesEnum, WorkspaceSchema } from "../../../api-client";
import { UserContext } from "../../../crud/user/context";
import { userIsAdmin } from "../../../auth";
import { AzureInfrastructureForm } from "./AzureInfrastructure";
export const InfrastructureForm = ({
  workspace,
}: {
  workspace: WorkspaceSchema;
}): React.ReactElement => {
  const { memberships } = useContext(UserContext);
  const isAdmin = userIsAdmin(memberships, workspace.slug);
  return (
    <>
      {!isAdmin && (
        <Alert severity="error" sx={{ borderRadius: 0 }}>
          Editing has been disabled as only workspace admins can configure
          infrastructure. Find your admins on the{" "}
          <Link
            component={RouterLink}
            to={`/${Urls.Team}?account=${workspace.slug}`}
          >
            teams
          </Link>{" "}
          page.
        </Alert>
      )}
      {workspace.activeBackend === BackendTypesEnum.VmAws && (
        <AWSInfrastructureForm workspaceSlug={workspace.slug} />
      )}
      {workspace.activeBackend === BackendTypesEnum.VmGcp && (
        <GCPInfrastructureForm workspaceSlug={workspace.slug} />
      )}
      {workspace.activeBackend === BackendTypesEnum.VmAzure && (
        <AzureInfrastructureForm workspaceSlug={workspace.slug} />
      )}
      {workspace.activeBackend === undefined && (
        <Alert severity="error" sx={{ borderRadius: 0 }}>
          You need to add credentials first
        </Alert>
      )}
    </>
  );
};
