import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { ApiClient } from "../../apiUtils";
import {
  PaymentHistorySchema,
  PaymentMethodSchema,
  PricingNinjaViewsProgramDowngradeToEssentialsRequest,
  PricingNinjaViewsProgramGetWorkspaceProgramUsageRequest,
  PricingNinjaViewsProgramUpdateLimitsRequest,
  PricingNinjaViewsProgramUpdateLimitsStaffRequest,
  PricingNinjaViewsProgramUpgradeToProfessionalRequest,
  ProgramSchema,
  ProgramUsageSchema,
  ResponseError,
  WorkspaceProgramUsageSchema,
} from "../../api-client";

export const useWorkspaceProgramUsage = (
  query: PricingNinjaViewsProgramGetWorkspaceProgramUsageRequest,
): UseQueryResult<WorkspaceProgramUsageSchema> => {
  return useQuery(["getWorkspaceProgramUsage", query], () =>
    ApiClient.pricingNinjaViewsProgramGetWorkspaceProgramUsage(query),
  );
};

export const useProgramUsage = (
  organizationId?: number,
  startDate?: Date,
  endDate?: Date,
): UseQueryResult<ProgramUsageSchema> => {
  return useQuery(
    ["getProgramUsage", organizationId, startDate, endDate],
    () =>
      ApiClient.pricingNinjaViewsProgramGetProgramUsage({
        organizationId: organizationId as number,
        startDate,
        endDate,
      }),
    {
      enabled: !!organizationId,
    },
  );
};

export const useProgram = (
  organizationId?: number,
  startDate?: Date,
  endDate?: Date,
): UseQueryResult<ProgramSchema> => {
  return useQuery(
    ["getProgram", organizationId, startDate, endDate],
    () =>
      ApiClient.pricingNinjaViewsProgramGetProgram({
        organizationId: organizationId as number,
        startDate,
        endDate,
      }),
    {
      enabled: !!organizationId,
    },
  );
};

export const useUpdateLimitsMutation = (): UseMutationResult<
  ProgramSchema,
  ResponseError,
  PricingNinjaViewsProgramUpdateLimitsRequest,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    (data) => ApiClient.pricingNinjaViewsProgramUpdateLimits(data),
    {
      onSuccess: (result) => {
        client.setQueryData(["getProgram", result.id], result);
      },
    },
  );
};

export const useUpdateLimitsStaffMutation = (): UseMutationResult<
  ProgramSchema,
  ResponseError,
  PricingNinjaViewsProgramUpdateLimitsStaffRequest,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    (data) => ApiClient.pricingNinjaViewsProgramUpdateLimitsStaff(data),
    {
      onSuccess: (result) => {
        client.setQueryData(["getProgram", result.id], result);
      },
    },
  );
};

export const useUpgradeToProfessionalMutation = (): UseMutationResult<
  ProgramSchema,
  ResponseError,
  PricingNinjaViewsProgramUpgradeToProfessionalRequest,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    (data) => ApiClient.pricingNinjaViewsProgramUpgradeToProfessional(data),
    {
      onSuccess: (result) => {
        client.setQueryData(["getProgram", result.id], result);
      },
    },
  );
};

export const useDowngradeToEssentialsMutation = (): UseMutationResult<
  ProgramSchema,
  ResponseError,
  PricingNinjaViewsProgramDowngradeToEssentialsRequest,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    (data) => ApiClient.pricingNinjaViewsProgramDowngradeToEssentials(data),
    {
      onSuccess: (result) => {
        client.setQueryData(["getProgram", result.id], result);
      },
    },
  );
};

export const useOrganizationPaymentMethod = (
  organizationId?: number,
): UseQueryResult<PaymentMethodSchema> => {
  return useQuery(
    ["getOrganizationPaymentMethod", organizationId],
    () =>
      ApiClient.pricingNinjaViewsProgramGetOrganizationPaymentMethod({
        organizationId: organizationId as number,
      }),
    {
      enabled: !!organizationId,
    },
  );
};

export const useOrganizationPaymentHistory = (
  organizationId?: number,
): UseQueryResult<PaymentHistorySchema> => {
  return useQuery(
    ["getOrganizationPaymentHistory", organizationId],
    () =>
      ApiClient.pricingNinjaViewsProgramGetOrganizationPaymentHistory({
        organizationId: organizationId as number,
      }),
    {
      enabled: !!organizationId,
    },
  );
};
