/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const CompoundScopes = {
    Everything: 'Everything',
    JustMine: 'Just mine',
    AllStaff: 'All staff',
    AllExceptStaff: 'All except staff',
    FirstClusterInLast10Days: 'First cluster in last 10 days',
    FirstClusterInLast30Days: 'First cluster in last 30 days',
    InterestingDealStage: 'Interesting deal stage',
    CoiledHosted: 'Coiled-hosted'
} as const;
export type CompoundScopes = typeof CompoundScopes[keyof typeof CompoundScopes];


export function CompoundScopesFromJSON(json: any): CompoundScopes {
    return CompoundScopesFromJSONTyped(json, false);
}

export function CompoundScopesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompoundScopes {
    return json as CompoundScopes;
}

export function CompoundScopesToJSON(value?: CompoundScopes | null): any {
    return value as any;
}

