import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  Skeleton,
  Slide,
  Stack,
  TextField,
  Link as MuiLink,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  lighten,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
} from "@mui/material";
import animationData from "../../../assets/waiting.lottie.json";
import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import OngoingPolicyJson from "../../../assets/policy/aws-required-policy-ongoing.json";
import SetupPolicyJson from "../../../assets/policy/aws-required-policy-setup.json";

import {
  AUTH_TYPE,
  AWSCredentials,
  BackendTypeServer,
} from "../../../domain/people";
import {
  useAWSCredentials,
  useCFSetupAttempt,
  useCreateCFSetupAttemptMutation,
  useDeleteAWSCredentialsMutation,
  useUpdateAWSCredentialsMutation,
  useRoleAssumptionSetup,
} from "../../../crud/setup/hooks";
import { LoadingButton } from "@mui/lab";
import { HookRadio } from "../../../shared-components/HookRadio";
import {
  StepProps,
  StepperContext,
} from "../../../shared-components/StepperContext";
import { AWSSteps } from "./const";
import { Link } from "react-router-dom";
import { Urls } from "../../../domain/urls";
import { useQueryClient } from "react-query";
import {
  AccountQueryKeys,
  useIsAccountAdmin,
} from "../../../crud/account/hooks";
import { CheckCircleOutlineOutlined, ExpandMore } from "@mui/icons-material";
import Lottie from "lottie-react";
import { ConfirmationDialog } from "./AWSRegionalInfraForm";
import { CodeBlock } from "../../../shared-components/CodeBlock";
import { HTTPError } from "ky";
import { useSnackbar } from "notistack";
import { analytics } from "../../../analytics";

type AWSCredentialsFormProps = {
  disabled?: boolean;
  workspaceSlug: string;
};
type CloudFormSetupForm = {
  agreeToComeback: boolean;
};

type CFDialogProps = {
  workspaceSlug: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};
const CFDialog = ({
  workspaceSlug,
  open,
  onClose,
  onSuccess,
}: CFDialogProps): React.ReactElement => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<CloudFormSetupForm>({
    defaultValues: {
      agreeToComeback: false,
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = (data: CloudFormSetupForm) => {
    startCF.mutate(
      {},
      {
        onSuccess: (resp, vars) => {
          window.open(resp.link, "_blank");
          analytics.track("setup-aws-cloudformation-started");
        },
        onError: (err) => {
          if (err instanceof HTTPError) {
            if (err.response.status === 403) {
              enqueueSnackbar(
                "You do not have permission to start CloudFormation setup. Please contact the administrator for your Coiled workspace.",
                { variant: "error" },
              );
            }
          }
          analytics.track("setup-aws-cloudformation-start-error");
        },
      },
    );
  };
  const startCF = useCreateCFSetupAttemptMutation(workspaceSlug);
  const cfSetup = useCFSetupAttempt(workspaceSlug, startCF.data?.id);
  const client = useQueryClient();
  useEffect(() => {
    if (cfSetup.data?.complete) {
      client.setQueryData(AccountQueryKeys.ACCOUNT_DETAILS, (old: any) => {
        return {
          ...old,
          activeBackend: BackendTypeServer.AWS_HOSTED,
        };
      });
      analytics.track("setup-aws-cloudformation-success");
    }
  }, [cfSetup, client, analytics]);
  const [loadingSlideDirection, setLoadingSlideDirection] = useState<
    "left" | "right"
  >("left");
  const [launchOut, setLaunchOut] = useState(false);
  const [loadOut, setLoadOut] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>CloudFormation Setup</DialogTitle>
        <DialogContent>
          <Slide
            in={!startCF.data?.id}
            appear={false}
            mountOnEnter
            direction={"left"}
            unmountOnExit
            onExited={() => setLaunchOut(true)}
          >
            <div>
              <Typography>
                {
                  "The button below will take you to the AWS Console. We've set everything up for you. You just need to press two buttons as in the video below:"
                }
              </Typography>
              <br />
              <video width="100%" loop muted autoPlay>
                <source
                  src="/static/how-to-cloud-formation.mp4"
                  type="video/mp4"
                />
              </video>
              <List>
                <ListItem>
                  <FormControl error={errors.agreeToComeback !== undefined}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...register("agreeToComeback", {
                            required: "You need to agree to this!",
                            validate: (v) => v === true,
                          })}
                        />
                      }
                      label={
                        <>
                          I promise to come back here after I click{" "}
                          <b>Create stack</b>
                        </>
                      }
                    />
                    {errors.agreeToComeback && (
                      <FormHelperText>
                        {errors.agreeToComeback.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </ListItem>
              </List>
            </div>
          </Slide>
          <Slide
            in={launchOut && cfSetup.data && !cfSetup.data?.complete}
            direction={loadingSlideDirection}
            mountOnEnter
            unmountOnExit
            onEntered={() => setLoadingSlideDirection("right")}
            onExited={() => {
              setLoadOut(true);
              setLoadingSlideDirection("left");
            }}
          >
            <Stack spacing={2} alignItems="center">
              <Typography>
                Waiting for AWS to finish, this usually takes about a minute.
              </Typography>
              <Typography>
                <strong>
                  You remembered to hit <u>Create stack</u>, right?
                </strong>
              </Typography>
              <Lottie animationData={animationData} style={{ width: "40%" }} />
              <Typography variant="subtitle1">
                If you did not see CloudFormation, your browser may have blocked
                us from opening a new tab.{" "}
                <p>
                  <MuiLink
                    href={startCF.data?.link || ""}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Here is a link just in case.
                  </MuiLink>
                </p>
              </Typography>
            </Stack>
          </Slide>
          <Slide
            in={loadOut && cfSetup.data && cfSetup.data?.complete}
            unmountOnExit
            mountOnEnter
            direction="right"
          >
            <Stack
              sx={{
                height: "100%",
                width: "100%",
              }}
              spacing={2}
              alignItems={"center"}
            >
              <>
                <Typography>AWS successfully connected to Coiled!</Typography>
                <CheckCircleOutlineOutlined
                  sx={{
                    width: "75%",
                    height: "75%",
                    color: (theme) => lighten(theme.palette.success.main, 0.4),
                  }}
                />
              </>
            </Stack>
          </Slide>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button
              variant="secondary"
              onClick={() => {
                setLoadingSlideDirection("right");
                startCF.reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            {!cfSetup.data && (
              <LoadingButton
                size="large"
                type="submit"
                sx={{ maxWidth: "250px" }}
                disabled={!watch("agreeToComeback")}
                loading={startCF.isLoading}
              >
                Launch CloudFormation
              </LoadingButton>
            )}
            {cfSetup.data && (
              <Button
                disabled={!cfSetup.data?.complete}
                component={Link}
                to={`/${Urls.Setup}/${Urls.Infrastructure}?viewedAccount="${workspaceSlug}"`}
              >
                Next
              </Button>
            )}
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const AWSCredentialsStep = ({
  index,
  workspaceSlug,
}: StepProps): React.ReactElement => {
  const { data: current } = useAWSCredentials(workspaceSlug);
  const {
    state: { completedSteps },
    dispatch,
  } = useContext(StepperContext);
  useEffect(() => {
    if (current) {
      dispatch({ type: "STEP_COMPLETED", payload: index });
    }
  }, [current, dispatch, index]);
  const isDisabled = !completedSteps.includes(AWSSteps.BEFORE);
  return (
    <AWSCredentialsForm disabled={isDisabled} workspaceSlug={workspaceSlug} />
  );
};

export const AWSCredentialsForm = ({
  disabled,
  workspaceSlug,
}: AWSCredentialsFormProps): React.ReactElement => {
  const { data: current, isLoading } = useAWSCredentials(workspaceSlug);
  const isAdmin = useIsAccountAdmin(workspaceSlug);
  const { data: roleAssumptionData } = useRoleAssumptionSetup(workspaceSlug);

  const {
    control,
    register,
    reset,
    handleSubmit,
    unregister,
    setValue,
    resetField,
    formState: {
      isDirty,
      errors,
      dirtyFields: {
        secretAccessKey: secretAccessKeyDirty,
        accessKeyId: accessKeyIdDirty,
      },
    },
  } = useForm<AWSCredentials>({
    defaultValues: {
      type: AUTH_TYPE.ROLE_AUTH,
      roleArn: "",
      externalId: roleAssumptionData?.externalId,
    },
  });
  const [cfDialogOpen, setCFDialogOpen] = useState(false);

  const submitCreds = useUpdateAWSCredentialsMutation(workspaceSlug);
  const deleteCreds = useDeleteAWSCredentialsMutation(workspaceSlug);
  const authMethod = useWatch({ name: "type", control });
  useEffect(() => {
    reset(current);
  }, [current, reset, roleAssumptionData]);
  useEffect(() => {
    if (authMethod === AUTH_TYPE.ROLE_AUTH) {
      unregister("accessKeyId", {
        keepDefaultValue: true,
      });
      unregister("secretAccessKey", {
        keepDefaultValue: true,
      });
      resetField("roleArn");
      resetField("externalId", {
        defaultValue: roleAssumptionData?.externalId,
      });
    } else {
      unregister("roleArn", { keepDefaultValue: true });
      unregister("externalId", {
        keepDefaultValue: true,
      });
      resetField("accessKeyId");
      resetField("secretAccessKey");
    }
  }, [authMethod, unregister, resetField, roleAssumptionData]);

  const onSubmit: SubmitHandler<AWSCredentials> = (data, e) => {
    e?.preventDefault();
    submitCreds.mutate(data, {
      onSuccess: () => {
        reset(current, { keepDirty: false, keepTouched: false });
        analytics.track("setup-credentials-added", {
          provider: BackendTypeServer.AWS_HOSTED,
        });
      },
      onError: () => {
        analytics.track("setup-credentials-error", {
          provider: BackendTypeServer.AWS_HOSTED,
        });
      },
    });
  };
  const [mode, setMode] = useState<"automatic" | "manual">("automatic");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  return (
    <>
      <CFDialog
        workspaceSlug={workspaceSlug}
        open={cfDialogOpen}
        onClose={() => setCFDialogOpen(false)}
        onSuccess={() => {
          setCFDialogOpen(false);
        }}
      />
      <ConfirmationDialog
        title="Delete Credentials"
        onClose={(success) => {
          if (success) {
            deleteCreds.mutate();
          }
          setDeleteConfirmation(false);
        }}
        open={deleteConfirmation}
      >
        This will immediately delete your credentials, running clusters will not
        be stopped, and Coiled will not be able to interact with your account
        anymore. Are you sure you want to continue?
      </ConfirmationDialog>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading && (
          <>
            <Skeleton sx={{ height: "50px" }} />
            <Skeleton sx={{ height: "50px" }} />
            <Skeleton sx={{ height: "50px" }} />
          </>
        )}
        {current && (
          <Alert severity="success">
            This workspace is connected to an AWS account!
          </Alert>
        )}
        {!isLoading && (
          <Stack spacing={2} alignItems={"flex-start"}>
            {!current && (
              <ToggleButtonGroup
                exclusive
                value={mode}
                color="info"
                disabled={disabled}
                onChange={(e, value) => {
                  if (value !== null) setMode(value);
                }}
              >
                <ToggleButton
                  value="automatic"
                  aria-label="left aligned"
                  sx={{ maxWidth: "300px" }}
                  onClick={() => {
                    analytics.track("setup-aws-credential-mode-change", {
                      mode: "cloudformation",
                    });
                  }}
                >
                  <Stack
                    spacing={2}
                    alignItems="center"
                    sx={{ width: "100%", height: "100%" }}
                  >
                    <Typography variant="h2">
                      Automatic (recommended)
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      sx={{ textTransform: "none" }}
                    >
                      {
                        "Coiled creates the IAM policies and network configuration for your account, asking for your permission before each step."
                      }
                    </Typography>
                  </Stack>
                </ToggleButton>
                <ToggleButton
                  value="manual"
                  aria-label="centered"
                  sx={{ maxWidth: "300px" }}
                  onClick={() => {
                    analytics.track("setup-aws-credential-mode-change", {
                      mode: "manual",
                    });
                  }}
                >
                  <Stack
                    spacing={2}
                    alignItems="center"
                    sx={{ width: "100%", height: "100%" }}
                  >
                    <Typography variant="h2">
                      Manual (Not recommended)
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      sx={{ textTransform: "none" }}
                    >
                      {
                        "This is uncommon. Automatic setup works for most people."
                      }
                    </Typography>
                  </Stack>
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            {(mode === "manual" || current !== undefined) && (
              <Stack spacing={2}>
                {!current && (
                  <Accordion
                    sx={(theme) => ({
                      border: `1px solid ${theme.palette.divider}}`,
                      backgroundColor: theme.palette.primary.light,
                    })}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      How to manually create a Role for Coiled
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack spacing={2}>
                        <Typography>
                          <p>
                            <i>
                              Note we do not recommended this approach and
                              strongly suggest the CloudFormation approach.
                            </i>
                          </p>
                          <p>
                            If you are manually creating an IAM role for Coiled,
                            we required the following policies.
                          </p>
                        </Typography>
                        <div>
                          <Typography variant="h3">Trust Policy:</Typography>
                          <CodeBlock
                            language="json"
                            snippet={roleAssumptionData?.trustPolicy || ""}
                          />
                        </div>
                        <div>
                          <Typography variant="h3">Ongoing Policy:</Typography>
                          <CodeBlock
                            language="json"
                            snippet={JSON.stringify(OngoingPolicyJson, null, 2)}
                          />
                        </div>
                        <div>
                          <Typography variant="h3">
                            Setup Policy (can be removed after initial setup):
                          </Typography>
                          <CodeBlock
                            language="json"
                            snippet={JSON.stringify(SetupPolicyJson, null, 2)}
                          />
                        </div>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                )}
                <Stack direction="row" alignItems={"center"} spacing={2}>
                  <Typography>Authentication Method:</Typography>
                  <HookRadio
                    name="type"
                    control={control}
                    row
                    disabled={!isAdmin}
                    options={[
                      {
                        label: "Role Assumption",
                        value: AUTH_TYPE.ROLE_AUTH,
                      },
                      {
                        label: "Access Key",
                        value: AUTH_TYPE.KEY_AUTH,
                      },
                    ]}
                  />
                </Stack>
                {authMethod === AUTH_TYPE.ROLE_AUTH && (
                  <>
                    <TextField
                      key="roleArn"
                      label="Role ARN"
                      size="small"
                      required={authMethod === AUTH_TYPE.ROLE_AUTH}
                      disabled={authMethod !== AUTH_TYPE.ROLE_AUTH || !isAdmin}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      placeholder="ex: arn:aws:iam::111111111111:role/CoiledIAMStack-IAMRole-123d"
                      sx={{ maxWidth: "500px" }}
                      {...register("roleArn", {
                        pattern: {
                          value:
                            /^arn:aws:iam::[0-9]{12}:role\/[0-9A-Za-z\+\.@_,-]+$/,
                          message:
                            "Invalid Role ARN, expected arn:aws:iam::<account-id>:role/<role-name>",
                        },
                      })}
                      error={!!errors.roleArn}
                      helperText={errors.roleArn?.message}
                    />
                    <TextField
                      key="externalId"
                      size="small"
                      label="External ID"
                      defaultValue={roleAssumptionData?.externalId || ""}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      sx={{
                        maxWidth: "500px",
                        display:
                          authMethod === AUTH_TYPE.ROLE_AUTH
                            ? undefined
                            : "none",
                      }}
                      required={authMethod === AUTH_TYPE.ROLE_AUTH}
                      inputProps={{
                        // using "disabled" seems to (not fully reliably) prevent this
                        // field from being sent to the backend, resulting in
                        // https://github.com/coiled/platform/issues/6193
                        // So instead we'll make it act and look like a disabled field, with "readOnly"
                        // alongside slightly changing the color.
                        //
                        // A better improvement for the future might be to not make this look like
                        // a form field at all, since the user can't control it.
                        readOnly: true,
                        style: { color: "rgba(0, 0, 0, 0.6)" }, // Adjusts text color to grey
                      }}
                      error={!!errors.externalId}
                      helperText={
                        errors.externalId?.message ||
                        "This should match the External ID in your trust policy"
                      }
                      {...register("externalId")}
                    />
                  </>
                )}
                {authMethod === AUTH_TYPE.KEY_AUTH && (
                  <>
                    <TextField
                      key="accessKeyId"
                      type="password"
                      label="Access Key ID"
                      size="small"
                      sx={{ maxWidth: "300px" }}
                      fullWidth
                      onFocus={() => {
                        if (!accessKeyIdDirty) {
                          setValue("accessKeyId", "", {
                            shouldDirty: false,
                          });
                        }
                      }}
                      required={authMethod === AUTH_TYPE.KEY_AUTH}
                      disabled={authMethod !== AUTH_TYPE.KEY_AUTH || !isAdmin}
                      {...register("accessKeyId", {
                        pattern: {
                          value: /^[A-Z0-9]{20}$/,
                          message:
                            "Malformed AWS Access Key ID: Should have 20 alphanumeric characters",
                        },
                      })}
                      error={!!errors.accessKeyId}
                      helperText={errors.accessKeyId?.message}
                    />
                    <TextField
                      key="secretAccessKey"
                      label="Secret Access Key"
                      type="password"
                      sx={{ maxWidth: "300px" }}
                      size="small"
                      fullWidth
                      onFocus={() => {
                        if (!secretAccessKeyDirty) {
                          setValue("secretAccessKey", "", {
                            shouldDirty: false,
                          });
                        }
                      }}
                      required={authMethod === AUTH_TYPE.KEY_AUTH}
                      disabled={authMethod !== AUTH_TYPE.KEY_AUTH || !isAdmin}
                      {...register("secretAccessKey", {
                        pattern: {
                          value: /^[A-Za-z0-9+/]{40}$/,
                          message:
                            "Malformed AWS Secret Access Key: Should have 40 base64 characters",
                        },
                      })}
                      error={!!errors.secretAccessKey}
                      helperText={errors.secretAccessKey?.message}
                    />
                  </>
                )}
                <Stack direction="row" spacing={2}>
                  {current && (
                    <LoadingButton
                      variant="secondary"
                      color="error"
                      disabled={disabled || !isAdmin}
                      loading={deleteCreds.isLoading || isLoading}
                      onClick={() => {
                        setDeleteConfirmation(true);
                      }}
                    >
                      Delete
                    </LoadingButton>
                  )}
                  <LoadingButton
                    type="submit"
                    disabled={
                      !isDirty ||
                      isLoading ||
                      submitCreds.isLoading ||
                      disabled ||
                      !isAdmin
                    }
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Stack>
            )}
            {mode === "automatic" && current === undefined && (
              <>
                <Typography>
                  Press the following button to grant access
                </Typography>
                <Button
                  disabled={!isAdmin || disabled}
                  onClick={() => setCFDialogOpen(true)}
                >
                  Connect to AWS
                </Button>
              </>
            )}
          </Stack>
        )}
      </form>
    </>
  );
};
