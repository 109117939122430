import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export interface EmptyStateDialogProps {
  open: boolean;
  title?: string;
  message?: string;
}

export const GetStartedDialogue = ({
  open,
  title,
  message,
}: EmptyStateDialogProps): React.ReactElement | null => {
  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {}} // Prevent closing on backdrop click
      disableEscapeKeyDown // Prevent closing with Escape key
    >
      <DialogTitle sx={{ textAlign: "center" }}>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography align="center" gutterBottom sx={{ mb: 3 }}>
            {message}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              component={RouterLink}
              to="/get-started"
              sx={{
                minWidth: "200px",
              }}
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
