/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Valid states for Instances
 * @export
 */
export const InstanceStateEnum = {
    Queued: 'queued',
    Starting: 'starting',
    Started: 'started',
    Ready: 'ready',
    Pausing: 'pausing',
    Paused: 'paused',
    Stopping: 'stopping',
    Stopped: 'stopped',
    Error: 'error'
} as const;
export type InstanceStateEnum = typeof InstanceStateEnum[keyof typeof InstanceStateEnum];


export function InstanceStateEnumFromJSON(json: any): InstanceStateEnum {
    return InstanceStateEnumFromJSONTyped(json, false);
}

export function InstanceStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceStateEnum {
    return json as InstanceStateEnum;
}

export function InstanceStateEnumToJSON(value?: InstanceStateEnum | null): any {
    return value as any;
}

