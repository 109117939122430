import React, { ReactElement, ReactNode, useEffect } from "react";
import {
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from "use-query-params";
import { BannersContextProvider } from "../crud/banners/context";
import { useBanners } from "../crud/banners/hooks";
import { LoadingScreen } from "../shared-components/LoadingScreen";
import { usePersistentJson } from "../utils/hooks";
import { useSnackbar } from "notistack";
import { useInteractionFlags } from "../crud/interactions/hooks";
import { InteractionFlagNames } from "../crud/interactions/types";
import { InteractionFlagsContextProvider } from "../crud/interactions/context";
import { ResponseError } from "../api-client";
import {
  ScopedContextProvider,
  ValidScopes,
} from "../shared-components/ScopeSelector";
import { ApiClient } from "../apiUtils";

type Props = {
  children?: ReactNode;
};

const useScopeQueryParams = () => {
  const [friendlyParam, setFriendlyParam] = useQueryParams({
    quick: StringParam,
    workspace: StringParam,
    organization: StringParam,
    user: StringParam,
    customGroup: NumberParam,
    scopes: JsonParam,
  });
  const setQueryParam = (scopeToSet: ValidScopes) => {
    // `undefined` value removes key from URL query params
    const friendlyStruct: Record<
      string,
      string | number | undefined | ValidScopes
    > = {
      quick: undefined,
      workspace: undefined,
      organization: undefined,
      user: undefined,
      customGroup: undefined,
      scopes: undefined,
    };
    if (scopeToSet) {
      switch (scopeToSet.type) {
        case "organization":
          friendlyStruct.organization = scopeToSet.name;
          break;
        case "account":
          friendlyStruct.workspace = scopeToSet.name;
          break;
        case "user":
          friendlyStruct.user = scopeToSet.name;
          break;
        case "compound":
          friendlyStruct.quick = scopeToSet.name;
          break;
        case "custom-staff-list":
          friendlyStruct.customGroup = scopeToSet.id;
          break;
        default:
          friendlyStruct.scopes = scopeToSet;
          break;
      }
    }
    setFriendlyParam(friendlyStruct, "replaceIn");
  };
  let scope: Record<string, string | number | undefined> | undefined;
  if (friendlyParam.scopes) {
    scope = friendlyParam.scopes;
  } else if (friendlyParam.workspace) {
    scope = { type: "account", name: friendlyParam.workspace };
  } else if (friendlyParam.organization) {
    scope = { type: "organization", name: friendlyParam.organization };
  } else if (friendlyParam.user) {
    scope = { type: "user", name: friendlyParam.user };
  } else if (friendlyParam.quick) {
    scope = { type: "compound", name: friendlyParam.quick };
  } else if (friendlyParam.customGroup) {
    scope = { type: "custom-staff-list", id: friendlyParam.customGroup };
  }
  return [scope, setQueryParam];
};

export const MainLayoutContextProvider = ({
  children,
}: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: allFlags, isSuccess: flagsLoaded } = useInteractionFlags(
    (
      Object.keys(InteractionFlagNames) as (keyof typeof InteractionFlagNames)[]
    ).map((v) => InteractionFlagNames[v]),
  );

  const defaultScope: ValidScopes = {
    type: "compound",
    name: "Everything",
  };
  // custom get/set for the scope query params allows us to encode these in a more human-friendly way
  const [scopeQueryParam, setScopeQueryParam] = useScopeQueryParams();

  const [scopeValue, setScopeValue] = usePersistentJson<ValidScopes>({
    key: "scopes",
    defaultValue: defaultScope,
    persistDefault: false,
    queryParam: scopeQueryParam,
    setQueryParam: setScopeQueryParam,
  });
  const [legacyViewedAccount, setLegacyViewedAccount] = useQueryParam(
    "account",
    StringParam,
  );
  useEffect(() => {
    if (legacyViewedAccount) {
      // if we detect a legacy account query param, we should set the viewed account
      // and clear the legacy account query param
      ApiClient.usersViewsWorkspacesWorkspaceDetail({
        workspaceSlug: legacyViewedAccount,
      })
        .then((res) => {
          setScopeValue({
            type: "account",
            id: res.id,
            name: res.name,
            organizationId: res.organization?.id,
            slug: res.slug,
          });
          setLegacyViewedAccount(undefined, "replaceIn");
        })
        .catch((e) => {
          if (e instanceof ResponseError && e.response.status === 404) {
            enqueueSnackbar(`Account named ${legacyViewedAccount} not found`, {
              variant: "error",
            });
            setLegacyViewedAccount(undefined, "replaceIn");
          }
        });
    } else if (
      scopeValue.type === "account" &&
      scopeValue.name &&
      !scopeValue.organizationId
    ) {
      ApiClient.usersViewsWorkspacesWorkspaceDetail({
        workspaceName: scopeValue.name,
      })
        .then((res) => {
          setScopeValue({
            type: "account",
            id: res.id,
            name: res.name,
            organizationId: res.organization?.id,
            slug: res.slug,
          });
        })
        .catch((e) => {
          if (e instanceof ResponseError && e.response.status === 404) {
            enqueueSnackbar(`Account named ${scopeValue.name} not found`, {
              variant: "error",
            });
          }
        });
    }
  }, [
    legacyViewedAccount,
    scopeValue,
    setLegacyViewedAccount,
    enqueueSnackbar,
  ]);
  const { data: banners, isSuccess: bannersLoaded } = useBanners();

  if (legacyViewedAccount || !bannersLoaded || !flagsLoaded) {
    return <LoadingScreen />;
  }

  return (
    <BannersContextProvider banners={banners}>
      <InteractionFlagsContextProvider flags={allFlags}>
        <ScopedContextProvider value={[scopeValue, setScopeValue]}>
          {children}
        </ScopedContextProvider>
      </InteractionFlagsContextProvider>
    </BannersContextProvider>
  );
};
