/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IndividualRunTaskSchema } from './IndividualRunTaskSchema';
import {
    IndividualRunTaskSchemaFromJSON,
    IndividualRunTaskSchemaFromJSONTyped,
    IndividualRunTaskSchemaToJSON,
} from './IndividualRunTaskSchema';
import type { JobCommonTaskSchema } from './JobCommonTaskSchema';
import {
    JobCommonTaskSchemaFromJSON,
    JobCommonTaskSchemaFromJSONTyped,
    JobCommonTaskSchemaToJSON,
} from './JobCommonTaskSchema';

/**
 * 
 * @export
 * @interface JobTaskListSchema
 */
export interface JobTaskListSchema {
    /**
     * 
     * @type {Array<IndividualRunTaskSchema>}
     * @memberof JobTaskListSchema
     */
    tasks: Array<IndividualRunTaskSchema>;
    /**
     * 
     * @type {JobCommonTaskSchema}
     * @memberof JobTaskListSchema
     */
    jobInfo?: JobCommonTaskSchema;
}

/**
 * Check if a given object implements the JobTaskListSchema interface.
 */
export function instanceOfJobTaskListSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tasks" in value;

    return isInstance;
}

export function JobTaskListSchemaFromJSON(json: any): JobTaskListSchema {
    return JobTaskListSchemaFromJSONTyped(json, false);
}

export function JobTaskListSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobTaskListSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tasks': ((json['tasks'] as Array<any>).map(IndividualRunTaskSchemaFromJSON)),
        'jobInfo': !exists(json, 'job_info') ? undefined : JobCommonTaskSchemaFromJSON(json['job_info']),
    };
}

export function JobTaskListSchemaToJSON(value?: JobTaskListSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tasks': ((value.tasks as Array<any>).map(IndividualRunTaskSchemaToJSON)),
        'job_info': JobCommonTaskSchemaToJSON(value.jobInfo),
    };
}

