import { datadogLogs, HandlerType } from "@datadog/browser-logs";
import { AnalyticsBrowser, Plugin } from "@segment/analytics-next";
import { datadogRum } from "@datadog/browser-rum";
import { UserSchema } from "../api-client";

const customEnricherPlugin: Plugin = {
  name: "enrich-segment",
  type: "enrichment",
  version: "1.0.0",

  isLoaded: () => true,
  load: async (_ctx, _ajs) => {},

  track: async (ctx) => {
    // Retrieve email from user traits
    const user = await analytics.user();
    const currentEmail = user.traits()?.email || "";

    // Enrich event properties with email
    ctx.updateEvent("properties", {
      ...ctx.event.properties,
      email: currentEmail,
    });

    // Send to Datadog
    datadogRum.addAction(ctx.event.event!, ctx.event.properties);

    return ctx;
  },
};

export const analytics = AnalyticsBrowser.load(
  {
    writeKey: import.meta.env.FE_SEGMENT_WRITE_KEY,
    plugins: [customEnricherPlugin],
    cdnURL: import.meta.env.FE_SEGMENT_CDN_URL,
  },
  {
    disable: import.meta.env.FE_SEGMENT_WRITE_KEY ? false : true,
  },
);

export const initialize = (): void => {
  datadogLogs.init({
    clientToken: "pub231f00748151d906e040ef36497521d2",
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    service: "frontend",
    env: import.meta.env.FE_TARGET_ENV,
    sessionSampleRate: 100,
  });
  datadogRum.init({
    applicationId: "9115ed6b-81eb-45ca-909f-fd41a61133f7",
    clientToken: "pub231f00748151d906e040ef36497521d2",
    site: "datadoghq.com",
    service: "frontend",
    env: import.meta.env.FE_TARGET_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [
      /^(https?:\/\/)(cloud\.coiled\.io|staging\.coiledhq\.com|localhost)/,
    ],
  });

  datadogLogs.logger.setHandler(
    import.meta.env.FE_DD_ENABLED === "true"
      ? HandlerType.http
      : HandlerType.console,
  );
};

export const trackPageView = (path: string): void => {
  analytics.page();
  // Putting the pageview in datadog too to make debugging easier
  datadogLogs.logger.info(`page view ${path}`, {
    path,
  });
};

/**
 * Mark the user (presumably freshly logged-in) for various analytics
 * frameworks in use at the time.
 */
export const identifyUser = (user: UserSchema): void => {
  datadogRum.setUser({
    id: `${user.id}`,
    email: user.email,
    name: user.fullName,
    username: user.username,
    isStaff: user.isStaff,
  });
  analytics.identify(`${user.id}`, {
    // segment
    id: `${user.id}`,
    email: user.email,
    fullName: user.fullName,
    preferredName: user.preferredName,
    firstName: user.fullName.split(" ")[0],
    lastName: user.fullName.split(" ").slice(1).join(" "),
    username: user.username,
    company: { name: user.profile.company },
    isStaff: user.isStaff,
    title: user.profile.title,
  });

  // for Common Room
  // (window.signals is defined in a script loaded in index.html)
  // @ts-ignore
  window.signals.identify({
    email: user.email,
    name: user.fullName,
  });
};
