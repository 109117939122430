import { Button, Container, Divider, styled, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Terminal } from "./TerminalComponent";
import { analytics } from "../analytics";
const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(22),
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: "1rem",
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  marginBottom: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  "&::before": {
    border: "1px solid #413f3b",
    borderRadius: "10px",
    content: '""',
    display: "inline-block",
    height: "15px",
    marginRight: "15px",
    width: "15px",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "350%",
  marginBottom: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: "-0.15rem",
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightLight,
  padding: "8px 16px 0 16px",
}));

export const ExternalLink = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  "&:hover, &:focus": {
    color: theme.palette.secondary.dark,
  },
}));

export const InternalLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&:hover, &:focus": {
    color: theme.palette.secondary.dark,
  },
}));

export const BackToSignInButton = styled(Button)({
  paddingTop: "16px",
  display: "flex",
});

const DocsButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  textTransform: "none",
  justifyContent: "flex-start",
  paddingLeft: 0,
  fontSize: theme.typography.pxToRem(16),
}));

export const ErrorMessage = styled(Typography)({
  marginTop: "16px",
});

export const AuthForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-evenly",
  width: "100%",
  minHeight: "100px",
  paddingTop: "24px",
  gap: "8px",
});

export const ColumnDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  textAlign: "left",
  width: "100%",
  minHeight: "200px",
});

export const HalfDivider = styled(Divider)(({ theme }) => ({
  width: "50%",
  color: theme.palette.grey[500],
  backgroundColor: "transparent",
}));

export const Split = styled("div")({
  display: "flex",
  flexWrap: "wrap-reverse",
  minHeight: "100vh",
});

const LeftSectionContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  marginBottom: "2rem",
});

type LeftSectionProps = {
  subtitle: string;
  analyticsEvent: string;
  href: string;
  description: string;
};

export const LeftSection = ({
  subtitle,
  analyticsEvent,
  href,
  description,
}: LeftSectionProps): React.ReactElement => {
  return (
    <LeftSectionContainer maxWidth="sm">
      <Subtitle>{subtitle}</Subtitle>
      <Title variant="h1">Cloud Computing for Data People</Title>
      <Terminal />
      <Heading>
        Reliably scale Python in your cloud account. Get started for free.
      </Heading>
      <DocsButton
        color="primary"
        variant="text"
        onClick={() => analytics.track(analyticsEvent)}
        href={href}
      >
        {description}
      </DocsButton>
    </LeftSectionContainer>
  );
};
