/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AWSAccountSettingsSchema,
  AWSCredentialsSchema,
  AWSGlobalInfraSchema,
  AWSRegionalInfraSchema,
  AWSRegionalInfraSchemaCreate,
  AZSchema,
  AccountLightSchema,
  AccountPatchSchema,
  AccountUsageSchema,
  AddResultSchema,
  AddUserToOrganizationSchema,
  AnalyticsProfileSchema,
  ApproximateSpec,
  AzureCredentialsSchema,
  BatchJobStatusResponse,
  BillingContactSchema,
  BillingEventListSchema,
  BuildDownloadLink,
  BuildPhoneHomeSchema,
  CloseSchema,
  ClusterAlertsSchema,
  ClusterAnalyticsSummary,
  ClusterEventData,
  ClusterFrontendSchema,
  ClusterLiteSchema,
  ClusterLogInfoSchema,
  ClusterLogsSchema,
  ClusterMinimalStateSchema,
  ClusterSSHSchema,
  ClusterSchema,
  ClusterSummaryMetricsSchema,
  CoiledSpanSchema,
  ComputationSchema,
  ConfigSchema,
  CreateBatchJobResponse,
  CreateBatchJobSchema,
  CreateBillingEventSchema,
  CreateClusterResponse,
  CreateClusterSchema,
  CreateOrganizationSchema,
  CreateSingleScriptClusterSchema,
  CreateSoftwareEnvironmentSpecSchema,
  CreateWorkspaceSchema,
  CustomCertSchema,
  CustomCertStatusSchema,
  CustomGroupSchema,
  DashboardCert,
  DaskSchedulerSchema,
  DesiredClusterState,
  DockerSettingsSchema,
  EventSchema,
  EventsSchema,
  GCPAccountInfraCreateSchema,
  GCPAccountInfraSchema,
  GCPAccountSettingsSchema,
  GCPCredentialsSchema,
  GCPRegionalInfraCreateSchema,
  GCPRegionalInfraSchema,
  GCPSubnetSchema,
  GroupUsageSchema,
  InstanceLogEventSchema,
  InstanceSchema,
  InstanceSpecLiteSchema,
  InstanceSpecSchema,
  JobTaskListSchema,
  LogEventSchema,
  LogPageSessionRequestSchema,
  LogSessionInit,
  LogSessionPage,
  MembershipSchema,
  MetricSeries,
  MetricsSchema,
  NetworkSchema,
  OrganizationInviteSchema,
  OrganizationListSchema,
  OrganizationMembershipSchema,
  OrganizationProgramUsageSchema,
  OrganizationSchema,
  PackageApproxResult,
  PackageSchema,
  PagedBatchJobSummaryResponse,
  PagedBillingEventListSchema,
  PagedCacheEntrySchema,
  PagedListClusterV2,
  PagedOrganizationMembershipSchema,
  PagedOrganizationStaffListSchema,
  PagedSoftwareEnvironmentAliasListSchema,
  PagedSoftwareEnvironmentAliasSchema,
  PagedSoftwareEnvironmentSpecListSchema,
  PagedUserInteractionListSchema,
  PagedWorkspaceMemberSchema,
  PaymentHistorySchema,
  PaymentMethodSchema,
  PhoneHomePayload,
  ProgramSchema,
  ProgramUsageSchema,
  PrometheusReadCredSchema,
  RegisterSchema,
  RoleAssumptionSetupSchema,
  RoleInfoSchema,
  RunTaskSchema,
  RunTaskStatePayload,
  SecurityGroupSchema,
  SetDefaultOrganizationSchema,
  SetOrgGroupsSchema,
  SetupAttemptSchema,
  SizeResponseSchema,
  SoftwareEnvBuildLight,
  SoftwareEnvironmentAliasSchema,
  SoftwareEnvironmentBuildLogs,
  SoftwareEnvironmentBuildSchema,
  SoftwareEnvironmentSpecSchema,
  SoftwareEnvironmentSpecWithDownloadSchema,
  SpanSchema,
  StartBuildUploadResponseSchema,
  StartBuildUploadSchema,
  StatesResponseSchema,
  StatusSchema,
  SubnetSchema,
  SubscriptionSchema,
  TagAlertHitWithMessageSchema,
  TagLabelSchema,
  UpdateLimitsSchema,
  UpdateLimitsSchemaStaff,
  UpdateOrganizationInviteSchema,
  UpdateOrganizationMembershipSchema,
  UpdateWorkspaceInviteDirectSchema,
  UpdateWorkspaceMembershipSchema,
  UpdateWorkspaceSchema,
  UploadPackageRequestSchema,
  UploadPackageResponseSchema,
  UserFlagInteractionSchema,
  UserFlagUpdateCreateSchema,
  UserInteractionListSchema,
  UserInteractionSchema,
  UserSchema,
  VPCSchema,
  ValidScopesSchema,
  WorkerCreateSchema,
  WorkerListSchema,
  WorkerPhoneHomeResponse,
  WorkerSchema,
  WorkspaceInviteSchema,
  WorkspaceLightWithMembershipSchema,
  WorkspaceMembershipSchema,
  WorkspaceProgramUsageSchema,
  WorkspaceSchema,
} from '../models/index';
import {
    AWSAccountSettingsSchemaFromJSON,
    AWSAccountSettingsSchemaToJSON,
    AWSCredentialsSchemaFromJSON,
    AWSCredentialsSchemaToJSON,
    AWSGlobalInfraSchemaFromJSON,
    AWSGlobalInfraSchemaToJSON,
    AWSRegionalInfraSchemaFromJSON,
    AWSRegionalInfraSchemaToJSON,
    AWSRegionalInfraSchemaCreateFromJSON,
    AWSRegionalInfraSchemaCreateToJSON,
    AZSchemaFromJSON,
    AZSchemaToJSON,
    AccountLightSchemaFromJSON,
    AccountLightSchemaToJSON,
    AccountPatchSchemaFromJSON,
    AccountPatchSchemaToJSON,
    AccountUsageSchemaFromJSON,
    AccountUsageSchemaToJSON,
    AddResultSchemaFromJSON,
    AddResultSchemaToJSON,
    AddUserToOrganizationSchemaFromJSON,
    AddUserToOrganizationSchemaToJSON,
    AnalyticsProfileSchemaFromJSON,
    AnalyticsProfileSchemaToJSON,
    ApproximateSpecFromJSON,
    ApproximateSpecToJSON,
    AzureCredentialsSchemaFromJSON,
    AzureCredentialsSchemaToJSON,
    BatchJobStatusResponseFromJSON,
    BatchJobStatusResponseToJSON,
    BillingContactSchemaFromJSON,
    BillingContactSchemaToJSON,
    BillingEventListSchemaFromJSON,
    BillingEventListSchemaToJSON,
    BuildDownloadLinkFromJSON,
    BuildDownloadLinkToJSON,
    BuildPhoneHomeSchemaFromJSON,
    BuildPhoneHomeSchemaToJSON,
    CloseSchemaFromJSON,
    CloseSchemaToJSON,
    ClusterAlertsSchemaFromJSON,
    ClusterAlertsSchemaToJSON,
    ClusterAnalyticsSummaryFromJSON,
    ClusterAnalyticsSummaryToJSON,
    ClusterEventDataFromJSON,
    ClusterEventDataToJSON,
    ClusterFrontendSchemaFromJSON,
    ClusterFrontendSchemaToJSON,
    ClusterLiteSchemaFromJSON,
    ClusterLiteSchemaToJSON,
    ClusterLogInfoSchemaFromJSON,
    ClusterLogInfoSchemaToJSON,
    ClusterLogsSchemaFromJSON,
    ClusterLogsSchemaToJSON,
    ClusterMinimalStateSchemaFromJSON,
    ClusterMinimalStateSchemaToJSON,
    ClusterSSHSchemaFromJSON,
    ClusterSSHSchemaToJSON,
    ClusterSchemaFromJSON,
    ClusterSchemaToJSON,
    ClusterSummaryMetricsSchemaFromJSON,
    ClusterSummaryMetricsSchemaToJSON,
    CoiledSpanSchemaFromJSON,
    CoiledSpanSchemaToJSON,
    ComputationSchemaFromJSON,
    ComputationSchemaToJSON,
    ConfigSchemaFromJSON,
    ConfigSchemaToJSON,
    CreateBatchJobResponseFromJSON,
    CreateBatchJobResponseToJSON,
    CreateBatchJobSchemaFromJSON,
    CreateBatchJobSchemaToJSON,
    CreateBillingEventSchemaFromJSON,
    CreateBillingEventSchemaToJSON,
    CreateClusterResponseFromJSON,
    CreateClusterResponseToJSON,
    CreateClusterSchemaFromJSON,
    CreateClusterSchemaToJSON,
    CreateOrganizationSchemaFromJSON,
    CreateOrganizationSchemaToJSON,
    CreateSingleScriptClusterSchemaFromJSON,
    CreateSingleScriptClusterSchemaToJSON,
    CreateSoftwareEnvironmentSpecSchemaFromJSON,
    CreateSoftwareEnvironmentSpecSchemaToJSON,
    CreateWorkspaceSchemaFromJSON,
    CreateWorkspaceSchemaToJSON,
    CustomCertSchemaFromJSON,
    CustomCertSchemaToJSON,
    CustomCertStatusSchemaFromJSON,
    CustomCertStatusSchemaToJSON,
    CustomGroupSchemaFromJSON,
    CustomGroupSchemaToJSON,
    DashboardCertFromJSON,
    DashboardCertToJSON,
    DaskSchedulerSchemaFromJSON,
    DaskSchedulerSchemaToJSON,
    DesiredClusterStateFromJSON,
    DesiredClusterStateToJSON,
    DockerSettingsSchemaFromJSON,
    DockerSettingsSchemaToJSON,
    EventSchemaFromJSON,
    EventSchemaToJSON,
    EventsSchemaFromJSON,
    EventsSchemaToJSON,
    GCPAccountInfraCreateSchemaFromJSON,
    GCPAccountInfraCreateSchemaToJSON,
    GCPAccountInfraSchemaFromJSON,
    GCPAccountInfraSchemaToJSON,
    GCPAccountSettingsSchemaFromJSON,
    GCPAccountSettingsSchemaToJSON,
    GCPCredentialsSchemaFromJSON,
    GCPCredentialsSchemaToJSON,
    GCPRegionalInfraCreateSchemaFromJSON,
    GCPRegionalInfraCreateSchemaToJSON,
    GCPRegionalInfraSchemaFromJSON,
    GCPRegionalInfraSchemaToJSON,
    GCPSubnetSchemaFromJSON,
    GCPSubnetSchemaToJSON,
    GroupUsageSchemaFromJSON,
    GroupUsageSchemaToJSON,
    InstanceLogEventSchemaFromJSON,
    InstanceLogEventSchemaToJSON,
    InstanceSchemaFromJSON,
    InstanceSchemaToJSON,
    InstanceSpecLiteSchemaFromJSON,
    InstanceSpecLiteSchemaToJSON,
    InstanceSpecSchemaFromJSON,
    InstanceSpecSchemaToJSON,
    JobTaskListSchemaFromJSON,
    JobTaskListSchemaToJSON,
    LogEventSchemaFromJSON,
    LogEventSchemaToJSON,
    LogPageSessionRequestSchemaFromJSON,
    LogPageSessionRequestSchemaToJSON,
    LogSessionInitFromJSON,
    LogSessionInitToJSON,
    LogSessionPageFromJSON,
    LogSessionPageToJSON,
    MembershipSchemaFromJSON,
    MembershipSchemaToJSON,
    MetricSeriesFromJSON,
    MetricSeriesToJSON,
    MetricsSchemaFromJSON,
    MetricsSchemaToJSON,
    NetworkSchemaFromJSON,
    NetworkSchemaToJSON,
    OrganizationInviteSchemaFromJSON,
    OrganizationInviteSchemaToJSON,
    OrganizationListSchemaFromJSON,
    OrganizationListSchemaToJSON,
    OrganizationMembershipSchemaFromJSON,
    OrganizationMembershipSchemaToJSON,
    OrganizationProgramUsageSchemaFromJSON,
    OrganizationProgramUsageSchemaToJSON,
    OrganizationSchemaFromJSON,
    OrganizationSchemaToJSON,
    PackageApproxResultFromJSON,
    PackageApproxResultToJSON,
    PackageSchemaFromJSON,
    PackageSchemaToJSON,
    PagedBatchJobSummaryResponseFromJSON,
    PagedBatchJobSummaryResponseToJSON,
    PagedBillingEventListSchemaFromJSON,
    PagedBillingEventListSchemaToJSON,
    PagedCacheEntrySchemaFromJSON,
    PagedCacheEntrySchemaToJSON,
    PagedListClusterV2FromJSON,
    PagedListClusterV2ToJSON,
    PagedOrganizationMembershipSchemaFromJSON,
    PagedOrganizationMembershipSchemaToJSON,
    PagedOrganizationStaffListSchemaFromJSON,
    PagedOrganizationStaffListSchemaToJSON,
    PagedSoftwareEnvironmentAliasListSchemaFromJSON,
    PagedSoftwareEnvironmentAliasListSchemaToJSON,
    PagedSoftwareEnvironmentAliasSchemaFromJSON,
    PagedSoftwareEnvironmentAliasSchemaToJSON,
    PagedSoftwareEnvironmentSpecListSchemaFromJSON,
    PagedSoftwareEnvironmentSpecListSchemaToJSON,
    PagedUserInteractionListSchemaFromJSON,
    PagedUserInteractionListSchemaToJSON,
    PagedWorkspaceMemberSchemaFromJSON,
    PagedWorkspaceMemberSchemaToJSON,
    PaymentHistorySchemaFromJSON,
    PaymentHistorySchemaToJSON,
    PaymentMethodSchemaFromJSON,
    PaymentMethodSchemaToJSON,
    PhoneHomePayloadFromJSON,
    PhoneHomePayloadToJSON,
    ProgramSchemaFromJSON,
    ProgramSchemaToJSON,
    ProgramUsageSchemaFromJSON,
    ProgramUsageSchemaToJSON,
    PrometheusReadCredSchemaFromJSON,
    PrometheusReadCredSchemaToJSON,
    RegisterSchemaFromJSON,
    RegisterSchemaToJSON,
    RoleAssumptionSetupSchemaFromJSON,
    RoleAssumptionSetupSchemaToJSON,
    RoleInfoSchemaFromJSON,
    RoleInfoSchemaToJSON,
    RunTaskSchemaFromJSON,
    RunTaskSchemaToJSON,
    RunTaskStatePayloadFromJSON,
    RunTaskStatePayloadToJSON,
    SecurityGroupSchemaFromJSON,
    SecurityGroupSchemaToJSON,
    SetDefaultOrganizationSchemaFromJSON,
    SetDefaultOrganizationSchemaToJSON,
    SetOrgGroupsSchemaFromJSON,
    SetOrgGroupsSchemaToJSON,
    SetupAttemptSchemaFromJSON,
    SetupAttemptSchemaToJSON,
    SizeResponseSchemaFromJSON,
    SizeResponseSchemaToJSON,
    SoftwareEnvBuildLightFromJSON,
    SoftwareEnvBuildLightToJSON,
    SoftwareEnvironmentAliasSchemaFromJSON,
    SoftwareEnvironmentAliasSchemaToJSON,
    SoftwareEnvironmentBuildLogsFromJSON,
    SoftwareEnvironmentBuildLogsToJSON,
    SoftwareEnvironmentBuildSchemaFromJSON,
    SoftwareEnvironmentBuildSchemaToJSON,
    SoftwareEnvironmentSpecSchemaFromJSON,
    SoftwareEnvironmentSpecSchemaToJSON,
    SoftwareEnvironmentSpecWithDownloadSchemaFromJSON,
    SoftwareEnvironmentSpecWithDownloadSchemaToJSON,
    SpanSchemaFromJSON,
    SpanSchemaToJSON,
    StartBuildUploadResponseSchemaFromJSON,
    StartBuildUploadResponseSchemaToJSON,
    StartBuildUploadSchemaFromJSON,
    StartBuildUploadSchemaToJSON,
    StatesResponseSchemaFromJSON,
    StatesResponseSchemaToJSON,
    StatusSchemaFromJSON,
    StatusSchemaToJSON,
    SubnetSchemaFromJSON,
    SubnetSchemaToJSON,
    SubscriptionSchemaFromJSON,
    SubscriptionSchemaToJSON,
    TagAlertHitWithMessageSchemaFromJSON,
    TagAlertHitWithMessageSchemaToJSON,
    TagLabelSchemaFromJSON,
    TagLabelSchemaToJSON,
    UpdateLimitsSchemaFromJSON,
    UpdateLimitsSchemaToJSON,
    UpdateLimitsSchemaStaffFromJSON,
    UpdateLimitsSchemaStaffToJSON,
    UpdateOrganizationInviteSchemaFromJSON,
    UpdateOrganizationInviteSchemaToJSON,
    UpdateOrganizationMembershipSchemaFromJSON,
    UpdateOrganizationMembershipSchemaToJSON,
    UpdateWorkspaceInviteDirectSchemaFromJSON,
    UpdateWorkspaceInviteDirectSchemaToJSON,
    UpdateWorkspaceMembershipSchemaFromJSON,
    UpdateWorkspaceMembershipSchemaToJSON,
    UpdateWorkspaceSchemaFromJSON,
    UpdateWorkspaceSchemaToJSON,
    UploadPackageRequestSchemaFromJSON,
    UploadPackageRequestSchemaToJSON,
    UploadPackageResponseSchemaFromJSON,
    UploadPackageResponseSchemaToJSON,
    UserFlagInteractionSchemaFromJSON,
    UserFlagInteractionSchemaToJSON,
    UserFlagUpdateCreateSchemaFromJSON,
    UserFlagUpdateCreateSchemaToJSON,
    UserInteractionListSchemaFromJSON,
    UserInteractionListSchemaToJSON,
    UserInteractionSchemaFromJSON,
    UserInteractionSchemaToJSON,
    UserSchemaFromJSON,
    UserSchemaToJSON,
    VPCSchemaFromJSON,
    VPCSchemaToJSON,
    ValidScopesSchemaFromJSON,
    ValidScopesSchemaToJSON,
    WorkerCreateSchemaFromJSON,
    WorkerCreateSchemaToJSON,
    WorkerListSchemaFromJSON,
    WorkerListSchemaToJSON,
    WorkerPhoneHomeResponseFromJSON,
    WorkerPhoneHomeResponseToJSON,
    WorkerSchemaFromJSON,
    WorkerSchemaToJSON,
    WorkspaceInviteSchemaFromJSON,
    WorkspaceInviteSchemaToJSON,
    WorkspaceLightWithMembershipSchemaFromJSON,
    WorkspaceLightWithMembershipSchemaToJSON,
    WorkspaceMembershipSchemaFromJSON,
    WorkspaceMembershipSchemaToJSON,
    WorkspaceProgramUsageSchemaFromJSON,
    WorkspaceProgramUsageSchemaToJSON,
    WorkspaceSchemaFromJSON,
    WorkspaceSchemaToJSON,
} from '../models/index';

export interface AnalyticsViewsClusterFacingCloseRequest {
    closeSchema: CloseSchema;
}

export interface AnalyticsViewsClusterFacingEventsRequest {
    eventsSchema: EventsSchema;
}

export interface AnalyticsViewsClusterFacingMetricsRequest {
    metricsSchema: MetricsSchema;
}

export interface AnalyticsViewsClusterFacingProfileRequest {
    analyticsProfileSchema: AnalyticsProfileSchema;
}

export interface AnalyticsViewsClusterFacingRegisterRequest {
    registerSchema: RegisterSchema;
}

export interface AnalyticsViewsQueryAddCoiledSpanEndpointRequest {
    account: string;
    cluster: number;
    spanIdentifier: string;
    coiledSpanSchema: CoiledSpanSchema;
}

export interface AnalyticsViewsQueryGetAnalyticsByClusterIdRequest {
    account: string;
    cluster: number;
}

export interface AnalyticsViewsQueryGetClusterTimeseriesRequest {
    account: string;
    cluster: number;
    groupBy?: string;
    durationType?: string;
    interval?: string;
}

export interface AnalyticsViewsQueryGetCoiledClusterSummaryRequest {
    account: string;
    cluster: number;
    taskPrefixLimit?: number;
}

export interface AnalyticsViewsQueryGetDaskSchedulerSummaryRequest {
    account: string;
    daskScheduler: number;
    taskPrefixLimit?: number;
}

export interface AnalyticsViewsQueryGetDaskTimeseriesRequest {
    account: string;
    daskScheduler: number;
    groupBy?: string;
    durationType?: string;
    interval?: string;
}

export interface AnalyticsViewsQueryGetSpansRequest {
    account: string;
    cluster: number;
}

export interface AnalyticsViewsQueryListClustersRequest {
    account: string;
    since?: number;
    user?: string;
    offset?: number;
    limit?: number;
}

export interface AnalyticsViewsQueryListComputationsRequest {
    account: string;
    schedulerId: number;
    offset?: number;
    limit?: number;
}

export interface AnalyticsViewsQueryListComputationsByClusterIdRequest {
    account: string;
    cluster: number;
    offset?: number;
    limit?: number;
}

export interface AnalyticsViewsQueryListEventsRequest {
    account: string;
    cluster: number;
    offset?: number;
    limit?: number;
}

export interface AnalyticsViewsQuerySetDesiredStateRequest {
    account: string;
    cluster: number;
    desiredClusterState: DesiredClusterState;
}

export interface DeclarativeViewsCloudCredentialsAwsCredentialsDeleteRequest {
    account: string;
}

export interface DeclarativeViewsCloudCredentialsAzureCredentialsCreateRequest {
    account: string;
    azureCredentialsSchema: AzureCredentialsSchema;
}

export interface DeclarativeViewsCloudCredentialsAzureCredentialsDeleteRequest {
    account: string;
}

export interface DeclarativeViewsCloudCredentialsCallbackEndpointRequest {
    roleInfoSchema: RoleInfoSchema;
}

export interface DeclarativeViewsCloudCredentialsCheckRoleAssumptionRequest {
    account: string;
    roleInfoSchema: RoleInfoSchema;
}

export interface DeclarativeViewsCloudCredentialsCompleteRoleAssumptionSetupRequest {
    account: string;
    roleInfoSchema: RoleInfoSchema;
}

export interface DeclarativeViewsCloudCredentialsCreateCfSetupAttemptRequest {
    account: string;
}

export interface DeclarativeViewsCloudCredentialsGcpCredentialsCreateRequest {
    account: string;
    gCPCredentialsSchema: GCPCredentialsSchema;
}

export interface DeclarativeViewsCloudCredentialsGcpCredentialsDeleteRequest {
    account: string;
}

export interface DeclarativeViewsCloudCredentialsGetAwsCredentialsRequest {
    account: string;
}

export interface DeclarativeViewsCloudCredentialsGetAzureCredentialsRequest {
    account: string;
}

export interface DeclarativeViewsCloudCredentialsGetCfSetupAttemptRequest {
    account: string;
    id: number;
}

export interface DeclarativeViewsCloudCredentialsGetGcpCredentialsRequest {
    account: string;
}

export interface DeclarativeViewsCloudCredentialsPostAwsCredentialsRequest {
    account: string;
    aWSCredentialsSchema: AWSCredentialsSchema;
}

export interface DeclarativeViewsCloudCredentialsStartRoleAssumptionSetupRequest {
    account: string;
}

export interface DeclarativeViewsClusterAddClusterEventRequest {
    clusterId: number;
    clusterEventData: ClusterEventData;
}

export interface DeclarativeViewsClusterCheckCustomCertRequest {
    account: string;
    subdomain: string;
}

export interface DeclarativeViewsClusterClusterCreateRequest {
    account: string;
    createClusterSchema: CreateClusterSchema;
}

export interface DeclarativeViewsClusterClusterDeleteByIdRequest {
    account: string;
    pk: number;
    reason?: string;
    pause?: boolean;
}

export interface DeclarativeViewsClusterClusterGetByIdRequest {
    account: string;
    pk: number;
}

export interface DeclarativeViewsClusterClusterGetByIdForFrontendRequest {
    pk: number;
}

export interface DeclarativeViewsClusterClusterGetByNameRequest {
    account: string;
    name: string;
    includePaused?: boolean;
}

export interface DeclarativeViewsClusterClusterGetSizeRequest {
    account: string;
    pk: number;
    startTime?: Date;
}

export interface DeclarativeViewsClusterClusterGetStatesRequest {
    account: string;
    pk: number;
    startTime?: Date;
}

export interface DeclarativeViewsClusterClusterListRequest {
    account: string;
    name?: string;
    justMine?: boolean;
    offset?: number;
    limit?: number;
}

export interface DeclarativeViewsClusterClusterListV3Request {
    clusterName?: string;
    state?: Array<DeclarativeViewsClusterClusterListV3StateEnum>;
    tags?: Array<string>;
    minAlertLevel?: number;
    orderBy?: DeclarativeViewsClusterClusterListV3OrderByEnum;
    orderByDirection?: DeclarativeViewsClusterClusterListV3OrderByDirectionEnum;
    account?: string;
    organization?: string;
    user?: string;
    compoundScope?: DeclarativeViewsClusterClusterListV3CompoundScopeEnum;
    customStaffScope?: number;
    limit?: number;
    offset?: number;
}

export interface DeclarativeViewsClusterClusterLogInfoRequest {
    account: string;
    pk: number;
}

export interface DeclarativeViewsClusterClusterLogsQueryRequest {
    account: string;
    pk: number;
    instanceIds?: string;
    system?: boolean;
    dask?: boolean;
    sinceMs?: number;
    untilMs?: number;
    limit?: number;
    filterPattern?: string;
    pageLimit?: number;
}

export interface DeclarativeViewsClusterFacingGetFileRefByIdRequest {
    key: number;
}

export interface DeclarativeViewsClusterFacingGetInstanceStateRequest {
    backendId: string;
}

export interface DeclarativeViewsClusterFacingGetPendingRunTaskRequest {
    instanceBackendId: string;
}

export interface DeclarativeViewsClusterFacingGetPendingRunTasksRequest {
    instanceBackendId: string;
}

export interface DeclarativeViewsClusterFacingGetSoftwareBuildDownloadLinkRequest {
    build: string;
}

export interface DeclarativeViewsClusterFacingGetSoftwareBuildSpecRequest {
    build: string;
}

export interface DeclarativeViewsClusterFacingInstancePhoneHomeRequest {
    phoneHomePayload: PhoneHomePayload;
}

export interface DeclarativeViewsClusterFacingPostStartMultipartUploadRequest {
    build: string;
    startBuildUploadSchema: StartBuildUploadSchema;
}

export interface DeclarativeViewsClusterFacingSchedulerPhoneHomeRequest {
    phoneHomePayload: PhoneHomePayload;
}

export interface DeclarativeViewsClusterFacingSetRunTaskStateRequest {
    taskId: number;
    runTaskStatePayload: RunTaskStatePayload;
}

export interface DeclarativeViewsClusterFacingSoftwareBuildPhoneHomeRequest {
    build: string;
    buildPhoneHomeSchema: BuildPhoneHomeSchema;
}

export interface DeclarativeViewsClusterFacingWorkerPhoneHomeRequest {
    phoneHomePayload: PhoneHomePayload;
}

export interface DeclarativeViewsClusterGetClusterIdRequest {
    account: string;
    pk: number;
}

export interface DeclarativeViewsClusterGetClusterInfraEventsAsLogsRequest {
    account: string;
    pk: number;
}

export interface DeclarativeViewsClusterGetClusterStateRequest {
    account: string;
    pk: number;
}

export interface DeclarativeViewsClusterGetSshKeyRequest {
    account: string;
    pk: number;
    worker?: string;
}

export interface DeclarativeViewsClusterInstanceLogsGetByNameRequest {
    account: string;
    pk: number;
}

export interface DeclarativeViewsClusterMakeCustomCertRequest {
    account: string;
    customCertSchema: CustomCertSchema;
}

export interface DeclarativeViewsClusterStaffHasSeenClusterRequest {
    account: string;
    pk: number;
    seen: boolean;
}

export interface DeclarativeViewsInstanceInstanceGetByIdRequest {
    account: string;
    pk: number;
}

export interface DeclarativeViewsInstanceInstanceListRequest {
    account: string;
    offset?: number;
    limit?: number;
}

export interface DeclarativeViewsInstanceInstanceLogsGetByIdRequest {
    account: string;
    instanceId: number;
    since?: number;
    limit?: number;
}

export interface DeclarativeViewsInstanceInstanceLogsGetByNameRequest {
    account: string;
    instanceName: string;
    since?: number;
    limit?: number;
}

export interface DeclarativeViewsJobClusterDeleteByIdRequest {
    jobId: number;
}

export interface DeclarativeViewsJobCreateBatchJobViewRequest {
    createBatchJobSchema: CreateBatchJobSchema;
}

export interface DeclarativeViewsJobGetBatchJobViewRequest {
    jobId: number;
}

export interface DeclarativeViewsJobGetBatchJobsViewRequest {
    workspace?: string;
    limit?: number;
    offset?: number;
}

export interface DeclarativeViewsJobGetClusterBatchJobViewRequest {
    clusterId: number;
}

export interface DeclarativeViewsJobRunSingleScriptViewRequest {
    createSingleScriptClusterSchema: CreateSingleScriptClusterSchema;
}

export interface DeclarativeViewsLogsGetInstanceLogsRequest {
    pk: number;
}

export interface DeclarativeViewsLogsInitClusterLogsRequest {
    pk: number;
}

export interface DeclarativeViewsLogsNextClusterLogsRequest {
    pk: number;
    encodedSession: string;
}

export interface DeclarativeViewsLogsNextClusterLogsPostRequest {
    pk: number;
    logPageSessionRequestSchema: LogPageSessionRequestSchema;
}

export interface DeclarativeViewsMetricsGetClusterMetricAdhocRequest {
    account: string;
    name: string;
    query: string;
    overTime: string;
    unit?: string;
    nClusters?: number;
    clusters?: string;
    setTags?: boolean;
}

export interface DeclarativeViewsMetricsGetClusterMetricDebugStuffRequest {
    account: string;
    pk: number;
    setTags?: boolean;
}

export interface DeclarativeViewsMetricsGetClusterMetricsRequest {
    account: string;
    pk: number;
    query: string;
    sort?: string;
    start?: number;
    end?: number;
    scheduler?: boolean;
}

export interface DeclarativeViewsMetricsGetClusterMetricsValueRequest {
    account: string;
    pk: number;
    query: string;
    overTime: string;
    scheduler?: boolean;
    startTs?: number;
    endTs?: number;
}

export interface DeclarativeViewsMetricsGetClusterSummaryMetricsViewRequest {
    account: string;
    pk: number;
}

export interface DeclarativeViewsPackagesCacheListRequest {
    channelUrl?: string;
    name?: string;
    platform?: string;
    pythonVersion?: string;
    source?: DeclarativeViewsPackagesCacheListSourceEnum;
    version?: string;
    limit?: number;
    offset?: number;
}

export interface DeclarativeViewsPackagesPackagesListRequest {
    account?: string;
}

export interface DeclarativeViewsSetupCreateAwsGlobalInfraRequest {
    account: string;
}

export interface DeclarativeViewsSetupCreateAwsRegionalInfraRequest {
    account: string;
    aWSRegionalInfraSchemaCreate: AWSRegionalInfraSchemaCreate;
}

export interface DeclarativeViewsSetupCreateGcpGlobalInfraRequest {
    account: string;
    gCPAccountInfraCreateSchema: GCPAccountInfraCreateSchema;
}

export interface DeclarativeViewsSetupCreateGcpRegionalInfraRequest {
    account: string;
    gCPRegionalInfraCreateSchema: GCPRegionalInfraCreateSchema;
}

export interface DeclarativeViewsSetupDeleteAwsInfraRequest {
    account: string;
}

export interface DeclarativeViewsSetupDeleteAwsRegionInfraRequest {
    account: string;
    id: number;
}

export interface DeclarativeViewsSetupDeleteGcpInfraRequest {
    account: string;
}

export interface DeclarativeViewsSetupDeleteGcpRegionInfraRequest {
    account: string;
    id: number;
}

export interface DeclarativeViewsSetupGetAwsAccountSettingsRequest {
    account: string;
}

export interface DeclarativeViewsSetupGetAwsAvailableAzsRequest {
    account: string;
    region: string;
}

export interface DeclarativeViewsSetupGetAwsAvailableSecurityGroupsRequest {
    account: string;
    region: string;
    vpcId: string;
}

export interface DeclarativeViewsSetupGetAwsAvailableSubnetsRequest {
    account: string;
    region: string;
    vpcId: string;
}

export interface DeclarativeViewsSetupGetAwsAvailableVpcsRequest {
    account: string;
    region: string;
}

export interface DeclarativeViewsSetupGetAwsGlobalInfraRequest {
    account: string;
}

export interface DeclarativeViewsSetupGetAwsRegionalInfraRequest {
    account: string;
}

export interface DeclarativeViewsSetupGetDockerSettingsRequest {
    account: string;
}

export interface DeclarativeViewsSetupGetGcpAccountSettingsRequest {
    account: string;
}

export interface DeclarativeViewsSetupGetGcpAvailableNetworksRequest {
    account: string;
}

export interface DeclarativeViewsSetupGetGcpAvailableSubnetsRequest {
    account: string;
    region: string;
}

export interface DeclarativeViewsSetupGetGcpAvailableTargetTagsRequest {
    account: string;
    networkLink: string;
}

export interface DeclarativeViewsSetupGetGcpGlobalInfraRequest {
    account: string;
}

export interface DeclarativeViewsSetupGetGcpRegionalInfraRequest {
    account: string;
}

export interface DeclarativeViewsSetupPatchAwsAccountSettingsRequest {
    account: string;
    aWSAccountSettingsSchema: AWSAccountSettingsSchema;
}

export interface DeclarativeViewsSetupPatchDockerSettingsRequest {
    account: string;
    dockerSettingsSchema: DockerSettingsSchema;
}

export interface DeclarativeViewsSetupPatchGcpAccountSettingsRequest {
    account: string;
    gCPAccountSettingsSchema: GCPAccountSettingsSchema;
}

export interface DeclarativeViewsSoftwareEnvironmentApproximateSoftwarePackagesRequest {
    approximateSpec: ApproximateSpec;
}

export interface DeclarativeViewsSoftwareEnvironmentDeleteSoftwareEnvAliasRequest {
    account: string;
    name: string;
}

export interface DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByIdRequest {
    account: string;
    pk: number;
}

export interface DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByNameRequest {
    account: string;
    name: string;
}

export interface DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildRequest {
    account: string;
    id: number;
}

export interface DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildLogsRequest {
    account: string;
    id: number;
    limit?: number;
    pageToken?: string;
}

export interface DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecRequest {
    account: string;
    pk: number;
}

export interface DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecYamlRequest {
    account: string;
    id: number;
}

export interface DeclarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliasesRequest {
    name?: string;
    packageSync?: boolean;
    account?: string;
    organization?: string;
    user?: string;
    compoundScope?: DeclarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliasesCompoundScopeEnum;
    customStaffScope?: number;
    status?: string;
    limit?: number;
    offset?: number;
}

export interface DeclarativeViewsSoftwareEnvironmentListSoftwareAliasBuildsRequest {
    account: string;
    id: number;
}

export interface DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesRequest {
    key: DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesKeyEnum;
    startsWith: string;
}

export interface DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasSpecsRequest {
    account: string;
    pk: number;
    limit?: number;
    offset?: number;
}

export interface DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasesRequest {
    account: string;
    includePackageSync?: boolean;
    limit?: number;
    offset?: number;
}

export interface DeclarativeViewsSoftwareEnvironmentListSoftwareEnvSpecBuildsBySpecRequest {
    account: string;
    id: number;
}

export interface DeclarativeViewsSoftwareEnvironmentPackageUploadRequestRequest {
    account: string;
    uploadPackageRequestSchema: UploadPackageRequestSchema;
}

export interface DeclarativeViewsSoftwareEnvironmentSoftwareEnvCreateRequest {
    account: string;
    createSoftwareEnvironmentSpecSchema: CreateSoftwareEnvironmentSpecSchema;
}

export interface DeclarativeViewsSpecSpecGetByIdRequest {
    account: string;
    pk: number;
}

export interface DeclarativeViewsSpecSpecListRequest {
    account: string;
    offset?: number;
    limit?: number;
}

export interface DeclarativeViewsTagsGetAccountTagValuesEndpointRequest {
    account?: string;
    organization?: string;
    user?: string;
    compoundScope?: DeclarativeViewsTagsGetAccountTagValuesEndpointCompoundScopeEnum;
    customStaffScope?: number;
}

export interface DeclarativeViewsTagsGetTagListRequest {
    systemTags?: boolean;
    account?: string;
    organization?: string;
    user?: string;
    compoundScope?: DeclarativeViewsTagsGetTagListCompoundScopeEnum;
    customStaffScope?: number;
}

export interface DeclarativeViewsTestFancyPaginationTestRequest {
    offset?: number;
    limit?: number;
}

export interface DeclarativeViewsUsageGetUsageByUserRequest {
    start: Date;
    end: Date;
    unit?: DeclarativeViewsUsageGetUsageByUserUnitEnum;
    period?: DeclarativeViewsUsageGetUsageByUserPeriodEnum;
    groupby?: DeclarativeViewsUsageGetUsageByUserGroupbyEnum;
    groupbyTag?: string;
    account?: string;
    organization?: string;
    user?: string;
    compoundScope?: DeclarativeViewsUsageGetUsageByUserCompoundScopeEnum;
    customStaffScope?: number;
}

export interface DeclarativeViewsUserGetAccountOptionsRequest {
    account: string;
}

export interface DeclarativeViewsUserGetAccountRegistryRequest {
    account: string;
}

export interface DeclarativeViewsUserGetAccountUsageRequest {
    account: string;
}

export interface DeclarativeViewsUserGetPrometheusReadCredsRequest {
    account: string;
}

export interface DeclarativeViewsUserGetPublicAccountRequest {
    account: string;
}

export interface DeclarativeViewsUserPatchAccountRequest {
    account: string;
    accountPatchSchema: AccountPatchSchema;
}

export interface DeclarativeViewsWorkerWorkerGetByIdRequest {
    account: string;
    pk: number;
}

export interface DeclarativeViewsWorkerWorkersCreateRequest {
    account: string;
    cluster: number;
    workerCreateSchema: WorkerCreateSchema;
}

export interface DeclarativeViewsWorkerWorkersGetListRequest {
    account: string;
    cluster: number;
    name?: Array<string>;
    reason?: string;
    target?: number;
    offset?: number;
    limit?: number;
}

export interface DeclarativeViewsWorkerWorkersStopRequest {
    account: string;
    cluster: number;
    name?: Array<string>;
    reason?: string;
    target?: number;
}

export interface DeclarativeViewsWorkspaceListMembersRequest {
    workspace: string;
    limit?: number;
    offset?: number;
}

export interface InteractionsViewsAddInteractionRequest {
    userInteractionSchema: UserInteractionSchema;
}

export interface InteractionsViewsGetUserFlagRequest {
    name: string;
}

export interface InteractionsViewsGetUserFlagsRequest {
    name: Array<string>;
}

export interface InteractionsViewsGetUserInteractionsRequest {
    account?: string;
    organization?: string;
    user?: string;
    compoundScope?: InteractionsViewsGetUserInteractionsCompoundScopeEnum;
    customStaffScope?: number;
    timestamp?: string;
    action?: string;
    version?: number;
    source?: string;
    success?: boolean;
    errorMessage?: string;
    errorClass?: string;
    isStaff?: boolean;
    limit?: number;
    offset?: number;
}

export interface InteractionsViewsGetUserInteractionsListKeyValuesRequest {
    key: InteractionsViewsGetUserInteractionsListKeyValuesKeyEnum;
    startsWith: string;
}

export interface InteractionsViewsGetUserInteractionsListKeysRequest {
    query: string;
}

export interface InteractionsViewsUpdateGetStartedInteractionRequest {
    userFlagInteractionSchema: UserFlagInteractionSchema;
}

export interface InteractionsViewsUpdateUserFlagRequest {
    name: string;
    userFlagUpdateCreateSchema: UserFlagUpdateCreateSchema;
}

export interface PricingNinjaViewsBillingEventsCreateBillingEventRequest {
    createBillingEventSchema: CreateBillingEventSchema;
}

export interface PricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesRequest {
    key: PricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesKeyEnum;
    startsWith: string;
}

export interface PricingNinjaViewsBillingEventsListBillingEventsRequest {
    organization?: string;
    account?: string;
    organizationId?: number;
    accountId?: number;
    limit?: number;
    offset?: number;
}

export interface PricingNinjaViewsProgramDowngradeToEssentialsRequest {
    organizationId: number;
}

export interface PricingNinjaViewsProgramGetOrganizationBillingContactRequest {
    organizationId: number;
}

export interface PricingNinjaViewsProgramGetOrganizationPaymentHistoryRequest {
    organizationId: number;
}

export interface PricingNinjaViewsProgramGetOrganizationPaymentMethodRequest {
    organizationId: number;
}

export interface PricingNinjaViewsProgramGetOrganizationProgramUsageRequest {
    organizationId: number;
    startDate?: Date;
    endDate?: Date;
}

export interface PricingNinjaViewsProgramGetProgramRequest {
    organizationId: number;
    startDate?: Date;
    endDate?: Date;
}

export interface PricingNinjaViewsProgramGetProgramUsageRequest {
    organizationId: number;
    startDate?: Date;
    endDate?: Date;
}

export interface PricingNinjaViewsProgramGetWorkspaceProgramUsageRequest {
    workspaceId: number;
    startDate?: Date;
    endDate?: Date;
}

export interface PricingNinjaViewsProgramUpdateLimitsRequest {
    organizationId: number;
    updateLimitsSchema: UpdateLimitsSchema;
}

export interface PricingNinjaViewsProgramUpdateLimitsStaffRequest {
    organizationId: number;
    updateLimitsSchemaStaff: UpdateLimitsSchemaStaff;
}

export interface PricingNinjaViewsProgramUpgradeToProfessionalRequest {
    organizationId: number;
}

export interface SubscriptionsViewsSubscriptionsActiveSubscriptionsRequest {
    organizationId?: number;
    workspaceSlug?: string;
    workspaceId?: number;
}

export interface SubscriptionsViewsSubscriptionsBillingPortalRequest {
    organizationId: number;
}

export interface SubscriptionsViewsSubscriptionsCheckoutSessionRequest {
    organizationId: number;
}

export interface SubscriptionsViewsSubscriptionsGetSubscriptionRequest {
    subscriptionId: number;
}

export interface UsersViewsGroupSetOrgGroupsRequest {
    setOrgGroupsSchema: SetOrgGroupsSchema;
}

export interface UsersViewsInvitesDeleteOrganizationInviteRequest {
    inviteId: number;
}

export interface UsersViewsInvitesDeleteWorkspaceInviteRequest {
    inviteId: number;
}

export interface UsersViewsInvitesGetOrganizationInviteRequest {
    inviteId: number;
}

export interface UsersViewsInvitesOrganizationInvitesRequest {
    organizationId: number;
}

export interface UsersViewsInvitesResendOrgInviteRequest {
    inviteId: number;
}

export interface UsersViewsInvitesUpdateOrganizationInviteRequest {
    inviteId: number;
    updateOrganizationInviteSchema: UpdateOrganizationInviteSchema;
}

export interface UsersViewsInvitesUpdateWorkspaceInviteRequest {
    inviteId: number;
    updateWorkspaceInviteDirectSchema: UpdateWorkspaceInviteDirectSchema;
}

export interface UsersViewsInvitesWorkspaceInvitesRequest {
    workspaceId: number;
}

export interface UsersViewsOrganizationsOrganizationAddMemberRequest {
    addUserToOrganizationSchema: AddUserToOrganizationSchema;
}

export interface UsersViewsOrganizationsOrganizationCreateRequest {
    createOrganizationSchema: CreateOrganizationSchema;
}

export interface UsersViewsOrganizationsOrganizationDeleteRequest {
    organizationId: number;
}

export interface UsersViewsOrganizationsOrganizationDetailRequest {
    organizationId: number;
}

export interface UsersViewsOrganizationsOrganizationMembersRequest {
    organizationId: number;
    limit?: number;
    offset?: number;
}

export interface UsersViewsOrganizationsOrganizationMembershipDetailRequest {
    membershipId: number;
}

export interface UsersViewsOrganizationsOrganizationRemoveMemberRequest {
    membershipId: number;
}

export interface UsersViewsOrganizationsOrganizationUpdateMemberRequest {
    membershipId: number;
    updateOrganizationMembershipSchema: UpdateOrganizationMembershipSchema;
}

export interface UsersViewsOrganizationsOrganizationsStaffRequest {
    account?: string;
    organization?: string;
    user?: string;
    compoundScope?: UsersViewsOrganizationsOrganizationsStaffCompoundScopeEnum;
    customStaffScope?: number;
    limit?: number;
    offset?: number;
}

export interface UsersViewsOrganizationsOrganizationsStaffValuesRequest {
    startsWith?: string;
}

export interface UsersViewsUserChangeDefaultOrganizationRequest {
    setDefaultOrganizationSchema: SetDefaultOrganizationSchema;
}

export interface UsersViewsUserGetValidScopesRequest {
    account?: string;
    organization?: string;
    user?: string;
}

export interface UsersViewsWorkspacesCreateWorkspaceRequest {
    createWorkspaceSchema: CreateWorkspaceSchema;
}

export interface UsersViewsWorkspacesUpdateWorkspaceRequest {
    workspaceId: number;
    updateWorkspaceSchema: UpdateWorkspaceSchema;
}

export interface UsersViewsWorkspacesWorkspaceDetailRequest {
    workspaceSlug?: string;
    workspaceId?: number;
    workspaceName?: string;
}

export interface UsersViewsWorkspacesWorkspaceRemoveMembershipRequest {
    membershipId: number;
}

export interface UsersViewsWorkspacesWorkspaceUpdateMembershipRequest {
    membershipId: number;
    updateWorkspaceMembershipSchema: UpdateWorkspaceMembershipSchema;
}

export interface UsersViewsWorkspacesWorkspacesRequest {
    account?: string;
    organization?: string;
    user?: string;
    compoundScope?: UsersViewsWorkspacesWorkspacesCompoundScopeEnum;
    customStaffScope?: number;
    organizationId?: number;
}

export interface WorkosIntegrationViewsWorkosCodeLoginRequest {
    code: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Close
     */
    async analyticsViewsClusterFacingCloseRaw(requestParameters: AnalyticsViewsClusterFacingCloseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.closeSchema === null || requestParameters.closeSchema === undefined) {
            throw new runtime.RequiredError('closeSchema','Required parameter requestParameters.closeSchema was null or undefined when calling analyticsViewsClusterFacingClose.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/close`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CloseSchemaToJSON(requestParameters.closeSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Close
     */
    async analyticsViewsClusterFacingClose(requestParameters: AnalyticsViewsClusterFacingCloseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.analyticsViewsClusterFacingCloseRaw(requestParameters, initOverrides);
    }

    /**
     * Events
     */
    async analyticsViewsClusterFacingEventsRaw(requestParameters: AnalyticsViewsClusterFacingEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.eventsSchema === null || requestParameters.eventsSchema === undefined) {
            throw new runtime.RequiredError('eventsSchema','Required parameter requestParameters.eventsSchema was null or undefined when calling analyticsViewsClusterFacingEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/events`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EventsSchemaToJSON(requestParameters.eventsSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Events
     */
    async analyticsViewsClusterFacingEvents(requestParameters: AnalyticsViewsClusterFacingEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.analyticsViewsClusterFacingEventsRaw(requestParameters, initOverrides);
    }

    /**
     * Metrics
     */
    async analyticsViewsClusterFacingMetricsRaw(requestParameters: AnalyticsViewsClusterFacingMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.metricsSchema === null || requestParameters.metricsSchema === undefined) {
            throw new runtime.RequiredError('metricsSchema','Required parameter requestParameters.metricsSchema was null or undefined when calling analyticsViewsClusterFacingMetrics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/metrics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetricsSchemaToJSON(requestParameters.metricsSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Metrics
     */
    async analyticsViewsClusterFacingMetrics(requestParameters: AnalyticsViewsClusterFacingMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.analyticsViewsClusterFacingMetricsRaw(requestParameters, initOverrides);
    }

    /**
     * Metrics Compress
     */
    async analyticsViewsClusterFacingMetricsCompressRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/metrics-compress`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Metrics Compress
     */
    async analyticsViewsClusterFacingMetricsCompress(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.analyticsViewsClusterFacingMetricsCompressRaw(initOverrides);
    }

    /**
     * Profile
     */
    async analyticsViewsClusterFacingProfileRaw(requestParameters: AnalyticsViewsClusterFacingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.analyticsProfileSchema === null || requestParameters.analyticsProfileSchema === undefined) {
            throw new runtime.RequiredError('analyticsProfileSchema','Required parameter requestParameters.analyticsProfileSchema was null or undefined when calling analyticsViewsClusterFacingProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalyticsProfileSchemaToJSON(requestParameters.analyticsProfileSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Profile
     */
    async analyticsViewsClusterFacingProfile(requestParameters: AnalyticsViewsClusterFacingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.analyticsViewsClusterFacingProfileRaw(requestParameters, initOverrides);
    }

    /**
     * Register
     */
    async analyticsViewsClusterFacingRegisterRaw(requestParameters: AnalyticsViewsClusterFacingRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.registerSchema === null || requestParameters.registerSchema === undefined) {
            throw new runtime.RequiredError('registerSchema','Required parameter requestParameters.registerSchema was null or undefined when calling analyticsViewsClusterFacingRegister.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterSchemaToJSON(requestParameters.registerSchema),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Register
     */
    async analyticsViewsClusterFacingRegister(requestParameters: AnalyticsViewsClusterFacingRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.analyticsViewsClusterFacingRegisterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Telemetry Preload
     */
    async analyticsViewsClusterFacingTelemetryPreloadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/analytics/preload`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Telemetry Preload
     */
    async analyticsViewsClusterFacingTelemetryPreload(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.analyticsViewsClusterFacingTelemetryPreloadRaw(initOverrides);
    }

    /**
     * Add Coiled Span Endpoint
     */
    async analyticsViewsQueryAddCoiledSpanEndpointRaw(requestParameters: AnalyticsViewsQueryAddCoiledSpanEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling analyticsViewsQueryAddCoiledSpanEndpoint.');
        }

        if (requestParameters.cluster === null || requestParameters.cluster === undefined) {
            throw new runtime.RequiredError('cluster','Required parameter requestParameters.cluster was null or undefined when calling analyticsViewsQueryAddCoiledSpanEndpoint.');
        }

        if (requestParameters.spanIdentifier === null || requestParameters.spanIdentifier === undefined) {
            throw new runtime.RequiredError('spanIdentifier','Required parameter requestParameters.spanIdentifier was null or undefined when calling analyticsViewsQueryAddCoiledSpanEndpoint.');
        }

        if (requestParameters.coiledSpanSchema === null || requestParameters.coiledSpanSchema === undefined) {
            throw new runtime.RequiredError('coiledSpanSchema','Required parameter requestParameters.coiledSpanSchema was null or undefined when calling analyticsViewsQueryAddCoiledSpanEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/{account}/cluster/{cluster}/span/{span_identifier}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"cluster"}}`, encodeURIComponent(String(requestParameters.cluster))).replace(`{${"span_identifier"}}`, encodeURIComponent(String(requestParameters.spanIdentifier))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CoiledSpanSchemaToJSON(requestParameters.coiledSpanSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add Coiled Span Endpoint
     */
    async analyticsViewsQueryAddCoiledSpanEndpoint(requestParameters: AnalyticsViewsQueryAddCoiledSpanEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.analyticsViewsQueryAddCoiledSpanEndpointRaw(requestParameters, initOverrides);
    }

    /**
     * Get Analytics By Cluster Id
     */
    async analyticsViewsQueryGetAnalyticsByClusterIdRaw(requestParameters: AnalyticsViewsQueryGetAnalyticsByClusterIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DaskSchedulerSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling analyticsViewsQueryGetAnalyticsByClusterId.');
        }

        if (requestParameters.cluster === null || requestParameters.cluster === undefined) {
            throw new runtime.RequiredError('cluster','Required parameter requestParameters.cluster was null or undefined when calling analyticsViewsQueryGetAnalyticsByClusterId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/{account}/cluster/{cluster}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"cluster"}}`, encodeURIComponent(String(requestParameters.cluster))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DaskSchedulerSchemaFromJSON(jsonValue));
    }

    /**
     * Get Analytics By Cluster Id
     */
    async analyticsViewsQueryGetAnalyticsByClusterId(requestParameters: AnalyticsViewsQueryGetAnalyticsByClusterIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DaskSchedulerSchema> {
        const response = await this.analyticsViewsQueryGetAnalyticsByClusterIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Cluster Timeseries
     */
    async analyticsViewsQueryGetClusterTimeseriesRaw(requestParameters: AnalyticsViewsQueryGetClusterTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MetricSeries>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling analyticsViewsQueryGetClusterTimeseries.');
        }

        if (requestParameters.cluster === null || requestParameters.cluster === undefined) {
            throw new runtime.RequiredError('cluster','Required parameter requestParameters.cluster was null or undefined when calling analyticsViewsQueryGetClusterTimeseries.');
        }

        const queryParameters: any = {};

        if (requestParameters.groupBy !== undefined) {
            queryParameters['group_by'] = requestParameters.groupBy;
        }

        if (requestParameters.durationType !== undefined) {
            queryParameters['duration_type'] = requestParameters.durationType;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/{account}/cluster/{cluster}/timeseries`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"cluster"}}`, encodeURIComponent(String(requestParameters.cluster))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetricSeriesFromJSON));
    }

    /**
     * Get Cluster Timeseries
     */
    async analyticsViewsQueryGetClusterTimeseries(requestParameters: AnalyticsViewsQueryGetClusterTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MetricSeries>> {
        const response = await this.analyticsViewsQueryGetClusterTimeseriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Coiled Cluster Summary
     */
    async analyticsViewsQueryGetCoiledClusterSummaryRaw(requestParameters: AnalyticsViewsQueryGetCoiledClusterSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterAnalyticsSummary>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling analyticsViewsQueryGetCoiledClusterSummary.');
        }

        if (requestParameters.cluster === null || requestParameters.cluster === undefined) {
            throw new runtime.RequiredError('cluster','Required parameter requestParameters.cluster was null or undefined when calling analyticsViewsQueryGetCoiledClusterSummary.');
        }

        const queryParameters: any = {};

        if (requestParameters.taskPrefixLimit !== undefined) {
            queryParameters['task_prefix_limit'] = requestParameters.taskPrefixLimit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/{account}/cluster/{cluster}/summary`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"cluster"}}`, encodeURIComponent(String(requestParameters.cluster))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterAnalyticsSummaryFromJSON(jsonValue));
    }

    /**
     * Get Coiled Cluster Summary
     */
    async analyticsViewsQueryGetCoiledClusterSummary(requestParameters: AnalyticsViewsQueryGetCoiledClusterSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterAnalyticsSummary> {
        const response = await this.analyticsViewsQueryGetCoiledClusterSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Dask Scheduler Summary
     */
    async analyticsViewsQueryGetDaskSchedulerSummaryRaw(requestParameters: AnalyticsViewsQueryGetDaskSchedulerSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterAnalyticsSummary>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling analyticsViewsQueryGetDaskSchedulerSummary.');
        }

        if (requestParameters.daskScheduler === null || requestParameters.daskScheduler === undefined) {
            throw new runtime.RequiredError('daskScheduler','Required parameter requestParameters.daskScheduler was null or undefined when calling analyticsViewsQueryGetDaskSchedulerSummary.');
        }

        const queryParameters: any = {};

        if (requestParameters.taskPrefixLimit !== undefined) {
            queryParameters['task_prefix_limit'] = requestParameters.taskPrefixLimit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/{account}/dask/{dask_scheduler}/summary`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"dask_scheduler"}}`, encodeURIComponent(String(requestParameters.daskScheduler))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterAnalyticsSummaryFromJSON(jsonValue));
    }

    /**
     * Get Dask Scheduler Summary
     */
    async analyticsViewsQueryGetDaskSchedulerSummary(requestParameters: AnalyticsViewsQueryGetDaskSchedulerSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterAnalyticsSummary> {
        const response = await this.analyticsViewsQueryGetDaskSchedulerSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Dask Timeseries
     */
    async analyticsViewsQueryGetDaskTimeseriesRaw(requestParameters: AnalyticsViewsQueryGetDaskTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MetricSeries>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling analyticsViewsQueryGetDaskTimeseries.');
        }

        if (requestParameters.daskScheduler === null || requestParameters.daskScheduler === undefined) {
            throw new runtime.RequiredError('daskScheduler','Required parameter requestParameters.daskScheduler was null or undefined when calling analyticsViewsQueryGetDaskTimeseries.');
        }

        const queryParameters: any = {};

        if (requestParameters.groupBy !== undefined) {
            queryParameters['group_by'] = requestParameters.groupBy;
        }

        if (requestParameters.durationType !== undefined) {
            queryParameters['duration_type'] = requestParameters.durationType;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/{account}/dask/{dask_scheduler}/timeseries`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"dask_scheduler"}}`, encodeURIComponent(String(requestParameters.daskScheduler))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetricSeriesFromJSON));
    }

    /**
     * Get Dask Timeseries
     */
    async analyticsViewsQueryGetDaskTimeseries(requestParameters: AnalyticsViewsQueryGetDaskTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MetricSeries>> {
        const response = await this.analyticsViewsQueryGetDaskTimeseriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Spans
     */
    async analyticsViewsQueryGetSpansRaw(requestParameters: AnalyticsViewsQueryGetSpansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SpanSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling analyticsViewsQueryGetSpans.');
        }

        if (requestParameters.cluster === null || requestParameters.cluster === undefined) {
            throw new runtime.RequiredError('cluster','Required parameter requestParameters.cluster was null or undefined when calling analyticsViewsQueryGetSpans.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/{account}/cluster/{cluster}/spans`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"cluster"}}`, encodeURIComponent(String(requestParameters.cluster))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpanSchemaFromJSON));
    }

    /**
     * Get Spans
     */
    async analyticsViewsQueryGetSpans(requestParameters: AnalyticsViewsQueryGetSpansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SpanSchema>> {
        const response = await this.analyticsViewsQueryGetSpansRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Clusters
     */
    async analyticsViewsQueryListClustersRaw(requestParameters: AnalyticsViewsQueryListClustersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DaskSchedulerSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling analyticsViewsQueryListClusters.');
        }

        const queryParameters: any = {};

        if (requestParameters.since !== undefined) {
            queryParameters['since'] = requestParameters.since;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/{account}/clusters/list`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DaskSchedulerSchemaFromJSON));
    }

    /**
     * List Clusters
     */
    async analyticsViewsQueryListClusters(requestParameters: AnalyticsViewsQueryListClustersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DaskSchedulerSchema>> {
        const response = await this.analyticsViewsQueryListClustersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Computations
     */
    async analyticsViewsQueryListComputationsRaw(requestParameters: AnalyticsViewsQueryListComputationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ComputationSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling analyticsViewsQueryListComputations.');
        }

        if (requestParameters.schedulerId === null || requestParameters.schedulerId === undefined) {
            throw new runtime.RequiredError('schedulerId','Required parameter requestParameters.schedulerId was null or undefined when calling analyticsViewsQueryListComputations.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/{account}/{scheduler_id}/computations/list`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"scheduler_id"}}`, encodeURIComponent(String(requestParameters.schedulerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ComputationSchemaFromJSON));
    }

    /**
     * List Computations
     */
    async analyticsViewsQueryListComputations(requestParameters: AnalyticsViewsQueryListComputationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ComputationSchema>> {
        const response = await this.analyticsViewsQueryListComputationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Computations By Cluster Id
     */
    async analyticsViewsQueryListComputationsByClusterIdRaw(requestParameters: AnalyticsViewsQueryListComputationsByClusterIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ComputationSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling analyticsViewsQueryListComputationsByClusterId.');
        }

        if (requestParameters.cluster === null || requestParameters.cluster === undefined) {
            throw new runtime.RequiredError('cluster','Required parameter requestParameters.cluster was null or undefined when calling analyticsViewsQueryListComputationsByClusterId.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/{account}/cluster/{cluster}/computations/list`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"cluster"}}`, encodeURIComponent(String(requestParameters.cluster))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ComputationSchemaFromJSON));
    }

    /**
     * List Computations By Cluster Id
     */
    async analyticsViewsQueryListComputationsByClusterId(requestParameters: AnalyticsViewsQueryListComputationsByClusterIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ComputationSchema>> {
        const response = await this.analyticsViewsQueryListComputationsByClusterIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Events
     */
    async analyticsViewsQueryListEventsRaw(requestParameters: AnalyticsViewsQueryListEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EventSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling analyticsViewsQueryListEvents.');
        }

        if (requestParameters.cluster === null || requestParameters.cluster === undefined) {
            throw new runtime.RequiredError('cluster','Required parameter requestParameters.cluster was null or undefined when calling analyticsViewsQueryListEvents.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/{account}/{cluster}/events/list`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"cluster"}}`, encodeURIComponent(String(requestParameters.cluster))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventSchemaFromJSON));
    }

    /**
     * List Events
     */
    async analyticsViewsQueryListEvents(requestParameters: AnalyticsViewsQueryListEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EventSchema>> {
        const response = await this.analyticsViewsQueryListEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Desired State
     */
    async analyticsViewsQuerySetDesiredStateRaw(requestParameters: AnalyticsViewsQuerySetDesiredStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling analyticsViewsQuerySetDesiredState.');
        }

        if (requestParameters.cluster === null || requestParameters.cluster === undefined) {
            throw new runtime.RequiredError('cluster','Required parameter requestParameters.cluster was null or undefined when calling analyticsViewsQuerySetDesiredState.');
        }

        if (requestParameters.desiredClusterState === null || requestParameters.desiredClusterState === undefined) {
            throw new runtime.RequiredError('desiredClusterState','Required parameter requestParameters.desiredClusterState was null or undefined when calling analyticsViewsQuerySetDesiredState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/analytics/{account}/{cluster}/desired-state`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"cluster"}}`, encodeURIComponent(String(requestParameters.cluster))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DesiredClusterStateToJSON(requestParameters.desiredClusterState),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set Desired State
     */
    async analyticsViewsQuerySetDesiredState(requestParameters: AnalyticsViewsQuerySetDesiredStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.analyticsViewsQuerySetDesiredStateRaw(requestParameters, initOverrides);
    }

    /**
     * Get Config
     */
    async cloudViewsNinjaConfigGetConfigRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfigSchema>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/cloud/config/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigSchemaFromJSON(jsonValue));
    }

    /**
     * Get Config
     */
    async cloudViewsNinjaConfigGetConfig(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfigSchema> {
        const response = await this.cloudViewsNinjaConfigGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Aws Credentials Delete
     */
    async declarativeViewsCloudCredentialsAwsCredentialsDeleteRaw(requestParameters: DeclarativeViewsCloudCredentialsAwsCredentialsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsAwsCredentialsDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/{account}/aws`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Aws Credentials Delete
     */
    async declarativeViewsCloudCredentialsAwsCredentialsDelete(requestParameters: DeclarativeViewsCloudCredentialsAwsCredentialsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsCloudCredentialsAwsCredentialsDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Azure Credentials Create
     */
    async declarativeViewsCloudCredentialsAzureCredentialsCreateRaw(requestParameters: DeclarativeViewsCloudCredentialsAzureCredentialsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsAzureCredentialsCreate.');
        }

        if (requestParameters.azureCredentialsSchema === null || requestParameters.azureCredentialsSchema === undefined) {
            throw new runtime.RequiredError('azureCredentialsSchema','Required parameter requestParameters.azureCredentialsSchema was null or undefined when calling declarativeViewsCloudCredentialsAzureCredentialsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/{account}/azure`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AzureCredentialsSchemaToJSON(requestParameters.azureCredentialsSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Azure Credentials Create
     */
    async declarativeViewsCloudCredentialsAzureCredentialsCreate(requestParameters: DeclarativeViewsCloudCredentialsAzureCredentialsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsCloudCredentialsAzureCredentialsCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Azure Credentials Delete
     */
    async declarativeViewsCloudCredentialsAzureCredentialsDeleteRaw(requestParameters: DeclarativeViewsCloudCredentialsAzureCredentialsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsAzureCredentialsDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/{account}/azure`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Azure Credentials Delete
     */
    async declarativeViewsCloudCredentialsAzureCredentialsDelete(requestParameters: DeclarativeViewsCloudCredentialsAzureCredentialsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsCloudCredentialsAzureCredentialsDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Callback Endpoint
     */
    async declarativeViewsCloudCredentialsCallbackEndpointRaw(requestParameters: DeclarativeViewsCloudCredentialsCallbackEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roleInfoSchema === null || requestParameters.roleInfoSchema === undefined) {
            throw new runtime.RequiredError('roleInfoSchema','Required parameter requestParameters.roleInfoSchema was null or undefined when calling declarativeViewsCloudCredentialsCallbackEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v2/cloud-credentials/callback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleInfoSchemaToJSON(requestParameters.roleInfoSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback Endpoint
     */
    async declarativeViewsCloudCredentialsCallbackEndpoint(requestParameters: DeclarativeViewsCloudCredentialsCallbackEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsCloudCredentialsCallbackEndpointRaw(requestParameters, initOverrides);
    }

    /**
     * Check Role Assumption
     */
    async declarativeViewsCloudCredentialsCheckRoleAssumptionRaw(requestParameters: DeclarativeViewsCloudCredentialsCheckRoleAssumptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsCheckRoleAssumption.');
        }

        if (requestParameters.roleInfoSchema === null || requestParameters.roleInfoSchema === undefined) {
            throw new runtime.RequiredError('roleInfoSchema','Required parameter requestParameters.roleInfoSchema was null or undefined when calling declarativeViewsCloudCredentialsCheckRoleAssumption.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/account/{account}/check-role-assumption`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleInfoSchemaToJSON(requestParameters.roleInfoSchema),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Check Role Assumption
     */
    async declarativeViewsCloudCredentialsCheckRoleAssumption(requestParameters: DeclarativeViewsCloudCredentialsCheckRoleAssumptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.declarativeViewsCloudCredentialsCheckRoleAssumptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Complete Role Assumption Setup
     */
    async declarativeViewsCloudCredentialsCompleteRoleAssumptionSetupRaw(requestParameters: DeclarativeViewsCloudCredentialsCompleteRoleAssumptionSetupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsCompleteRoleAssumptionSetup.');
        }

        if (requestParameters.roleInfoSchema === null || requestParameters.roleInfoSchema === undefined) {
            throw new runtime.RequiredError('roleInfoSchema','Required parameter requestParameters.roleInfoSchema was null or undefined when calling declarativeViewsCloudCredentialsCompleteRoleAssumptionSetup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/account/{account}/complete-role-assumption-setup`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleInfoSchemaToJSON(requestParameters.roleInfoSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Complete Role Assumption Setup
     */
    async declarativeViewsCloudCredentialsCompleteRoleAssumptionSetup(requestParameters: DeclarativeViewsCloudCredentialsCompleteRoleAssumptionSetupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsCloudCredentialsCompleteRoleAssumptionSetupRaw(requestParameters, initOverrides);
    }

    /**
     * Create Cf Setup Attempt
     */
    async declarativeViewsCloudCredentialsCreateCfSetupAttemptRaw(requestParameters: DeclarativeViewsCloudCredentialsCreateCfSetupAttemptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetupAttemptSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsCreateCfSetupAttempt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/account/{account}/cf-setup-attempt`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetupAttemptSchemaFromJSON(jsonValue));
    }

    /**
     * Create Cf Setup Attempt
     */
    async declarativeViewsCloudCredentialsCreateCfSetupAttempt(requestParameters: DeclarativeViewsCloudCredentialsCreateCfSetupAttemptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetupAttemptSchema> {
        const response = await this.declarativeViewsCloudCredentialsCreateCfSetupAttemptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gcp Credentials Create
     */
    async declarativeViewsCloudCredentialsGcpCredentialsCreateRaw(requestParameters: DeclarativeViewsCloudCredentialsGcpCredentialsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsGcpCredentialsCreate.');
        }

        if (requestParameters.gCPCredentialsSchema === null || requestParameters.gCPCredentialsSchema === undefined) {
            throw new runtime.RequiredError('gCPCredentialsSchema','Required parameter requestParameters.gCPCredentialsSchema was null or undefined when calling declarativeViewsCloudCredentialsGcpCredentialsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/{account}/gcp`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GCPCredentialsSchemaToJSON(requestParameters.gCPCredentialsSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Gcp Credentials Create
     */
    async declarativeViewsCloudCredentialsGcpCredentialsCreate(requestParameters: DeclarativeViewsCloudCredentialsGcpCredentialsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsCloudCredentialsGcpCredentialsCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Gcp Credentials Delete
     */
    async declarativeViewsCloudCredentialsGcpCredentialsDeleteRaw(requestParameters: DeclarativeViewsCloudCredentialsGcpCredentialsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsGcpCredentialsDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/{account}/gcp`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Gcp Credentials Delete
     */
    async declarativeViewsCloudCredentialsGcpCredentialsDelete(requestParameters: DeclarativeViewsCloudCredentialsGcpCredentialsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsCloudCredentialsGcpCredentialsDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get Aws Credentials
     */
    async declarativeViewsCloudCredentialsGetAwsCredentialsRaw(requestParameters: DeclarativeViewsCloudCredentialsGetAwsCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AWSCredentialsSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsGetAwsCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/{account}/aws`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AWSCredentialsSchemaFromJSON(jsonValue));
    }

    /**
     * Get Aws Credentials
     */
    async declarativeViewsCloudCredentialsGetAwsCredentials(requestParameters: DeclarativeViewsCloudCredentialsGetAwsCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AWSCredentialsSchema> {
        const response = await this.declarativeViewsCloudCredentialsGetAwsCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Azure Credentials
     */
    async declarativeViewsCloudCredentialsGetAzureCredentialsRaw(requestParameters: DeclarativeViewsCloudCredentialsGetAzureCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AzureCredentialsSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsGetAzureCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/{account}/azure`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AzureCredentialsSchemaFromJSON(jsonValue));
    }

    /**
     * Get Azure Credentials
     */
    async declarativeViewsCloudCredentialsGetAzureCredentials(requestParameters: DeclarativeViewsCloudCredentialsGetAzureCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AzureCredentialsSchema> {
        const response = await this.declarativeViewsCloudCredentialsGetAzureCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Cf Setup Attempt
     */
    async declarativeViewsCloudCredentialsGetCfSetupAttemptRaw(requestParameters: DeclarativeViewsCloudCredentialsGetCfSetupAttemptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetupAttemptSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsGetCfSetupAttempt.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling declarativeViewsCloudCredentialsGetCfSetupAttempt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/account/{account}/cf-setup-attempt/{id}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetupAttemptSchemaFromJSON(jsonValue));
    }

    /**
     * Get Cf Setup Attempt
     */
    async declarativeViewsCloudCredentialsGetCfSetupAttempt(requestParameters: DeclarativeViewsCloudCredentialsGetCfSetupAttemptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetupAttemptSchema> {
        const response = await this.declarativeViewsCloudCredentialsGetCfSetupAttemptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Gcp Credentials
     */
    async declarativeViewsCloudCredentialsGetGcpCredentialsRaw(requestParameters: DeclarativeViewsCloudCredentialsGetGcpCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GCPCredentialsSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsGetGcpCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/{account}/gcp`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GCPCredentialsSchemaFromJSON(jsonValue));
    }

    /**
     * Get Gcp Credentials
     */
    async declarativeViewsCloudCredentialsGetGcpCredentials(requestParameters: DeclarativeViewsCloudCredentialsGetGcpCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GCPCredentialsSchema> {
        const response = await this.declarativeViewsCloudCredentialsGetGcpCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Post Aws Credentials
     */
    async declarativeViewsCloudCredentialsPostAwsCredentialsRaw(requestParameters: DeclarativeViewsCloudCredentialsPostAwsCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AWSCredentialsSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsPostAwsCredentials.');
        }

        if (requestParameters.aWSCredentialsSchema === null || requestParameters.aWSCredentialsSchema === undefined) {
            throw new runtime.RequiredError('aWSCredentialsSchema','Required parameter requestParameters.aWSCredentialsSchema was null or undefined when calling declarativeViewsCloudCredentialsPostAwsCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/{account}/aws`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AWSCredentialsSchemaToJSON(requestParameters.aWSCredentialsSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AWSCredentialsSchemaFromJSON(jsonValue));
    }

    /**
     * Post Aws Credentials
     */
    async declarativeViewsCloudCredentialsPostAwsCredentials(requestParameters: DeclarativeViewsCloudCredentialsPostAwsCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AWSCredentialsSchema> {
        const response = await this.declarativeViewsCloudCredentialsPostAwsCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This creates an external ID (and DB record) required for AWS cross-account role assumption.  This flow is for creating a non-expiring DB record, and authentication should be required when completing the flow by submitting the ARN of role to assume in user account. CloudFormation flow, which does not use auth when submitting the ARN, and instead matches Coiled account based on the external ID, should not use the external ID/DB record created here.
     * Start Role Assumption Setup
     */
    async declarativeViewsCloudCredentialsStartRoleAssumptionSetupRaw(requestParameters: DeclarativeViewsCloudCredentialsStartRoleAssumptionSetupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleAssumptionSetupSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsCloudCredentialsStartRoleAssumptionSetup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/cloud-credentials/account/{account}/start-role-assumption-setup`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleAssumptionSetupSchemaFromJSON(jsonValue));
    }

    /**
     * This creates an external ID (and DB record) required for AWS cross-account role assumption.  This flow is for creating a non-expiring DB record, and authentication should be required when completing the flow by submitting the ARN of role to assume in user account. CloudFormation flow, which does not use auth when submitting the ARN, and instead matches Coiled account based on the external ID, should not use the external ID/DB record created here.
     * Start Role Assumption Setup
     */
    async declarativeViewsCloudCredentialsStartRoleAssumptionSetup(requestParameters: DeclarativeViewsCloudCredentialsStartRoleAssumptionSetupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleAssumptionSetupSchema> {
        const response = await this.declarativeViewsCloudCredentialsStartRoleAssumptionSetupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add Cluster Event
     */
    async declarativeViewsClusterAddClusterEventRaw(requestParameters: DeclarativeViewsClusterAddClusterEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId','Required parameter requestParameters.clusterId was null or undefined when calling declarativeViewsClusterAddClusterEvent.');
        }

        if (requestParameters.clusterEventData === null || requestParameters.clusterEventData === undefined) {
            throw new runtime.RequiredError('clusterEventData','Required parameter requestParameters.clusterEventData was null or undefined when calling declarativeViewsClusterAddClusterEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/id/{cluster_id}/event`.replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClusterEventDataToJSON(requestParameters.clusterEventData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add Cluster Event
     */
    async declarativeViewsClusterAddClusterEvent(requestParameters: DeclarativeViewsClusterAddClusterEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsClusterAddClusterEventRaw(requestParameters, initOverrides);
    }

    /**
     * Check Custom Cert
     */
    async declarativeViewsClusterCheckCustomCertRaw(requestParameters: DeclarativeViewsClusterCheckCustomCertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomCertStatusSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterCheckCustomCert.');
        }

        if (requestParameters.subdomain === null || requestParameters.subdomain === undefined) {
            throw new runtime.RequiredError('subdomain','Required parameter requestParameters.subdomain was null or undefined when calling declarativeViewsClusterCheckCustomCert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/https-certificate/{subdomain}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"subdomain"}}`, encodeURIComponent(String(requestParameters.subdomain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomCertStatusSchemaFromJSON(jsonValue));
    }

    /**
     * Check Custom Cert
     */
    async declarativeViewsClusterCheckCustomCert(requestParameters: DeclarativeViewsClusterCheckCustomCertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomCertStatusSchema> {
        const response = await this.declarativeViewsClusterCheckCustomCertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cluster Create
     */
    async declarativeViewsClusterClusterCreateRaw(requestParameters: DeclarativeViewsClusterClusterCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateClusterResponse>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterClusterCreate.');
        }

        if (requestParameters.createClusterSchema === null || requestParameters.createClusterSchema === undefined) {
            throw new runtime.RequiredError('createClusterSchema','Required parameter requestParameters.createClusterSchema was null or undefined when calling declarativeViewsClusterClusterCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateClusterSchemaToJSON(requestParameters.createClusterSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateClusterResponseFromJSON(jsonValue));
    }

    /**
     * Cluster Create
     */
    async declarativeViewsClusterClusterCreate(requestParameters: DeclarativeViewsClusterClusterCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateClusterResponse> {
        const response = await this.declarativeViewsClusterClusterCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cluster Delete By Id
     */
    async declarativeViewsClusterClusterDeleteByIdRaw(requestParameters: DeclarativeViewsClusterClusterDeleteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterLiteSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterClusterDeleteById.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterClusterDeleteById.');
        }

        const queryParameters: any = {};

        if (requestParameters.reason !== undefined) {
            queryParameters['reason'] = requestParameters.reason;
        }

        if (requestParameters.pause !== undefined) {
            queryParameters['pause'] = requestParameters.pause;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/id/{pk}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterLiteSchemaFromJSON(jsonValue));
    }

    /**
     * Cluster Delete By Id
     */
    async declarativeViewsClusterClusterDeleteById(requestParameters: DeclarativeViewsClusterClusterDeleteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterLiteSchema> {
        const response = await this.declarativeViewsClusterClusterDeleteByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cluster Get By Id
     */
    async declarativeViewsClusterClusterGetByIdRaw(requestParameters: DeclarativeViewsClusterClusterGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterClusterGetById.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterClusterGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/id/{pk}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterSchemaFromJSON(jsonValue));
    }

    /**
     * Cluster Get By Id
     */
    async declarativeViewsClusterClusterGetById(requestParameters: DeclarativeViewsClusterClusterGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterSchema> {
        const response = await this.declarativeViewsClusterClusterGetByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cluster Get By Id For Frontend
     */
    async declarativeViewsClusterClusterGetByIdForFrontendRaw(requestParameters: DeclarativeViewsClusterClusterGetByIdForFrontendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterFrontendSchema>> {
        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterClusterGetByIdForFrontend.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/id/{pk}/frontend`.replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterFrontendSchemaFromJSON(jsonValue));
    }

    /**
     * Cluster Get By Id For Frontend
     */
    async declarativeViewsClusterClusterGetByIdForFrontend(requestParameters: DeclarativeViewsClusterClusterGetByIdForFrontendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterFrontendSchema> {
        const response = await this.declarativeViewsClusterClusterGetByIdForFrontendRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cluster Get By Name
     */
    async declarativeViewsClusterClusterGetByNameRaw(requestParameters: DeclarativeViewsClusterClusterGetByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterLiteSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterClusterGetByName.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling declarativeViewsClusterClusterGetByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.includePaused !== undefined) {
            queryParameters['include_paused'] = requestParameters.includePaused;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/name/{name}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterLiteSchemaFromJSON(jsonValue));
    }

    /**
     * Cluster Get By Name
     */
    async declarativeViewsClusterClusterGetByName(requestParameters: DeclarativeViewsClusterClusterGetByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterLiteSchema> {
        const response = await this.declarativeViewsClusterClusterGetByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cluster Get Size
     */
    async declarativeViewsClusterClusterGetSizeRaw(requestParameters: DeclarativeViewsClusterClusterGetSizeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SizeResponseSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterClusterGetSize.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterClusterGetSize.');
        }

        const queryParameters: any = {};

        if (requestParameters.startTime !== undefined) {
            queryParameters['start_time'] = (requestParameters.startTime as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/id/{pk}/size`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SizeResponseSchemaFromJSON));
    }

    /**
     * Cluster Get Size
     */
    async declarativeViewsClusterClusterGetSize(requestParameters: DeclarativeViewsClusterClusterGetSizeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SizeResponseSchema>> {
        const response = await this.declarativeViewsClusterClusterGetSizeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cluster Get States
     */
    async declarativeViewsClusterClusterGetStatesRaw(requestParameters: DeclarativeViewsClusterClusterGetStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatesResponseSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterClusterGetStates.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterClusterGetStates.');
        }

        const queryParameters: any = {};

        if (requestParameters.startTime !== undefined) {
            queryParameters['start_time'] = (requestParameters.startTime as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/id/{pk}/states`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatesResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Cluster Get States
     */
    async declarativeViewsClusterClusterGetStates(requestParameters: DeclarativeViewsClusterClusterGetStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatesResponseSchema> {
        const response = await this.declarativeViewsClusterClusterGetStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cluster List
     */
    async declarativeViewsClusterClusterListRaw(requestParameters: DeclarativeViewsClusterClusterListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClusterLiteSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterClusterList.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.justMine !== undefined) {
            queryParameters['just_mine'] = requestParameters.justMine;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClusterLiteSchemaFromJSON));
    }

    /**
     * Cluster List
     */
    async declarativeViewsClusterClusterList(requestParameters: DeclarativeViewsClusterClusterListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClusterLiteSchema>> {
        const response = await this.declarativeViewsClusterClusterListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cluster List V3
     */
    async declarativeViewsClusterClusterListV3Raw(requestParameters: DeclarativeViewsClusterClusterListV3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedListClusterV2>> {
        const queryParameters: any = {};

        if (requestParameters.clusterName !== undefined) {
            queryParameters['cluster_name'] = requestParameters.clusterName;
        }

        if (requestParameters.state) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.minAlertLevel !== undefined) {
            queryParameters['min_alert_level'] = requestParameters.minAlertLevel;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }

        if (requestParameters.orderByDirection !== undefined) {
            queryParameters['order_by_direction'] = requestParameters.orderByDirection;
        }

        if (requestParameters.account !== undefined) {
            queryParameters['account'] = requestParameters.account;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.compoundScope !== undefined) {
            queryParameters['compound_scope'] = requestParameters.compoundScope;
        }

        if (requestParameters.customStaffScope !== undefined) {
            queryParameters['custom_staff_scope'] = requestParameters.customStaffScope;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/paginate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedListClusterV2FromJSON(jsonValue));
    }

    /**
     * Cluster List V3
     */
    async declarativeViewsClusterClusterListV3(requestParameters: DeclarativeViewsClusterClusterListV3Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedListClusterV2> {
        const response = await this.declarativeViewsClusterClusterListV3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cluster Log Info
     */
    async declarativeViewsClusterClusterLogInfoRaw(requestParameters: DeclarativeViewsClusterClusterLogInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterLogInfoSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterClusterLogInfo.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterClusterLogInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/id/{pk}/log-info`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterLogInfoSchemaFromJSON(jsonValue));
    }

    /**
     * Cluster Log Info
     */
    async declarativeViewsClusterClusterLogInfo(requestParameters: DeclarativeViewsClusterClusterLogInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterLogInfoSchema> {
        const response = await this.declarativeViewsClusterClusterLogInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cluster Logs Query
     */
    async declarativeViewsClusterClusterLogsQueryRaw(requestParameters: DeclarativeViewsClusterClusterLogsQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InstanceLogEventSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterClusterLogsQuery.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterClusterLogsQuery.');
        }

        const queryParameters: any = {};

        if (requestParameters.instanceIds !== undefined) {
            queryParameters['instance_ids'] = requestParameters.instanceIds;
        }

        if (requestParameters.system !== undefined) {
            queryParameters['system'] = requestParameters.system;
        }

        if (requestParameters.dask !== undefined) {
            queryParameters['dask'] = requestParameters.dask;
        }

        if (requestParameters.sinceMs !== undefined) {
            queryParameters['since_ms'] = requestParameters.sinceMs;
        }

        if (requestParameters.untilMs !== undefined) {
            queryParameters['until_ms'] = requestParameters.untilMs;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.filterPattern !== undefined) {
            queryParameters['filter_pattern'] = requestParameters.filterPattern;
        }

        if (requestParameters.pageLimit !== undefined) {
            queryParameters['page_limit'] = requestParameters.pageLimit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/id/{pk}/better-logs`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InstanceLogEventSchemaFromJSON));
    }

    /**
     * Cluster Logs Query
     */
    async declarativeViewsClusterClusterLogsQuery(requestParameters: DeclarativeViewsClusterClusterLogsQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InstanceLogEventSchema>> {
        const response = await this.declarativeViewsClusterClusterLogsQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Aws Creds Plugin Preload
     */
    async declarativeViewsClusterFacingAwsCredsPluginPreloadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/cluster_facing/preload/aws-creds-loader`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Aws Creds Plugin Preload
     */
    async declarativeViewsClusterFacingAwsCredsPluginPreload(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsClusterFacingAwsCredsPluginPreloadRaw(initOverrides);
    }

    /**
     * Aws Spot Plugin Preload
     */
    async declarativeViewsClusterFacingAwsSpotPluginPreloadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/cluster_facing/preload/aws-spot`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Aws Spot Plugin Preload
     */
    async declarativeViewsClusterFacingAwsSpotPluginPreload(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsClusterFacingAwsSpotPluginPreloadRaw(initOverrides);
    }

    /**
     * Gcp Spot Plugin Preload
     */
    async declarativeViewsClusterFacingGcpSpotPluginPreloadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/cluster_facing/preload/gcp-spot`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Gcp Spot Plugin Preload
     */
    async declarativeViewsClusterFacingGcpSpotPluginPreload(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsClusterFacingGcpSpotPluginPreloadRaw(initOverrides);
    }

    /**
     * Get File Ref By Id
     */
    async declarativeViewsClusterFacingGetFileRefByIdRaw(requestParameters: DeclarativeViewsClusterFacingGetFileRefByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling declarativeViewsClusterFacingGetFileRefById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/db-file-ref/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get File Ref By Id
     */
    async declarativeViewsClusterFacingGetFileRefById(requestParameters: DeclarativeViewsClusterFacingGetFileRefByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.declarativeViewsClusterFacingGetFileRefByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Instance State
     */
    async declarativeViewsClusterFacingGetInstanceStateRaw(requestParameters: DeclarativeViewsClusterFacingGetInstanceStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.backendId === null || requestParameters.backendId === undefined) {
            throw new runtime.RequiredError('backendId','Required parameter requestParameters.backendId was null or undefined when calling declarativeViewsClusterFacingGetInstanceState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/instance/{backend_id}/state`.replace(`{${"backend_id"}}`, encodeURIComponent(String(requestParameters.backendId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Instance State
     */
    async declarativeViewsClusterFacingGetInstanceState(requestParameters: DeclarativeViewsClusterFacingGetInstanceStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.declarativeViewsClusterFacingGetInstanceStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Pending Run Task
     */
    async declarativeViewsClusterFacingGetPendingRunTaskRaw(requestParameters: DeclarativeViewsClusterFacingGetPendingRunTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RunTaskSchema>> {
        if (requestParameters.instanceBackendId === null || requestParameters.instanceBackendId === undefined) {
            throw new runtime.RequiredError('instanceBackendId','Required parameter requestParameters.instanceBackendId was null or undefined when calling declarativeViewsClusterFacingGetPendingRunTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/pending-run-task/{instance_backend_id}`.replace(`{${"instance_backend_id"}}`, encodeURIComponent(String(requestParameters.instanceBackendId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RunTaskSchemaFromJSON(jsonValue));
    }

    /**
     * Get Pending Run Task
     */
    async declarativeViewsClusterFacingGetPendingRunTask(requestParameters: DeclarativeViewsClusterFacingGetPendingRunTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RunTaskSchema> {
        const response = await this.declarativeViewsClusterFacingGetPendingRunTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Pending Run Tasks
     */
    async declarativeViewsClusterFacingGetPendingRunTasksRaw(requestParameters: DeclarativeViewsClusterFacingGetPendingRunTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobTaskListSchema>> {
        if (requestParameters.instanceBackendId === null || requestParameters.instanceBackendId === undefined) {
            throw new runtime.RequiredError('instanceBackendId','Required parameter requestParameters.instanceBackendId was null or undefined when calling declarativeViewsClusterFacingGetPendingRunTasks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/pending-run-tasks/{instance_backend_id}`.replace(`{${"instance_backend_id"}}`, encodeURIComponent(String(requestParameters.instanceBackendId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobTaskListSchemaFromJSON(jsonValue));
    }

    /**
     * Get Pending Run Tasks
     */
    async declarativeViewsClusterFacingGetPendingRunTasks(requestParameters: DeclarativeViewsClusterFacingGetPendingRunTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobTaskListSchema> {
        const response = await this.declarativeViewsClusterFacingGetPendingRunTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Software Build Download Link
     */
    async declarativeViewsClusterFacingGetSoftwareBuildDownloadLinkRaw(requestParameters: DeclarativeViewsClusterFacingGetSoftwareBuildDownloadLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuildDownloadLink>> {
        if (requestParameters.build === null || requestParameters.build === undefined) {
            throw new runtime.RequiredError('build','Required parameter requestParameters.build was null or undefined when calling declarativeViewsClusterFacingGetSoftwareBuildDownloadLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/software-environment/build/{build}/download`.replace(`{${"build"}}`, encodeURIComponent(String(requestParameters.build))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildDownloadLinkFromJSON(jsonValue));
    }

    /**
     * Get Software Build Download Link
     */
    async declarativeViewsClusterFacingGetSoftwareBuildDownloadLink(requestParameters: DeclarativeViewsClusterFacingGetSoftwareBuildDownloadLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuildDownloadLink> {
        const response = await this.declarativeViewsClusterFacingGetSoftwareBuildDownloadLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Software Build Spec
     */
    async declarativeViewsClusterFacingGetSoftwareBuildSpecRaw(requestParameters: DeclarativeViewsClusterFacingGetSoftwareBuildSpecRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SoftwareEnvironmentSpecWithDownloadSchema>> {
        if (requestParameters.build === null || requestParameters.build === undefined) {
            throw new runtime.RequiredError('build','Required parameter requestParameters.build was null or undefined when calling declarativeViewsClusterFacingGetSoftwareBuildSpec.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SoftwareAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/software-environment/build/{build}/spec`.replace(`{${"build"}}`, encodeURIComponent(String(requestParameters.build))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SoftwareEnvironmentSpecWithDownloadSchemaFromJSON(jsonValue));
    }

    /**
     * Get Software Build Spec
     */
    async declarativeViewsClusterFacingGetSoftwareBuildSpec(requestParameters: DeclarativeViewsClusterFacingGetSoftwareBuildSpecRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SoftwareEnvironmentSpecWithDownloadSchema> {
        const response = await this.declarativeViewsClusterFacingGetSoftwareBuildSpecRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Phone home from Coiled agent running on host VM.  There are many different types of phone homes, these each have different `status` string: - health-ping - starting-up - shutting-down - spot-interruption (this one comes from a dask plugin) - docker-pull - docker-stopped
     * Instance Phone Home
     */
    async declarativeViewsClusterFacingInstancePhoneHomeRaw(requestParameters: DeclarativeViewsClusterFacingInstancePhoneHomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.phoneHomePayload === null || requestParameters.phoneHomePayload === undefined) {
            throw new runtime.RequiredError('phoneHomePayload','Required parameter requestParameters.phoneHomePayload was null or undefined when calling declarativeViewsClusterFacingInstancePhoneHome.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/instance/phone-home/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PhoneHomePayloadToJSON(requestParameters.phoneHomePayload),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Phone home from Coiled agent running on host VM.  There are many different types of phone homes, these each have different `status` string: - health-ping - starting-up - shutting-down - spot-interruption (this one comes from a dask plugin) - docker-pull - docker-stopped
     * Instance Phone Home
     */
    async declarativeViewsClusterFacingInstancePhoneHome(requestParameters: DeclarativeViewsClusterFacingInstancePhoneHomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.declarativeViewsClusterFacingInstancePhoneHomeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * No Client Plugin Preload
     */
    async declarativeViewsClusterFacingNoClientPluginPreloadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/cluster_facing/preload/no-client-shutdown`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * No Client Plugin Preload
     */
    async declarativeViewsClusterFacingNoClientPluginPreload(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsClusterFacingNoClientPluginPreloadRaw(initOverrides);
    }

    /**
     * Post Start Multipart Upload
     */
    async declarativeViewsClusterFacingPostStartMultipartUploadRaw(requestParameters: DeclarativeViewsClusterFacingPostStartMultipartUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StartBuildUploadResponseSchema>> {
        if (requestParameters.build === null || requestParameters.build === undefined) {
            throw new runtime.RequiredError('build','Required parameter requestParameters.build was null or undefined when calling declarativeViewsClusterFacingPostStartMultipartUpload.');
        }

        if (requestParameters.startBuildUploadSchema === null || requestParameters.startBuildUploadSchema === undefined) {
            throw new runtime.RequiredError('startBuildUploadSchema','Required parameter requestParameters.startBuildUploadSchema was null or undefined when calling declarativeViewsClusterFacingPostStartMultipartUpload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SoftwareAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/software-environment/build/{build}/start-upload`.replace(`{${"build"}}`, encodeURIComponent(String(requestParameters.build))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartBuildUploadSchemaToJSON(requestParameters.startBuildUploadSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StartBuildUploadResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Post Start Multipart Upload
     */
    async declarativeViewsClusterFacingPostStartMultipartUpload(requestParameters: DeclarativeViewsClusterFacingPostStartMultipartUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StartBuildUploadResponseSchema> {
        const response = await this.declarativeViewsClusterFacingPostStartMultipartUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scheduler Dashboard Cert
     */
    async declarativeViewsClusterFacingSchedulerDashboardCertRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardCert>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/scheduler/dashboard/cert`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardCertFromJSON(jsonValue));
    }

    /**
     * Scheduler Dashboard Cert
     */
    async declarativeViewsClusterFacingSchedulerDashboardCert(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardCert> {
        const response = await this.declarativeViewsClusterFacingSchedulerDashboardCertRaw(initOverrides);
        return await response.value();
    }

    /**
     * Scheduler Phone Home
     */
    async declarativeViewsClusterFacingSchedulerPhoneHomeRaw(requestParameters: DeclarativeViewsClusterFacingSchedulerPhoneHomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.phoneHomePayload === null || requestParameters.phoneHomePayload === undefined) {
            throw new runtime.RequiredError('phoneHomePayload','Required parameter requestParameters.phoneHomePayload was null or undefined when calling declarativeViewsClusterFacingSchedulerPhoneHome.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/scheduler/phone-home/instance`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PhoneHomePayloadToJSON(requestParameters.phoneHomePayload),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Scheduler Phone Home
     */
    async declarativeViewsClusterFacingSchedulerPhoneHome(requestParameters: DeclarativeViewsClusterFacingSchedulerPhoneHomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsClusterFacingSchedulerPhoneHomeRaw(requestParameters, initOverrides);
    }

    /**
     * Scheduler Preload
     */
    async declarativeViewsClusterFacingSchedulerPreloadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/cluster_facing/preload/scheduler`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Scheduler Preload
     */
    async declarativeViewsClusterFacingSchedulerPreload(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsClusterFacingSchedulerPreloadRaw(initOverrides);
    }

    /**
     * Set Run Task State
     */
    async declarativeViewsClusterFacingSetRunTaskStateRaw(requestParameters: DeclarativeViewsClusterFacingSetRunTaskStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling declarativeViewsClusterFacingSetRunTaskState.');
        }

        if (requestParameters.runTaskStatePayload === null || requestParameters.runTaskStatePayload === undefined) {
            throw new runtime.RequiredError('runTaskStatePayload','Required parameter requestParameters.runTaskStatePayload was null or undefined when calling declarativeViewsClusterFacingSetRunTaskState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/run-task/{task_id}`.replace(`{${"task_id"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RunTaskStatePayloadToJSON(requestParameters.runTaskStatePayload),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set Run Task State
     */
    async declarativeViewsClusterFacingSetRunTaskState(requestParameters: DeclarativeViewsClusterFacingSetRunTaskStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsClusterFacingSetRunTaskStateRaw(requestParameters, initOverrides);
    }

    /**
     * Software Build Phone Home
     */
    async declarativeViewsClusterFacingSoftwareBuildPhoneHomeRaw(requestParameters: DeclarativeViewsClusterFacingSoftwareBuildPhoneHomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.build === null || requestParameters.build === undefined) {
            throw new runtime.RequiredError('build','Required parameter requestParameters.build was null or undefined when calling declarativeViewsClusterFacingSoftwareBuildPhoneHome.');
        }

        if (requestParameters.buildPhoneHomeSchema === null || requestParameters.buildPhoneHomeSchema === undefined) {
            throw new runtime.RequiredError('buildPhoneHomeSchema','Required parameter requestParameters.buildPhoneHomeSchema was null or undefined when calling declarativeViewsClusterFacingSoftwareBuildPhoneHome.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SoftwareAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/software-environment/build/{build}`.replace(`{${"build"}}`, encodeURIComponent(String(requestParameters.build))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BuildPhoneHomeSchemaToJSON(requestParameters.buildPhoneHomeSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Software Build Phone Home
     */
    async declarativeViewsClusterFacingSoftwareBuildPhoneHome(requestParameters: DeclarativeViewsClusterFacingSoftwareBuildPhoneHomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsClusterFacingSoftwareBuildPhoneHomeRaw(requestParameters, initOverrides);
    }

    /**
     * Phone home from the dask worker plugin, it\'s how worker gets IP address for scheduler.
     * Worker Phone Home
     */
    async declarativeViewsClusterFacingWorkerPhoneHomeRaw(requestParameters: DeclarativeViewsClusterFacingWorkerPhoneHomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkerPhoneHomeResponse>> {
        if (requestParameters.phoneHomePayload === null || requestParameters.phoneHomePayload === undefined) {
            throw new runtime.RequiredError('phoneHomePayload','Required parameter requestParameters.phoneHomePayload was null or undefined when calling declarativeViewsClusterFacingWorkerPhoneHome.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("ClusterAuthToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/cluster_facing/worker/phone-home/instance/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PhoneHomePayloadToJSON(requestParameters.phoneHomePayload),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkerPhoneHomeResponseFromJSON(jsonValue));
    }

    /**
     * Phone home from the dask worker plugin, it\'s how worker gets IP address for scheduler.
     * Worker Phone Home
     */
    async declarativeViewsClusterFacingWorkerPhoneHome(requestParameters: DeclarativeViewsClusterFacingWorkerPhoneHomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkerPhoneHomeResponse> {
        const response = await this.declarativeViewsClusterFacingWorkerPhoneHomeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Worker Preload
     */
    async declarativeViewsClusterFacingWorkerPreloadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/cluster_facing/preload/worker`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Worker Preload
     */
    async declarativeViewsClusterFacingWorkerPreload(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsClusterFacingWorkerPreloadRaw(initOverrides);
    }

    /**
     * Get Cluster Id
     */
    async declarativeViewsClusterGetClusterIdRaw(requestParameters: DeclarativeViewsClusterGetClusterIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClusterAlertsSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterGetClusterId.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterGetClusterId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/id/{pk}/alerts`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClusterAlertsSchemaFromJSON));
    }

    /**
     * Get Cluster Id
     */
    async declarativeViewsClusterGetClusterId(requestParameters: DeclarativeViewsClusterGetClusterIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClusterAlertsSchema>> {
        const response = await this.declarativeViewsClusterGetClusterIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Cluster Infra Events As Logs
     */
    async declarativeViewsClusterGetClusterInfraEventsAsLogsRaw(requestParameters: DeclarativeViewsClusterGetClusterInfraEventsAsLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InstanceLogEventSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterGetClusterInfraEventsAsLogs.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterGetClusterInfraEventsAsLogs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/id/{pk}/infra-events-log`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InstanceLogEventSchemaFromJSON));
    }

    /**
     * Get Cluster Infra Events As Logs
     */
    async declarativeViewsClusterGetClusterInfraEventsAsLogs(requestParameters: DeclarativeViewsClusterGetClusterInfraEventsAsLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InstanceLogEventSchema>> {
        const response = await this.declarativeViewsClusterGetClusterInfraEventsAsLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Cluster State
     */
    async declarativeViewsClusterGetClusterStateRaw(requestParameters: DeclarativeViewsClusterGetClusterStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterMinimalStateSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterGetClusterState.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterGetClusterState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/id/{pk}/state`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterMinimalStateSchemaFromJSON(jsonValue));
    }

    /**
     * Get Cluster State
     */
    async declarativeViewsClusterGetClusterState(requestParameters: DeclarativeViewsClusterGetClusterStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterMinimalStateSchema> {
        const response = await this.declarativeViewsClusterGetClusterStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Ssh Key
     */
    async declarativeViewsClusterGetSshKeyRaw(requestParameters: DeclarativeViewsClusterGetSshKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterSSHSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterGetSshKey.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterGetSshKey.');
        }

        const queryParameters: any = {};

        if (requestParameters.worker !== undefined) {
            queryParameters['worker'] = requestParameters.worker;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/id/{pk}/ssh-key`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterSSHSchemaFromJSON(jsonValue));
    }

    /**
     * Get Ssh Key
     */
    async declarativeViewsClusterGetSshKey(requestParameters: DeclarativeViewsClusterGetSshKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterSSHSchema> {
        const response = await this.declarativeViewsClusterGetSshKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This route is a bit of a hack to mimic the legacy (pre-declarative) logs retrieval route and avoid needing to write new frontend code.  It should definitely not be used outside of that one context, and even that will hopefully go away when we figure out something we like better.
     * Instance Logs Get By Name
     */
    async declarativeViewsClusterInstanceLogsGetByNameRaw(requestParameters: DeclarativeViewsClusterInstanceLogsGetByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterLogsSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterInstanceLogsGetByName.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterInstanceLogsGetByName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/id/{pk}/logs`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterLogsSchemaFromJSON(jsonValue));
    }

    /**
     * This route is a bit of a hack to mimic the legacy (pre-declarative) logs retrieval route and avoid needing to write new frontend code.  It should definitely not be used outside of that one context, and even that will hopefully go away when we figure out something we like better.
     * Instance Logs Get By Name
     */
    async declarativeViewsClusterInstanceLogsGetByName(requestParameters: DeclarativeViewsClusterInstanceLogsGetByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterLogsSchema> {
        const response = await this.declarativeViewsClusterInstanceLogsGetByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Make Custom Cert
     */
    async declarativeViewsClusterMakeCustomCertRaw(requestParameters: DeclarativeViewsClusterMakeCustomCertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterMakeCustomCert.');
        }

        if (requestParameters.customCertSchema === null || requestParameters.customCertSchema === undefined) {
            throw new runtime.RequiredError('customCertSchema','Required parameter requestParameters.customCertSchema was null or undefined when calling declarativeViewsClusterMakeCustomCert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/https-certificate`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomCertSchemaToJSON(requestParameters.customCertSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Make Custom Cert
     */
    async declarativeViewsClusterMakeCustomCert(requestParameters: DeclarativeViewsClusterMakeCustomCertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsClusterMakeCustomCertRaw(requestParameters, initOverrides);
    }

    /**
     * Staff Has Seen Cluster
     */
    async declarativeViewsClusterStaffHasSeenClusterRaw(requestParameters: DeclarativeViewsClusterStaffHasSeenClusterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsClusterStaffHasSeenCluster.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsClusterStaffHasSeenCluster.');
        }

        if (requestParameters.seen === null || requestParameters.seen === undefined) {
            throw new runtime.RequiredError('seen','Required parameter requestParameters.seen was null or undefined when calling declarativeViewsClusterStaffHasSeenCluster.');
        }

        const queryParameters: any = {};

        if (requestParameters.seen !== undefined) {
            queryParameters['seen'] = requestParameters.seen;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/clusters/account/{account}/id/{pk}/staff-has-seen`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Staff Has Seen Cluster
     */
    async declarativeViewsClusterStaffHasSeenCluster(requestParameters: DeclarativeViewsClusterStaffHasSeenClusterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.declarativeViewsClusterStaffHasSeenClusterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Instance Get By Id
     */
    async declarativeViewsInstanceInstanceGetByIdRaw(requestParameters: DeclarativeViewsInstanceInstanceGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstanceSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsInstanceInstanceGetById.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsInstanceInstanceGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/instances/{account}/id/{pk}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceSchemaFromJSON(jsonValue));
    }

    /**
     * Instance Get By Id
     */
    async declarativeViewsInstanceInstanceGetById(requestParameters: DeclarativeViewsInstanceInstanceGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstanceSchema> {
        const response = await this.declarativeViewsInstanceInstanceGetByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Instance List
     */
    async declarativeViewsInstanceInstanceListRaw(requestParameters: DeclarativeViewsInstanceInstanceListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InstanceSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsInstanceInstanceList.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/instances/{account}/`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InstanceSchemaFromJSON));
    }

    /**
     * Instance List
     */
    async declarativeViewsInstanceInstanceList(requestParameters: DeclarativeViewsInstanceInstanceListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InstanceSchema>> {
        const response = await this.declarativeViewsInstanceInstanceListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Instance Logs Get By Id
     */
    async declarativeViewsInstanceInstanceLogsGetByIdRaw(requestParameters: DeclarativeViewsInstanceInstanceLogsGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LogEventSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsInstanceInstanceLogsGetById.');
        }

        if (requestParameters.instanceId === null || requestParameters.instanceId === undefined) {
            throw new runtime.RequiredError('instanceId','Required parameter requestParameters.instanceId was null or undefined when calling declarativeViewsInstanceInstanceLogsGetById.');
        }

        const queryParameters: any = {};

        if (requestParameters.since !== undefined) {
            queryParameters['since'] = requestParameters.since;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/instances/{account}/id/{instance_id}/logs`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"instance_id"}}`, encodeURIComponent(String(requestParameters.instanceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LogEventSchemaFromJSON));
    }

    /**
     * Instance Logs Get By Id
     */
    async declarativeViewsInstanceInstanceLogsGetById(requestParameters: DeclarativeViewsInstanceInstanceLogsGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LogEventSchema>> {
        const response = await this.declarativeViewsInstanceInstanceLogsGetByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Instance Logs Get By Name
     */
    async declarativeViewsInstanceInstanceLogsGetByNameRaw(requestParameters: DeclarativeViewsInstanceInstanceLogsGetByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LogEventSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsInstanceInstanceLogsGetByName.');
        }

        if (requestParameters.instanceName === null || requestParameters.instanceName === undefined) {
            throw new runtime.RequiredError('instanceName','Required parameter requestParameters.instanceName was null or undefined when calling declarativeViewsInstanceInstanceLogsGetByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.since !== undefined) {
            queryParameters['since'] = requestParameters.since;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/instances/{account}/instance/{instance_name}/logs`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"instance_name"}}`, encodeURIComponent(String(requestParameters.instanceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LogEventSchemaFromJSON));
    }

    /**
     * Instance Logs Get By Name
     */
    async declarativeViewsInstanceInstanceLogsGetByName(requestParameters: DeclarativeViewsInstanceInstanceLogsGetByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LogEventSchema>> {
        const response = await this.declarativeViewsInstanceInstanceLogsGetByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cluster Delete By Id
     */
    async declarativeViewsJobClusterDeleteByIdRaw(requestParameters: DeclarativeViewsJobClusterDeleteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterLiteSchema>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling declarativeViewsJobClusterDeleteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/jobs/{job_id}`.replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterLiteSchemaFromJSON(jsonValue));
    }

    /**
     * Cluster Delete By Id
     */
    async declarativeViewsJobClusterDeleteById(requestParameters: DeclarativeViewsJobClusterDeleteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterLiteSchema> {
        const response = await this.declarativeViewsJobClusterDeleteByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Batch Job View
     */
    async declarativeViewsJobCreateBatchJobViewRaw(requestParameters: DeclarativeViewsJobCreateBatchJobViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateBatchJobResponse>> {
        if (requestParameters.createBatchJobSchema === null || requestParameters.createBatchJobSchema === undefined) {
            throw new runtime.RequiredError('createBatchJobSchema','Required parameter requestParameters.createBatchJobSchema was null or undefined when calling declarativeViewsJobCreateBatchJobView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/jobs/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBatchJobSchemaToJSON(requestParameters.createBatchJobSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateBatchJobResponseFromJSON(jsonValue));
    }

    /**
     * Create Batch Job View
     */
    async declarativeViewsJobCreateBatchJobView(requestParameters: DeclarativeViewsJobCreateBatchJobViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateBatchJobResponse> {
        const response = await this.declarativeViewsJobCreateBatchJobViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Batch Job View
     */
    async declarativeViewsJobGetBatchJobViewRaw(requestParameters: DeclarativeViewsJobGetBatchJobViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatchJobStatusResponse>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling declarativeViewsJobGetBatchJobView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/jobs/{job_id}`.replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchJobStatusResponseFromJSON(jsonValue));
    }

    /**
     * Get Batch Job View
     */
    async declarativeViewsJobGetBatchJobView(requestParameters: DeclarativeViewsJobGetBatchJobViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatchJobStatusResponse> {
        const response = await this.declarativeViewsJobGetBatchJobViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Batch Jobs View
     */
    async declarativeViewsJobGetBatchJobsViewRaw(requestParameters: DeclarativeViewsJobGetBatchJobsViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedBatchJobSummaryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.workspace !== undefined) {
            queryParameters['workspace'] = requestParameters.workspace;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/jobs/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedBatchJobSummaryResponseFromJSON(jsonValue));
    }

    /**
     * Get Batch Jobs View
     */
    async declarativeViewsJobGetBatchJobsView(requestParameters: DeclarativeViewsJobGetBatchJobsViewRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedBatchJobSummaryResponse> {
        const response = await this.declarativeViewsJobGetBatchJobsViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Cluster Batch Job View
     */
    async declarativeViewsJobGetClusterBatchJobViewRaw(requestParameters: DeclarativeViewsJobGetClusterBatchJobViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BatchJobStatusResponse>>> {
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId','Required parameter requestParameters.clusterId was null or undefined when calling declarativeViewsJobGetClusterBatchJobView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/jobs/cluster/{cluster_id}`.replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BatchJobStatusResponseFromJSON));
    }

    /**
     * Get Cluster Batch Job View
     */
    async declarativeViewsJobGetClusterBatchJobView(requestParameters: DeclarativeViewsJobGetClusterBatchJobViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BatchJobStatusResponse>> {
        const response = await this.declarativeViewsJobGetClusterBatchJobViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run Single Script View
     */
    async declarativeViewsJobRunSingleScriptViewRaw(requestParameters: DeclarativeViewsJobRunSingleScriptViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateBatchJobResponse>> {
        if (requestParameters.createSingleScriptClusterSchema === null || requestParameters.createSingleScriptClusterSchema === undefined) {
            throw new runtime.RequiredError('createSingleScriptClusterSchema','Required parameter requestParameters.createSingleScriptClusterSchema was null or undefined when calling declarativeViewsJobRunSingleScriptView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/jobs/single-job-script/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSingleScriptClusterSchemaToJSON(requestParameters.createSingleScriptClusterSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateBatchJobResponseFromJSON(jsonValue));
    }

    /**
     * Run Single Script View
     */
    async declarativeViewsJobRunSingleScriptView(requestParameters: DeclarativeViewsJobRunSingleScriptViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateBatchJobResponse> {
        const response = await this.declarativeViewsJobRunSingleScriptViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Instance Logs
     */
    async declarativeViewsLogsGetInstanceLogsRaw(requestParameters: DeclarativeViewsLogsGetInstanceLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsLogsGetInstanceLogs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/logs/instance/{pk}`.replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get Instance Logs
     */
    async declarativeViewsLogsGetInstanceLogs(requestParameters: DeclarativeViewsLogsGetInstanceLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsLogsGetInstanceLogsRaw(requestParameters, initOverrides);
    }

    /**
     * Init Cluster Logs
     */
    async declarativeViewsLogsInitClusterLogsRaw(requestParameters: DeclarativeViewsLogsInitClusterLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LogSessionInit>> {
        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsLogsInitClusterLogs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/logs/cluster/{pk}/init`.replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LogSessionInitFromJSON(jsonValue));
    }

    /**
     * Init Cluster Logs
     */
    async declarativeViewsLogsInitClusterLogs(requestParameters: DeclarativeViewsLogsInitClusterLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LogSessionInit> {
        const response = await this.declarativeViewsLogsInitClusterLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Next Cluster Logs
     */
    async declarativeViewsLogsNextClusterLogsRaw(requestParameters: DeclarativeViewsLogsNextClusterLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LogSessionInit>> {
        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsLogsNextClusterLogs.');
        }

        if (requestParameters.encodedSession === null || requestParameters.encodedSession === undefined) {
            throw new runtime.RequiredError('encodedSession','Required parameter requestParameters.encodedSession was null or undefined when calling declarativeViewsLogsNextClusterLogs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/logs/cluster/{pk}/page/{encoded_session}`.replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))).replace(`{${"encoded_session"}}`, encodeURIComponent(String(requestParameters.encodedSession))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LogSessionInitFromJSON(jsonValue));
    }

    /**
     * Next Cluster Logs
     */
    async declarativeViewsLogsNextClusterLogs(requestParameters: DeclarativeViewsLogsNextClusterLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LogSessionInit> {
        const response = await this.declarativeViewsLogsNextClusterLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Next Cluster Logs Post
     */
    async declarativeViewsLogsNextClusterLogsPostRaw(requestParameters: DeclarativeViewsLogsNextClusterLogsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LogSessionPage>> {
        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsLogsNextClusterLogsPost.');
        }

        if (requestParameters.logPageSessionRequestSchema === null || requestParameters.logPageSessionRequestSchema === undefined) {
            throw new runtime.RequiredError('logPageSessionRequestSchema','Required parameter requestParameters.logPageSessionRequestSchema was null or undefined when calling declarativeViewsLogsNextClusterLogsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/logs/cluster/{pk}/page/`.replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogPageSessionRequestSchemaToJSON(requestParameters.logPageSessionRequestSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LogSessionPageFromJSON(jsonValue));
    }

    /**
     * Next Cluster Logs Post
     */
    async declarativeViewsLogsNextClusterLogsPost(requestParameters: DeclarativeViewsLogsNextClusterLogsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LogSessionPage> {
        const response = await this.declarativeViewsLogsNextClusterLogsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Cluster Metric Adhoc
     */
    async declarativeViewsMetricsGetClusterMetricAdhocRaw(requestParameters: DeclarativeViewsMetricsGetClusterMetricAdhocRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsMetricsGetClusterMetricAdhoc.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling declarativeViewsMetricsGetClusterMetricAdhoc.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling declarativeViewsMetricsGetClusterMetricAdhoc.');
        }

        if (requestParameters.overTime === null || requestParameters.overTime === undefined) {
            throw new runtime.RequiredError('overTime','Required parameter requestParameters.overTime was null or undefined when calling declarativeViewsMetricsGetClusterMetricAdhoc.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.overTime !== undefined) {
            queryParameters['over_time'] = requestParameters.overTime;
        }

        if (requestParameters.unit !== undefined) {
            queryParameters['unit'] = requestParameters.unit;
        }

        if (requestParameters.nClusters !== undefined) {
            queryParameters['n_clusters'] = requestParameters.nClusters;
        }

        if (requestParameters.clusters !== undefined) {
            queryParameters['clusters'] = requestParameters.clusters;
        }

        if (requestParameters.setTags !== undefined) {
            queryParameters['set_tags'] = requestParameters.setTags;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/metrics/account/{account}/adhoc`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get Cluster Metric Adhoc
     */
    async declarativeViewsMetricsGetClusterMetricAdhoc(requestParameters: DeclarativeViewsMetricsGetClusterMetricAdhocRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsMetricsGetClusterMetricAdhocRaw(requestParameters, initOverrides);
    }

    /**
     * Get Cluster Metric Debug Stuff
     */
    async declarativeViewsMetricsGetClusterMetricDebugStuffRaw(requestParameters: DeclarativeViewsMetricsGetClusterMetricDebugStuffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsMetricsGetClusterMetricDebugStuff.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsMetricsGetClusterMetricDebugStuff.');
        }

        const queryParameters: any = {};

        if (requestParameters.setTags !== undefined) {
            queryParameters['set_tags'] = requestParameters.setTags;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/metrics/account/{account}/cluster/{pk}/debug-stuff`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get Cluster Metric Debug Stuff
     */
    async declarativeViewsMetricsGetClusterMetricDebugStuff(requestParameters: DeclarativeViewsMetricsGetClusterMetricDebugStuffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsMetricsGetClusterMetricDebugStuffRaw(requestParameters, initOverrides);
    }

    /**
     * Get Cluster Metrics
     */
    async declarativeViewsMetricsGetClusterMetricsRaw(requestParameters: DeclarativeViewsMetricsGetClusterMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MetricSeries>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsMetricsGetClusterMetrics.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsMetricsGetClusterMetrics.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling declarativeViewsMetricsGetClusterMetrics.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.scheduler !== undefined) {
            queryParameters['scheduler'] = requestParameters.scheduler;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/metrics/account/{account}/cluster/{pk}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetricSeriesFromJSON));
    }

    /**
     * Get Cluster Metrics
     */
    async declarativeViewsMetricsGetClusterMetrics(requestParameters: DeclarativeViewsMetricsGetClusterMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MetricSeries>> {
        const response = await this.declarativeViewsMetricsGetClusterMetricsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Cluster Metrics Value
     */
    async declarativeViewsMetricsGetClusterMetricsValueRaw(requestParameters: DeclarativeViewsMetricsGetClusterMetricsValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsMetricsGetClusterMetricsValue.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsMetricsGetClusterMetricsValue.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling declarativeViewsMetricsGetClusterMetricsValue.');
        }

        if (requestParameters.overTime === null || requestParameters.overTime === undefined) {
            throw new runtime.RequiredError('overTime','Required parameter requestParameters.overTime was null or undefined when calling declarativeViewsMetricsGetClusterMetricsValue.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.overTime !== undefined) {
            queryParameters['over_time'] = requestParameters.overTime;
        }

        if (requestParameters.scheduler !== undefined) {
            queryParameters['scheduler'] = requestParameters.scheduler;
        }

        if (requestParameters.startTs !== undefined) {
            queryParameters['start_ts'] = requestParameters.startTs;
        }

        if (requestParameters.endTs !== undefined) {
            queryParameters['end_ts'] = requestParameters.endTs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/metrics/account/{account}/cluster/{pk}/value`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Cluster Metrics Value
     */
    async declarativeViewsMetricsGetClusterMetricsValue(requestParameters: DeclarativeViewsMetricsGetClusterMetricsValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.declarativeViewsMetricsGetClusterMetricsValueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Cluster Summary Metrics View
     */
    async declarativeViewsMetricsGetClusterSummaryMetricsViewRaw(requestParameters: DeclarativeViewsMetricsGetClusterSummaryMetricsViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterSummaryMetricsSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsMetricsGetClusterSummaryMetricsView.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsMetricsGetClusterSummaryMetricsView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/metrics/account/{account}/id/{pk}/summary-metrics`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterSummaryMetricsSchemaFromJSON(jsonValue));
    }

    /**
     * Get Cluster Summary Metrics View
     */
    async declarativeViewsMetricsGetClusterSummaryMetricsView(requestParameters: DeclarativeViewsMetricsGetClusterSummaryMetricsViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterSummaryMetricsSchema> {
        const response = await this.declarativeViewsMetricsGetClusterSummaryMetricsViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cache Flush
     */
    async declarativeViewsPackagesCacheFlushRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatusSchema>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/packages/cache/flush`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusSchemaFromJSON(jsonValue));
    }

    /**
     * Cache Flush
     */
    async declarativeViewsPackagesCacheFlush(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatusSchema> {
        const response = await this.declarativeViewsPackagesCacheFlushRaw(initOverrides);
        return await response.value();
    }

    /**
     * Cache Invalidate
     */
    async declarativeViewsPackagesCacheInvalidateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatusSchema>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/packages/cache/invalidate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusSchemaFromJSON(jsonValue));
    }

    /**
     * Cache Invalidate
     */
    async declarativeViewsPackagesCacheInvalidate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatusSchema> {
        const response = await this.declarativeViewsPackagesCacheInvalidateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Cache List
     */
    async declarativeViewsPackagesCacheListRaw(requestParameters: DeclarativeViewsPackagesCacheListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedCacheEntrySchema>> {
        const queryParameters: any = {};

        if (requestParameters.channelUrl !== undefined) {
            queryParameters['channel_url'] = requestParameters.channelUrl;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.platform !== undefined) {
            queryParameters['platform'] = requestParameters.platform;
        }

        if (requestParameters.pythonVersion !== undefined) {
            queryParameters['python_version'] = requestParameters.pythonVersion;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/packages/cache`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedCacheEntrySchemaFromJSON(jsonValue));
    }

    /**
     * Cache List
     */
    async declarativeViewsPackagesCacheList(requestParameters: DeclarativeViewsPackagesCacheListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedCacheEntrySchema> {
        const response = await this.declarativeViewsPackagesCacheListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Packages List
     */
    async declarativeViewsPackagesPackagesListRaw(requestParameters: DeclarativeViewsPackagesPackagesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PackageSchema>>> {
        const queryParameters: any = {};

        if (requestParameters.account !== undefined) {
            queryParameters['account'] = requestParameters.account;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/packages/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PackageSchemaFromJSON));
    }

    /**
     * Packages List
     */
    async declarativeViewsPackagesPackagesList(requestParameters: DeclarativeViewsPackagesPackagesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PackageSchema>> {
        const response = await this.declarativeViewsPackagesPackagesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Aws Global Infra
     */
    async declarativeViewsSetupCreateAwsGlobalInfraRaw(requestParameters: DeclarativeViewsSetupCreateAwsGlobalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AWSGlobalInfraSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupCreateAwsGlobalInfra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/aws/global`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AWSGlobalInfraSchemaFromJSON(jsonValue));
    }

    /**
     * Create Aws Global Infra
     */
    async declarativeViewsSetupCreateAwsGlobalInfra(requestParameters: DeclarativeViewsSetupCreateAwsGlobalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AWSGlobalInfraSchema> {
        const response = await this.declarativeViewsSetupCreateAwsGlobalInfraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Aws Regional Infra
     */
    async declarativeViewsSetupCreateAwsRegionalInfraRaw(requestParameters: DeclarativeViewsSetupCreateAwsRegionalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AWSRegionalInfraSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupCreateAwsRegionalInfra.');
        }

        if (requestParameters.aWSRegionalInfraSchemaCreate === null || requestParameters.aWSRegionalInfraSchemaCreate === undefined) {
            throw new runtime.RequiredError('aWSRegionalInfraSchemaCreate','Required parameter requestParameters.aWSRegionalInfraSchemaCreate was null or undefined when calling declarativeViewsSetupCreateAwsRegionalInfra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/aws/regions`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AWSRegionalInfraSchemaCreateToJSON(requestParameters.aWSRegionalInfraSchemaCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AWSRegionalInfraSchemaFromJSON(jsonValue));
    }

    /**
     * Create Aws Regional Infra
     */
    async declarativeViewsSetupCreateAwsRegionalInfra(requestParameters: DeclarativeViewsSetupCreateAwsRegionalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AWSRegionalInfraSchema> {
        const response = await this.declarativeViewsSetupCreateAwsRegionalInfraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Gcp Global Infra
     */
    async declarativeViewsSetupCreateGcpGlobalInfraRaw(requestParameters: DeclarativeViewsSetupCreateGcpGlobalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GCPAccountInfraSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupCreateGcpGlobalInfra.');
        }

        if (requestParameters.gCPAccountInfraCreateSchema === null || requestParameters.gCPAccountInfraCreateSchema === undefined) {
            throw new runtime.RequiredError('gCPAccountInfraCreateSchema','Required parameter requestParameters.gCPAccountInfraCreateSchema was null or undefined when calling declarativeViewsSetupCreateGcpGlobalInfra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/gcp/global`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GCPAccountInfraCreateSchemaToJSON(requestParameters.gCPAccountInfraCreateSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GCPAccountInfraSchemaFromJSON(jsonValue));
    }

    /**
     * Create Gcp Global Infra
     */
    async declarativeViewsSetupCreateGcpGlobalInfra(requestParameters: DeclarativeViewsSetupCreateGcpGlobalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GCPAccountInfraSchema> {
        const response = await this.declarativeViewsSetupCreateGcpGlobalInfraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Gcp Regional Infra
     */
    async declarativeViewsSetupCreateGcpRegionalInfraRaw(requestParameters: DeclarativeViewsSetupCreateGcpRegionalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GCPRegionalInfraSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupCreateGcpRegionalInfra.');
        }

        if (requestParameters.gCPRegionalInfraCreateSchema === null || requestParameters.gCPRegionalInfraCreateSchema === undefined) {
            throw new runtime.RequiredError('gCPRegionalInfraCreateSchema','Required parameter requestParameters.gCPRegionalInfraCreateSchema was null or undefined when calling declarativeViewsSetupCreateGcpRegionalInfra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/gcp/regions`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GCPRegionalInfraCreateSchemaToJSON(requestParameters.gCPRegionalInfraCreateSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GCPRegionalInfraSchemaFromJSON(jsonValue));
    }

    /**
     * Create Gcp Regional Infra
     */
    async declarativeViewsSetupCreateGcpRegionalInfra(requestParameters: DeclarativeViewsSetupCreateGcpRegionalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GCPRegionalInfraSchema> {
        const response = await this.declarativeViewsSetupCreateGcpRegionalInfraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Aws Infra
     */
    async declarativeViewsSetupDeleteAwsInfraRaw(requestParameters: DeclarativeViewsSetupDeleteAwsInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupDeleteAwsInfra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/aws`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Aws Infra
     */
    async declarativeViewsSetupDeleteAwsInfra(requestParameters: DeclarativeViewsSetupDeleteAwsInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsSetupDeleteAwsInfraRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Aws Region Infra
     */
    async declarativeViewsSetupDeleteAwsRegionInfraRaw(requestParameters: DeclarativeViewsSetupDeleteAwsRegionInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupDeleteAwsRegionInfra.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling declarativeViewsSetupDeleteAwsRegionInfra.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/aws/region`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Aws Region Infra
     */
    async declarativeViewsSetupDeleteAwsRegionInfra(requestParameters: DeclarativeViewsSetupDeleteAwsRegionInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsSetupDeleteAwsRegionInfraRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Gcp Infra
     */
    async declarativeViewsSetupDeleteGcpInfraRaw(requestParameters: DeclarativeViewsSetupDeleteGcpInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupDeleteGcpInfra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/gcp`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Gcp Infra
     */
    async declarativeViewsSetupDeleteGcpInfra(requestParameters: DeclarativeViewsSetupDeleteGcpInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsSetupDeleteGcpInfraRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Gcp Region Infra
     */
    async declarativeViewsSetupDeleteGcpRegionInfraRaw(requestParameters: DeclarativeViewsSetupDeleteGcpRegionInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupDeleteGcpRegionInfra.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling declarativeViewsSetupDeleteGcpRegionInfra.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/gcp/region`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Gcp Region Infra
     */
    async declarativeViewsSetupDeleteGcpRegionInfra(requestParameters: DeclarativeViewsSetupDeleteGcpRegionInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsSetupDeleteGcpRegionInfraRaw(requestParameters, initOverrides);
    }

    /**
     * Get Aws Account Settings
     */
    async declarativeViewsSetupGetAwsAccountSettingsRaw(requestParameters: DeclarativeViewsSetupGetAwsAccountSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AWSAccountSettingsSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetAwsAccountSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/aws/settings`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AWSAccountSettingsSchemaFromJSON(jsonValue));
    }

    /**
     * Get Aws Account Settings
     */
    async declarativeViewsSetupGetAwsAccountSettings(requestParameters: DeclarativeViewsSetupGetAwsAccountSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AWSAccountSettingsSchema> {
        const response = await this.declarativeViewsSetupGetAwsAccountSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Aws Available Azs
     */
    async declarativeViewsSetupGetAwsAvailableAzsRaw(requestParameters: DeclarativeViewsSetupGetAwsAvailableAzsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AZSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetAwsAvailableAzs.');
        }

        if (requestParameters.region === null || requestParameters.region === undefined) {
            throw new runtime.RequiredError('region','Required parameter requestParameters.region was null or undefined when calling declarativeViewsSetupGetAwsAvailableAzs.');
        }

        const queryParameters: any = {};

        if (requestParameters.region !== undefined) {
            queryParameters['region'] = requestParameters.region;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/aws/available-azs`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AZSchemaFromJSON));
    }

    /**
     * Get Aws Available Azs
     */
    async declarativeViewsSetupGetAwsAvailableAzs(requestParameters: DeclarativeViewsSetupGetAwsAvailableAzsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AZSchema>> {
        const response = await this.declarativeViewsSetupGetAwsAvailableAzsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Aws Available Security Groups
     */
    async declarativeViewsSetupGetAwsAvailableSecurityGroupsRaw(requestParameters: DeclarativeViewsSetupGetAwsAvailableSecurityGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SecurityGroupSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetAwsAvailableSecurityGroups.');
        }

        if (requestParameters.region === null || requestParameters.region === undefined) {
            throw new runtime.RequiredError('region','Required parameter requestParameters.region was null or undefined when calling declarativeViewsSetupGetAwsAvailableSecurityGroups.');
        }

        if (requestParameters.vpcId === null || requestParameters.vpcId === undefined) {
            throw new runtime.RequiredError('vpcId','Required parameter requestParameters.vpcId was null or undefined when calling declarativeViewsSetupGetAwsAvailableSecurityGroups.');
        }

        const queryParameters: any = {};

        if (requestParameters.region !== undefined) {
            queryParameters['region'] = requestParameters.region;
        }

        if (requestParameters.vpcId !== undefined) {
            queryParameters['vpc_id'] = requestParameters.vpcId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/aws/available-security-groups`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SecurityGroupSchemaFromJSON));
    }

    /**
     * Get Aws Available Security Groups
     */
    async declarativeViewsSetupGetAwsAvailableSecurityGroups(requestParameters: DeclarativeViewsSetupGetAwsAvailableSecurityGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SecurityGroupSchema>> {
        const response = await this.declarativeViewsSetupGetAwsAvailableSecurityGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Aws Available Subnets
     */
    async declarativeViewsSetupGetAwsAvailableSubnetsRaw(requestParameters: DeclarativeViewsSetupGetAwsAvailableSubnetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubnetSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetAwsAvailableSubnets.');
        }

        if (requestParameters.region === null || requestParameters.region === undefined) {
            throw new runtime.RequiredError('region','Required parameter requestParameters.region was null or undefined when calling declarativeViewsSetupGetAwsAvailableSubnets.');
        }

        if (requestParameters.vpcId === null || requestParameters.vpcId === undefined) {
            throw new runtime.RequiredError('vpcId','Required parameter requestParameters.vpcId was null or undefined when calling declarativeViewsSetupGetAwsAvailableSubnets.');
        }

        const queryParameters: any = {};

        if (requestParameters.region !== undefined) {
            queryParameters['region'] = requestParameters.region;
        }

        if (requestParameters.vpcId !== undefined) {
            queryParameters['vpc_id'] = requestParameters.vpcId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/aws/available-subnets`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubnetSchemaFromJSON));
    }

    /**
     * Get Aws Available Subnets
     */
    async declarativeViewsSetupGetAwsAvailableSubnets(requestParameters: DeclarativeViewsSetupGetAwsAvailableSubnetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubnetSchema>> {
        const response = await this.declarativeViewsSetupGetAwsAvailableSubnetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Aws Available Vpcs
     */
    async declarativeViewsSetupGetAwsAvailableVpcsRaw(requestParameters: DeclarativeViewsSetupGetAwsAvailableVpcsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VPCSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetAwsAvailableVpcs.');
        }

        if (requestParameters.region === null || requestParameters.region === undefined) {
            throw new runtime.RequiredError('region','Required parameter requestParameters.region was null or undefined when calling declarativeViewsSetupGetAwsAvailableVpcs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/aws/available-vpcs/{region}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"region"}}`, encodeURIComponent(String(requestParameters.region))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VPCSchemaFromJSON));
    }

    /**
     * Get Aws Available Vpcs
     */
    async declarativeViewsSetupGetAwsAvailableVpcs(requestParameters: DeclarativeViewsSetupGetAwsAvailableVpcsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VPCSchema>> {
        const response = await this.declarativeViewsSetupGetAwsAvailableVpcsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Aws Global Infra
     */
    async declarativeViewsSetupGetAwsGlobalInfraRaw(requestParameters: DeclarativeViewsSetupGetAwsGlobalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AWSGlobalInfraSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetAwsGlobalInfra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/aws/global`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AWSGlobalInfraSchemaFromJSON(jsonValue));
    }

    /**
     * Get Aws Global Infra
     */
    async declarativeViewsSetupGetAwsGlobalInfra(requestParameters: DeclarativeViewsSetupGetAwsGlobalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AWSGlobalInfraSchema> {
        const response = await this.declarativeViewsSetupGetAwsGlobalInfraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Aws Regional Infra
     */
    async declarativeViewsSetupGetAwsRegionalInfraRaw(requestParameters: DeclarativeViewsSetupGetAwsRegionalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AWSRegionalInfraSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetAwsRegionalInfra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/aws/regions`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AWSRegionalInfraSchemaFromJSON));
    }

    /**
     * Get Aws Regional Infra
     */
    async declarativeViewsSetupGetAwsRegionalInfra(requestParameters: DeclarativeViewsSetupGetAwsRegionalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AWSRegionalInfraSchema>> {
        const response = await this.declarativeViewsSetupGetAwsRegionalInfraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Docker Settings
     */
    async declarativeViewsSetupGetDockerSettingsRaw(requestParameters: DeclarativeViewsSetupGetDockerSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DockerSettingsSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetDockerSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/docker`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DockerSettingsSchemaFromJSON(jsonValue));
    }

    /**
     * Get Docker Settings
     */
    async declarativeViewsSetupGetDockerSettings(requestParameters: DeclarativeViewsSetupGetDockerSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DockerSettingsSchema> {
        const response = await this.declarativeViewsSetupGetDockerSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Gcp Account Settings
     */
    async declarativeViewsSetupGetGcpAccountSettingsRaw(requestParameters: DeclarativeViewsSetupGetGcpAccountSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GCPAccountSettingsSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetGcpAccountSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/gcp/settings`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GCPAccountSettingsSchemaFromJSON(jsonValue));
    }

    /**
     * Get Gcp Account Settings
     */
    async declarativeViewsSetupGetGcpAccountSettings(requestParameters: DeclarativeViewsSetupGetGcpAccountSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GCPAccountSettingsSchema> {
        const response = await this.declarativeViewsSetupGetGcpAccountSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Gcp Available Networks
     */
    async declarativeViewsSetupGetGcpAvailableNetworksRaw(requestParameters: DeclarativeViewsSetupGetGcpAvailableNetworksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NetworkSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetGcpAvailableNetworks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/gcp/available-networks`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NetworkSchemaFromJSON));
    }

    /**
     * Get Gcp Available Networks
     */
    async declarativeViewsSetupGetGcpAvailableNetworks(requestParameters: DeclarativeViewsSetupGetGcpAvailableNetworksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NetworkSchema>> {
        const response = await this.declarativeViewsSetupGetGcpAvailableNetworksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Gcp Available Subnets
     */
    async declarativeViewsSetupGetGcpAvailableSubnetsRaw(requestParameters: DeclarativeViewsSetupGetGcpAvailableSubnetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GCPSubnetSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetGcpAvailableSubnets.');
        }

        if (requestParameters.region === null || requestParameters.region === undefined) {
            throw new runtime.RequiredError('region','Required parameter requestParameters.region was null or undefined when calling declarativeViewsSetupGetGcpAvailableSubnets.');
        }

        const queryParameters: any = {};

        if (requestParameters.region !== undefined) {
            queryParameters['region'] = requestParameters.region;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/gcp/available-subnets`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GCPSubnetSchemaFromJSON));
    }

    /**
     * Get Gcp Available Subnets
     */
    async declarativeViewsSetupGetGcpAvailableSubnets(requestParameters: DeclarativeViewsSetupGetGcpAvailableSubnetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GCPSubnetSchema>> {
        const response = await this.declarativeViewsSetupGetGcpAvailableSubnetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Gcp Available Target Tags
     */
    async declarativeViewsSetupGetGcpAvailableTargetTagsRaw(requestParameters: DeclarativeViewsSetupGetGcpAvailableTargetTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetGcpAvailableTargetTags.');
        }

        if (requestParameters.networkLink === null || requestParameters.networkLink === undefined) {
            throw new runtime.RequiredError('networkLink','Required parameter requestParameters.networkLink was null or undefined when calling declarativeViewsSetupGetGcpAvailableTargetTags.');
        }

        const queryParameters: any = {};

        if (requestParameters.networkLink !== undefined) {
            queryParameters['network_link'] = requestParameters.networkLink;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/gcp/available-target-tags`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Gcp Available Target Tags
     */
    async declarativeViewsSetupGetGcpAvailableTargetTags(requestParameters: DeclarativeViewsSetupGetGcpAvailableTargetTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.declarativeViewsSetupGetGcpAvailableTargetTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Gcp Global Infra
     */
    async declarativeViewsSetupGetGcpGlobalInfraRaw(requestParameters: DeclarativeViewsSetupGetGcpGlobalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GCPAccountInfraSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetGcpGlobalInfra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/gcp/global`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GCPAccountInfraSchemaFromJSON(jsonValue));
    }

    /**
     * Get Gcp Global Infra
     */
    async declarativeViewsSetupGetGcpGlobalInfra(requestParameters: DeclarativeViewsSetupGetGcpGlobalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GCPAccountInfraSchema> {
        const response = await this.declarativeViewsSetupGetGcpGlobalInfraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Gcp Regional Infra
     */
    async declarativeViewsSetupGetGcpRegionalInfraRaw(requestParameters: DeclarativeViewsSetupGetGcpRegionalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GCPRegionalInfraSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupGetGcpRegionalInfra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/gcp/regions`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GCPRegionalInfraSchemaFromJSON));
    }

    /**
     * Get Gcp Regional Infra
     */
    async declarativeViewsSetupGetGcpRegionalInfra(requestParameters: DeclarativeViewsSetupGetGcpRegionalInfraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GCPRegionalInfraSchema>> {
        const response = await this.declarativeViewsSetupGetGcpRegionalInfraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Patch Aws Account Settings
     */
    async declarativeViewsSetupPatchAwsAccountSettingsRaw(requestParameters: DeclarativeViewsSetupPatchAwsAccountSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AWSAccountSettingsSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupPatchAwsAccountSettings.');
        }

        if (requestParameters.aWSAccountSettingsSchema === null || requestParameters.aWSAccountSettingsSchema === undefined) {
            throw new runtime.RequiredError('aWSAccountSettingsSchema','Required parameter requestParameters.aWSAccountSettingsSchema was null or undefined when calling declarativeViewsSetupPatchAwsAccountSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/aws/settings`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AWSAccountSettingsSchemaToJSON(requestParameters.aWSAccountSettingsSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AWSAccountSettingsSchemaFromJSON(jsonValue));
    }

    /**
     * Patch Aws Account Settings
     */
    async declarativeViewsSetupPatchAwsAccountSettings(requestParameters: DeclarativeViewsSetupPatchAwsAccountSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AWSAccountSettingsSchema> {
        const response = await this.declarativeViewsSetupPatchAwsAccountSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Patch Docker Settings
     */
    async declarativeViewsSetupPatchDockerSettingsRaw(requestParameters: DeclarativeViewsSetupPatchDockerSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupPatchDockerSettings.');
        }

        if (requestParameters.dockerSettingsSchema === null || requestParameters.dockerSettingsSchema === undefined) {
            throw new runtime.RequiredError('dockerSettingsSchema','Required parameter requestParameters.dockerSettingsSchema was null or undefined when calling declarativeViewsSetupPatchDockerSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/docker`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DockerSettingsSchemaToJSON(requestParameters.dockerSettingsSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Patch Docker Settings
     */
    async declarativeViewsSetupPatchDockerSettings(requestParameters: DeclarativeViewsSetupPatchDockerSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsSetupPatchDockerSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * Patch Gcp Account Settings
     */
    async declarativeViewsSetupPatchGcpAccountSettingsRaw(requestParameters: DeclarativeViewsSetupPatchGcpAccountSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GCPAccountSettingsSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSetupPatchGcpAccountSettings.');
        }

        if (requestParameters.gCPAccountSettingsSchema === null || requestParameters.gCPAccountSettingsSchema === undefined) {
            throw new runtime.RequiredError('gCPAccountSettingsSchema','Required parameter requestParameters.gCPAccountSettingsSchema was null or undefined when calling declarativeViewsSetupPatchGcpAccountSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/setup/account/{account}/gcp/settings`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: GCPAccountSettingsSchemaToJSON(requestParameters.gCPAccountSettingsSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GCPAccountSettingsSchemaFromJSON(jsonValue));
    }

    /**
     * Patch Gcp Account Settings
     */
    async declarativeViewsSetupPatchGcpAccountSettings(requestParameters: DeclarativeViewsSetupPatchGcpAccountSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GCPAccountSettingsSchema> {
        const response = await this.declarativeViewsSetupPatchGcpAccountSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Approximate Software Packages
     */
    async declarativeViewsSoftwareEnvironmentApproximateSoftwarePackagesRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentApproximateSoftwarePackagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PackageApproxResult>>> {
        if (requestParameters.approximateSpec === null || requestParameters.approximateSpec === undefined) {
            throw new runtime.RequiredError('approximateSpec','Required parameter requestParameters.approximateSpec was null or undefined when calling declarativeViewsSoftwareEnvironmentApproximateSoftwarePackages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v2/software-environment/approximate-packages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApproximateSpecToJSON(requestParameters.approximateSpec),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PackageApproxResultFromJSON));
    }

    /**
     * Approximate Software Packages
     */
    async declarativeViewsSoftwareEnvironmentApproximateSoftwarePackages(requestParameters: DeclarativeViewsSoftwareEnvironmentApproximateSoftwarePackagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PackageApproxResult>> {
        const response = await this.declarativeViewsSoftwareEnvironmentApproximateSoftwarePackagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Software Env Alias
     */
    async declarativeViewsSoftwareEnvironmentDeleteSoftwareEnvAliasRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentDeleteSoftwareEnvAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentDeleteSoftwareEnvAlias.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling declarativeViewsSoftwareEnvironmentDeleteSoftwareEnvAlias.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}/alias/name/{name}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Software Env Alias
     */
    async declarativeViewsSoftwareEnvironmentDeleteSoftwareEnvAlias(requestParameters: DeclarativeViewsSoftwareEnvironmentDeleteSoftwareEnvAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsSoftwareEnvironmentDeleteSoftwareEnvAliasRaw(requestParameters, initOverrides);
    }

    /**
     * Get Software Env Alias By Id
     */
    async declarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByIdRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SoftwareEnvironmentAliasSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasById.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}/alias/id/{pk}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SoftwareEnvironmentAliasSchemaFromJSON(jsonValue));
    }

    /**
     * Get Software Env Alias By Id
     */
    async declarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasById(requestParameters: DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SoftwareEnvironmentAliasSchema> {
        const response = await this.declarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Software Env Alias By Name
     */
    async declarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByNameRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SoftwareEnvironmentAliasSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByName.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling declarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}/alias/name/{name}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SoftwareEnvironmentAliasSchemaFromJSON(jsonValue));
    }

    /**
     * Get Software Env Alias By Name
     */
    async declarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByName(requestParameters: DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SoftwareEnvironmentAliasSchema> {
        const response = await this.declarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Software Env Build
     */
    async declarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SoftwareEnvironmentBuildSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentGetSoftwareEnvBuild.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling declarativeViewsSoftwareEnvironmentGetSoftwareEnvBuild.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}/build/{id}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SoftwareEnvironmentBuildSchemaFromJSON(jsonValue));
    }

    /**
     * Get Software Env Build
     */
    async declarativeViewsSoftwareEnvironmentGetSoftwareEnvBuild(requestParameters: DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SoftwareEnvironmentBuildSchema> {
        const response = await this.declarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Software Env Build Logs
     */
    async declarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildLogsRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SoftwareEnvironmentBuildLogs>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildLogs.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling declarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildLogs.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}/build/logs/{id}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SoftwareEnvironmentBuildLogsFromJSON(jsonValue));
    }

    /**
     * Get Software Env Build Logs
     */
    async declarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildLogs(requestParameters: DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SoftwareEnvironmentBuildLogs> {
        const response = await this.declarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Software Env Spec
     */
    async declarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SoftwareEnvironmentSpecSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentGetSoftwareEnvSpec.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsSoftwareEnvironmentGetSoftwareEnvSpec.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}/spec/{pk}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SoftwareEnvironmentSpecSchemaFromJSON(jsonValue));
    }

    /**
     * Get Software Env Spec
     */
    async declarativeViewsSoftwareEnvironmentGetSoftwareEnvSpec(requestParameters: DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SoftwareEnvironmentSpecSchema> {
        const response = await this.declarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Software Env Spec Yaml
     */
    async declarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecYamlRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecYamlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecYaml.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling declarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecYaml.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}/spec-yaml/{id}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Software Env Spec Yaml
     */
    async declarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecYaml(requestParameters: DeclarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecYamlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.declarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecYamlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Filter Software Env Aliases
     */
    async declarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliasesRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliasesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedSoftwareEnvironmentAliasListSchema>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.packageSync !== undefined) {
            queryParameters['package_sync'] = requestParameters.packageSync;
        }

        if (requestParameters.account !== undefined) {
            queryParameters['account'] = requestParameters.account;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.compoundScope !== undefined) {
            queryParameters['compound_scope'] = requestParameters.compoundScope;
        }

        if (requestParameters.customStaffScope !== undefined) {
            queryParameters['custom_staff_scope'] = requestParameters.customStaffScope;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/alias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedSoftwareEnvironmentAliasListSchemaFromJSON(jsonValue));
    }

    /**
     * List Filter Software Env Aliases
     */
    async declarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliases(requestParameters: DeclarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliasesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedSoftwareEnvironmentAliasListSchema> {
        const response = await this.declarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliasesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Software Alias Builds
     */
    async declarativeViewsSoftwareEnvironmentListSoftwareAliasBuildsRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentListSoftwareAliasBuildsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SoftwareEnvBuildLight>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentListSoftwareAliasBuilds.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling declarativeViewsSoftwareEnvironmentListSoftwareAliasBuilds.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}/alias/id/{id}/builds`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SoftwareEnvBuildLightFromJSON));
    }

    /**
     * List Software Alias Builds
     */
    async declarativeViewsSoftwareEnvironmentListSoftwareAliasBuilds(requestParameters: DeclarativeViewsSoftwareEnvironmentListSoftwareAliasBuildsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SoftwareEnvBuildLight>> {
        const response = await this.declarativeViewsSoftwareEnvironmentListSoftwareAliasBuildsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Software Env Alias Key Values
     */
    async declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValues.');
        }

        if (requestParameters.startsWith === null || requestParameters.startsWith === undefined) {
            throw new runtime.RequiredError('startsWith','Required parameter requestParameters.startsWith was null or undefined when calling declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValues.');
        }

        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        if (requestParameters.startsWith !== undefined) {
            queryParameters['starts_with'] = requestParameters.startsWith;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/values`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * List Software Env Alias Key Values
     */
    async declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValues(requestParameters: DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Software Env Alias Specs
     */
    async declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasSpecsRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasSpecsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedSoftwareEnvironmentSpecListSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasSpecs.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasSpecs.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}/alias/{pk}/specs`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedSoftwareEnvironmentSpecListSchemaFromJSON(jsonValue));
    }

    /**
     * List Software Env Alias Specs
     */
    async declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasSpecs(requestParameters: DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasSpecsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedSoftwareEnvironmentSpecListSchema> {
        const response = await this.declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasSpecsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Software Env Aliases
     */
    async declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasesRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedSoftwareEnvironmentAliasSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentListSoftwareEnvAliases.');
        }

        const queryParameters: any = {};

        if (requestParameters.includePackageSync !== undefined) {
            queryParameters['include_package_sync'] = requestParameters.includePackageSync;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}/alias`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedSoftwareEnvironmentAliasSchemaFromJSON(jsonValue));
    }

    /**
     * List Software Env Aliases
     */
    async declarativeViewsSoftwareEnvironmentListSoftwareEnvAliases(requestParameters: DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedSoftwareEnvironmentAliasSchema> {
        const response = await this.declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Software Env Spec Builds By Spec
     */
    async declarativeViewsSoftwareEnvironmentListSoftwareEnvSpecBuildsBySpecRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentListSoftwareEnvSpecBuildsBySpecRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SoftwareEnvBuildLight>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentListSoftwareEnvSpecBuildsBySpec.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling declarativeViewsSoftwareEnvironmentListSoftwareEnvSpecBuildsBySpec.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}/spec/{id}/builds`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SoftwareEnvBuildLightFromJSON));
    }

    /**
     * List Software Env Spec Builds By Spec
     */
    async declarativeViewsSoftwareEnvironmentListSoftwareEnvSpecBuildsBySpec(requestParameters: DeclarativeViewsSoftwareEnvironmentListSoftwareEnvSpecBuildsBySpecRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SoftwareEnvBuildLight>> {
        const response = await this.declarativeViewsSoftwareEnvironmentListSoftwareEnvSpecBuildsBySpecRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Package Upload Request
     */
    async declarativeViewsSoftwareEnvironmentPackageUploadRequestRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentPackageUploadRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadPackageResponseSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentPackageUploadRequest.');
        }

        if (requestParameters.uploadPackageRequestSchema === null || requestParameters.uploadPackageRequestSchema === undefined) {
            throw new runtime.RequiredError('uploadPackageRequestSchema','Required parameter requestParameters.uploadPackageRequestSchema was null or undefined when calling declarativeViewsSoftwareEnvironmentPackageUploadRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}/package-upload`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadPackageRequestSchemaToJSON(requestParameters.uploadPackageRequestSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadPackageResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Package Upload Request
     */
    async declarativeViewsSoftwareEnvironmentPackageUploadRequest(requestParameters: DeclarativeViewsSoftwareEnvironmentPackageUploadRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadPackageResponseSchema> {
        const response = await this.declarativeViewsSoftwareEnvironmentPackageUploadRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Software Env Create
     */
    async declarativeViewsSoftwareEnvironmentSoftwareEnvCreateRaw(requestParameters: DeclarativeViewsSoftwareEnvironmentSoftwareEnvCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SoftwareEnvironmentAliasSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSoftwareEnvironmentSoftwareEnvCreate.');
        }

        if (requestParameters.createSoftwareEnvironmentSpecSchema === null || requestParameters.createSoftwareEnvironmentSpecSchema === undefined) {
            throw new runtime.RequiredError('createSoftwareEnvironmentSpecSchema','Required parameter requestParameters.createSoftwareEnvironmentSpecSchema was null or undefined when calling declarativeViewsSoftwareEnvironmentSoftwareEnvCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/software-environment/account/{account}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSoftwareEnvironmentSpecSchemaToJSON(requestParameters.createSoftwareEnvironmentSpecSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SoftwareEnvironmentAliasSchemaFromJSON(jsonValue));
    }

    /**
     * Software Env Create
     */
    async declarativeViewsSoftwareEnvironmentSoftwareEnvCreate(requestParameters: DeclarativeViewsSoftwareEnvironmentSoftwareEnvCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SoftwareEnvironmentAliasSchema> {
        const response = await this.declarativeViewsSoftwareEnvironmentSoftwareEnvCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Spec Get By Id
     */
    async declarativeViewsSpecSpecGetByIdRaw(requestParameters: DeclarativeViewsSpecSpecGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstanceSpecSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSpecSpecGetById.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsSpecSpecGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/instance_specs/account/{account}/id/{pk}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceSpecSchemaFromJSON(jsonValue));
    }

    /**
     * Spec Get By Id
     */
    async declarativeViewsSpecSpecGetById(requestParameters: DeclarativeViewsSpecSpecGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstanceSpecSchema> {
        const response = await this.declarativeViewsSpecSpecGetByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Spec List
     */
    async declarativeViewsSpecSpecListRaw(requestParameters: DeclarativeViewsSpecSpecListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InstanceSpecLiteSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsSpecSpecList.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/instance_specs/account/{account}/`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InstanceSpecLiteSchemaFromJSON));
    }

    /**
     * Spec List
     */
    async declarativeViewsSpecSpecList(requestParameters: DeclarativeViewsSpecSpecListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InstanceSpecLiteSchema>> {
        const response = await this.declarativeViewsSpecSpecListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Account Tag Values Endpoint
     */
    async declarativeViewsTagsGetAccountTagValuesEndpointRaw(requestParameters: DeclarativeViewsTagsGetAccountTagValuesEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<string>; }>> {
        const queryParameters: any = {};

        if (requestParameters.account !== undefined) {
            queryParameters['account'] = requestParameters.account;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.compoundScope !== undefined) {
            queryParameters['compound_scope'] = requestParameters.compoundScope;
        }

        if (requestParameters.customStaffScope !== undefined) {
            queryParameters['custom_staff_scope'] = requestParameters.customStaffScope;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/tags/values`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Account Tag Values Endpoint
     */
    async declarativeViewsTagsGetAccountTagValuesEndpoint(requestParameters: DeclarativeViewsTagsGetAccountTagValuesEndpointRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<string>; }> {
        const response = await this.declarativeViewsTagsGetAccountTagValuesEndpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All Alert Content
     */
    async declarativeViewsTagsGetAllAlertContentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TagAlertHitWithMessageSchema>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/tags/alert-contents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagAlertHitWithMessageSchemaFromJSON));
    }

    /**
     * Get All Alert Content
     */
    async declarativeViewsTagsGetAllAlertContent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TagAlertHitWithMessageSchema>> {
        const response = await this.declarativeViewsTagsGetAllAlertContentRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Tag List
     */
    async declarativeViewsTagsGetTagListRaw(requestParameters: DeclarativeViewsTagsGetTagListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TagLabelSchema>>> {
        const queryParameters: any = {};

        if (requestParameters.systemTags !== undefined) {
            queryParameters['system_tags'] = requestParameters.systemTags;
        }

        if (requestParameters.account !== undefined) {
            queryParameters['account'] = requestParameters.account;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.compoundScope !== undefined) {
            queryParameters['compound_scope'] = requestParameters.compoundScope;
        }

        if (requestParameters.customStaffScope !== undefined) {
            queryParameters['custom_staff_scope'] = requestParameters.customStaffScope;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/tags/labels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagLabelSchemaFromJSON));
    }

    /**
     * Get Tag List
     */
    async declarativeViewsTagsGetTagList(requestParameters: DeclarativeViewsTagsGetTagListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TagLabelSchema>> {
        const response = await this.declarativeViewsTagsGetTagListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fancy Pagination Test
     */
    async declarativeViewsTestFancyPaginationTestRaw(requestParameters: DeclarativeViewsTestFancyPaginationTestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/test/pagination/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Fancy Pagination Test
     */
    async declarativeViewsTestFancyPaginationTest(requestParameters: DeclarativeViewsTestFancyPaginationTestRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.declarativeViewsTestFancyPaginationTestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Authentication Request
     */
    async declarativeViewsTestGetAuthenticationRequestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/test/authentication-required/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get Authentication Request
     */
    async declarativeViewsTestGetAuthenticationRequest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsTestGetAuthenticationRequestRaw(initOverrides);
    }

    /**
     * Post Authentication Request
     */
    async declarativeViewsTestPostAuthenticationRequestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/test/authentication-required/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post Authentication Request
     */
    async declarativeViewsTestPostAuthenticationRequest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsTestPostAuthenticationRequestRaw(initOverrides);
    }

    /**
     * Raise Coiled Exception
     */
    async declarativeViewsTestRaiseCoiledExceptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/test/raise-coiled-exception/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Raise Coiled Exception
     */
    async declarativeViewsTestRaiseCoiledException(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsTestRaiseCoiledExceptionRaw(initOverrides);
    }

    /**
     * Raise Exception
     */
    async declarativeViewsTestRaiseExceptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/test/raise-exception/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Raise Exception
     */
    async declarativeViewsTestRaiseException(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declarativeViewsTestRaiseExceptionRaw(initOverrides);
    }

    /**
     * Get Usage By User
     */
    async declarativeViewsUsageGetUsageByUserRaw(requestParameters: DeclarativeViewsUsageGetUsageByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupUsageSchema>> {
        if (requestParameters.start === null || requestParameters.start === undefined) {
            throw new runtime.RequiredError('start','Required parameter requestParameters.start was null or undefined when calling declarativeViewsUsageGetUsageByUser.');
        }

        if (requestParameters.end === null || requestParameters.end === undefined) {
            throw new runtime.RequiredError('end','Required parameter requestParameters.end was null or undefined when calling declarativeViewsUsageGetUsageByUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.unit !== undefined) {
            queryParameters['unit'] = requestParameters.unit;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString().substring(0,10);
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = (requestParameters.end as any).toISOString().substring(0,10);
        }

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        if (requestParameters.groupby !== undefined) {
            queryParameters['groupby'] = requestParameters.groupby;
        }

        if (requestParameters.groupbyTag !== undefined) {
            queryParameters['groupby_tag'] = requestParameters.groupbyTag;
        }

        if (requestParameters.account !== undefined) {
            queryParameters['account'] = requestParameters.account;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.compoundScope !== undefined) {
            queryParameters['compound_scope'] = requestParameters.compoundScope;
        }

        if (requestParameters.customStaffScope !== undefined) {
            queryParameters['custom_staff_scope'] = requestParameters.customStaffScope;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/usage/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupUsageSchemaFromJSON(jsonValue));
    }

    /**
     * Get Usage By User
     */
    async declarativeViewsUsageGetUsageByUser(requestParameters: DeclarativeViewsUsageGetUsageByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupUsageSchema> {
        const response = await this.declarativeViewsUsageGetUsageByUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Account Options
     */
    async declarativeViewsUserGetAccountOptionsRaw(requestParameters: DeclarativeViewsUserGetAccountOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsUserGetAccountOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/user/account/{account}/options`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Account Options
     */
    async declarativeViewsUserGetAccountOptions(requestParameters: DeclarativeViewsUserGetAccountOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.declarativeViewsUserGetAccountOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Account Registry
     */
    async declarativeViewsUserGetAccountRegistryRaw(requestParameters: DeclarativeViewsUserGetAccountRegistryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsUserGetAccountRegistry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/user/account/{account}/registry`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Account Registry
     */
    async declarativeViewsUserGetAccountRegistry(requestParameters: DeclarativeViewsUserGetAccountRegistryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.declarativeViewsUserGetAccountRegistryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Account Usage
     */
    async declarativeViewsUserGetAccountUsageRaw(requestParameters: DeclarativeViewsUserGetAccountUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountUsageSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsUserGetAccountUsage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/user/account/{account}/usage`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountUsageSchemaFromJSON(jsonValue));
    }

    /**
     * Get Account Usage
     */
    async declarativeViewsUserGetAccountUsage(requestParameters: DeclarativeViewsUserGetAccountUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountUsageSchema> {
        const response = await this.declarativeViewsUserGetAccountUsageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Identity
     */
    async declarativeViewsUserGetIdentityRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSchema>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/user/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSchemaFromJSON(jsonValue));
    }

    /**
     * Get Identity
     */
    async declarativeViewsUserGetIdentity(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSchema> {
        const response = await this.declarativeViewsUserGetIdentityRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Ip
     */
    async declarativeViewsUserGetIpRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/user/source-ip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Ip
     */
    async declarativeViewsUserGetIp(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.declarativeViewsUserGetIpRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Memberships
     */
    async declarativeViewsUserGetMembershipsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MembershipSchema>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/user/me/memberships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MembershipSchemaFromJSON));
    }

    /**
     * Get Memberships
     */
    async declarativeViewsUserGetMemberships(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MembershipSchema>> {
        const response = await this.declarativeViewsUserGetMembershipsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Prometheus Read Creds
     */
    async declarativeViewsUserGetPrometheusReadCredsRaw(requestParameters: DeclarativeViewsUserGetPrometheusReadCredsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PrometheusReadCredSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsUserGetPrometheusReadCreds.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/user/account/{account}/prometheus-read-credentials`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PrometheusReadCredSchemaFromJSON));
    }

    /**
     * Get Prometheus Read Creds
     */
    async declarativeViewsUserGetPrometheusReadCreds(requestParameters: DeclarativeViewsUserGetPrometheusReadCredsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PrometheusReadCredSchema>> {
        const response = await this.declarativeViewsUserGetPrometheusReadCredsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Public Account
     */
    async declarativeViewsUserGetPublicAccountRaw(requestParameters: DeclarativeViewsUserGetPublicAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountLightSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsUserGetPublicAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/user/account/{account}/public`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountLightSchemaFromJSON(jsonValue));
    }

    /**
     * Get Public Account
     */
    async declarativeViewsUserGetPublicAccount(requestParameters: DeclarativeViewsUserGetPublicAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountLightSchema> {
        const response = await this.declarativeViewsUserGetPublicAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Hosted Approval Dry Run
     */
    async declarativeViewsUserHostedApprovalDryRunRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/user/hosted-approval-dry-run`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Hosted Approval Dry Run
     */
    async declarativeViewsUserHostedApprovalDryRun(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.declarativeViewsUserHostedApprovalDryRunRaw(initOverrides);
        return await response.value();
    }

    /**
     * Patch Account
     */
    async declarativeViewsUserPatchAccountRaw(requestParameters: DeclarativeViewsUserPatchAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsUserPatchAccount.');
        }

        if (requestParameters.accountPatchSchema === null || requestParameters.accountPatchSchema === undefined) {
            throw new runtime.RequiredError('accountPatchSchema','Required parameter requestParameters.accountPatchSchema was null or undefined when calling declarativeViewsUserPatchAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/user/account/{account}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AccountPatchSchemaToJSON(requestParameters.accountPatchSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceSchemaFromJSON(jsonValue));
    }

    /**
     * Patch Account
     */
    async declarativeViewsUserPatchAccount(requestParameters: DeclarativeViewsUserPatchAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceSchema> {
        const response = await this.declarativeViewsUserPatchAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Worker Get By Id
     */
    async declarativeViewsWorkerWorkerGetByIdRaw(requestParameters: DeclarativeViewsWorkerWorkerGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkerSchema>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsWorkerWorkerGetById.');
        }

        if (requestParameters.pk === null || requestParameters.pk === undefined) {
            throw new runtime.RequiredError('pk','Required parameter requestParameters.pk was null or undefined when calling declarativeViewsWorkerWorkerGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/workers/account/{account}/id/{pk}`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"pk"}}`, encodeURIComponent(String(requestParameters.pk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkerSchemaFromJSON(jsonValue));
    }

    /**
     * Worker Get By Id
     */
    async declarativeViewsWorkerWorkerGetById(requestParameters: DeclarativeViewsWorkerWorkerGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkerSchema> {
        const response = await this.declarativeViewsWorkerWorkerGetByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Workers Create
     */
    async declarativeViewsWorkerWorkersCreateRaw(requestParameters: DeclarativeViewsWorkerWorkersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WorkerListSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsWorkerWorkersCreate.');
        }

        if (requestParameters.cluster === null || requestParameters.cluster === undefined) {
            throw new runtime.RequiredError('cluster','Required parameter requestParameters.cluster was null or undefined when calling declarativeViewsWorkerWorkersCreate.');
        }

        if (requestParameters.workerCreateSchema === null || requestParameters.workerCreateSchema === undefined) {
            throw new runtime.RequiredError('workerCreateSchema','Required parameter requestParameters.workerCreateSchema was null or undefined when calling declarativeViewsWorkerWorkersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/workers/account/{account}/cluster/{cluster}/`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"cluster"}}`, encodeURIComponent(String(requestParameters.cluster))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkerCreateSchemaToJSON(requestParameters.workerCreateSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkerListSchemaFromJSON));
    }

    /**
     * Workers Create
     */
    async declarativeViewsWorkerWorkersCreate(requestParameters: DeclarativeViewsWorkerWorkersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WorkerListSchema>> {
        const response = await this.declarativeViewsWorkerWorkersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Workers Get List
     */
    async declarativeViewsWorkerWorkersGetListRaw(requestParameters: DeclarativeViewsWorkerWorkersGetListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WorkerListSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsWorkerWorkersGetList.');
        }

        if (requestParameters.cluster === null || requestParameters.cluster === undefined) {
            throw new runtime.RequiredError('cluster','Required parameter requestParameters.cluster was null or undefined when calling declarativeViewsWorkerWorkersGetList.');
        }

        const queryParameters: any = {};

        if (requestParameters.name) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.reason !== undefined) {
            queryParameters['reason'] = requestParameters.reason;
        }

        if (requestParameters.target !== undefined) {
            queryParameters['target'] = requestParameters.target;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/workers/account/{account}/cluster/{cluster}/`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"cluster"}}`, encodeURIComponent(String(requestParameters.cluster))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkerListSchemaFromJSON));
    }

    /**
     * Workers Get List
     */
    async declarativeViewsWorkerWorkersGetList(requestParameters: DeclarativeViewsWorkerWorkersGetListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WorkerListSchema>> {
        const response = await this.declarativeViewsWorkerWorkersGetListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Workers Stop
     */
    async declarativeViewsWorkerWorkersStopRaw(requestParameters: DeclarativeViewsWorkerWorkersStopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WorkerListSchema>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling declarativeViewsWorkerWorkersStop.');
        }

        if (requestParameters.cluster === null || requestParameters.cluster === undefined) {
            throw new runtime.RequiredError('cluster','Required parameter requestParameters.cluster was null or undefined when calling declarativeViewsWorkerWorkersStop.');
        }

        const queryParameters: any = {};

        if (requestParameters.name) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.reason !== undefined) {
            queryParameters['reason'] = requestParameters.reason;
        }

        if (requestParameters.target !== undefined) {
            queryParameters['target'] = requestParameters.target;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/workers/account/{account}/cluster/{cluster}/`.replace(`{${"account"}}`, encodeURIComponent(String(requestParameters.account))).replace(`{${"cluster"}}`, encodeURIComponent(String(requestParameters.cluster))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkerListSchemaFromJSON));
    }

    /**
     * Workers Stop
     */
    async declarativeViewsWorkerWorkersStop(requestParameters: DeclarativeViewsWorkerWorkersStopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WorkerListSchema>> {
        const response = await this.declarativeViewsWorkerWorkersStopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Members
     */
    async declarativeViewsWorkspaceListMembersRaw(requestParameters: DeclarativeViewsWorkspaceListMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedWorkspaceMemberSchema>> {
        if (requestParameters.workspace === null || requestParameters.workspace === undefined) {
            throw new runtime.RequiredError('workspace','Required parameter requestParameters.workspace was null or undefined when calling declarativeViewsWorkspaceListMembers.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/workspaces/{workspace}/members`.replace(`{${"workspace"}}`, encodeURIComponent(String(requestParameters.workspace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedWorkspaceMemberSchemaFromJSON(jsonValue));
    }

    /**
     * List Members
     */
    async declarativeViewsWorkspaceListMembers(requestParameters: DeclarativeViewsWorkspaceListMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedWorkspaceMemberSchema> {
        const response = await this.declarativeViewsWorkspaceListMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add Interaction
     */
    async interactionsViewsAddInteractionRaw(requestParameters: InteractionsViewsAddInteractionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userInteractionSchema === null || requestParameters.userInteractionSchema === undefined) {
            throw new runtime.RequiredError('userInteractionSchema','Required parameter requestParameters.userInteractionSchema was null or undefined when calling interactionsViewsAddInteraction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/interactions/interaction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserInteractionSchemaToJSON(requestParameters.userInteractionSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add Interaction
     */
    async interactionsViewsAddInteraction(requestParameters: InteractionsViewsAddInteractionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.interactionsViewsAddInteractionRaw(requestParameters, initOverrides);
    }

    /**
     * Get or create the get started interaction.  This is so we don\'t call multiple endpoints just to fetch and then create the interaction. It also allow us to pre-fill some of the things to be used in the frontend.
     * Get Get Started Interaction
     */
    async interactionsViewsGetGetStartedInteractionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFlagInteractionSchema>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/interactions/get-started`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFlagInteractionSchemaFromJSON(jsonValue));
    }

    /**
     * Get or create the get started interaction.  This is so we don\'t call multiple endpoints just to fetch and then create the interaction. It also allow us to pre-fill some of the things to be used in the frontend.
     * Get Get Started Interaction
     */
    async interactionsViewsGetGetStartedInteraction(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFlagInteractionSchema> {
        const response = await this.interactionsViewsGetGetStartedInteractionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get User Flag
     */
    async interactionsViewsGetUserFlagRaw(requestParameters: InteractionsViewsGetUserFlagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFlagInteractionSchema>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling interactionsViewsGetUserFlag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/interactions/flag/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFlagInteractionSchemaFromJSON(jsonValue));
    }

    /**
     * Get User Flag
     */
    async interactionsViewsGetUserFlag(requestParameters: InteractionsViewsGetUserFlagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFlagInteractionSchema> {
        const response = await this.interactionsViewsGetUserFlagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get User Flags
     */
    async interactionsViewsGetUserFlagsRaw(requestParameters: InteractionsViewsGetUserFlagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserFlagInteractionSchema>>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling interactionsViewsGetUserFlags.');
        }

        const queryParameters: any = {};

        if (requestParameters.name) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/interactions/flag`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFlagInteractionSchemaFromJSON));
    }

    /**
     * Get User Flags
     */
    async interactionsViewsGetUserFlags(requestParameters: InteractionsViewsGetUserFlagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserFlagInteractionSchema>> {
        const response = await this.interactionsViewsGetUserFlagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get User Hello Interactions
     */
    async interactionsViewsGetUserHelloInteractionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserInteractionListSchema>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/interactions/user-interactions/hello`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserInteractionListSchemaFromJSON));
    }

    /**
     * Get User Hello Interactions
     */
    async interactionsViewsGetUserHelloInteractions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserInteractionListSchema>> {
        const response = await this.interactionsViewsGetUserHelloInteractionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get User Interactions
     */
    async interactionsViewsGetUserInteractionsRaw(requestParameters: InteractionsViewsGetUserInteractionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedUserInteractionListSchema>> {
        const queryParameters: any = {};

        if (requestParameters.account !== undefined) {
            queryParameters['account'] = requestParameters.account;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.compoundScope !== undefined) {
            queryParameters['compound_scope'] = requestParameters.compoundScope;
        }

        if (requestParameters.customStaffScope !== undefined) {
            queryParameters['custom_staff_scope'] = requestParameters.customStaffScope;
        }

        if (requestParameters.timestamp !== undefined) {
            queryParameters['timestamp'] = requestParameters.timestamp;
        }

        if (requestParameters.action !== undefined) {
            queryParameters['action'] = requestParameters.action;
        }

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.success !== undefined) {
            queryParameters['success'] = requestParameters.success;
        }

        if (requestParameters.errorMessage !== undefined) {
            queryParameters['error_message'] = requestParameters.errorMessage;
        }

        if (requestParameters.errorClass !== undefined) {
            queryParameters['error_class'] = requestParameters.errorClass;
        }

        if (requestParameters.isStaff !== undefined) {
            queryParameters['is_staff'] = requestParameters.isStaff;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/interactions/user-interactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedUserInteractionListSchemaFromJSON(jsonValue));
    }

    /**
     * Get User Interactions
     */
    async interactionsViewsGetUserInteractions(requestParameters: InteractionsViewsGetUserInteractionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedUserInteractionListSchema> {
        const response = await this.interactionsViewsGetUserInteractionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get User Interactions List Key Values
     */
    async interactionsViewsGetUserInteractionsListKeyValuesRaw(requestParameters: InteractionsViewsGetUserInteractionsListKeyValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling interactionsViewsGetUserInteractionsListKeyValues.');
        }

        if (requestParameters.startsWith === null || requestParameters.startsWith === undefined) {
            throw new runtime.RequiredError('startsWith','Required parameter requestParameters.startsWith was null or undefined when calling interactionsViewsGetUserInteractionsListKeyValues.');
        }

        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        if (requestParameters.startsWith !== undefined) {
            queryParameters['starts_with'] = requestParameters.startsWith;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/interactions/user-interactions/key/values`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get User Interactions List Key Values
     */
    async interactionsViewsGetUserInteractionsListKeyValues(requestParameters: InteractionsViewsGetUserInteractionsListKeyValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.interactionsViewsGetUserInteractionsListKeyValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get User Interactions List Keys
     */
    async interactionsViewsGetUserInteractionsListKeysRaw(requestParameters: InteractionsViewsGetUserInteractionsListKeysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling interactionsViewsGetUserInteractionsListKeys.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/interactions/user-interactions/keys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get User Interactions List Keys
     */
    async interactionsViewsGetUserInteractionsListKeys(requestParameters: InteractionsViewsGetUserInteractionsListKeysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.interactionsViewsGetUserInteractionsListKeysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Get Started Interaction
     */
    async interactionsViewsUpdateGetStartedInteractionRaw(requestParameters: InteractionsViewsUpdateGetStartedInteractionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFlagInteractionSchema>> {
        if (requestParameters.userFlagInteractionSchema === null || requestParameters.userFlagInteractionSchema === undefined) {
            throw new runtime.RequiredError('userFlagInteractionSchema','Required parameter requestParameters.userFlagInteractionSchema was null or undefined when calling interactionsViewsUpdateGetStartedInteraction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/interactions/get-started`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserFlagInteractionSchemaToJSON(requestParameters.userFlagInteractionSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFlagInteractionSchemaFromJSON(jsonValue));
    }

    /**
     * Update Get Started Interaction
     */
    async interactionsViewsUpdateGetStartedInteraction(requestParameters: InteractionsViewsUpdateGetStartedInteractionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFlagInteractionSchema> {
        const response = await this.interactionsViewsUpdateGetStartedInteractionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update User Flag
     */
    async interactionsViewsUpdateUserFlagRaw(requestParameters: InteractionsViewsUpdateUserFlagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFlagInteractionSchema>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling interactionsViewsUpdateUserFlag.');
        }

        if (requestParameters.userFlagUpdateCreateSchema === null || requestParameters.userFlagUpdateCreateSchema === undefined) {
            throw new runtime.RequiredError('userFlagUpdateCreateSchema','Required parameter requestParameters.userFlagUpdateCreateSchema was null or undefined when calling interactionsViewsUpdateUserFlag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/interactions/flag/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserFlagUpdateCreateSchemaToJSON(requestParameters.userFlagUpdateCreateSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFlagInteractionSchemaFromJSON(jsonValue));
    }

    /**
     * Update User Flag
     */
    async interactionsViewsUpdateUserFlag(requestParameters: InteractionsViewsUpdateUserFlagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFlagInteractionSchema> {
        const response = await this.interactionsViewsUpdateUserFlagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Billing Event
     */
    async pricingNinjaViewsBillingEventsCreateBillingEventRaw(requestParameters: PricingNinjaViewsBillingEventsCreateBillingEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingEventListSchema>> {
        if (requestParameters.createBillingEventSchema === null || requestParameters.createBillingEventSchema === undefined) {
            throw new runtime.RequiredError('createBillingEventSchema','Required parameter requestParameters.createBillingEventSchema was null or undefined when calling pricingNinjaViewsBillingEventsCreateBillingEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/billing-events/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBillingEventSchemaToJSON(requestParameters.createBillingEventSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingEventListSchemaFromJSON(jsonValue));
    }

    /**
     * Create Billing Event
     */
    async pricingNinjaViewsBillingEventsCreateBillingEvent(requestParameters: PricingNinjaViewsBillingEventsCreateBillingEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingEventListSchema> {
        const response = await this.pricingNinjaViewsBillingEventsCreateBillingEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Billing Events List Key Values
     */
    async pricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesRaw(requestParameters: PricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling pricingNinjaViewsBillingEventsGetBillingEventsListKeyValues.');
        }

        if (requestParameters.startsWith === null || requestParameters.startsWith === undefined) {
            throw new runtime.RequiredError('startsWith','Required parameter requestParameters.startsWith was null or undefined when calling pricingNinjaViewsBillingEventsGetBillingEventsListKeyValues.');
        }

        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        if (requestParameters.startsWith !== undefined) {
            queryParameters['starts_with'] = requestParameters.startsWith;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/billing-events/key/values`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Billing Events List Key Values
     */
    async pricingNinjaViewsBillingEventsGetBillingEventsListKeyValues(requestParameters: PricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.pricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Billing Events
     */
    async pricingNinjaViewsBillingEventsListBillingEventsRaw(requestParameters: PricingNinjaViewsBillingEventsListBillingEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedBillingEventListSchema>> {
        const queryParameters: any = {};

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.account !== undefined) {
            queryParameters['account'] = requestParameters.account;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organization_id'] = requestParameters.organizationId;
        }

        if (requestParameters.accountId !== undefined) {
            queryParameters['account_id'] = requestParameters.accountId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/billing-events/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedBillingEventListSchemaFromJSON(jsonValue));
    }

    /**
     * List Billing Events
     */
    async pricingNinjaViewsBillingEventsListBillingEvents(requestParameters: PricingNinjaViewsBillingEventsListBillingEventsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedBillingEventListSchema> {
        const response = await this.pricingNinjaViewsBillingEventsListBillingEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Downgrade To Essentials
     */
    async pricingNinjaViewsProgramDowngradeToEssentialsRaw(requestParameters: PricingNinjaViewsProgramDowngradeToEssentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramSchema>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling pricingNinjaViewsProgramDowngradeToEssentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/downgrade-to-essentials/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramSchemaFromJSON(jsonValue));
    }

    /**
     * Downgrade To Essentials
     */
    async pricingNinjaViewsProgramDowngradeToEssentials(requestParameters: PricingNinjaViewsProgramDowngradeToEssentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramSchema> {
        const response = await this.pricingNinjaViewsProgramDowngradeToEssentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Organization Billing Contact
     */
    async pricingNinjaViewsProgramGetOrganizationBillingContactRaw(requestParameters: PricingNinjaViewsProgramGetOrganizationBillingContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingContactSchema>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling pricingNinjaViewsProgramGetOrganizationBillingContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/organization/{organization_id}/billing-contact`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingContactSchemaFromJSON(jsonValue));
    }

    /**
     * Get Organization Billing Contact
     */
    async pricingNinjaViewsProgramGetOrganizationBillingContact(requestParameters: PricingNinjaViewsProgramGetOrganizationBillingContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingContactSchema> {
        const response = await this.pricingNinjaViewsProgramGetOrganizationBillingContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Organization Payment History
     */
    async pricingNinjaViewsProgramGetOrganizationPaymentHistoryRaw(requestParameters: PricingNinjaViewsProgramGetOrganizationPaymentHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentHistorySchema>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling pricingNinjaViewsProgramGetOrganizationPaymentHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/organization/{organization_id}/payment-history`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentHistorySchemaFromJSON(jsonValue));
    }

    /**
     * Get Organization Payment History
     */
    async pricingNinjaViewsProgramGetOrganizationPaymentHistory(requestParameters: PricingNinjaViewsProgramGetOrganizationPaymentHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentHistorySchema> {
        const response = await this.pricingNinjaViewsProgramGetOrganizationPaymentHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Organization Payment Method
     */
    async pricingNinjaViewsProgramGetOrganizationPaymentMethodRaw(requestParameters: PricingNinjaViewsProgramGetOrganizationPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentMethodSchema>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling pricingNinjaViewsProgramGetOrganizationPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/organization/{organization_id}/payment-method`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentMethodSchemaFromJSON(jsonValue));
    }

    /**
     * Get Organization Payment Method
     */
    async pricingNinjaViewsProgramGetOrganizationPaymentMethod(requestParameters: PricingNinjaViewsProgramGetOrganizationPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentMethodSchema> {
        const response = await this.pricingNinjaViewsProgramGetOrganizationPaymentMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Organization Program Usage
     */
    async pricingNinjaViewsProgramGetOrganizationProgramUsageRaw(requestParameters: PricingNinjaViewsProgramGetOrganizationProgramUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationProgramUsageSchema>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling pricingNinjaViewsProgramGetOrganizationProgramUsage.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = (requestParameters.startDate as any).toISOString().substring(0,10);
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = (requestParameters.endDate as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/organization/{organization_id}/usage`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationProgramUsageSchemaFromJSON(jsonValue));
    }

    /**
     * Get Organization Program Usage
     */
    async pricingNinjaViewsProgramGetOrganizationProgramUsage(requestParameters: PricingNinjaViewsProgramGetOrganizationProgramUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationProgramUsageSchema> {
        const response = await this.pricingNinjaViewsProgramGetOrganizationProgramUsageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Program
     */
    async pricingNinjaViewsProgramGetProgramRaw(requestParameters: PricingNinjaViewsProgramGetProgramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramSchema>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling pricingNinjaViewsProgramGetProgram.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = (requestParameters.startDate as any).toISOString().substring(0,10);
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = (requestParameters.endDate as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramSchemaFromJSON(jsonValue));
    }

    /**
     * Get Program
     */
    async pricingNinjaViewsProgramGetProgram(requestParameters: PricingNinjaViewsProgramGetProgramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramSchema> {
        const response = await this.pricingNinjaViewsProgramGetProgramRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Program Usage
     */
    async pricingNinjaViewsProgramGetProgramUsageRaw(requestParameters: PricingNinjaViewsProgramGetProgramUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramUsageSchema>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling pricingNinjaViewsProgramGetProgramUsage.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = (requestParameters.startDate as any).toISOString().substring(0,10);
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = (requestParameters.endDate as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/usage/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramUsageSchemaFromJSON(jsonValue));
    }

    /**
     * Get Program Usage
     */
    async pricingNinjaViewsProgramGetProgramUsage(requestParameters: PricingNinjaViewsProgramGetProgramUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramUsageSchema> {
        const response = await this.pricingNinjaViewsProgramGetProgramUsageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Workspace Program Usage
     */
    async pricingNinjaViewsProgramGetWorkspaceProgramUsageRaw(requestParameters: PricingNinjaViewsProgramGetWorkspaceProgramUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceProgramUsageSchema>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling pricingNinjaViewsProgramGetWorkspaceProgramUsage.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = (requestParameters.startDate as any).toISOString().substring(0,10);
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = (requestParameters.endDate as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/workspace/{workspace_id}/usage`.replace(`{${"workspace_id"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceProgramUsageSchemaFromJSON(jsonValue));
    }

    /**
     * Get Workspace Program Usage
     */
    async pricingNinjaViewsProgramGetWorkspaceProgramUsage(requestParameters: PricingNinjaViewsProgramGetWorkspaceProgramUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceProgramUsageSchema> {
        const response = await this.pricingNinjaViewsProgramGetWorkspaceProgramUsageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Limits
     */
    async pricingNinjaViewsProgramUpdateLimitsRaw(requestParameters: PricingNinjaViewsProgramUpdateLimitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramSchema>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling pricingNinjaViewsProgramUpdateLimits.');
        }

        if (requestParameters.updateLimitsSchema === null || requestParameters.updateLimitsSchema === undefined) {
            throw new runtime.RequiredError('updateLimitsSchema','Required parameter requestParameters.updateLimitsSchema was null or undefined when calling pricingNinjaViewsProgramUpdateLimits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/update/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLimitsSchemaToJSON(requestParameters.updateLimitsSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramSchemaFromJSON(jsonValue));
    }

    /**
     * Update Limits
     */
    async pricingNinjaViewsProgramUpdateLimits(requestParameters: PricingNinjaViewsProgramUpdateLimitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramSchema> {
        const response = await this.pricingNinjaViewsProgramUpdateLimitsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Limits Staff
     */
    async pricingNinjaViewsProgramUpdateLimitsStaffRaw(requestParameters: PricingNinjaViewsProgramUpdateLimitsStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramSchema>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling pricingNinjaViewsProgramUpdateLimitsStaff.');
        }

        if (requestParameters.updateLimitsSchemaStaff === null || requestParameters.updateLimitsSchemaStaff === undefined) {
            throw new runtime.RequiredError('updateLimitsSchemaStaff','Required parameter requestParameters.updateLimitsSchemaStaff was null or undefined when calling pricingNinjaViewsProgramUpdateLimitsStaff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/update-staff/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLimitsSchemaStaffToJSON(requestParameters.updateLimitsSchemaStaff),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramSchemaFromJSON(jsonValue));
    }

    /**
     * Update Limits Staff
     */
    async pricingNinjaViewsProgramUpdateLimitsStaff(requestParameters: PricingNinjaViewsProgramUpdateLimitsStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramSchema> {
        const response = await this.pricingNinjaViewsProgramUpdateLimitsStaffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upgrade To Professional
     */
    async pricingNinjaViewsProgramUpgradeToProfessionalRaw(requestParameters: PricingNinjaViewsProgramUpgradeToProfessionalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramSchema>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling pricingNinjaViewsProgramUpgradeToProfessional.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/pricing/upgrade-to-professional/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramSchemaFromJSON(jsonValue));
    }

    /**
     * Upgrade To Professional
     */
    async pricingNinjaViewsProgramUpgradeToProfessional(requestParameters: PricingNinjaViewsProgramUpgradeToProfessionalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramSchema> {
        const response = await this.pricingNinjaViewsProgramUpgradeToProfessionalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Active Subscriptions
     */
    async subscriptionsViewsSubscriptionsActiveSubscriptionsRaw(requestParameters: SubscriptionsViewsSubscriptionsActiveSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionSchema>> {
        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organization_id'] = requestParameters.organizationId;
        }

        if (requestParameters.workspaceSlug !== undefined) {
            queryParameters['workspace_slug'] = requestParameters.workspaceSlug;
        }

        if (requestParameters.workspaceId !== undefined) {
            queryParameters['workspace_id'] = requestParameters.workspaceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/subscriptions/subscriptions/active-subscription`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionSchemaFromJSON(jsonValue));
    }

    /**
     * Active Subscriptions
     */
    async subscriptionsViewsSubscriptionsActiveSubscriptions(requestParameters: SubscriptionsViewsSubscriptionsActiveSubscriptionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionSchema> {
        const response = await this.subscriptionsViewsSubscriptionsActiveSubscriptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Billing Portal
     */
    async subscriptionsViewsSubscriptionsBillingPortalRaw(requestParameters: SubscriptionsViewsSubscriptionsBillingPortalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling subscriptionsViewsSubscriptionsBillingPortal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/subscriptions/subscriptions/billing-portal/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Billing Portal
     */
    async subscriptionsViewsSubscriptionsBillingPortal(requestParameters: SubscriptionsViewsSubscriptionsBillingPortalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.subscriptionsViewsSubscriptionsBillingPortalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Checkout Session
     */
    async subscriptionsViewsSubscriptionsCheckoutSessionRaw(requestParameters: SubscriptionsViewsSubscriptionsCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling subscriptionsViewsSubscriptionsCheckoutSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/subscriptions/subscriptions/checkout-session/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Checkout Session
     */
    async subscriptionsViewsSubscriptionsCheckoutSession(requestParameters: SubscriptionsViewsSubscriptionsCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.subscriptionsViewsSubscriptionsCheckoutSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Subscription
     */
    async subscriptionsViewsSubscriptionsGetSubscriptionRaw(requestParameters: SubscriptionsViewsSubscriptionsGetSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionSchema>> {
        if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
            throw new runtime.RequiredError('subscriptionId','Required parameter requestParameters.subscriptionId was null or undefined when calling subscriptionsViewsSubscriptionsGetSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/subscriptions/subscriptions/subscription/{subscription_id}`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionSchemaFromJSON(jsonValue));
    }

    /**
     * Get Subscription
     */
    async subscriptionsViewsSubscriptionsGetSubscription(requestParameters: SubscriptionsViewsSubscriptionsGetSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionSchema> {
        const response = await this.subscriptionsViewsSubscriptionsGetSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handle Stripe webhook events: https://dashboard.stripe.com/webhooks/  Setup the Coiled URL: https://cloud.coiled.io/api/v1/stripe-webook Setup Event types:   - customer.created   - customer.subscription.created   - customer.subscription.updated   - customer.subscription.deleted   - invoice.created   - invoice.paid   - invoice.updated   - invoice.payment_failed   - invoiceitem.created   - payment_method.attached   - product.created   - price.created   - payment_intent.created   - payment_intent.succeeded   - setup_intent (all setup_intent events)  If we want to track more events, add them to Stripe and create the corresponding `event_type` hook here.
     * Stripe Webhook
     */
    async subscriptionsViewsWebhooksStripeWebhookRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/subscriptions/webhooks/stripe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Stripe webhook events: https://dashboard.stripe.com/webhooks/  Setup the Coiled URL: https://cloud.coiled.io/api/v1/stripe-webook Setup Event types:   - customer.created   - customer.subscription.created   - customer.subscription.updated   - customer.subscription.deleted   - invoice.created   - invoice.paid   - invoice.updated   - invoice.payment_failed   - invoiceitem.created   - payment_method.attached   - product.created   - price.created   - payment_intent.created   - payment_intent.succeeded   - setup_intent (all setup_intent events)  If we want to track more events, add them to Stripe and create the corresponding `event_type` hook here.
     * Stripe Webhook
     */
    async subscriptionsViewsWebhooksStripeWebhook(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.subscriptionsViewsWebhooksStripeWebhookRaw(initOverrides);
    }

    /**
     * Get Group List
     */
    async usersViewsGroupGetGroupListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomGroupSchema>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/group/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomGroupSchemaFromJSON));
    }

    /**
     * Get Group List
     */
    async usersViewsGroupGetGroupList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomGroupSchema>> {
        const response = await this.usersViewsGroupGetGroupListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set Org Groups
     */
    async usersViewsGroupSetOrgGroupsRaw(requestParameters: UsersViewsGroupSetOrgGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomGroupSchema>>> {
        if (requestParameters.setOrgGroupsSchema === null || requestParameters.setOrgGroupsSchema === undefined) {
            throw new runtime.RequiredError('setOrgGroupsSchema','Required parameter requestParameters.setOrgGroupsSchema was null or undefined when calling usersViewsGroupSetOrgGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/group/organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrgGroupsSchemaToJSON(requestParameters.setOrgGroupsSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomGroupSchemaFromJSON));
    }

    /**
     * Set Org Groups
     */
    async usersViewsGroupSetOrgGroups(requestParameters: UsersViewsGroupSetOrgGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomGroupSchema>> {
        const response = await this.usersViewsGroupSetOrgGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Organization Invite
     */
    async usersViewsInvitesDeleteOrganizationInviteRaw(requestParameters: UsersViewsInvitesDeleteOrganizationInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling usersViewsInvitesDeleteOrganizationInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/invites/organization-invite/{invite_id}`.replace(`{${"invite_id"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Organization Invite
     */
    async usersViewsInvitesDeleteOrganizationInvite(requestParameters: UsersViewsInvitesDeleteOrganizationInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersViewsInvitesDeleteOrganizationInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Workspace Invite
     */
    async usersViewsInvitesDeleteWorkspaceInviteRaw(requestParameters: UsersViewsInvitesDeleteWorkspaceInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling usersViewsInvitesDeleteWorkspaceInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/invites/workspace-invite/{invite_id}`.replace(`{${"invite_id"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Workspace Invite
     */
    async usersViewsInvitesDeleteWorkspaceInvite(requestParameters: UsersViewsInvitesDeleteWorkspaceInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersViewsInvitesDeleteWorkspaceInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Get Organization Invite
     */
    async usersViewsInvitesGetOrganizationInviteRaw(requestParameters: UsersViewsInvitesGetOrganizationInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationInviteSchema>> {
        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling usersViewsInvitesGetOrganizationInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/invites/organization-invite/{invite_id}`.replace(`{${"invite_id"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationInviteSchemaFromJSON(jsonValue));
    }

    /**
     * Get Organization Invite
     */
    async usersViewsInvitesGetOrganizationInvite(requestParameters: UsersViewsInvitesGetOrganizationInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationInviteSchema> {
        const response = await this.usersViewsInvitesGetOrganizationInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Organization Invites
     */
    async usersViewsInvitesOrganizationInvitesRaw(requestParameters: UsersViewsInvitesOrganizationInvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrganizationInviteSchema>>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling usersViewsInvitesOrganizationInvites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/invites/organization/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationInviteSchemaFromJSON));
    }

    /**
     * Organization Invites
     */
    async usersViewsInvitesOrganizationInvites(requestParameters: UsersViewsInvitesOrganizationInvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrganizationInviteSchema>> {
        const response = await this.usersViewsInvitesOrganizationInvitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend Org Invite
     */
    async usersViewsInvitesResendOrgInviteRaw(requestParameters: UsersViewsInvitesResendOrgInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling usersViewsInvitesResendOrgInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/invites/organization-invite-resend/{invite_id}`.replace(`{${"invite_id"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend Org Invite
     */
    async usersViewsInvitesResendOrgInvite(requestParameters: UsersViewsInvitesResendOrgInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersViewsInvitesResendOrgInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Update Organization Invite
     */
    async usersViewsInvitesUpdateOrganizationInviteRaw(requestParameters: UsersViewsInvitesUpdateOrganizationInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationInviteSchema>> {
        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling usersViewsInvitesUpdateOrganizationInvite.');
        }

        if (requestParameters.updateOrganizationInviteSchema === null || requestParameters.updateOrganizationInviteSchema === undefined) {
            throw new runtime.RequiredError('updateOrganizationInviteSchema','Required parameter requestParameters.updateOrganizationInviteSchema was null or undefined when calling usersViewsInvitesUpdateOrganizationInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/invites/organization-invite/{invite_id}`.replace(`{${"invite_id"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationInviteSchemaToJSON(requestParameters.updateOrganizationInviteSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationInviteSchemaFromJSON(jsonValue));
    }

    /**
     * Update Organization Invite
     */
    async usersViewsInvitesUpdateOrganizationInvite(requestParameters: UsersViewsInvitesUpdateOrganizationInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationInviteSchema> {
        const response = await this.usersViewsInvitesUpdateOrganizationInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Workspace Invite
     */
    async usersViewsInvitesUpdateWorkspaceInviteRaw(requestParameters: UsersViewsInvitesUpdateWorkspaceInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceInviteSchema>> {
        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling usersViewsInvitesUpdateWorkspaceInvite.');
        }

        if (requestParameters.updateWorkspaceInviteDirectSchema === null || requestParameters.updateWorkspaceInviteDirectSchema === undefined) {
            throw new runtime.RequiredError('updateWorkspaceInviteDirectSchema','Required parameter requestParameters.updateWorkspaceInviteDirectSchema was null or undefined when calling usersViewsInvitesUpdateWorkspaceInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/invites/workspace-invite/{invite_id}`.replace(`{${"invite_id"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkspaceInviteDirectSchemaToJSON(requestParameters.updateWorkspaceInviteDirectSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceInviteSchemaFromJSON(jsonValue));
    }

    /**
     * Update Workspace Invite
     */
    async usersViewsInvitesUpdateWorkspaceInvite(requestParameters: UsersViewsInvitesUpdateWorkspaceInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceInviteSchema> {
        const response = await this.usersViewsInvitesUpdateWorkspaceInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Workspace Invites
     */
    async usersViewsInvitesWorkspaceInvitesRaw(requestParameters: UsersViewsInvitesWorkspaceInvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WorkspaceInviteSchema>>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling usersViewsInvitesWorkspaceInvites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/invites/workspace/{workspace_id}`.replace(`{${"workspace_id"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkspaceInviteSchemaFromJSON));
    }

    /**
     * Workspace Invites
     */
    async usersViewsInvitesWorkspaceInvites(requestParameters: UsersViewsInvitesWorkspaceInvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WorkspaceInviteSchema>> {
        const response = await this.usersViewsInvitesWorkspaceInvitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Organization Add Member
     */
    async usersViewsOrganizationsOrganizationAddMemberRaw(requestParameters: UsersViewsOrganizationsOrganizationAddMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddResultSchema>> {
        if (requestParameters.addUserToOrganizationSchema === null || requestParameters.addUserToOrganizationSchema === undefined) {
            throw new runtime.RequiredError('addUserToOrganizationSchema','Required parameter requestParameters.addUserToOrganizationSchema was null or undefined when calling usersViewsOrganizationsOrganizationAddMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/organizations/add-membership`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddUserToOrganizationSchemaToJSON(requestParameters.addUserToOrganizationSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddResultSchemaFromJSON(jsonValue));
    }

    /**
     * Organization Add Member
     */
    async usersViewsOrganizationsOrganizationAddMember(requestParameters: UsersViewsOrganizationsOrganizationAddMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddResultSchema> {
        const response = await this.usersViewsOrganizationsOrganizationAddMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Organization Create
     */
    async usersViewsOrganizationsOrganizationCreateRaw(requestParameters: UsersViewsOrganizationsOrganizationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationSchema>> {
        if (requestParameters.createOrganizationSchema === null || requestParameters.createOrganizationSchema === undefined) {
            throw new runtime.RequiredError('createOrganizationSchema','Required parameter requestParameters.createOrganizationSchema was null or undefined when calling usersViewsOrganizationsOrganizationCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/organizations/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrganizationSchemaToJSON(requestParameters.createOrganizationSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationSchemaFromJSON(jsonValue));
    }

    /**
     * Organization Create
     */
    async usersViewsOrganizationsOrganizationCreate(requestParameters: UsersViewsOrganizationsOrganizationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationSchema> {
        const response = await this.usersViewsOrganizationsOrganizationCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Organization Delete
     */
    async usersViewsOrganizationsOrganizationDeleteRaw(requestParameters: UsersViewsOrganizationsOrganizationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling usersViewsOrganizationsOrganizationDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/organizations/delete/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Organization Delete
     */
    async usersViewsOrganizationsOrganizationDelete(requestParameters: UsersViewsOrganizationsOrganizationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersViewsOrganizationsOrganizationDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Organization Detail
     */
    async usersViewsOrganizationsOrganizationDetailRaw(requestParameters: UsersViewsOrganizationsOrganizationDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationSchema>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling usersViewsOrganizationsOrganizationDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/organizations/details/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationSchemaFromJSON(jsonValue));
    }

    /**
     * Organization Detail
     */
    async usersViewsOrganizationsOrganizationDetail(requestParameters: UsersViewsOrganizationsOrganizationDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationSchema> {
        const response = await this.usersViewsOrganizationsOrganizationDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Organization Members
     */
    async usersViewsOrganizationsOrganizationMembersRaw(requestParameters: UsersViewsOrganizationsOrganizationMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedOrganizationMembershipSchema>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling usersViewsOrganizationsOrganizationMembers.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/organizations/members/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedOrganizationMembershipSchemaFromJSON(jsonValue));
    }

    /**
     * Organization Members
     */
    async usersViewsOrganizationsOrganizationMembers(requestParameters: UsersViewsOrganizationsOrganizationMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedOrganizationMembershipSchema> {
        const response = await this.usersViewsOrganizationsOrganizationMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Organization Membership Detail
     */
    async usersViewsOrganizationsOrganizationMembershipDetailRaw(requestParameters: UsersViewsOrganizationsOrganizationMembershipDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationMembershipSchema>> {
        if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
            throw new runtime.RequiredError('membershipId','Required parameter requestParameters.membershipId was null or undefined when calling usersViewsOrganizationsOrganizationMembershipDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/organizations/membership/{membership_id}`.replace(`{${"membership_id"}}`, encodeURIComponent(String(requestParameters.membershipId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationMembershipSchemaFromJSON(jsonValue));
    }

    /**
     * Organization Membership Detail
     */
    async usersViewsOrganizationsOrganizationMembershipDetail(requestParameters: UsersViewsOrganizationsOrganizationMembershipDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationMembershipSchema> {
        const response = await this.usersViewsOrganizationsOrganizationMembershipDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Organization Remove Member
     */
    async usersViewsOrganizationsOrganizationRemoveMemberRaw(requestParameters: UsersViewsOrganizationsOrganizationRemoveMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
            throw new runtime.RequiredError('membershipId','Required parameter requestParameters.membershipId was null or undefined when calling usersViewsOrganizationsOrganizationRemoveMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/organizations/remove-membership/{membership_id}`.replace(`{${"membership_id"}}`, encodeURIComponent(String(requestParameters.membershipId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Organization Remove Member
     */
    async usersViewsOrganizationsOrganizationRemoveMember(requestParameters: UsersViewsOrganizationsOrganizationRemoveMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersViewsOrganizationsOrganizationRemoveMemberRaw(requestParameters, initOverrides);
    }

    /**
     * Organization Update Member
     */
    async usersViewsOrganizationsOrganizationUpdateMemberRaw(requestParameters: UsersViewsOrganizationsOrganizationUpdateMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationMembershipSchema>> {
        if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
            throw new runtime.RequiredError('membershipId','Required parameter requestParameters.membershipId was null or undefined when calling usersViewsOrganizationsOrganizationUpdateMember.');
        }

        if (requestParameters.updateOrganizationMembershipSchema === null || requestParameters.updateOrganizationMembershipSchema === undefined) {
            throw new runtime.RequiredError('updateOrganizationMembershipSchema','Required parameter requestParameters.updateOrganizationMembershipSchema was null or undefined when calling usersViewsOrganizationsOrganizationUpdateMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/organizations/update-membership/{membership_id}`.replace(`{${"membership_id"}}`, encodeURIComponent(String(requestParameters.membershipId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationMembershipSchemaToJSON(requestParameters.updateOrganizationMembershipSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationMembershipSchemaFromJSON(jsonValue));
    }

    /**
     * Organization Update Member
     */
    async usersViewsOrganizationsOrganizationUpdateMember(requestParameters: UsersViewsOrganizationsOrganizationUpdateMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationMembershipSchema> {
        const response = await this.usersViewsOrganizationsOrganizationUpdateMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Organizations
     */
    async usersViewsOrganizationsOrganizationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrganizationListSchema>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/organizations/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationListSchemaFromJSON));
    }

    /**
     * Organizations
     */
    async usersViewsOrganizationsOrganizations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrganizationListSchema>> {
        const response = await this.usersViewsOrganizationsOrganizationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Organizations Staff
     */
    async usersViewsOrganizationsOrganizationsStaffRaw(requestParameters: UsersViewsOrganizationsOrganizationsStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedOrganizationStaffListSchema>> {
        const queryParameters: any = {};

        if (requestParameters.account !== undefined) {
            queryParameters['account'] = requestParameters.account;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.compoundScope !== undefined) {
            queryParameters['compound_scope'] = requestParameters.compoundScope;
        }

        if (requestParameters.customStaffScope !== undefined) {
            queryParameters['custom_staff_scope'] = requestParameters.customStaffScope;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/organizations/staff-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedOrganizationStaffListSchemaFromJSON(jsonValue));
    }

    /**
     * Organizations Staff
     */
    async usersViewsOrganizationsOrganizationsStaff(requestParameters: UsersViewsOrganizationsOrganizationsStaffRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedOrganizationStaffListSchema> {
        const response = await this.usersViewsOrganizationsOrganizationsStaffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Organizations Staff Values
     */
    async usersViewsOrganizationsOrganizationsStaffValuesRaw(requestParameters: UsersViewsOrganizationsOrganizationsStaffValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.startsWith !== undefined) {
            queryParameters['starts_with'] = requestParameters.startsWith;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/organizations/staff-list-values`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Organizations Staff Values
     */
    async usersViewsOrganizationsOrganizationsStaffValues(requestParameters: UsersViewsOrganizationsOrganizationsStaffValuesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.usersViewsOrganizationsOrganizationsStaffValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change Default Organization
     */
    async usersViewsUserChangeDefaultOrganizationRaw(requestParameters: UsersViewsUserChangeDefaultOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSchema>> {
        if (requestParameters.setDefaultOrganizationSchema === null || requestParameters.setDefaultOrganizationSchema === undefined) {
            throw new runtime.RequiredError('setDefaultOrganizationSchema','Required parameter requestParameters.setDefaultOrganizationSchema was null or undefined when calling usersViewsUserChangeDefaultOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/user/change-default-organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetDefaultOrganizationSchemaToJSON(requestParameters.setDefaultOrganizationSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSchemaFromJSON(jsonValue));
    }

    /**
     * Change Default Organization
     */
    async usersViewsUserChangeDefaultOrganization(requestParameters: UsersViewsUserChangeDefaultOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSchema> {
        const response = await this.usersViewsUserChangeDefaultOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Organization Memberships
     */
    async usersViewsUserGetOrganizationMembershipsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrganizationMembershipSchema>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/user/organization/memberships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationMembershipSchemaFromJSON));
    }

    /**
     * Get Organization Memberships
     */
    async usersViewsUserGetOrganizationMemberships(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrganizationMembershipSchema>> {
        const response = await this.usersViewsUserGetOrganizationMembershipsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Valid Scopes
     */
    async usersViewsUserGetValidScopesRaw(requestParameters: UsersViewsUserGetValidScopesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidScopesSchema>> {
        const queryParameters: any = {};

        if (requestParameters.account !== undefined) {
            queryParameters['account'] = requestParameters.account;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/user/valid-scopes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidScopesSchemaFromJSON(jsonValue));
    }

    /**
     * Get Valid Scopes
     */
    async usersViewsUserGetValidScopes(requestParameters: UsersViewsUserGetValidScopesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidScopesSchema> {
        const response = await this.usersViewsUserGetValidScopesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Workspace
     */
    async usersViewsWorkspacesCreateWorkspaceRaw(requestParameters: UsersViewsWorkspacesCreateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceSchema>> {
        if (requestParameters.createWorkspaceSchema === null || requestParameters.createWorkspaceSchema === undefined) {
            throw new runtime.RequiredError('createWorkspaceSchema','Required parameter requestParameters.createWorkspaceSchema was null or undefined when calling usersViewsWorkspacesCreateWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/workspaces/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkspaceSchemaToJSON(requestParameters.createWorkspaceSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceSchemaFromJSON(jsonValue));
    }

    /**
     * Create Workspace
     */
    async usersViewsWorkspacesCreateWorkspace(requestParameters: UsersViewsWorkspacesCreateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceSchema> {
        const response = await this.usersViewsWorkspacesCreateWorkspaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Workspace
     */
    async usersViewsWorkspacesUpdateWorkspaceRaw(requestParameters: UsersViewsWorkspacesUpdateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceSchema>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling usersViewsWorkspacesUpdateWorkspace.');
        }

        if (requestParameters.updateWorkspaceSchema === null || requestParameters.updateWorkspaceSchema === undefined) {
            throw new runtime.RequiredError('updateWorkspaceSchema','Required parameter requestParameters.updateWorkspaceSchema was null or undefined when calling usersViewsWorkspacesUpdateWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/workspaces/details/{workspace_id}`.replace(`{${"workspace_id"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkspaceSchemaToJSON(requestParameters.updateWorkspaceSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceSchemaFromJSON(jsonValue));
    }

    /**
     * Update Workspace
     */
    async usersViewsWorkspacesUpdateWorkspace(requestParameters: UsersViewsWorkspacesUpdateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceSchema> {
        const response = await this.usersViewsWorkspacesUpdateWorkspaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Workspace Detail
     */
    async usersViewsWorkspacesWorkspaceDetailRaw(requestParameters: UsersViewsWorkspacesWorkspaceDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceSchema>> {
        const queryParameters: any = {};

        if (requestParameters.workspaceSlug !== undefined) {
            queryParameters['workspace_slug'] = requestParameters.workspaceSlug;
        }

        if (requestParameters.workspaceId !== undefined) {
            queryParameters['workspace_id'] = requestParameters.workspaceId;
        }

        if (requestParameters.workspaceName !== undefined) {
            queryParameters['workspace_name'] = requestParameters.workspaceName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/workspaces/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceSchemaFromJSON(jsonValue));
    }

    /**
     * Workspace Detail
     */
    async usersViewsWorkspacesWorkspaceDetail(requestParameters: UsersViewsWorkspacesWorkspaceDetailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceSchema> {
        const response = await this.usersViewsWorkspacesWorkspaceDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Workspace Remove Membership
     */
    async usersViewsWorkspacesWorkspaceRemoveMembershipRaw(requestParameters: UsersViewsWorkspacesWorkspaceRemoveMembershipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
            throw new runtime.RequiredError('membershipId','Required parameter requestParameters.membershipId was null or undefined when calling usersViewsWorkspacesWorkspaceRemoveMembership.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/workspaces/membership/{membership_id}`.replace(`{${"membership_id"}}`, encodeURIComponent(String(requestParameters.membershipId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Workspace Remove Membership
     */
    async usersViewsWorkspacesWorkspaceRemoveMembership(requestParameters: UsersViewsWorkspacesWorkspaceRemoveMembershipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersViewsWorkspacesWorkspaceRemoveMembershipRaw(requestParameters, initOverrides);
    }

    /**
     * Workspace Update Membership
     */
    async usersViewsWorkspacesWorkspaceUpdateMembershipRaw(requestParameters: UsersViewsWorkspacesWorkspaceUpdateMembershipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceMembershipSchema>> {
        if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
            throw new runtime.RequiredError('membershipId','Required parameter requestParameters.membershipId was null or undefined when calling usersViewsWorkspacesWorkspaceUpdateMembership.');
        }

        if (requestParameters.updateWorkspaceMembershipSchema === null || requestParameters.updateWorkspaceMembershipSchema === undefined) {
            throw new runtime.RequiredError('updateWorkspaceMembershipSchema','Required parameter requestParameters.updateWorkspaceMembershipSchema was null or undefined when calling usersViewsWorkspacesWorkspaceUpdateMembership.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/workspaces/membership/{membership_id}`.replace(`{${"membership_id"}}`, encodeURIComponent(String(requestParameters.membershipId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkspaceMembershipSchemaToJSON(requestParameters.updateWorkspaceMembershipSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMembershipSchemaFromJSON(jsonValue));
    }

    /**
     * Workspace Update Membership
     */
    async usersViewsWorkspacesWorkspaceUpdateMembership(requestParameters: UsersViewsWorkspacesWorkspaceUpdateMembershipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceMembershipSchema> {
        const response = await this.usersViewsWorkspacesWorkspaceUpdateMembershipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Workspaces
     */
    async usersViewsWorkspacesWorkspacesRaw(requestParameters: UsersViewsWorkspacesWorkspacesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WorkspaceLightWithMembershipSchema>>> {
        const queryParameters: any = {};

        if (requestParameters.account !== undefined) {
            queryParameters['account'] = requestParameters.account;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.compoundScope !== undefined) {
            queryParameters['compound_scope'] = requestParameters.compoundScope;
        }

        if (requestParameters.customStaffScope !== undefined) {
            queryParameters['custom_staff_scope'] = requestParameters.customStaffScope;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organization_id'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/v2/users/workspaces/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkspaceLightWithMembershipSchemaFromJSON));
    }

    /**
     * Workspaces
     */
    async usersViewsWorkspacesWorkspaces(requestParameters: UsersViewsWorkspacesWorkspacesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WorkspaceLightWithMembershipSchema>> {
        const response = await this.usersViewsWorkspacesWorkspacesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Workos Login Page
     */
    async workosIntegrationViewsGetWorkosLoginPageRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/workos/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get Workos Login Page
     */
    async workosIntegrationViewsGetWorkosLoginPage(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.workosIntegrationViewsGetWorkosLoginPageRaw(initOverrides);
    }

    /**
     * Workos Code Login
     */
    async workosIntegrationViewsWorkosCodeLoginRaw(requestParameters: WorkosIntegrationViewsWorkosCodeLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling workosIntegrationViewsWorkosCodeLogin.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v2/workos/success`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Workos Code Login
     */
    async workosIntegrationViewsWorkosCodeLogin(requestParameters: WorkosIntegrationViewsWorkosCodeLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.workosIntegrationViewsWorkosCodeLoginRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const DeclarativeViewsClusterClusterListV3StateEnum = {
    Pending: 'pending',
    Starting: 'starting',
    Scaling: 'scaling',
    Ready: 'ready',
    Pausing: 'pausing',
    Paused: 'paused',
    Stopping: 'stopping',
    Stopped: 'stopped',
    Error: 'error'
} as const;
export type DeclarativeViewsClusterClusterListV3StateEnum = typeof DeclarativeViewsClusterClusterListV3StateEnum[keyof typeof DeclarativeViewsClusterClusterListV3StateEnum];
/**
 * @export
 */
export const DeclarativeViewsClusterClusterListV3OrderByEnum = {
    Id: 'id',
    TotalCost: 'total_cost',
    CostPerHour: 'cost_per_hour',
    Created: 'created',
    StaffHasSeen: 'staff_has_seen'
} as const;
export type DeclarativeViewsClusterClusterListV3OrderByEnum = typeof DeclarativeViewsClusterClusterListV3OrderByEnum[keyof typeof DeclarativeViewsClusterClusterListV3OrderByEnum];
/**
 * @export
 */
export const DeclarativeViewsClusterClusterListV3OrderByDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type DeclarativeViewsClusterClusterListV3OrderByDirectionEnum = typeof DeclarativeViewsClusterClusterListV3OrderByDirectionEnum[keyof typeof DeclarativeViewsClusterClusterListV3OrderByDirectionEnum];
/**
 * @export
 */
export const DeclarativeViewsClusterClusterListV3CompoundScopeEnum = {
    Everything: 'Everything',
    JustMine: 'Just mine',
    AllStaff: 'All staff',
    AllExceptStaff: 'All except staff',
    FirstClusterInLast10Days: 'First cluster in last 10 days',
    FirstClusterInLast30Days: 'First cluster in last 30 days',
    InterestingDealStage: 'Interesting deal stage',
    CoiledHosted: 'Coiled-hosted'
} as const;
export type DeclarativeViewsClusterClusterListV3CompoundScopeEnum = typeof DeclarativeViewsClusterClusterListV3CompoundScopeEnum[keyof typeof DeclarativeViewsClusterClusterListV3CompoundScopeEnum];
/**
 * @export
 */
export const DeclarativeViewsPackagesCacheListSourceEnum = {
    Conda: 'conda',
    Pip: 'pip'
} as const;
export type DeclarativeViewsPackagesCacheListSourceEnum = typeof DeclarativeViewsPackagesCacheListSourceEnum[keyof typeof DeclarativeViewsPackagesCacheListSourceEnum];
/**
 * @export
 */
export const DeclarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliasesCompoundScopeEnum = {
    Everything: 'Everything',
    JustMine: 'Just mine',
    AllStaff: 'All staff',
    AllExceptStaff: 'All except staff',
    FirstClusterInLast10Days: 'First cluster in last 10 days',
    FirstClusterInLast30Days: 'First cluster in last 30 days',
    InterestingDealStage: 'Interesting deal stage',
    CoiledHosted: 'Coiled-hosted'
} as const;
export type DeclarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliasesCompoundScopeEnum = typeof DeclarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliasesCompoundScopeEnum[keyof typeof DeclarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliasesCompoundScopeEnum];
/**
 * @export
 */
export const DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesKeyEnum = {
    Name: 'name',
    PackageSync: 'package_sync',
    Status: 'status'
} as const;
export type DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesKeyEnum = typeof DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesKeyEnum[keyof typeof DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesKeyEnum];
/**
 * @export
 */
export const DeclarativeViewsTagsGetAccountTagValuesEndpointCompoundScopeEnum = {
    Everything: 'Everything',
    JustMine: 'Just mine',
    AllStaff: 'All staff',
    AllExceptStaff: 'All except staff',
    FirstClusterInLast10Days: 'First cluster in last 10 days',
    FirstClusterInLast30Days: 'First cluster in last 30 days',
    InterestingDealStage: 'Interesting deal stage',
    CoiledHosted: 'Coiled-hosted'
} as const;
export type DeclarativeViewsTagsGetAccountTagValuesEndpointCompoundScopeEnum = typeof DeclarativeViewsTagsGetAccountTagValuesEndpointCompoundScopeEnum[keyof typeof DeclarativeViewsTagsGetAccountTagValuesEndpointCompoundScopeEnum];
/**
 * @export
 */
export const DeclarativeViewsTagsGetTagListCompoundScopeEnum = {
    Everything: 'Everything',
    JustMine: 'Just mine',
    AllStaff: 'All staff',
    AllExceptStaff: 'All except staff',
    FirstClusterInLast10Days: 'First cluster in last 10 days',
    FirstClusterInLast30Days: 'First cluster in last 30 days',
    InterestingDealStage: 'Interesting deal stage',
    CoiledHosted: 'Coiled-hosted'
} as const;
export type DeclarativeViewsTagsGetTagListCompoundScopeEnum = typeof DeclarativeViewsTagsGetTagListCompoundScopeEnum[keyof typeof DeclarativeViewsTagsGetTagListCompoundScopeEnum];
/**
 * @export
 */
export const DeclarativeViewsUsageGetUsageByUserUnitEnum = {
    Credits: 'credits',
    CoiledCost: 'coiled-cost',
    InstanceMinutes: 'instance-minutes',
    ApproxCloudCost: 'approx-cloud-cost'
} as const;
export type DeclarativeViewsUsageGetUsageByUserUnitEnum = typeof DeclarativeViewsUsageGetUsageByUserUnitEnum[keyof typeof DeclarativeViewsUsageGetUsageByUserUnitEnum];
/**
 * @export
 */
export const DeclarativeViewsUsageGetUsageByUserPeriodEnum = {
    Hour: 'hour',
    Day: 'day',
    Week: 'week',
    Month: 'month'
} as const;
export type DeclarativeViewsUsageGetUsageByUserPeriodEnum = typeof DeclarativeViewsUsageGetUsageByUserPeriodEnum[keyof typeof DeclarativeViewsUsageGetUsageByUserPeriodEnum];
/**
 * @export
 */
export const DeclarativeViewsUsageGetUsageByUserGroupbyEnum = {
    Account: 'account',
    Cluster: 'cluster',
    InstanceType: 'instance_type',
    Tag: 'tag',
    User: 'user',
    Zone: 'zone',
    Organization: 'organization',
    CloudProvider: 'cloud_provider',
    GpuType: 'gpu_type'
} as const;
export type DeclarativeViewsUsageGetUsageByUserGroupbyEnum = typeof DeclarativeViewsUsageGetUsageByUserGroupbyEnum[keyof typeof DeclarativeViewsUsageGetUsageByUserGroupbyEnum];
/**
 * @export
 */
export const DeclarativeViewsUsageGetUsageByUserCompoundScopeEnum = {
    Everything: 'Everything',
    JustMine: 'Just mine',
    AllStaff: 'All staff',
    AllExceptStaff: 'All except staff',
    FirstClusterInLast10Days: 'First cluster in last 10 days',
    FirstClusterInLast30Days: 'First cluster in last 30 days',
    InterestingDealStage: 'Interesting deal stage',
    CoiledHosted: 'Coiled-hosted'
} as const;
export type DeclarativeViewsUsageGetUsageByUserCompoundScopeEnum = typeof DeclarativeViewsUsageGetUsageByUserCompoundScopeEnum[keyof typeof DeclarativeViewsUsageGetUsageByUserCompoundScopeEnum];
/**
 * @export
 */
export const InteractionsViewsGetUserInteractionsCompoundScopeEnum = {
    Everything: 'Everything',
    JustMine: 'Just mine',
    AllStaff: 'All staff',
    AllExceptStaff: 'All except staff',
    FirstClusterInLast10Days: 'First cluster in last 10 days',
    FirstClusterInLast30Days: 'First cluster in last 30 days',
    InterestingDealStage: 'Interesting deal stage',
    CoiledHosted: 'Coiled-hosted'
} as const;
export type InteractionsViewsGetUserInteractionsCompoundScopeEnum = typeof InteractionsViewsGetUserInteractionsCompoundScopeEnum[keyof typeof InteractionsViewsGetUserInteractionsCompoundScopeEnum];
/**
 * @export
 */
export const InteractionsViewsGetUserInteractionsListKeyValuesKeyEnum = {
    Timestamp: 'timestamp',
    Action: 'action',
    Version: 'version',
    Source: 'source',
    Success: 'success',
    ErrorMessage: 'error_message',
    ErrorClass: 'error_class'
} as const;
export type InteractionsViewsGetUserInteractionsListKeyValuesKeyEnum = typeof InteractionsViewsGetUserInteractionsListKeyValuesKeyEnum[keyof typeof InteractionsViewsGetUserInteractionsListKeyValuesKeyEnum];
/**
 * @export
 */
export const PricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesKeyEnum = {
    Organization: 'organization',
    OrganizationId: 'organization_id',
    Account: 'account',
    AccountId: 'account_id'
} as const;
export type PricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesKeyEnum = typeof PricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesKeyEnum[keyof typeof PricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesKeyEnum];
/**
 * @export
 */
export const UsersViewsOrganizationsOrganizationsStaffCompoundScopeEnum = {
    Everything: 'Everything',
    JustMine: 'Just mine',
    AllStaff: 'All staff',
    AllExceptStaff: 'All except staff',
    FirstClusterInLast10Days: 'First cluster in last 10 days',
    FirstClusterInLast30Days: 'First cluster in last 30 days',
    InterestingDealStage: 'Interesting deal stage',
    CoiledHosted: 'Coiled-hosted'
} as const;
export type UsersViewsOrganizationsOrganizationsStaffCompoundScopeEnum = typeof UsersViewsOrganizationsOrganizationsStaffCompoundScopeEnum[keyof typeof UsersViewsOrganizationsOrganizationsStaffCompoundScopeEnum];
/**
 * @export
 */
export const UsersViewsWorkspacesWorkspacesCompoundScopeEnum = {
    Everything: 'Everything',
    JustMine: 'Just mine',
    AllStaff: 'All staff',
    AllExceptStaff: 'All except staff',
    FirstClusterInLast10Days: 'First cluster in last 10 days',
    FirstClusterInLast30Days: 'First cluster in last 30 days',
    InterestingDealStage: 'Interesting deal stage',
    CoiledHosted: 'Coiled-hosted'
} as const;
export type UsersViewsWorkspacesWorkspacesCompoundScopeEnum = typeof UsersViewsWorkspacesWorkspacesCompoundScopeEnum[keyof typeof UsersViewsWorkspacesWorkspacesCompoundScopeEnum];
