import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";

import {
  useTheme,
  Box,
  Breadcrumbs,
  Container,
  Link,
  styled,
  Stack,
} from "@mui/material";

import { UserMenu } from "./UserMenu";
import { SideBar } from "./SideBar";
import { CoiledLogo } from "../icons/CoiledLogo";
import { AccountLimitAlerts } from "../shared-components/Alerts/AccountLimitAlert";
import { ApiTokenAlert } from "../shared-components/Alerts/ApiTokenAlert";
import { BannerAlerts } from "../shared-components/Banners/BannerAlerts";
import { PageContext } from "../shared-components/Page";
import { Help } from "@mui/icons-material";
import { ThemedAppBar } from "./components";
import { UserSourceDialog } from "../shared-components/Dialogs/UserSourceDialog";
import { TrialExpirationAlert } from "../shared-components/Alerts/TrialExpirationAlert";

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  "& .MuiBreadcrumbs-ol": { flexWrap: "nowrap !important" },
  ".MuiBreadcrumbs-li": {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "bold",
    maxWidth: "50hw",
    whiteSpace: "nowrap",
    textAlign: "center",
    overflow: "hidden",
    display: "flex",
    flexWrap: "nowrap",
    textOverflow: "ellipsis",
    gap: theme.spacing(1),
    alignItems: "center",
    ":last-child": {
      color: theme.palette.text.primary,
    },
  },
}));

const AppBarLink = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

type Props = {
  children: any;
};

export const drawerWidth = 200;

export const MainLayout: React.FC<Props> = ({ children }: Props) => {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <Box>
      <Box style={{ height: "10%", marginTop: "32px", marginBottom: "40px" }}>
        <CoiledLogo sx={{ width: "100%", height: "100%", color: "white" }} />
      </Box>
      <SideBar />
    </Box>
  );
  const container = () => window.document.body;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const userMenuOpen = Boolean(anchorEl);
  const handleUserMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const { breadCrumbs, docsUrl } = useContext(PageContext);

  return (
    <Box sx={{ display: "flex" }}>
      <ThemedAppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Stack
              id="left-nav"
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <IconButton
                color="primary"
                size="medium"
                aria-label="open drawer"
                edge="start"
                sx={{
                  [theme.breakpoints.up("md")]: {
                    display: "none",
                  },
                }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <StyledBreadcrumbs
                separator="›"
                aria-label="breadcrumb"
                sx={{
                  "& .MuiBreadcrumbs-ol": { flexWrap: "nowrap !important" },
                  [theme.breakpoints.down("sm")]: {
                    display: "none",
                  },
                  [theme.breakpoints.up("md")]: {
                    // act like a first child if the drawer
                    // is hidden
                    marginLeft: "0 !important",
                  },
                }}
              >
                {breadCrumbs &&
                  breadCrumbs.map((crumb, i) => {
                    return crumb.link ? (
                      <Link
                        component={RouterLink}
                        to={crumb.link}
                        key={i}
                        underline="none"
                        color="inherit"
                        onClick={crumb.onClick}
                      >
                        {crumb.text}
                      </Link>
                    ) : (
                      <Stack
                        key={i}
                        direction="row"
                        spacing={2}
                        alignItems={"center"}
                      >
                        {crumb.text}
                      </Stack>
                    );
                  })}
              </StyledBreadcrumbs>
              {docsUrl && (
                <Link href={docsUrl} underline="hover">
                  <Help
                    sx={{
                      marginLeft: "15px",
                      marginTop: "2px",
                      height: "1.5rem",
                      width: "1.5rem",
                    }}
                  />
                </Link>
              )}
            </Stack>
            <Stack
              id="right-nav"
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <AppBarLink
                href="https://docs.coiled.io"
                target="_blank"
                underline="none"
              >
                Docs
              </AppBarLink>
              <AppBarLink
                href="https://docs.coiled.io/user_guide/support.html"
                target="_blank"
                underline="none"
              >
                Support
              </AppBarLink>
              <UserMenu
                anchorEl={anchorEl}
                userMenuOpen={userMenuOpen}
                handleUserMenuClose={handleUserMenuClose}
                handleUserMenu={handleUserMenu}
              />
            </Stack>
          </Stack>
        </Toolbar>
      </ThemedAppBar>
      {/*
          Note: there's a warning about doing this in js instead to avoid
          duplication of links in seo.

          This is what's seen on smaller screens
        */}
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          variant="temporary"
          anchor={"left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile
          }}
        >
          {drawer}
        </Drawer>
        {/* This is shown on larger screens */}
        <Drawer
          variant="permanent"
          open
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingLeft: 3,
          paddingBottom: 3,
          paddingRight: 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {/*
          This div will be behind the top app bar, setting it to be the same class
          prevents our actual content from being covered up
        */}

        <Stack spacing={2} sx={{ paddingTop: theme.spacing(2) }}>
          <UserSourceDialog />
          <AccountLimitAlerts />
          <ApiTokenAlert />
          <TrialExpirationAlert />
          <BannerAlerts />
          <Container maxWidth={false} disableGutters>
            {children}
          </Container>
        </Stack>
      </Box>
    </Box>
  );
};
