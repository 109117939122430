import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  useDeleteAWSRegionSetup,
  useRegionAzs,
  useRegionSecurityGroups,
  useRegionSubnets,
  useRegionVPCs,
} from "../../../crud/setup/hooks";
import { Add, Delete, Save } from "@mui/icons-material";
import {
  AWSRegionalInfra,
  SubnetComponent,
  UserSecurityGroup,
  UserSubnet,
  UserVPC,
} from "../../../crud/setup/fetch";
import { HookSwitch } from "../../../shared-components/HookSwitch";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { ExpandableCard } from "../../../shared-components/Cards";
import { LoadingButton } from "@mui/lab";
import { ComponentIcon } from "./StateIcon";
import { useOnboarding } from "./hooks";
import { BackendTypeServer } from "../../../domain/people";
import {
  useAccountDetails,
  useIsAccountAdmin,
} from "../../../crud/account/hooks";
import { useOrganization } from "../../../crud/organizations/hooks";
import { Link } from "react-router-dom";
import { analytics } from "../../../analytics";

type ConfirmationDialogProps = {
  title: string;
  open: boolean;
  children: React.ReactNode;
  onClose: (success: boolean) => void;
};

export const ConfirmationDialog = (
  props: ConfirmationDialogProps,
): React.ReactElement => {
  const { onClose, open, ...other } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog open={open} {...other}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
      <DialogActions>
        <Button variant="secondary" autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
type AWSAddSubnetForm = {
  subnetId?: string;
  forWorkers: boolean;
  forSchedulers: boolean;
};

type SubnetDialogProps = {
  availableSubnets: UserSubnet[];
  open: boolean;
  onClose: () => void;
  onSubmit: (result: AWSAddSubnetForm) => void;
};

const customVcpFilter = (
  options: UserVPC[],
  { inputValue }: { inputValue?: string },
) => {
  return options.filter(
    (option) =>
      !inputValue ||
      (option.name || "").includes(inputValue) ||
      (option.id || "").includes(inputValue),
  );
};

const customSgFilter = (
  options: UserSecurityGroup[],
  { inputValue }: { inputValue?: string },
) => {
  return options.filter(
    (option) =>
      !inputValue ||
      (option.groupId || "").includes(inputValue) ||
      (option.securityGroupName || "").includes(inputValue),
  );
};

const customSubnetFilter = (
  options: UserSubnet[],
  { inputValue }: { inputValue?: string },
) => {
  return options.filter(
    (option) =>
      !inputValue ||
      (option.name || "").includes(inputValue) ||
      (option.subnetId || "").includes(inputValue),
  );
};

const SubnetDialog = ({
  availableSubnets,
  open,
  onClose,
  onSubmit,
}: SubnetDialogProps): React.ReactElement => {
  const { reset, control, handleSubmit } = useForm<AWSAddSubnetForm>({
    defaultValues: {
      subnetId: "",
      forWorkers: true,
      forSchedulers: true,
    },
  });
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
          reset();
        })}
      >
        <DialogTitle>Add existing Subnet</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemText primary="Subnet Id" />
              <Controller
                control={control}
                name="subnetId"
                rules={{ required: "Subnet Id is required" }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      if (typeof item === "string") {
                        onChange(item);
                      } else {
                        onChange(item?.subnetId);
                      }
                    }}
                    // for some reason we need to set value to string, and then it gets converted to object
                    // @ts-ignore
                    value={value}
                    sx={{ minWidth: "400px" }}
                    size="small"
                    autoComplete
                    filterOptions={customSubnetFilter}
                    getOptionLabel={(option) =>
                      typeof option === "string"
                        ? option
                        : option?.subnetId || ""
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>
                          {option.name && (
                            <Box
                              sx={{
                                flexGrow: 1,
                                "& span": {
                                  color: (theme) =>
                                    theme.palette.text.secondary,
                                },
                              }}
                            >
                              <>
                                {option.name}
                                <br />
                                <span>
                                  <em>{option.subnetId}</em>
                                  {" - "}
                                </span>
                                <span>
                                  <em>({option.availabilityZone})</em>
                                </span>
                              </>
                            </Box>
                          )}
                          {!option.name && (
                            <Box
                              sx={{
                                flexGrow: 1,
                                "& span": {
                                  color: (theme) =>
                                    theme.palette.text.secondary,
                                },
                              }}
                            >
                              <>
                                {option.subnetId}
                                <br />
                                <span>
                                  <em>{option.availabilityZone}</em>
                                </span>
                              </>
                            </Box>
                          )}
                        </li>
                      );
                    }}
                    options={availableSubnets || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Subnet Id"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Allow Schedulers" />
              <HookSwitch control={control} size="small" name="forSchedulers" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Allow Workers" />
              <HookSwitch control={control} size="small" name="forWorkers" />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="secondary"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Next</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

type RegionFormProps = {
  workspaceSlug: string;
  current: AWSRegionalInfra;
  allowUnmanaged?: boolean;
  onCancel: (id: number) => void;
  onSubmit: (data: AWSRegionalInfra) => void;
};

export const RegionForm = ({
  current,
  allowUnmanaged,
  onCancel,
  onSubmit,
  workspaceSlug,
}: RegionFormProps): React.ReactElement => {
  const isNew = current.id === -1;
  const [onboarding] = useOnboarding();
  const isAdmin = useIsAccountAdmin(workspaceSlug);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const { data: defaultAzs, isLoading: azsLoading } = useRegionAzs(
    workspaceSlug,
    current.region,
  );

  const { data: workspace } = useAccountDetails({
    workspaceSlug,
  });
  const { data: organization } = useOrganization(workspace?.organization?.id);

  const [subnetFormOpen, setSubnetFormOpen] = useState(false);

  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<AWSRegionalInfra>({
    defaultValues: {
      ...current,
    },
  });
  const {
    fields: subnetFields,
    append: appendSubnet,
    remove: removeSubnet,
  } = useFieldArray({
    name: "subnets",
    control,
    keyName: "index",
  });
  const vpc = watch("vpc");
  const subnets = watch("subnets");

  const deleteRegion = useDeleteAWSRegionSetup(workspaceSlug);
  const { data: userSubnets, isLoading: subnetsLoading } = useRegionSubnets(
    workspaceSlug,
    current.region,
    vpc?.vpcId,
  );
  const { data: userSecurityGroups } = useRegionSecurityGroups(
    workspaceSlug,
    current.region,
    vpc?.vpcId,
  );
  const { data: userVpcs } = useRegionVPCs(workspaceSlug, current.region);

  useEffect(() => {
    reset(current);
  }, [current, reset, defaultAzs]);

  const region = watch("region");
  const managed = watch("managed");

  const [editMode, setEditMode] = useState(isNew ? true : false);
  useEffect(() => {
    if (managed && isNew && defaultAzs) {
      removeSubnet();
      setValue("vpc.vpcId", "");
      appendSubnet(
        defaultAzs
          ?.filter((az) => az.type === "availability-zone")
          .map((az, i) => ({
            availabilityZone: az.name,
            forSchedulers: true,
            forWorkers: true,
            id: i,
            subnetId: "",
          })),
      );
    } else if (!managed && isNew) {
      removeSubnet();
      setValue("vpc.vpcId", "");
    }
  }, [setValue, defaultAzs, managed, isNew, removeSubnet, appendSubnet]);

  const wantsCustomButCant =
    !managed && organization?.canUseCustomNetworking === false;

  useEffect(() => {
    if (wantsCustomButCant) {
      analytics.track("wants-custom-but-cant");
    }
  }, [wantsCustomButCant]);

  useEffect(() => {
    if (onboarding && current?.component?.state === "created") {
      // they successfully created the global infra
      // close the form after a short delay
      setTimeout(() => setEditMode(false), 200);
      analytics.track("setup-region-created", {
        provider: BackendTypeServer.AWS_HOSTED,
        region: current.region,
        managed: current.managed,
      });
    }
  }, [defaultAzs, reset, current, isNew, onboarding, analytics]);
  const subnetColumns = useMemo<GridColDef<SubnetComponent>[]>(
    () => [
      {
        field: "component.state",
        valueGetter: (params) => params.row.component,
        renderCell: (params) => (
          <ComponentIcon coiledComponent={params.value} />
        ),
        headerName: "Status",
        maxWidth: 65,
      },
      {
        field: "availabilityZone",
        headerName: "Availability Zone",
        width: 150,
      },

      {
        field: "subnetId",
        headerName: "Subnet ID",
        width: 300,
      },
      {
        field: "forWorkers",
        headerName: "Workers",
        type: "boolean",
        maxWidth: 100,
      },
      {
        field: "forSchedulers",
        headerName: "Schedulers",
        type: "boolean",
        maxWidth: 100,
      },
      {
        field: "cidr",
        headerName: "CIDR",
        maxWidth: 150,
        flex: 1,
      },
      ...(managed || (!managed && !isNew)
        ? []
        : [
            {
              field: "actions",
              type: "actions",
              getActions: (params: GridRowParams) =>
                managed
                  ? []
                  : [
                      <GridActionsCellItem
                        key="delete"
                        icon={
                          <Tooltip key="delete" title="Forget Subnet">
                            <Delete />
                          </Tooltip>
                        }
                        onClick={() => removeSubnet(params.row.index)}
                        label="Delete"
                      />,
                    ],
            },
          ]),
    ],
    [managed, removeSubnet, isNew],
  );
  const complicationsIn = !isNew || !managed;
  return (
    <>
      <ConfirmationDialog
        open={deleteConfirmation}
        onClose={(sucess) => {
          if (sucess) {
            deleteRegion.mutate(current.id);
          }
          setDeleteConfirmation(false);
        }}
        title={"Delete Region"}
      >
        Are you sure you wish to delete the {current.region} region?
      </ConfirmationDialog>
      <SubnetDialog
        open={subnetFormOpen}
        availableSubnets={userSubnets || []}
        onClose={() => setSubnetFormOpen(false)}
        onSubmit={(result) => {
          const existing = userSubnets?.find(
            (s) => s.subnetId === result.subnetId,
          );
          appendSubnet({
            ...result,
            id: subnetFields.length + 1,
            arn: existing?.arn,
            availabilityZone: existing?.availabilityZone,
            cidr: existing?.cidr,
          });
          setSubnetFormOpen(false);
        }}
      />
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
        style={{ width: "100%" }}
      >
        <ExpandableCard
          title={
            current ? (
              <Stack
                direction="row"
                spacing={2}
                alignItems={"center"}
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
              >
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <ComponentIcon
                    coiledComponent={current.component}
                    animateIn
                    confetti
                  />
                  <span>Region - {current.region}</span>
                  {current.default && <Chip label="Default" size="small" />}
                  <Chip
                    label={managed ? "Coiled Managed" : "Custom"}
                    size="small"
                  />
                </Stack>
                {!isNew && (
                  <Stack direction={"row"} sx={{ marginLeft: "auto" }}>
                    {!isNew && (
                      <IconButton
                        disabled={!isDirty || isAdmin}
                        type="submit"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Tooltip title="Save changes">
                          <Save />
                        </Tooltip>
                      </IconButton>
                    )}

                    <IconButton
                      disabled={!isAdmin}
                      onClick={(e) => {
                        if (current.id === -1) {
                          onCancel(current.id);
                        } else {
                          setDeleteConfirmation(true);
                        }
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <Tooltip title="Remove this region">
                        <Delete />
                      </Tooltip>
                    </IconButton>
                  </Stack>
                )}
              </Stack>
            ) : (
              `Region - ${region}`
            )
          }
          isOpen={editMode}
          handleClick={() => setEditMode(!editMode)}
        >
          <CardContent>
            {wantsCustomButCant && (
              <Alert severity="warning" sx={{ marginBottom: "1rem" }}>
                Using a custom/existing network is only supported in the
                Professional Tier. Please see{" "}
                <Link to="https://www.coiled.io/pricing" target="_blank">
                  our pricing page
                </Link>{" "}
                and email{" "}
                <Link to="mailto:support@coiled.io">support@coiled.io</Link> to
                discuss.
              </Alert>
            )}
            <Stack direction="column" spacing={2}>
              {current.component?.state === "error" && (
                <Alert severity="error" sx={{ whiteSpace: "pre-line" }}>
                  <AlertTitle>Error </AlertTitle>
                  {current.component?.reason}
                </Alert>
              )}
              {!isNew && (
                <Alert severity="info">
                  To edit resources, delete and recreate the region
                </Alert>
              )}
              <Tooltip
                title={
                  !isNew
                    ? "This can only be toggled when creating a new region"
                    : allowUnmanaged
                      ? "Toggle between custom and managed regions"
                      : "To create an unmanaged region, first remove any managed regions"
                }
              >
                <div style={{ marginRight: "12px" }}>
                  <HookSwitch
                    control={control}
                    name="managed"
                    label={"Coiled Managed"}
                    disabled={!isNew || !allowUnmanaged}
                  />
                </div>
              </Tooltip>
              <Tooltip
                title={
                  !isNew
                    ? "To change the current default, select a new default region"
                    : "Make this region the default for clusters"
                }
              >
                <div style={{ marginRight: "12px" }}>
                  <HookSwitch
                    control={control}
                    name="default"
                    label="Default Region"
                    disabled={!isNew && current.default}
                  />
                </div>
              </Tooltip>
              <Collapse in={complicationsIn} unmountOnExit>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <ComponentIcon coiledComponent={current?.vpc?.component} />
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disabled={managed || (!managed && !isNew)}
                          onChange={(event, item) => {
                            if (typeof item === "string") {
                              onChange(item);
                            } else {
                              onChange(item?.id);
                            }
                          }}
                          // for some reason we need to set value to string, and then it gets converted to object
                          // @ts-ignore
                          value={value}
                          sx={{ minWidth: "450px" }}
                          autoComplete
                          filterOptions={customVcpFilter}
                          renderOption={(props, option) => {
                            return (
                              <li {...props}>
                                {option.name && (
                                  <Box
                                    sx={{
                                      flexGrow: 1,
                                      "& span": {
                                        color: (theme) =>
                                          theme.palette.text.secondary,
                                      },
                                    }}
                                  >
                                    <>
                                      {option.name}
                                      <br />
                                      <span>
                                        <em>{option.id}</em>
                                      </span>
                                    </>
                                  </Box>
                                )}
                                {!option.name && (
                                  <Typography>{option.id}</Typography>
                                )}
                              </li>
                            );
                          }}
                          options={userVpcs || []}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option?.id || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              placeholder="Select VPC-ID"
                              label="VPC ID"
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      name="vpc.vpcId"
                    />
                  </Stack>
                  {!managed && (
                    <>
                      <Stack direction="row" spacing={2} alignItems={"center"}>
                        <ComponentIcon
                          coiledComponent={
                            current.schedulerSecurityGroup?.component
                          }
                        />
                        <Controller
                          control={control}
                          name="schedulerSecurityGroup"
                          rules={{
                            required: "Scheduler security group is required",
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, item) => {
                                if (typeof item === "string") {
                                  const existingSg = userSecurityGroups?.find(
                                    (sg) => sg.groupId === item,
                                  );
                                  onChange({
                                    id: -1,
                                    groupId: item,
                                    securityGroupName:
                                      existingSg?.securityGroupName,
                                    forSchedulers: true,
                                    forWorkers: false,
                                  });
                                } else if (item) {
                                  onChange({
                                    id: -1,
                                    groupId: item.groupId,
                                    securityGroupName: item.securityGroupName,
                                    forSchedulers: true,
                                    forWorkers: false,
                                  });
                                }
                              }}
                              // for some reason we need to set value to string, and then it gets converted to object
                              // @ts-ignore
                              value={value?.groupId || ""}
                              disabled={!isNew || !vpc?.vpcId}
                              sx={{ minWidth: "450px" }}
                              autoComplete
                              filterOptions={customSgFilter}
                              options={userSecurityGroups || []}
                              getOptionLabel={(option) =>
                                typeof option === "string"
                                  ? option
                                  : option?.groupId || ""
                              }
                              renderOption={(props, option) => {
                                return (
                                  <li {...props}>
                                    <Box
                                      sx={{
                                        flexGrow: 1,
                                        "& span": {
                                          color: (theme) =>
                                            theme.palette.text.secondary,
                                        },
                                      }}
                                    >
                                      {option.securityGroupName ? (
                                        <>
                                          {option.securityGroupName}
                                          <br />
                                          <span>
                                            <em>{option.groupId}</em>
                                          </span>
                                        </>
                                      ) : (
                                        option.groupId
                                      )}
                                    </Box>
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  placeholder="example-123"
                                  label="Scheduler Security Group ID"
                                  InputLabelProps={{ shrink: true }}
                                  sx={{ maxWidth: "450px" }}
                                  value={value?.groupId || ""}
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems={"center"}>
                        <ComponentIcon
                          coiledComponent={
                            current.clusterSecurityGroup?.component
                          }
                        />
                        <Controller
                          control={control}
                          name="clusterSecurityGroup"
                          rules={{
                            required: "Cluster security group is required",
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, item) => {
                                if (typeof item === "string") {
                                  const existingSg = userSecurityGroups?.find(
                                    (sg) => sg.groupId === item,
                                  );
                                  onChange({
                                    id: -1,
                                    groupId: item,
                                    securityGroupName:
                                      existingSg?.securityGroupName,
                                    forSchedulers: true,
                                    forWorkers: true,
                                  });
                                } else if (item) {
                                  onChange({
                                    id: -1,
                                    groupId: item.groupId,
                                    securityGroupName: item.securityGroupName,
                                    forSchedulers: true,
                                    forWorkers: true,
                                  });
                                }
                              }}
                              // for some reason we need to set value to string, and then it gets converted to object
                              // @ts-ignore
                              value={value?.groupId || ""}
                              disabled={!isNew || !vpc?.vpcId}
                              sx={{ minWidth: "450px" }}
                              autoComplete
                              filterOptions={customSgFilter}
                              options={userSecurityGroups || []}
                              getOptionLabel={(option) =>
                                typeof option === "string"
                                  ? option
                                  : option?.groupId || ""
                              }
                              renderOption={(props, option) => {
                                return (
                                  <li {...props}>
                                    <Box
                                      sx={{
                                        flexGrow: 1,
                                        "& span": {
                                          color: (theme) =>
                                            theme.palette.text.secondary,
                                        },
                                      }}
                                    >
                                      {option.securityGroupName ? (
                                        <>
                                          {option.securityGroupName}
                                          <br />
                                          <span>
                                            <em>{option.groupId}</em>
                                          </span>
                                        </>
                                      ) : (
                                        option.groupId
                                      )}
                                    </Box>
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  label="Cluster Security Group Id"
                                  InputLabelProps={{ shrink: true }}
                                  placeholder="example-123"
                                  value={value?.groupId || ""}
                                  sx={{ maxWidth: "450px" }}
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                        />
                      </Stack>
                    </>
                  )}
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <ComponentIcon
                      coiledComponent={current?.cloudwatchLogGroup?.component}
                    />
                    <TextField
                      label={"Cloudwatch Log Group"}
                      value={current?.cloudwatchLogGroup?.logGroupName || ""}
                      disabled
                      size="small"
                      fullWidth
                      sx={{ maxWidth: "300px" }}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Will be created automatically"
                    />
                  </Stack>
                </Stack>
                <Divider>Subnets</Divider>
                <Stack spacing={2}>
                  <DataGridPro
                    rows={subnetFields.map((field, index) => ({
                      ...field,
                      id: index,
                    }))}
                    localeText={{ noRowsLabel: "No Subnets" }}
                    autoHeight
                    loading={azsLoading}
                    hideFooter
                    density="compact"
                    columns={subnetColumns}
                  />
                  {!managed && (
                    <Tooltip
                      title={vpc?.vpcId ? "Add Subnet" : "Add VPC first"}
                    >
                      <span>
                        <Button
                          startIcon={<Add />}
                          disabled={subnetsLoading || !vpc?.vpcId || !isNew}
                          onClick={() => setSubnetFormOpen(true)}
                        >
                          Add Subnet
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </Stack>
              </Collapse>
            </Stack>
            <CardActions>
              {isNew && (
                <Button
                  variant="secondary"
                  startIcon={<Delete />}
                  onClick={() => onCancel(current.id)}
                >
                  Cancel
                </Button>
              )}
              {isNew && (
                <LoadingButton
                  disabled={
                    azsLoading ||
                    !subnets?.length ||
                    (!isDirty && !isNew) ||
                    wantsCustomButCant
                  }
                  startIcon={<Save />}
                  type="submit"
                >
                  Create
                </LoadingButton>
              )}
            </CardActions>
          </CardContent>
        </ExpandableCard>
      </form>
    </>
  );
};
