import React from "react";
import { Legend, YAxis } from "recharts";
import prettyBytes from "pretty-bytes";
import { ChartProps, Variation } from "./types";
import { ZoomableAreaChartWithVariations } from "./ZoomableAreaChartWithVariations";
import { GroupedLegend } from "./GroupedLegend";
import { YAXIS_WIDTH } from "./const";

export const WorkerDiskChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Cluster Total Rate",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}/s` : "0 B/s"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "read_rate&worker-up-30s | sum",
          typeLabel: "read",
        },
        {
          name: "write_rate&worker-up-30s | sum",
          typeLabel: "write",
        },
        // 125Mb/s, default gp3 baseline
        {
          name: "cpu_rate | >0 | sum_by(instance) | count | *125000000",
          typeLabel: "125MiB/s/worker (baseline)",
        },
      ],
      domains: [
        { name: "read", color: "#9ecae9", stack: 0 },
        { name: "write", color: "#eeca3b", stack: 0 },
        {
          name: "125MiB/s/worker (baseline)",
          color: { stroke: "#908e89", fill: "white", opacity: 0 },
          stack: 1,
        },
      ],
    },
    {
      name: "Cluster Data Total",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}` : "0 B"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "read | sum",
          typeLabel: "read",
        },
        {
          name: "write | sum",
          typeLabel: "write",
        },
      ],
      domains: [
        { name: "read", color: "#9ecae9", stack: 0 },
        { name: "write", color: "#eeca3b", stack: 0 },
      ],
    },
    {
      name: "Disk Space (Worker with Least Free)",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}` : "0 B"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "ebs_size|min",
          typeLabel: "size",
        },
        {
          name: "ebs_size-ebs_free|max",
          typeLabel: "used",
        },
        {
          name: "ebs_free|min",
          typeLabel: "free",
        },
      ],
      domains: [
        {
          name: "size",
          color: { stroke: "black", fill: "white", opacity: 0 },
          stack: 0,
        },
        {
          name: "used",
          color: { stroke: "#9ecae9", fill: "#9ecae9", opacity: 0.4 },
          stack: 1,
        },
        {
          name: "free",
          color: { stroke: "#eeca3b", fill: "#eeca3b", opacity: 0.2 },
          stack: 1,
        },
      ],
    },
    {
      name: "Distribution",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}/s` : "0 B/s"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "read_rate&worker-up-30s | min",
          typeLabel: "min (read)",
        },
        {
          name: "read_rate&worker-up-30s | max",
          typeLabel: "max (read)",
        },
        {
          name: "read_rate&worker-up-30s | pct20",
          typeLabel: "pct20 (read)",
        },
        {
          name: "read_rate&worker-up-30s | pct80",
          typeLabel: "pct80 (read)",
        },
        {
          name: "read_rate&worker-up-30s | pct50",
          typeLabel: "median (read)",
        },

        {
          name: "write_rate&worker-up-30s | min",
          typeLabel: "min (write)",
        },
        {
          name: "write_rate&worker-up-30s | max",
          typeLabel: "max (write)",
        },
        {
          name: "write_rate&worker-up-30s | pct20",
          typeLabel: "pct20 (write)",
        },
        {
          name: "write_rate&worker-up-30s | pct80",
          typeLabel: "pct80 (write)",
        },
        {
          name: "write_rate&worker-up-30s | pct50",
          typeLabel: "median (write)",
        },
      ],
      domains: [
        { name: "min (read)", color: null, stack: 0, range: 1 },
        { name: "max (read)", color: null, stack: 0, range: 1 },
        { name: "pct20 (read)", color: null, stack: 0, range: 2 },
        { name: "pct80 (read)", color: null, stack: 0, range: 2 },
        { name: "min (write)", color: null, stack: 0, range: 3 },
        { name: "max (write)", color: null, stack: 0, range: 3 },
        { name: "pct20 (write)", color: null, stack: 0, range: 4 },
        { name: "pct80 (write)", color: null, stack: 0, range: 4 },
        {
          name: "median (read)",
          color: { stroke: "#9ecae9", fill: "white" },
          stack: 0,
        },
        {
          name: "pct20 (read)-pct80 (read)",
          color: { stroke: "", fill: "#9ecae9", opacity: 0.4 },
        },
        {
          name: "min (read)-max (read)",
          color: { stroke: "", fill: "#9ecae9", opacity: 0.2 },
        },
        {
          name: "median (write)",
          color: { stroke: "#eeca3b", fill: "white" },
          stack: 1,
        },
        {
          name: "pct20 (write)-pct80 (write)",
          color: { stroke: "", fill: "#eeca3b", opacity: 0.4 },
        },
        {
          name: "min (write)-max (write)",
          color: { stroke: "", fill: "#eeca3b", opacity: 0.2 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
    {
      name: "Read+Write Distribution",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}/s` : "0 B/s"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "(read_rate+write_rate)&worker-up-30s | min",
          typeLabel: "min",
        },
        {
          name: "(read_rate+write_rate)&worker-up-30s | max",
          typeLabel: "max",
        },
        {
          name: "(read_rate+write_rate)&worker-up-30s | pct20",
          typeLabel: "pct20",
        },
        {
          name: "(read_rate+write_rate)&worker-up-30s | pct80",
          typeLabel: "pct80",
        },
        {
          name: "(read_rate+write_rate)&worker-up-30s | pct50",
          typeLabel: "median",
        },
      ],
      domains: [
        { name: "min", color: null, stack: 0, range: 1 },
        { name: "max", color: null, stack: 0, range: 1 },
        { name: "pct20", color: null, stack: 0, range: 2 },
        { name: "pct80", color: null, stack: 0, range: 2 },
        {
          name: "median",
          color: { stroke: "#8fe38f", fill: "white" },
          stack: 0,
        },
        {
          name: "min-max",
          color: { stroke: "", fill: "#8fe38f", opacity: 0.2 },
        },
        {
          name: "pct20-pct80",
          color: { stroke: "", fill: "#8fe38f", opacity: 0.4 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="Worker Disk"
        variations={variations}
        {...props}
      />
    </>
  );
};

export const CombinedDiskChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Cluster Total Rate",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}/s` : "0 B/s"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "all:read_rate | sum",
          typeLabel: "read",
        },
        {
          name: "all:write_rate | sum",
          typeLabel: "write",
        },
        // 125Mb/s, default gp3 baseline
        {
          name: "all:cpu_rate | >0 | sum_by(instance) | count | *125000000",
          typeLabel: "125MiB/s/worker (baseline)",
        },
      ],
      domains: [
        { name: "read", color: "#9ecae9", stack: 0 },
        { name: "write", color: "#eeca3b", stack: 0 },
        {
          name: "125MiB/s/worker (baseline)",
          color: { stroke: "#908e89", fill: "white", opacity: 0 },
          stack: 1,
        },
      ],
    },
    {
      name: "Cluster Data Total",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}` : "0 B"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "all:read | sum",
          typeLabel: "read",
        },
        {
          name: "all:write | sum",
          typeLabel: "write",
        },
      ],
      domains: [
        { name: "read", color: "#9ecae9", stack: 0 },
        { name: "write", color: "#eeca3b", stack: 0 },
      ],
    },
    {
      name: "Distribution",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}/s` : "0 B/s"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "all:read_rate | min",
          typeLabel: "min (read)",
        },
        {
          name: "all:read_rate | max",
          typeLabel: "max (read)",
        },
        {
          name: "all:read_rate | pct20",
          typeLabel: "pct20 (read)",
        },
        {
          name: "all:read_rate | pct80",
          typeLabel: "pct80 (read)",
        },
        {
          name: "all:read_rate | pct50",
          typeLabel: "median (read)",
        },

        {
          name: "all:write_rate | min",
          typeLabel: "min (write)",
        },
        {
          name: "all:write_rate | max",
          typeLabel: "max (write)",
        },
        {
          name: "all:write_rate | pct20",
          typeLabel: "pct20 (write)",
        },
        {
          name: "all:write_rate | pct80",
          typeLabel: "pct80 (write)",
        },
        {
          name: "all:write_rate | pct50",
          typeLabel: "median (write)",
        },
      ],
      domains: [
        { name: "min (read)", color: null, stack: 0, range: 1 },
        { name: "max (read)", color: null, stack: 0, range: 1 },
        { name: "pct20 (read)", color: null, stack: 0, range: 2 },
        { name: "pct80 (read)", color: null, stack: 0, range: 2 },
        { name: "min (write)", color: null, stack: 0, range: 3 },
        { name: "max (write)", color: null, stack: 0, range: 3 },
        { name: "pct20 (write)", color: null, stack: 0, range: 4 },
        { name: "pct80 (write)", color: null, stack: 0, range: 4 },
        {
          name: "median (read)",
          color: { stroke: "#9ecae9", fill: "white" },
          stack: 0,
        },
        {
          name: "pct20 (read)-pct80 (read)",
          color: { stroke: "", fill: "#9ecae9", opacity: 0.4 },
        },
        {
          name: "min (read)-max (read)",
          color: { stroke: "", fill: "#9ecae9", opacity: 0.2 },
        },
        {
          name: "median (write)",
          color: { stroke: "#eeca3b", fill: "white" },
          stack: 1,
        },
        {
          name: "pct20 (write)-pct80 (write)",
          color: { stroke: "", fill: "#eeca3b", opacity: 0.4 },
        },
        {
          name: "min (write)-max (write)",
          color: { stroke: "", fill: "#eeca3b", opacity: 0.2 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
    {
      name: "Read+Write Distribution",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}/s` : "0 B/s"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "all:(read_rate+write_rate) | min",
          typeLabel: "min",
        },
        {
          name: "all:(read_rate+write_rate) | max",
          typeLabel: "max",
        },
        {
          name: "all:(read_rate+write_rate) | pct20",
          typeLabel: "pct20",
        },
        {
          name: "all:(read_rate+write_rate) | pct80",
          typeLabel: "pct80",
        },
        {
          name: "all:(read_rate+write_rate) | pct50",
          typeLabel: "median",
        },
      ],
      domains: [
        { name: "min", color: null, stack: 0, range: 1 },
        { name: "max", color: null, stack: 0, range: 1 },
        { name: "pct20", color: null, stack: 0, range: 2 },
        { name: "pct80", color: null, stack: 0, range: 2 },
        {
          name: "median",
          color: { stroke: "#8fe38f", fill: "white" },
          stack: 0,
        },
        {
          name: "min-max",
          color: { stroke: "", fill: "#8fe38f", opacity: 0.2 },
        },
        {
          name: "pct20-pct80",
          color: { stroke: "", fill: "#8fe38f", opacity: 0.4 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
    {
      name: "Disk Space",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}` : "0 B"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "all:ebs_size|min",
          typeLabel: "size",
        },
        {
          name: "all:ebs_size-ebs_free|max",
          typeLabel: "used",
        },
        {
          name: "all:ebs_free|min",
          typeLabel: "free",
        },
      ],
      domains: [
        {
          name: "size",
          color: { stroke: "black", fill: "white", opacity: 0 },
          stack: 0,
        },
        {
          name: "used",
          color: { stroke: "#9ecae9", fill: "#9ecae9", opacity: 0.4 },
          stack: 1,
        },
        {
          name: "free",
          color: { stroke: "#eeca3b", fill: "#eeca3b", opacity: 0.2 },
          stack: 1,
        },
      ],
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="Cluster Disk"
        variations={variations}
        {...props}
        yAxis={
          <YAxis
            domain={[0, 125000000]}
            tickFormatter={(value: number) =>
              Number.isFinite(value) ? prettyBytes(value) : "0 B"
            }
            width={YAXIS_WIDTH}
          />
        }
      />
    </>
  );
};

export const SingleNodeDiskChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Total Rate",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}/s` : "0 B/s"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "all:read_rate | sum",
          typeLabel: "read",
        },
        {
          name: "all:write_rate | sum",
          typeLabel: "write",
        },
        {
          name: "all:read_rate+write_rate | sum",
          typeLabel: "read+write",
        },
        // 125Mb/s, default gp3 baseline
        {
          name: "all:cpu_rate | >0 | sum_by(instance) | count | *125000000",
          typeLabel: "125MiB/s/node (baseline)",
        },
      ],
      domains: [
        { name: "read", color: "#9ecae9", stack: 0 },
        { name: "write", color: "#eeca3b", stack: 0 },
        {
          name: "read+write",
          color: { stroke: "black", fill: "white", opacity: 0 },
          stack: 1,
        },
        {
          name: "125MiB/s/node (baseline)",
          color: { stroke: "#908e89", fill: "white", opacity: 0 },
          stack: 2,
        },
      ],
    },
    {
      name: "Data Total",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}` : "0 B"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "all:read | sum",
          typeLabel: "read",
        },
        {
          name: "all:write | sum",
          typeLabel: "write",
        },
      ],
      domains: [
        { name: "read", color: "#9ecae9", stack: 0 },
        { name: "write", color: "#eeca3b", stack: 0 },
      ],
    },
    {
      name: "Disk Space",
      yAxis: (
        <YAxis
          domain={[0, 125000000]}
          tickFormatter={(value: number) =>
            Number.isFinite(value) ? `${prettyBytes(value)}` : "0 B"
          }
          width={YAXIS_WIDTH}
        />
      ),
      groupKey: "type",
      queries: [
        {
          name: "all:ebs_size|min",
          typeLabel: "size",
        },
        {
          name: "all:ebs_size-ebs_free|max",
          typeLabel: "used",
        },
        {
          name: "all:ebs_free|min",
          typeLabel: "free",
        },
      ],
      domains: [
        {
          name: "size",
          color: { stroke: "black", fill: "white", opacity: 0 },
          stack: 0,
        },
        {
          name: "used",
          color: { stroke: "#9ecae9", fill: "#9ecae9", opacity: 0.4 },
          stack: 1,
        },
        {
          name: "free",
          color: { stroke: "#eeca3b", fill: "#eeca3b", opacity: 0.2 },
          stack: 1,
        },
      ],
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="Disk"
        variations={variations}
        {...props}
      />
    </>
  );
};
