import React, { useMemo, useState } from "react";
import { Link, Stack, Tooltip } from "@mui/material";
import { useInteractionFlag } from "../../../crud/interactions/hooks";
import { InteractionFlagNames } from "../../../crud/interactions/types";
import { DataGridProCard } from "../../../shared-components/Cards";
import { Link as RouterLink } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useSoftwareAliases } from "../../../crud/software/hooks";
import { Cloud, SelectAllOutlined } from "@mui/icons-material";
import { BuildStatusChip } from "../../../shared-components/Software/common";
import { NumberParam, withDefault } from "serialize-query-params";
import { useQueryParam } from "use-query-params";
import { Page } from "../../../shared-components/Page";
import { KeyValue, KeyValueAutocomplete } from "../../Staff/AutocompleteCustom";
import { ApiClient } from "../../../apiUtils";
import {
  AccountScopeSchemaTypeEnum,
  CompoundScopeSchemaTypeEnum,
  CompoundScopes,
  DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesKeyEnum,
  OrganizationScopeSchemaTypeEnum,
  SoftwareEnvironmentAliasListSchema,
  UserScopeSchemaTypeEnum,
} from "../../../api-client";
import { camelCase } from "lodash";
import {
  ScopeSelector,
  useScopedContext,
} from "../../../shared-components/ScopeSelector";
import { GetStartedDialogue } from "../../../shared-components/Dialogs/DirectToGettingStartedDialogue";
import { useUserMemberships } from "../../../crud/user/hooks";

export const SoftwareEnvironmentsTable = (): React.ReactElement => {
  const [scope] = useScopedContext();
  const defaultFilters = useMemo(
    () => [
      {
        key: "package_sync",
        value: "False",
      },
    ],
    [],
  );
  const [keyValues, setKeyValues] = useState<KeyValue[]>(defaultFilters);
  const [pageSize, setPageSize] = useQueryParam(
    "pagesize",
    withDefault(NumberParam, 50),
  );

  const { data: memberships } = useUserMemberships();
  const inMultipleWorkspacesOrWorkspacesNotLoaded =
    memberships == null ? true : memberships.length > 1;

  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 0));

  const hasMadeAClusterInteraction = useInteractionFlag(
    InteractionFlagNames.HasCreatedCluster,
  );
  const { data } = useSoftwareAliases({
    limit: pageSize,
    offset: page * pageSize,
    ...keyValues.reduce(
      (obj, item) =>
        Object.assign(obj, { [camelCase(item.key)]: item.value }, {}),
      [],
    ),

    ...(scope.type === "compound"
      ? {
          compoundScope: scope.name,
        }
      : {}),
    ...(scope.type === "account"
      ? {
          account: scope.name,
        }
      : {}),
    ...(scope.type === "organization"
      ? {
          organization: scope.name,
        }
      : {}),
    ...(scope.type === "user"
      ? {
          user: scope.name,
        }
      : {}),
  });

  const crumbs = useMemo(() => [{ text: "Software Environments" }], []);
  const columns = useMemo(
    (): GridColDef<SoftwareEnvironmentAliasListSchema>[] => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        renderCell: (params) => (
          <Link
            component={RouterLink}
            to={`alias/${params.row.id}/account/${params.row.accountSlug}/`}
          >
            {params.value}
          </Link>
        ),
      },
      {
        field: "account",
        headerName: "Workspace",
        flex: 1,
      },
      {
        field: "organization",
        headerName: "Organization",
        flex: 1,
      },
      {
        field: "latestBuildDate",
        headerName: "Last Build",
        type: "dateTime",
        flex: 1,
        valueGetter: (params) => params.row.latestSpec.latestBuild?.created,
      },
      {
        field: "type",
        headerName: "Type",
        renderCell: (params) =>
          params.row.latestSpec.containerUri ? (
            <Tooltip title="Container">
              <SelectAllOutlined />
            </Tooltip>
          ) : (
            <Tooltip title="Coiled Managed">
              <Cloud />
            </Tooltip>
          ),
      },
      {
        field: "buildState",
        valueGetter: (params) => params.row.latestSpec.latestBuild,
        headerName: "Status",
        renderCell: (params) => (
          <BuildStatusChip
            status={params.value?.state}
            reason={params.value?.reason}
          />
        ),
        width: 100,
      },
    ],
    [],
  );

  const hasMadeACluster = hasMadeAClusterInteraction.data?.completed;
  const anyItemsToShow = data?.items !== undefined && data.items.length > 0;

  // let's be pretty conservative about when to show this dialogue forcing users back to "Getting Started":
  // it'd be very bad to refuse to show this page when it actually has something they want to see!
  //
  // We also avoid showing this when data hasn't loaded, to avoid flashing it briefly for people who
  // shouldn't see it.
  //
  // in particular, let's just never show the dialogue if they're in multiple workspaces
  const showGettingStartedDialogue =
    !inMultipleWorkspacesOrWorkspacesNotLoaded &&
    !hasMadeACluster &&
    !anyItemsToShow;

  const scopeKeyValues = useMemo(() => {
    switch (scope.type) {
      case CompoundScopeSchemaTypeEnum.Compound:
        switch (scope.name) {
          case CompoundScopes.JustMine:
            return [
              {
                key: "user",
                value: "me",
              },
            ];
          default:
            return [];
        }
      case AccountScopeSchemaTypeEnum.Account:
        return [
          {
            key: "account",
            value: scope.name,
          },
        ];
      case OrganizationScopeSchemaTypeEnum.Organization:
        return [
          {
            key: "organization",
            value: scope.name,
          },
        ];
      case UserScopeSchemaTypeEnum.User:
        return [
          {
            key: "user",
            value: scope.name,
          },
        ];

      default:
        return [];
    }
  }, [scope]);
  return (
    <Page title="Software Environments" breadCrumbs={crumbs}>
      <Stack spacing={2}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <KeyValueAutocomplete
            disabledKeyValues={scopeKeyValues}
            defaultFilters={defaultFilters}
            keyValuesChanged={setKeyValues}
            fetchKeys={async (key) =>
              Object.values(
                DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesKeyEnum,
              ).filter((k) => k.startsWith(key))
            }
            fetchValues={(key, startsWith) =>
              ApiClient.declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValues(
                {
                  key: key as DeclarativeViewsSoftwareEnvironmentListSoftwareEnvAliasKeyValuesKeyEnum,
                  startsWith,
                },
              )
            }
          />
          <ScopeSelector />
        </Stack>
        <DataGridProCard
          sx={{ height: "80vh" }}
          dataGridProps={{
            disableRowSelectionOnClick: true,
            rowCount: data?.count || 0,
            pagination: true,
            paginationMode: "server",
            paginationModel: {
              pageSize,
              page,
            },
            onPaginationModelChange: (newModel) => {
              setPage(newModel.page);
              setPageSize(newModel.pageSize);
            },
            columns,
            rows: data?.items || [],
          }}
        />
      </Stack>
      <GetStartedDialogue
        open={showGettingStartedDialogue}
        title="See All Your Software"
        message="After you launch your first cloud VMs, you can view and manage all your software here. Whether it's an environment Coiled automatically synced from your local machine or a custom Docker image you've built yourself."
      />
    </Page>
  );
};
