/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RefType } from './RefType';
import {
    RefTypeFromJSON,
    RefTypeFromJSONTyped,
    RefTypeToJSON,
} from './RefType';

/**
 * 
 * @export
 * @interface FileRefSchema
 */
export interface FileRefSchema {
    /**
     * 
     * @type {string}
     * @memberof FileRefSchema
     */
    key: string;
    /**
     * 
     * @type {RefType}
     * @memberof FileRefSchema
     */
    refType: RefType;
    /**
     * 
     * @type {string}
     * @memberof FileRefSchema
     */
    path: string;
    /**
     * 
     * @type {number}
     * @memberof FileRefSchema
     */
    mode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FileRefSchema
     */
    append?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FileRefSchema
     */
    inlineContent?: string;
    /**
     * 
     * @type {object}
     * @memberof FileRefSchema
     */
    fetchOpts?: object;
    /**
     * 
     * @type {boolean}
     * @memberof FileRefSchema
     */
    alwaysFetch?: boolean;
}

/**
 * Check if a given object implements the FileRefSchema interface.
 */
export function instanceOfFileRefSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "refType" in value;
    isInstance = isInstance && "path" in value;

    return isInstance;
}

export function FileRefSchemaFromJSON(json: any): FileRefSchema {
    return FileRefSchemaFromJSONTyped(json, false);
}

export function FileRefSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileRefSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'refType': RefTypeFromJSON(json['ref_type']),
        'path': json['path'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'append': !exists(json, 'append') ? undefined : json['append'],
        'inlineContent': !exists(json, 'inline_content') ? undefined : json['inline_content'],
        'fetchOpts': !exists(json, 'fetch_opts') ? undefined : json['fetch_opts'],
        'alwaysFetch': !exists(json, 'always_fetch') ? undefined : json['always_fetch'],
    };
}

export function FileRefSchemaToJSON(value?: FileRefSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'ref_type': RefTypeToJSON(value.refType),
        'path': value.path,
        'mode': value.mode,
        'append': value.append,
        'inline_content': value.inlineContent,
        'fetch_opts': value.fetchOpts,
        'always_fetch': value.alwaysFetch,
    };
}

