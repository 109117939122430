import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
} from "@mui/material";

import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  createSearchParams,
  Link,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { REDIRECT_COOKIE } from "../cookieUtils";
import { Urls } from "../domain/urls";
import { GithubIcon } from "../icons/GithubIcon";

import { AuthLayout } from "./AuthLayout";
import { REDIRECT_URI_PARAM } from "./constants";
import { LoginFailReasons } from "./types";
import { Page } from "../shared-components/Page";
import { AuthForm, HalfDivider, LeftSection, Split } from "./components";
import { AuthenticationContext } from "../crud/auth/context";
import { useLoginMutation } from "../crud/auth/hooks";
import { useCookie } from "../utils/hooks";
import { HTTPError } from "ky";
import { StringParam, withDefault } from "serialize-query-params";
import { useQueryParam } from "use-query-params";
import { GoogleIcon } from "../icons/GoogleIcon";
import { LoadingButton } from "@mui/lab";
import { AccountBalance } from "@mui/icons-material";
import { analytics } from "../analytics";

export const FE_USER_INFO_FORM_ENABLED = false;

type Inputs = {
  username: string;
  password: string;
};

const ReSendConfirmationEmail = () => {
  return (
    <>
      {" "}
      We need to confirm your email address.
      <br />
      Please check your email or{" "}
      <Link to={`/${Urls.VerifyEmailReSend}`}>
        request another confirmation email
      </Link>
      .
    </>
  );
};

export const LoginForm = (): React.ReactElement => {
  const navigate = useNavigate();
  const loginMutate = useLoginMutation();
  const [searchParams] = useSearchParams();
  const { setToken } = useContext(AuthenticationContext);
  const location = useLocation();

  const match = useMatch(`/${Urls.Login}`);
  if (!match) {
    navigate(
      {
        pathname: `/${Urls.Login}`,
        search: createSearchParams({
          [REDIRECT_URI_PARAM]: `${location.pathname}?${searchParams}`,
        }).toString(),
      },
      {
        replace: true,
      },
    );
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const [requestedRedirect] = useQueryParam(
    REDIRECT_URI_PARAM,
    withDefault(StringParam, "/"),
  );
  const [redirectCookie, setRedirectCookie] = useCookie(REDIRECT_COOKIE);
  useEffect(() => {
    // Store the redirect URI in a cookie, so that we can send the user
    // there after login
    if (requestedRedirect !== redirectCookie) {
      setRedirectCookie(requestedRedirect);
    }
  }, [requestedRedirect, redirectCookie, setRedirectCookie]);
  const [loginError, setLoginError] = useState<LoginFailReasons | undefined>();
  const onSubmit = ({ username, password }: Inputs) => {
    loginMutate.mutate(
      { username, password },
      {
        onSuccess: (t) => {
          setToken(t);
          analytics.track("login-success", { provider: "password" });
          navigate(requestedRedirect);
          setRedirectCookie(undefined);
        },
        onError: async (err) => {
          if (err instanceof HTTPError) {
            if (err.response?.status === 403) {
              setLoginError(LoginFailReasons.LockedAccount);
            }
            const responseBody = await err.response?.json();
            // django rest auth validation error
            const errorMessage = responseBody?.non_field_errors?.[0];
            if (errorMessage === "E-mail is not verified.") {
              setLoginError(LoginFailReasons.ValidateEmail);
            } else if (
              errorMessage === "Unable to log in with provided credentials."
            ) {
              setLoginError(LoginFailReasons.InvalidCredentials);
            } else if (responseBody?.detail === "SSO required") {
              // `detail` is the arg when auth backend raises `AuthenticationFailed` exception
              setLoginError(LoginFailReasons.SsoRequired);
            } else {
              setLoginError(LoginFailReasons.Unknown);
            }
          } else {
            setLoginError(LoginFailReasons.Unknown);
          }
        },
      },
    );
  };
  const breadCrumbs = useMemo(() => [], []);
  return (
    <Page title="Login" breadCrumbs={breadCrumbs}>
      <AuthLayout>
        <Split>
          <LeftSection
            subtitle="Login"
            description="Learn more in the Coiled docs"
            analyticsEvent="cloud-login-getting-started-click"
            href="https://docs.coiled.io/user_guide/setup/index.html"
          />
          <Container
            maxWidth="xs"
            sx={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          >
            <Card sx={{ paddingTop: "2rem" }}>
              <CardHeader
                sx={{
                  textAlign: "center",
                }}
                title="Login to Coiled"
              />
              <CardContent>
                <AuthForm onSubmit={handleSubmit(onSubmit)} id="signin-form">
                  {loginMutate.isError && (
                    <Typography
                      sx={{ marginTop: "16px" }}
                      variant="body1"
                      align="center"
                      color="error"
                    >
                      {loginError === LoginFailReasons.ValidateEmail ? (
                        <ReSendConfirmationEmail />
                      ) : (
                        loginError
                      )}
                    </Typography>
                  )}
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<GithubIcon />}
                    href={`/${Urls.GithubInitLogin}`}
                    onClick={() =>
                      analytics.track("login-clicked", { provider: "github" })
                    }
                    fullWidth
                  >
                    Sign in with GitHub
                  </Button>
                  {/* https://developers.google.com/identity/branding-guidelines -- they _really_ prefer white background */}
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<GoogleIcon />}
                    onClick={() =>
                      analytics.track("login-clicked", { provider: "google" })
                    }
                    href={`/${Urls.GoogleInitLogin}`}
                    fullWidth
                  >
                    Sign in with Google
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<AccountBalance />}
                    href={"/workos"}
                    onClick={() =>
                      analytics.track("login-clicked", { provider: "sso" })
                    }
                    fullWidth
                  >
                    Sign in with SSO
                  </Button>
                  <HalfDivider>Or</HalfDivider>
                  <TextField
                    {...register("username", {
                      required: true,
                    })}
                    error={!!errors.username}
                    helperText={errors.username ? "username is required" : ""}
                    sx={{ width: "100%", marginBottom: "8px" }}
                    variant="outlined"
                    name="username"
                    placeholder="username or email"
                  />

                  <TextField
                    {...register("password", { required: true })}
                    error={!!errors.password}
                    helperText={errors.password ? "password is required" : ""}
                    sx={{ width: "100%", marginBottom: "8px" }}
                    variant="outlined"
                    placeholder="password"
                    type="password"
                  />

                  <LoadingButton
                    fullWidth
                    type="submit"
                    onClick={() =>
                      analytics.track("login-clicked", {
                        provider: "password",
                      })
                    }
                    id="login-form-submit"
                    loading={loginMutate.isLoading}
                  >
                    Login
                  </LoadingButton>
                </AuthForm>

                <Stack direction="row" spacing={2} justifyContent={"center"}>
                  <Button
                    variant="tertiary"
                    component={Link}
                    disableRipple
                    to={{ pathname: `/${Urls.RetrieveUsername}  ` }}
                    sx={{ maxWidth: "285px" }}
                  >
                    Forgot Username?
                  </Button>
                  <Button
                    variant="tertiary"
                    disableRipple
                    component={Link}
                    to={{ pathname: `/${Urls.ForgotPassword}` }}
                    sx={{ maxWidth: "285px" }}
                  >
                    Forgot Password?
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Container>
        </Split>
      </AuthLayout>
    </Page>
  );
};
