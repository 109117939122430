import { CardContent, Stack, Typography } from "@mui/material";
import { ExternalLink } from "../../../auth/components";
import React from "react";

export const AzureInfrastructureForm = ({
  workspaceSlug,
}: {
  workspaceSlug: string;
}): React.ReactElement => {
  return (
    <CardContent>
      <Stack spacing={2}>
        <Typography>
          Azure on Coiled does not currently support customizing your
          infrastructure.
        </Typography>
        <Typography>
          If custom networking is important to you, or if you have any
          questions, please{" "}
          <ExternalLink
            target={"_blank"}
            rel="noopener noreferrer"
            href={"mailto:support@coiled.io"}
          >
            contact us
          </ExternalLink>
          .
        </Typography>
      </Stack>
    </CardContent>
  );
};
