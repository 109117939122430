/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClusterAccountSchema } from './ClusterAccountSchema';
import {
    ClusterAccountSchemaFromJSON,
    ClusterAccountSchemaFromJSONTyped,
    ClusterAccountSchemaToJSON,
} from './ClusterAccountSchema';
import type { ClusterInfrastructureLite } from './ClusterInfrastructureLite';
import {
    ClusterInfrastructureLiteFromJSON,
    ClusterInfrastructureLiteFromJSONTyped,
    ClusterInfrastructureLiteToJSON,
} from './ClusterInfrastructureLite';
import type { ClusterMetricsId } from './ClusterMetricsId';
import {
    ClusterMetricsIdFromJSON,
    ClusterMetricsIdFromJSONTyped,
    ClusterMetricsIdToJSON,
} from './ClusterMetricsId';
import type { ClusterOptions } from './ClusterOptions';
import {
    ClusterOptionsFromJSON,
    ClusterOptionsFromJSONTyped,
    ClusterOptionsToJSON,
} from './ClusterOptions';
import type { ClusterStateSchema } from './ClusterStateSchema';
import {
    ClusterStateSchemaFromJSON,
    ClusterStateSchemaFromJSONTyped,
    ClusterStateSchemaToJSON,
} from './ClusterStateSchema';
import type { ClusterTag } from './ClusterTag';
import {
    ClusterTagFromJSON,
    ClusterTagFromJSONTyped,
    ClusterTagToJSON,
} from './ClusterTag';
import type { ClusterUserSchema } from './ClusterUserSchema';
import {
    ClusterUserSchemaFromJSON,
    ClusterUserSchemaFromJSONTyped,
    ClusterUserSchemaToJSON,
} from './ClusterUserSchema';
import type { MetricFlag } from './MetricFlag';
import {
    MetricFlagFromJSON,
    MetricFlagFromJSONTyped,
    MetricFlagToJSON,
} from './MetricFlag';
import type { SchedulerSchema } from './SchedulerSchema';
import {
    SchedulerSchemaFromJSON,
    SchedulerSchemaFromJSONTyped,
    SchedulerSchemaToJSON,
} from './SchedulerSchema';
import type { SoftwareEnvironmentAliasSchema } from './SoftwareEnvironmentAliasSchema';
import {
    SoftwareEnvironmentAliasSchemaFromJSON,
    SoftwareEnvironmentAliasSchemaFromJSONTyped,
    SoftwareEnvironmentAliasSchemaToJSON,
} from './SoftwareEnvironmentAliasSchema';
import type { WorkerSchema } from './WorkerSchema';
import {
    WorkerSchemaFromJSON,
    WorkerSchemaFromJSONTyped,
    WorkerSchemaToJSON,
} from './WorkerSchema';

/**
 * 
 * @export
 * @interface ClusterSchema
 */
export interface ClusterSchema {
    /**
     * 
     * @type {number}
     * @memberof ClusterSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ClusterSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClusterSchema
     */
    updated: Date;
    /**
     * 
     * @type {string}
     * @memberof ClusterSchema
     */
    name: string;
    /**
     * 
     * @type {ClusterAccountSchema}
     * @memberof ClusterSchema
     */
    account: ClusterAccountSchema;
    /**
     * 
     * @type {ClusterUserSchema}
     * @memberof ClusterSchema
     */
    creator: ClusterUserSchema;
    /**
     * 
     * @type {number}
     * @memberof ClusterSchema
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof ClusterSchema
     */
    backendType: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClusterSchema
     */
    coiledHosted: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ClusterSchema
     */
    schedulerReadyAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClusterSchema
     */
    terminatingAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClusterSchema
     */
    finishedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ClusterSchema
     */
    billableTime?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSchema
     */
    costPerHour?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSchema
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSchema
     */
    desiredWorkers: number;
    /**
     * 
     * @type {string}
     * @memberof ClusterSchema
     */
    schedulerDashboardHostname?: string;
    /**
     * 
     * @type {ClusterStateSchema}
     * @memberof ClusterSchema
     */
    currentState: ClusterStateSchema;
    /**
     * 
     * @type {SchedulerSchema}
     * @memberof ClusterSchema
     */
    scheduler: SchedulerSchema;
    /**
     * 
     * @type {Array<WorkerSchema>}
     * @memberof ClusterSchema
     */
    workers: Array<WorkerSchema>;
    /**
     * 
     * @type {boolean}
     * @memberof ClusterSchema
     */
    stopDesired: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClusterSchema
     */
    stopReason?: string;
    /**
     * 
     * @type {Date}
     * @memberof ClusterSchema
     */
    stopRequestedDt?: Date;
    /**
     * 
     * @type {ClusterOptions}
     * @memberof ClusterSchema
     */
    clusterOptions: ClusterOptions;
    /**
     * 
     * @type {number}
     * @memberof ClusterSchema
     */
    schedulerPort: number;
    /**
     * 
     * @type {ClusterInfrastructureLite}
     * @memberof ClusterSchema
     */
    clusterInfra?: ClusterInfrastructureLite;
    /**
     * 
     * @type {ClusterMetricsId}
     * @memberof ClusterSchema
     */
    clusterMetrics?: ClusterMetricsId;
    /**
     * 
     * @type {string}
     * @memberof ClusterSchema
     */
    internalGrafanaLink?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterSchema
     */
    externalGrafanaLink?: string;
    /**
     * 
     * @type {Array<MetricFlag>}
     * @memberof ClusterSchema
     */
    metricFlags: Array<MetricFlag>;
    /**
     * 
     * @type {string}
     * @memberof ClusterSchema
     */
    daskConfigYaml?: string;
    /**
     * 
     * @type {Array<ClusterTag>}
     * @memberof ClusterSchema
     */
    tagList: Array<ClusterTag>;
    /**
     * 
     * @type {number}
     * @memberof ClusterSchema
     */
    senvBuildId?: number;
    /**
     * 
     * @type {SoftwareEnvironmentAliasSchema}
     * @memberof ClusterSchema
     */
    senvAlias?: SoftwareEnvironmentAliasSchema;
    /**
     * 
     * @type {number}
     * @memberof ClusterSchema
     */
    cloudCostPerHour?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSchema
     */
    cloudTotalCost?: number;
}

/**
 * Check if a given object implements the ClusterSchema interface.
 */
export function instanceOfClusterSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "account" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "accountId" in value;
    isInstance = isInstance && "backendType" in value;
    isInstance = isInstance && "coiledHosted" in value;
    isInstance = isInstance && "desiredWorkers" in value;
    isInstance = isInstance && "currentState" in value;
    isInstance = isInstance && "scheduler" in value;
    isInstance = isInstance && "workers" in value;
    isInstance = isInstance && "stopDesired" in value;
    isInstance = isInstance && "clusterOptions" in value;
    isInstance = isInstance && "schedulerPort" in value;
    isInstance = isInstance && "metricFlags" in value;
    isInstance = isInstance && "tagList" in value;

    return isInstance;
}

export function ClusterSchemaFromJSON(json: any): ClusterSchema {
    return ClusterSchemaFromJSONTyped(json, false);
}

export function ClusterSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'name': json['name'],
        'account': ClusterAccountSchemaFromJSON(json['account']),
        'creator': ClusterUserSchemaFromJSON(json['creator']),
        'accountId': json['account_id'],
        'backendType': json['backend_type'],
        'coiledHosted': json['coiled_hosted'],
        'schedulerReadyAt': !exists(json, 'scheduler_ready_at') ? undefined : (new Date(json['scheduler_ready_at'])),
        'terminatingAt': !exists(json, 'terminating_at') ? undefined : (new Date(json['terminating_at'])),
        'finishedAt': !exists(json, 'finished_at') ? undefined : (new Date(json['finished_at'])),
        'billableTime': !exists(json, 'billable_time') ? undefined : json['billable_time'],
        'costPerHour': !exists(json, 'cost_per_hour') ? undefined : json['cost_per_hour'],
        'totalCost': !exists(json, 'total_cost') ? undefined : json['total_cost'],
        'desiredWorkers': json['desired_workers'],
        'schedulerDashboardHostname': !exists(json, 'scheduler_dashboard_hostname') ? undefined : json['scheduler_dashboard_hostname'],
        'currentState': ClusterStateSchemaFromJSON(json['current_state']),
        'scheduler': SchedulerSchemaFromJSON(json['scheduler']),
        'workers': ((json['workers'] as Array<any>).map(WorkerSchemaFromJSON)),
        'stopDesired': json['stop_desired'],
        'stopReason': !exists(json, 'stop_reason') ? undefined : json['stop_reason'],
        'stopRequestedDt': !exists(json, 'stop_requested_dt') ? undefined : (new Date(json['stop_requested_dt'])),
        'clusterOptions': ClusterOptionsFromJSON(json['cluster_options']),
        'schedulerPort': json['scheduler_port'],
        'clusterInfra': !exists(json, 'cluster_infra') ? undefined : ClusterInfrastructureLiteFromJSON(json['cluster_infra']),
        'clusterMetrics': !exists(json, 'cluster_metrics') ? undefined : ClusterMetricsIdFromJSON(json['cluster_metrics']),
        'internalGrafanaLink': !exists(json, 'internal_grafana_link') ? undefined : json['internal_grafana_link'],
        'externalGrafanaLink': !exists(json, 'external_grafana_link') ? undefined : json['external_grafana_link'],
        'metricFlags': ((json['metric_flags'] as Array<any>).map(MetricFlagFromJSON)),
        'daskConfigYaml': !exists(json, 'dask_config_yaml') ? undefined : json['dask_config_yaml'],
        'tagList': ((json['tag_list'] as Array<any>).map(ClusterTagFromJSON)),
        'senvBuildId': !exists(json, 'senv_build_id') ? undefined : json['senv_build_id'],
        'senvAlias': !exists(json, 'senv_alias') ? undefined : SoftwareEnvironmentAliasSchemaFromJSON(json['senv_alias']),
        'cloudCostPerHour': !exists(json, 'cloud_cost_per_hour') ? undefined : json['cloud_cost_per_hour'],
        'cloudTotalCost': !exists(json, 'cloud_total_cost') ? undefined : json['cloud_total_cost'],
    };
}

export function ClusterSchemaToJSON(value?: ClusterSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'name': value.name,
        'account': ClusterAccountSchemaToJSON(value.account),
        'creator': ClusterUserSchemaToJSON(value.creator),
        'account_id': value.accountId,
        'backend_type': value.backendType,
        'coiled_hosted': value.coiledHosted,
        'scheduler_ready_at': value.schedulerReadyAt === undefined ? undefined : (value.schedulerReadyAt.toISOString()),
        'terminating_at': value.terminatingAt === undefined ? undefined : (value.terminatingAt.toISOString()),
        'finished_at': value.finishedAt === undefined ? undefined : (value.finishedAt.toISOString()),
        'billable_time': value.billableTime,
        'cost_per_hour': value.costPerHour,
        'total_cost': value.totalCost,
        'desired_workers': value.desiredWorkers,
        'scheduler_dashboard_hostname': value.schedulerDashboardHostname,
        'current_state': ClusterStateSchemaToJSON(value.currentState),
        'scheduler': SchedulerSchemaToJSON(value.scheduler),
        'workers': ((value.workers as Array<any>).map(WorkerSchemaToJSON)),
        'stop_desired': value.stopDesired,
        'stop_reason': value.stopReason,
        'stop_requested_dt': value.stopRequestedDt === undefined ? undefined : (value.stopRequestedDt.toISOString()),
        'cluster_options': ClusterOptionsToJSON(value.clusterOptions),
        'scheduler_port': value.schedulerPort,
        'cluster_infra': ClusterInfrastructureLiteToJSON(value.clusterInfra),
        'cluster_metrics': ClusterMetricsIdToJSON(value.clusterMetrics),
        'internal_grafana_link': value.internalGrafanaLink,
        'external_grafana_link': value.externalGrafanaLink,
        'metric_flags': ((value.metricFlags as Array<any>).map(MetricFlagToJSON)),
        'dask_config_yaml': value.daskConfigYaml,
        'tag_list': ((value.tagList as Array<any>).map(ClusterTagToJSON)),
        'senv_build_id': value.senvBuildId,
        'senv_alias': SoftwareEnvironmentAliasSchemaToJSON(value.senvAlias),
        'cloud_cost_per_hour': value.cloudCostPerHour,
        'cloud_total_cost': value.cloudTotalCost,
    };
}

