import React from "react";
import { IconButton, Box, SxProps, useTheme } from "@mui/material";
import copy from "clipboard-copy";

import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { ContentCopyRounded } from "@mui/icons-material";
import { useSnackbar } from "notistack";
// @ts-ignore
import { stackoverflowLight as syntaxTheme } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface CodeBlockProps {
  language?: string;
  snippet: string;
  isTerminal?: boolean;
  wrapLongLines?: boolean;
  sx?: SxProps;
  allowCopy?: boolean;
}

export const CodeBlock = (props: CodeBlockProps): React.ReactElement => {
  const theme = useTheme();
  const { language = "shell", snippet, isTerminal = false } = props;
  const { enqueueSnackbar } = useSnackbar();
  const reallyIsTerminal =
    isTerminal || language === "bash" || language === "shell";
  const handleCopy = async () => {
    await copy(snippet);
    enqueueSnackbar("Code copied to clipboard", { variant: "default" });
  };
  return (
    <Box
      sx={{
        position: "relative",
        "& pre": { margin: "0" },
        width: "fit-content",
        margin: "8px",
        "& .terminal-line::before": {
          // Weird stuff, but we need to wrap content like this otherwise
          // it doesn't work. Yay material ui!
          content: "'$'",
          width: "10px",
          marginRight: "0.5rem",
        },
        ...props.sx,
      }}
    >
      <SyntaxHighlighter
        wrapLines
        wrapLongLines={props.wrapLongLines}
        lineProps={{
          class: reallyIsTerminal ? "terminal-line" : undefined,
        }}
        language={language}
        style={syntaxTheme}
        customStyle={{
          borderRadius: theme.shape.borderRadius,
          width: "88ch",
          paddingRight: theme.spacing(10),
        }}
      >
        {snippet}
      </SyntaxHighlighter>
      {props.allowCopy !== false ? (
        <IconButton
          onClick={() => handleCopy()}
          size="small"
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
            width: "26px",
            borderRadius: 1,
            height: "26px",
            "&:hover": {
              backgroundColor: theme.palette.secondary.light,
              borderColor: "#0062cc",
              "& .MuiSvgIcon-root": {
                color: theme.palette.primary.contrastText,
              },
            },
          }}
        >
          <ContentCopyRounded
            fontSize="small"
            sx={{
              color: theme.palette.primary.main,
            }}
          />
        </IconButton>
      ) : (
        <></>
      )}
    </Box>
  );
};
