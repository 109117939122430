import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import array_result from "../../assets/array_result.png";
import array_input from "../../assets/array_input.png";

import {
  Typography,
  Button,
  Stack,
  CardContent,
  LinearProgress,
  Skeleton,
  Box,
  styled,
  Link,
  Tab,
  Container,
  Card,
  Divider,
  useTheme,
  Stepper,
  Step,
  StepContent,
  StepButton,
  ListItem,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Urls } from "../../domain/urls";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import {
  AllFlags,
  InteractionFlagsContext,
} from "../../crud/interactions/context";
import { CodeBlock } from "../../shared-components/CodeBlock";
import {
  CheckCircle,
  Cloud,
  Computer,
  Functions,
  Login,
  QueryStats,
  Terminal,
} from "@mui/icons-material";
import { WorkspaceContext } from "../../utils/context";
import { TabPanelProps } from "@mui/base";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DaskIcon } from "../../icons/DaskIcon";
import { JupyterIcon } from "../../icons/JupyterIcon";
import { TeamIcon } from "../../icons/TeamIcon";
import { InteractionFlagNames } from "../../crud/interactions/types";
import { TitleCard } from "../../shared-components/Cards";
import List from "@mui/material/List";
import { useProgram, useProgramUsage } from "../../crud/pricing/hooks";
import { BillingMethod } from "../../api-client";
import { calendlyLink } from "../../utils";
import { ApiClient } from "../../apiUtils";

const CustomTabPanel = styled(TabPanel)<TabPanelProps>(({ theme }) => ({
  backgroundColor: theme.palette.custom.white,
  paddingLeft: 0,
  paddingBottom: 0,
}));

enum ValidTabs {
  dataframes = "dataframes",
  ml = "ml",
  xarray = "xarray",
  dask = "dask",
  cli = "cli",
  notebooks = "notebooks",
  functions = "functions",
}

type StepperButtonProps = {
  headerIcon: React.ReactNode;
  header: string;
  subtitle: string | React.ReactNode;
  complete?: boolean;
  button: React.ReactNode;
};

const TutorialTabs = (): React.ReactElement => {
  const [selectedTab, setSelectedTab] = useState<ValidTabs>(
    ValidTabs.dataframes,
  );
  return (
    <TabContext value={selectedTab}>
      <div>
        <TabList
          variant="fullWidth"
          onChange={(e, newValue) => setSelectedTab(newValue)}
        >
          <Tab
            icon={<DaskIcon />}
            label={"Dask: DataFrames"}
            iconPosition="start"
            value={ValidTabs.dataframes}
          />
          <Tab
            icon={<DaskIcon />}
            label={"Dask: Xarray"}
            iconPosition="start"
            value={ValidTabs.xarray}
          />
          <Tab
            icon={<DaskIcon />}
            label={"Dask: ML"}
            iconPosition="start"
            value={ValidTabs.ml}
          />
          <Tab
            icon={<Terminal />}
            label={"Scripts"}
            iconPosition="start"
            value={ValidTabs.cli}
          />
          <Tab
            icon={<Functions />}
            iconPosition="start"
            label={"Serverless Functions"}
            value={ValidTabs.functions}
          />
          <Tab
            icon={<JupyterIcon />}
            label={"Notebooks"}
            iconPosition="start"
            value={ValidTabs.notebooks}
          />
        </TabList>
        <CustomTabPanel value={ValidTabs.dataframes}>
          <Stack spacing={2}>
            <Typography>
              Dask tightly integrates with pandas to run large scale DataFrame
              computations.
            </Typography>
            <CodeBlock
              language="python"
              snippet={`import coiled
cluster = coiled.Cluster(n_workers=10)
client = cluster.get_client()
`}
            />
            <CodeBlock
              language="python"
              snippet={`import dask.dataframe as dd
              
# Load the Uber-Lyft Taxi dataset from s3 (200 GB)
df = dd.read_parquet("s3://coiled-data/uber/")

# How often do New Yorkers tip?
(df.tips != 0).mean().compute()`}
            />
            <Typography>
              Have a look at{" "}
              <Link href="https://docs.coiled.io/user_guide/usage/dask/dataframes.html">
                some more DataFrame/ETL Examples
              </Link>
              .
            </Typography>
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={ValidTabs.xarray}>
          <Stack spacing={2}>
            <Typography>
              Xarray is Python&apos;s most popular modern array library, and is
              able to seemlessly use Dask for parallelization.
            </Typography>
            <CodeBlock
              language="python"
              snippet={`import coiled

cluster = coiled.Cluster(n_workers=15, region="us-east-1")
client = cluster.get_client()
`}
            />
            <Typography>
              We&apos;ll start by opening the National Water Model dataset:
            </Typography>
            <CodeBlock
              language="python"
              snippet={`import xarray as xr
import fsspec

ds = xr.open_zarr(
    fsspec.get_mapper(
        "s3://noaa-nwm-retrospective-2-1-zarr-pds/rtout.zarr", anon=True
    ),
    consolidated=True,
)
`}
            />
            <Box
              component="img"
              sx={{
                width: 600,
              }}
              alt="An image of the input array."
              src={array_input}
            />
            <Typography>Then we can compute means by month:</Typography>
            <CodeBlock
              language="python"
              snippet={`
# Restrict time and spatial components
subset = ds.zwattablrt.sel(time=slice("2001", "2002"))
subset = subset.isel(x=slice(0, 350 * 8), y=slice(0, 350 * 8))

# Compute the mean per month
result = subset.groupby("time.month").mean().persist()

result`}
            />
            <Box
              component="img"
              sx={{
                width: 600,
              }}
              alt="An image of the resulting array."
              src={array_result}
            />

            <Typography>
              Have a look at the{" "}
              <Link href="https://docs.coiled.io/user_guide/xarray.html">
                National Water Model
              </Link>{" "}
              example in our docs for more details.
            </Typography>
            <Typography>
              Or browse the other{" "}
              <Link href="https://docs.coiled.io/user_guide/usage/dask/geospatial.html">
                Geospatial Examples
              </Link>{" "}
              for additional examples of array workloads.
            </Typography>
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={ValidTabs.ml}>
          <Stack spacing={2}>
            <Typography>
              Many of Python&apos;s machine learning libraries integrate with
              Dask to support parallelization.
            </Typography>
            <Typography>
              Here&apos;s an example with an XGBoost model. The Coiled cluster
              lets you train on a large dataset much more quickly than you could
              on a single machine.
            </Typography>
            <CodeBlock
              language="python"
              snippet={`import coiled

cluster = coiled.Cluster(n_workers=20)
client = cluster.get_client()

import dask.dataframe as dd
import xgboost
from dask_ml.metrics import mean_squared_error

df = dd.read_parquet(
  "s3://coiled-datasets/dask-xgboost-example/feature_table.parquet/"
)
df = df.categorize(columns=df.select_dtypes(include="category").columns.tolist()) # Categorize
`}
            />
            <Typography>
              Now train our model and make some predictions:
            </Typography>
            <CodeBlock
              language="python"
              snippet={`train = df.partitions[:200]
test = df.partitions[-2:]
y_train = train["trip_time"]
X_train = train.drop(columns=["trip_time"])
y_test = test["trip_time"]
X_test = test.drop(columns=["trip_time"])


d_train = xgboost.dask.DaskDMatrix(client, X_train, y_train, enable_categorical=True)
model = xgboost.dask.train(
    client,
    {"tree_method": "hist"},
    d_train,
    evals=[(d_train, "train")],
)

predictions = xgboost.dask.predict(client, model, X_test)

score = mean_squared_error(
    y_test.to_dask_array(),
    predictions.to_dask_array(),
    squared=False,
)

print(f"MSE score = {scores.mean()} +/- {scores.std()}")
`}
            />
            <Typography>
              Have a look at the{" "}
              <Link href="https://docs.coiled.io/user_guide/ml.html">
                other ML examples
              </Link>{" "}
              in our docs.
            </Typography>
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={ValidTabs.cli}>
          <Stack spacing={2}>
            <Typography>
              Easily create a cloud VM, run a program, and destroy that VM, all
              from the command line:
            </Typography>
            <CodeBlock
              language="shell"
              snippet={`coiled run echo "Hello, world"`}
            />
            <Typography>
              For more information, see the{" "}
              <Link href="https://docs.coiled.io/user_guide/labs/jobs.html">
                Coiled Run documentation.
              </Link>
            </Typography>
            <Typography>{"Here's a more complex example:"}</Typography>
            <CodeBlock
              language="shell"
              snippet={`coiled run echo "Hello, world" --region us-west-2 --vm-type m7i.16xlarge`}
            />
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={ValidTabs.functions}>
          <Stack spacing={2}>
            <Typography>
              Decorate Python functions to run them in the cloud:
            </Typography>
            <CodeBlock
              language="python"
              snippet={`import coiled, random

@coiled.function()
def estimate_pi(n: int) -> float:
    total = 0
    for _ in range(n):
        x = random.random()
        y = random.random()
        if x ** 2 + y ** 2 < 1:
            total += 1
    return total / n * 4

pi = estimate_pi(100_000)
print(pi)`}
            />
            <Typography>
              Scale out using the map method. You will see new machines in about
              a minute and things will finish in around three minutes.
            </Typography>
            <CodeBlock
              language="python"
              snippet={`L = list(estimate_pi.map([10_000_000] * 1000))
pi = sum(L) / len(L)
print(pi)
`}
            />
            <Typography>
              You can use these from anywhere you run Python locally, including
              Python scripts, IPython, or Jupyter notebooks.
              <br /> More examples:
              <List>
                <ListItem>
                  <Link href="https://docs.coiled.io/user_guide/usage/functions/duckdb.html">
                    Using DuckDB on a big VM
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="https://docs.coiled.io/user_guide/usage/functions/pytorch.html">
                    Using PyTorch on a GPU
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="https://docs.coiled.io/user_guide/usage/functions/sklearn.html">
                    Using scikit-learn on a compute-optimized VM
                  </Link>
                </ListItem>
              </List>
            </Typography>
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={ValidTabs.notebooks}>
          <Typography>
            Start a Jupyter server on a big cloud machine:
          </Typography>
          <CodeBlock
            language="shell"
            snippet={"coiled notebook start --cpu 64 --memory '256 GB'"}
          />
          <Typography>Or if you want a GPU then try this:</Typography>
          <CodeBlock
            language="shell"
            snippet={
              "coiled notebook start --vm-type g5.xlarge --container coiled/gpu-examples:latest --region us-west-2"
            }
          />
          <Typography>
            {"To turn on real-time file synchronization, add the "}
            <code>--sync</code>
            {" flag (you'll be prompted to install mutagen):"}
            <CodeBlock
              language="shell"
              snippet={"coiled notebook start --sync"}
            />
          </Typography>
          <Typography>
            For more information, see the{" "}
            <Link href="https://docs.coiled.io/user_guide/labs/notebooks.html">
              Coiled Notebooks documentation.
            </Link>
          </Typography>
        </CustomTabPanel>
      </div>
    </TabContext>
  );
};
const StepperButton = ({
  headerIcon,
  header,
  subtitle,
  complete,

  button,
}: StepperButtonProps): React.ReactElement => {
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        {complete ? <CheckCircle color="success" /> : headerIcon}
        <Stack>
          <Typography variant="h3">{header}</Typography>
          <Typography
            variant="subtitle2"
            color={(theme) => theme.palette.grey[700]}
          >
            {subtitle}
          </Typography>
        </Stack>
        {!complete && (
          <Box sx={{ marginLeft: "auto !important" }}>{button}</Box>
        )}
      </Stack>
    </Stack>
  );
};

const authenticateTask = {
  key: InteractionFlagNames.HasApiKey,
  title: "Step 1. Authenticate your computer",
  subtitle: "Set up an API token on your computer so it can access Coiled",
  content: (
    <CodeBlock
      language="shell"
      snippet={`pip install coiled "dask[complete]"\ncoiled login`}
    />
  ),
  icon: <Login />,
  completedSubtitle: null,
  completedContent: null,
};

const cloudTaskNoHosted = {
  key: InteractionFlagNames.AccessToAccountWithCompletedSetup,
  title: "Step 2. Connect your cloud",
  subtitle: "Grant Coiled access to your AWS, Google Cloud, or Azure account.",
  completedSubtitle:
    "You're already in a Coiled workspace that's connected to the cloud",
  icon: <Cloud />,
  content: (
    <Button
      variant="primary"
      component={RouterLink}
      to={`/${Urls.Setup}/${Urls.Credentials}`}
      sx={{ width: "130px" }}
    >
      Begin Setup
    </Button>
  ),
  completedContent: (
    <Typography>
      There’s no need to set up your cloud provider because you already have
      access to a Coiled workspace that’s connected to the cloud!
    </Typography>
  ),
};

const cloudTaskHosted = {
  key: InteractionFlagNames.AccessToAccountWithCompletedSetup,
  title: "Step 3. Connect your cloud",
  subtitle: "Grant Coiled access to your AWS, Google Cloud, or Azure account",
  completedSubtitle:
    "You're already in a Coiled workspace that's connected to the cloud",
  icon: <Cloud />,
  content: (
    <Stack>
      <Typography>
        You&apos;re currently running on our cloud account.
      </Typography>
      <Typography>
        Connect Coiled to your cloud for better access to your data, more
        security, and an expanded free tier.
      </Typography>
      <Typography>
        <br />
      </Typography>
      <Button
        variant="primary"
        component={RouterLink}
        to={`/${Urls.Setup}/${Urls.Credentials}`}
        sx={{ width: "130px" }}
      >
        Begin Setup
      </Button>
    </Stack>
  ),
  completedContent: (
    <Typography>
      There’s no need to set up your cloud provider because you already have
      access to a Coiled workspace that’s connected to the cloud!
    </Typography>
  ),
};

const examplesTaskHosted = {
  key: InteractionFlagNames.HasCreatedCluster,
  title: "Step 2. Start using Coiled",
  subtitle: "Get started with driving the cloud from your computer",
  icon: <Computer />,
  content: (
    <Stack>
      <Typography>
        Run the following to have Coiled walk you through some fun examples
      </Typography>
      <CodeBlock language="shell" snippet={`coiled quickstart`} />
      <Typography>
        Alternatively, you can run these examples from anywhere you run Python
      </Typography>
      <TutorialTabs />
    </Stack>
  ),
  completedSubtitle: null,
  completedContent: null,
};

const examplesTaskNoHosted = {
  ...examplesTaskHosted,
  title: "Step 3. Use Coiled",
};

const UsageSegment = ({
  programId,
}: {
  programId: number;
}): React.ReactElement => {
  const {
    data: program,
    isSuccess: programSuccess,
    isLoading: programLoading,
  } = useProgram(programId);
  const {
    data: programUsage,
    isSuccess: programUsageSuccess,
    isLoading: programUsageLoading,
  } = useProgramUsage(programId);
  const theme = useTheme();
  return (
    <StepperButton
      header="Keep an eye on your usage"
      headerIcon={<QueryStats />}
      subtitle={
        <Box sx={{ paddingTop: theme.spacing(1) }}>
          {programLoading || (programUsageLoading && <Skeleton />)}
          {programSuccess && programUsageSuccess && (
            <Stack alignItems={"flex-start"}>
              <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress
                  variant="determinate"
                  sx={{
                    height: 10,
                    width: 300,
                    borderRadius: 1,
                    backgroundColor: "success",
                  }}
                  color="primary"
                  value={programUsage.programCreditsUsagePercent}
                />
              </Box>
              <Box sx={{ minWidth: 150 }}>
                <Typography variant="subtitle1" color="text.secondary">
                  {programUsage.programCreditsSpent.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })}
                  {"/"}
                  {program.billingMethod === BillingMethod.Empty
                    ? `${program.creditAllotment.toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}  free credits`
                    : "∞"}
                  {}
                </Typography>
              </Box>
            </Stack>
          )}
        </Box>
      }
      button={
        <Button
          variant="primary"
          sx={{ maxHeight: "50px", minWidth: "200px " }}
          component={RouterLink}
          to={"/" + Urls.Billing}
        >
          Explore Billing
        </Button>
      }
    />
  );
};

export const GetStarted = (): React.ReactElement => {
  const viewedAccount = useContext(WorkspaceContext);
  const data = useContext(InteractionFlagsContext);

  const [expectCanUseHosted, setExpectCanUseHosted] = useState(false);
  const tasksIfHosted = [authenticateTask, examplesTaskHosted, cloudTaskHosted];
  const tasksIfNotHosted = [
    authenticateTask,
    cloudTaskNoHosted,
    examplesTaskNoHosted,
  ];
  const [tasks, setTasks] = useState(tasksIfHosted);

  useEffect(() => {
    setTasks(expectCanUseHosted ? tasksIfHosted : tasksIfNotHosted);
  }, [expectCanUseHosted]);

  useEffect(() => {
    activateNextIncompleteStep(); // needs to be explicitly called once we have updated
  }, [tasks]);

  useEffect(() => {
    const fetchHostedDryRun = async () => {
      const response =
        await ApiClient.declarativeViewsUserHostedApprovalDryRun();
      setExpectCanUseHosted(response);
    };
    fetchHostedDryRun();
    setTasks(expectCanUseHosted ? tasksIfHosted : tasksIfNotHosted);
  }, []);

  const getNextIncompleteStep = useCallback(() => {
    const index = tasks.findIndex((task) => !data[task.key].completed);
    return index === -1 ? tasks.length - 1 : index;
  }, [data]);
  const [activeStep, setActiveStep] = useState<number>(getNextIncompleteStep());
  const incompleteStepsIndexes = tasks.reduce<number[]>(
    (acc, task, index) => (data[task.key].completed ? acc : [...acc, index]),
    [],
  );
  const [expandedSteps, setExpandedSteps] = useState<number[]>(
    incompleteStepsIndexes,
  );

  const toggleStepExpansion = useCallback(
    (index: number) => {
      setExpandedSteps((prev) => {
        if (prev.includes(index)) {
          return prev.filter((i) => i !== index);
        } else {
          return [...prev, index];
        }
      });
    },
    [setExpandedSteps],
  );

  const activateNextIncompleteStep = useCallback(() => {
    tasks.some((task, index) => {
      if (!data[task.key].completed) {
        setActiveStep(index);
        return true;
      } else if (index === tasks.length - 1) {
        setActiveStep(index);
        return true;
      }
    });
  }, [data, setActiveStep, tasks, expectCanUseHosted]);

  const dataRef = useRef<AllFlags | undefined>();
  useEffect(() => {
    if (dataRef.current !== data) {
      if (dataRef.current === undefined) {
        activateNextIncompleteStep();
      } else {
        tasks.forEach((task, index) => {
          if (
            dataRef.current &&
            !dataRef.current[task.key].completed &&
            data[task.key].completed
          ) {
            activateNextIncompleteStep();
            toggleStepExpansion(index);
          }
        });
      }
      dataRef.current = data;
    }
  }, [data, activateNextIncompleteStep, toggleStepExpansion]);

  const theme = useTheme();
  return (
    <Container maxWidth="xl">
      <Stack spacing={2}>
        <TitleCard
          title="Getting started"
          cardContentProps={{
            sx: {
              paddingBottom: `${theme.spacing(1)} !important`,
              marginBottom: 0,
            },
          }}
        >
          <Stack spacing={2}>
            <Typography>
              Welcome to Coiled! To start, do these three steps (they&apos;re
              fun!)
            </Typography>
            <Typography>Here are the three steps:</Typography>
            <Stepper orientation="vertical" nonLinear>
              {tasks.map((step, index) => (
                <Step
                  key={step.key}
                  completed={data[step.key].completed || true}
                  active={activeStep === index}
                  expanded={expandedSteps.includes(index)}
                  sx={{ opacity: index === activeStep ? 1 : 0.4 }}
                >
                  <StepButton
                    onClick={() => toggleStepExpansion(index)}
                    icon={
                      data[step.key].completed ? (
                        <CheckCircle color="success" />
                      ) : (
                        step.icon
                      )
                    }
                    optional={
                      data[step.key].completed && step.completedSubtitle
                        ? step.completedSubtitle
                        : step.subtitle
                    }
                  >
                    {activeStep === index ? (
                      <strong>{step.title}</strong>
                    ) : (
                      step.title
                    )}
                  </StepButton>
                  <StepContent>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        {data[step.key].completed &&
                        step &&
                        step.completedContent
                          ? step.completedContent
                          : step.content}
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Stack>
        </TitleCard>
        <Card>
          <CardContent
            sx={{
              paddingBottom: `${theme.spacing(1)} !important`,
              marginBottom: 0,
              opacity: data[InteractionFlagNames.HasCreatedCluster].completed
                ? 1
                : 0.4,
            }}
          >
            <Stack spacing={2}>
              <Box>
                <Typography variant="h2">Optional</Typography>
                <Typography variant="subtitle2">
                  Explore other features of Coiled.
                </Typography>
              </Box>
              <Divider />
              <StepperButton
                header="Talk to an Engineer"
                headerIcon={<LocalPhoneRoundedIcon />}
                subtitle="Come tell us about your problems. It's like therapy, but nerdier."
                button={
                  <Button
                    variant="primary"
                    component={RouterLink}
                    to={calendlyLink}
                    sx={{
                      minWidth: "200px",
                    }}
                  >
                    Schedule Time
                  </Button>
                }
              />
              <StepperButton
                header="Add Teammates"
                headerIcon={<TeamIcon />}
                subtitle="Add teammates to your workspace. They can use the cloud account you've setup."
                button={
                  <Button
                    variant="primary"
                    component={RouterLink}
                    to={"/" + Urls.Team}
                    sx={{
                      minWidth: "200px",
                    }}
                  >
                    Invite Teammates
                  </Button>
                }
              />
              {viewedAccount.organization && (
                <>
                  <Divider />
                  <UsageSegment programId={viewedAccount.organization.id} />
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
};
