import { Link, ListItem, Stack, Typography } from "@mui/material";
import React from "react";
import { StepProps } from "../../../shared-components/StepperContext";
import { CodeBlock } from "../../../shared-components/CodeBlock";
import { useAzureCredentials } from "../../../crud/setup/hooks";
import TextField from "@mui/material/TextField";

export const AzureTalkToUsStep = ({
  index,
  workspaceSlug,
}: StepProps): React.ReactElement => {
  return <AzureTalkToUsInfo workspaceSlug={workspaceSlug} />;
};
type AzureTalkToUsProps = {
  alreadySetup?: boolean;
  workspaceSlug: string;
};

export const AzureTalkToUsInfo = ({
  alreadySetup,
  workspaceSlug,
}: AzureTalkToUsProps): React.ReactElement => {
  const { data: current, isLoading } = useAzureCredentials(workspaceSlug);

  return (
    <Stack alignItems={"flex-start"}>
      {alreadySetup ? (
        <>
          <Typography>
            This Coiled workspace is currently configured to use Azure.
          </Typography>

          {!isLoading && (
            <Stack spacing={2}>
              <ListItem>
                <TextField
                  disabled
                  size="small"
                  label="Azure Subscription ID"
                  value={current?.subscriptionId}
                />
              </ListItem>
              <ListItem>
                <TextField
                  disabled
                  size="small"
                  label="Resource Group"
                  value={current?.resourceGroupName}
                />
              </ListItem>
            </Stack>
          )}
        </>
      ) : (
        <Stack spacing={2}>
          <Typography>
            Coiled on Azure is currently in public beta. Please contact{" "}
            <Link
              href="mailto:support@coiled.io"
              rel="noreferrer"
              target="_blank"
            >
              support@coiled.io
            </Link>{" "}
            if you have any questions or problems.
          </Typography>
          <Typography>
            Install the{" "}
            <Link
              href="https://learn.microsoft.com/cli/azure/install-azure-cli"
              target="_blank"
            >
              Azure CLI
            </Link>
            , then run these commands to get started with Coiled:
          </Typography>
          <CodeBlock isTerminal snippet="pip install 'coiled[azure]'" />
          <CodeBlock isTerminal snippet="coiled setup azure" />
          <Typography>
            For more details about setting up and using Azure, see{" "}
            <Link
              href="https://docs.coiled.io/user_guide/setup/azure/cli.html"
              target="_blank"
            >
              our docs
            </Link>
            .
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
