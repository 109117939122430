import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  CardContent,
  Divider,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Urls } from "../../../domain/urls";
import {
  BackendTypesEnum,
  DockerSettingsSchema,
  WorkspaceSchema,
} from "../../../api-client";
import { UserContext } from "../../../crud/user/context";
import { userIsAdmin } from "../../../auth";
import { HookSwitch } from "../../../shared-components/HookSwitch";
import { LoadingButton } from "@mui/lab";
import { SubmitHandler, useForm } from "react-hook-form";
import { ApiClient } from "../../../apiUtils";
import { CodeBlock } from "../../../shared-components/CodeBlock";

export const backendToCloudProvider: Record<BackendTypesEnum, string> = {
  [BackendTypesEnum.VmAws]: "AWS",
  [BackendTypesEnum.VmGcp]: "GCP",
  [BackendTypesEnum.VmAzure]: "Azure",
  [BackendTypesEnum.InProcess]: "In Process",
  [BackendTypesEnum.Aks]: "AKS",
  [BackendTypesEnum.Gke]: "GKE",
};

export const DockerSettingsForm = ({
  workspace,
}: {
  workspace: WorkspaceSchema;
}): React.ReactElement => {
  const { memberships } = useContext(UserContext);

  const isAdmin = userIsAdmin(memberships, workspace.slug);

  const backendToRegistry: Record<BackendTypesEnum, string> = {
    [BackendTypesEnum.VmAws]: "Elastic Container Registry",
    [BackendTypesEnum.VmGcp]: "Google Artifact Registry",
    [BackendTypesEnum.VmAzure]: "Azure Container Registry",
    [BackendTypesEnum.InProcess]: "In Process",
    [BackendTypesEnum.Aks]: "AKS",
    [BackendTypesEnum.Gke]: "GKE",
  };

  const cloud_provider_name = workspace.activeBackend
    ? backendToCloudProvider[workspace.activeBackend]
    : null;
  const registry_name = workspace.activeBackend
    ? backendToRegistry[workspace.activeBackend]
    : null;

  const [currentSettings, setCurrentSettings] = useState({
    useDockerHub: false,
    username: "",
    password: "",
    uri: "",
  });

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<DockerSettingsSchema>({
    defaultValues: currentSettings,
  });

  useEffect(() => {
    reset(currentSettings);
  }, [currentSettings, reset]);

  useEffect(() => {
    const fetchDockerSettings = async () => {
      const response = await ApiClient.declarativeViewsSetupGetDockerSettings({
        account: workspace.slug,
      });
      setCurrentSettings({
        ...response,
        uri: response.uri || "",
      });
      reset(response, { keepDefaultValues: true });
    };
    fetchDockerSettings();
  }, [workspace.slug, reset]);

  const onSubmit: SubmitHandler<DockerSettingsSchema> = (data) => {
    ApiClient.declarativeViewsSetupPatchDockerSettings({
      account: workspace.slug,
      dockerSettingsSchema: data,
    });
    setCurrentSettings({
      ...data,
      uri: data.uri || "",
    });
  };

  return (
    <>
      {!isAdmin && (
        <Alert severity="error" sx={{ borderRadius: 0 }}>
          Editing has been disabled as only workspace admins can configure
          workspace settings. Find your admins on the{" "}
          <Link
            component={RouterLink}
            to={`/${Urls.Team}?account=${workspace.slug}`}
          >
            teams
          </Link>{" "}
          page.
        </Alert>
      )}
      <CardContent>
        {cloud_provider_name &&
        workspace.activeBackend !== BackendTypesEnum.VmAzure ? (
          <>
            <Typography>
              By default when you specify a Docker image, Coiled authenticates
              with {registry_name}, the Docker registry attached to your{" "}
              {cloud_provider_name} account.
            </Typography>
            <Typography>
              If you enable a different registry, we will no longer authenticate
              with {registry_name}.
            </Typography>
          </>
        ) : (
          <Typography>
            Coiled does not automatically authenticate with Azure Container
            registry, but if you want to use a Docker image there (or in another
            registry) you can configure that here.
          </Typography>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4} sx={{ paddingTop: (theme) => theme.spacing(2) }}>
            <Stack spacing={1}>
              <Divider sx={{ maxWidth: "700px" }}>Docker Settings</Divider>
              <Typography>
                For a custom registry, these fields should match the values you
                use to log in with the Docker CLI:
              </Typography>
              <CodeBlock
                snippet="docker login <URI-if-not-DockerHub> -u <username> -p <token-or-password> "
                allowCopy={false}
              />
              <Tooltip title="Enabling this means private images in your cloud provider's registry will not work.">
                <HookSwitch
                  label="Use a custom Docker registry"
                  control={control}
                  disabled={!isAdmin}
                  name="useDockerHub"
                />
              </Tooltip>
              <TextField
                size="small"
                disabled={!isAdmin}
                sx={{ maxWidth: "500px" }}
                InputLabelProps={{ shrink: true }}
                label="Docker Username"
                {...register("username", {
                  required: false,
                })}
              />
              <TextField
                size="small"
                disabled={!isAdmin}
                type={"password"}
                sx={{ maxWidth: "500px" }}
                InputLabelProps={{ shrink: true }}
                label="Docker Token/Password"
                {...register("password", {
                  required: false,
                })}
              />
              <TextField
                size="small"
                disabled={!isAdmin}
                sx={{ maxWidth: "500px" }}
                InputLabelProps={{ shrink: true }}
                label="Docker URI (if blank, Coiled will assume DockerHub)"
                {...register("uri", {
                  required: false,
                })}
              />
            </Stack>
            <LoadingButton
              disabled={!isAdmin || !isDirty}
              sx={{ maxWidth: "100px" }}
              type="submit"
            >
              Save
            </LoadingButton>
          </Stack>
        </form>
      </CardContent>
    </>
  );
};
