/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RefType
 */
export interface RefType {
}

/**
 * Check if a given object implements the RefType interface.
 */
export function instanceOfRefType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RefTypeFromJSON(json: any): RefType {
    return RefTypeFromJSONTyped(json, false);
}

export function RefTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefType {
    return json;
}

export function RefTypeToJSON(value?: RefType | null): any {
    return value;
}

