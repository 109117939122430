/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobUserFileRefSchema
 */
export interface JobUserFileRefSchema {
    /**
     * 
     * @type {string}
     * @memberof JobUserFileRefSchema
     */
    uri: string;
    /**
     * 
     * @type {string}
     * @memberof JobUserFileRefSchema
     */
    refType?: JobUserFileRefSchemaRefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JobUserFileRefSchema
     */
    relativePath?: string;
}


/**
 * @export
 */
export const JobUserFileRefSchemaRefTypeEnum = {
    S3: 's3'
} as const;
export type JobUserFileRefSchemaRefTypeEnum = typeof JobUserFileRefSchemaRefTypeEnum[keyof typeof JobUserFileRefSchemaRefTypeEnum];


/**
 * Check if a given object implements the JobUserFileRefSchema interface.
 */
export function instanceOfJobUserFileRefSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uri" in value;

    return isInstance;
}

export function JobUserFileRefSchemaFromJSON(json: any): JobUserFileRefSchema {
    return JobUserFileRefSchemaFromJSONTyped(json, false);
}

export function JobUserFileRefSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobUserFileRefSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uri': json['uri'],
        'refType': !exists(json, 'ref_type') ? undefined : json['ref_type'],
        'relativePath': !exists(json, 'relative_path') ? undefined : json['relative_path'],
    };
}

export function JobUserFileRefSchemaToJSON(value?: JobUserFileRefSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uri': value.uri,
        'ref_type': value.refType,
        'relative_path': value.relativePath,
    };
}

