import {
  Alert,
  ButtonGroup,
  Collapse,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useGCPRegionalInfra,
  useRequestGCPRegionalSetup,
} from "../../../crud/setup/hooks";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import { AppConfigContext } from "../../../crud/config/context";
import { useQueryClient } from "react-query";
import { TransitionGroup } from "react-transition-group";
import AnimatedButton from "./AnimatedButton";
import { GCPRegionalInfraSchema } from "../../../crud/setup/types";
import { GCPRegionForm } from "./GCPRegionalInfraForm";
import { BackendTypeServer } from "../../../domain/people";
import { useIsAccountAdmin } from "../../../crud/account/hooks";
import { analytics } from "../../../analytics";

const REGIONS_LOCATION_MAP: Record<string, string> = {
  "asia-east1": "Taiwan",
  "asia-east2": "Hong Kong",
  "asia-northeast1": "Tokyo",
  "asia-northeast2": "Osaka",
  "asia-northeast3": "Seoul",
  "asia-south1": "Mumbai",
  "asia-southeast1": "Singapore",
  "asia-southeast2": "Jakarta",
  "australia-southeast1": "Sydney",
  "europe-central2": "Warsaw",
  "europe-north1": "Finland",
  "europe-west1": "Belgium",
  "europe-west2": "London",
  "europe-west3": "Frankfurt",
  "europe-west4": "Netherlands",
  "europe-west6": "Zurich",
  "northamerica-northeast1": "Montreal",
  "southamerica-east1": "São Paulo",
  "us-central1": "Iowa",
  "us-east1": "South Carolina",
  "us-east4": "Northern Virginia",
  "us-west1": "Oregon",
  "us-west2": "Los Angeles",
  "us-west3": "Salt Lake City",
  "us-west4": "Las Vegas",
};
type GCPAddRegionForm = {
  region: string;
};
type GCPAddRegionFormProps = {
  disabledRegions: string[];
  disabled: boolean;
  animate?: boolean;
  onSubmit: (result: GCPAddRegionForm) => void;
};
const AddRegionForm = ({
  disabledRegions,
  disabled,
  animate,
  onSubmit,
}: GCPAddRegionFormProps): React.ReactElement => {
  const {
    gcp: { supportedRegions },
  } = useContext(AppConfigContext);
  const { reset } = useForm<GCPAddRegionForm>({
    defaultValues: {
      region: supportedRegions.find((r) => !disabledRegions.includes(r)),
    },
  });
  useEffect(
    () =>
      reset({
        region: supportedRegions.find((r) => !disabledRegions.includes(r)),
      }),
    [disabledRegions, supportedRegions, reset],
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <ButtonGroup>
      <AnimatedButton
        animate={animate}
        onClick={handleClick}
        disabled={disabled}
      >
        Add Region
        <ArrowDropDownOutlined />
      </AnimatedButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
      >
        {supportedRegions.map((region) => (
          <MenuItem
            key={region}
            value={region}
            disabled={disabledRegions.includes(region)}
            onClick={() => {
              onSubmit({ region });
              handleClose();
            }}
          >
            {region} - {REGIONS_LOCATION_MAP[region]}
          </MenuItem>
        ))}
      </Menu>
    </ButtonGroup>
  );
};

type GCPRegionsFormProps = {
  disabled: boolean;
  managed: boolean;
  workspaceSlug: string;
};
export const GCPRegionsForm = ({
  disabled,
  managed,
  workspaceSlug,
}: GCPRegionsFormProps): React.ReactElement => {
  const isAdmin = useIsAccountAdmin(workspaceSlug);
  const { data: current, isLoading } = useGCPRegionalInfra(workspaceSlug);
  const [localRegions, setLocalRegions] = useState<GCPRegionalInfraSchema[]>(
    [],
  );
  const occupiedRegions = current?.regions.map((r) => r.region) ?? [];
  const requestSetup = useRequestGCPRegionalSetup(workspaceSlug);
  const client = useQueryClient();
  const onSubmit = (data: GCPRegionalInfraSchema) => {
    requestSetup.mutateAsync(data, {
      onSuccess: (response) => {
        client.invalidateQueries("gcpRegionalInfra");
        if (data.id === -1) {
          setLocalRegions([]);
        }
        analytics.track("setup-region-requested", {
          provider: BackendTypeServer.GCP_HOSTED,
          region: data.region,
          managed: data.managed,
        });
      },
    });
  };

  const onRegionAdd = async (result: GCPAddRegionForm) => {
    setLocalRegions([
      {
        region: result.region,
        managed,
        subnets: [],
        default: true,
        id: -1,
      },
    ]);
  };
  const displayRegions = [
    ...localRegions,
    ...(current?.regions.filter(
      (region) =>
        !localRegions.find((localRegion) => localRegion.id === region.id),
    ) || []),
  ];
  return (
    <Stack spacing={2}>
      <div>
        <AddRegionForm
          animate={displayRegions.length === 0}
          disabled={
            !isAdmin ||
            localRegions.length > 0 ||
            disabled ||
            !!current?.regions.some((r) => !r.managed)
          }
          disabledRegions={occupiedRegions}
          onSubmit={onRegionAdd}
        />
      </div>
      {isLoading && (
        <>
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
        </>
      )}
      {!isLoading && (
        <>
          {displayRegions.length === 0 && (
            <Alert severity="error">{"No regions."}</Alert>
          )}
          <TransitionGroup component={Stack} spacing={2}>
            {displayRegions.map((regInfra) => (
              <Collapse in key={regInfra.region} appear={regInfra.id !== -1}>
                <GCPRegionForm
                  workspaceSlug={workspaceSlug}
                  key={regInfra.region}
                  current={regInfra}
                  managed={managed}
                  onSubmit={onSubmit}
                  onCancel={(id) => setLocalRegions([])}
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </>
      )}
    </Stack>
  );
};
