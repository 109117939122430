/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UsageBreakdownItem } from './UsageBreakdownItem';
import {
    UsageBreakdownItemFromJSON,
    UsageBreakdownItemFromJSONTyped,
    UsageBreakdownItemToJSON,
} from './UsageBreakdownItem';

/**
 * 
 * @export
 * @interface OrganizationProgramUsageSchema
 */
export interface OrganizationProgramUsageSchema {
    /**
     * 
     * @type {number}
     * @memberof OrganizationProgramUsageSchema
     */
    organizationCreditsSpent: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationProgramUsageSchema
     */
    organizationCreditsUsagePercent: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationProgramUsageSchema
     */
    organizationCreditsUserSpent: number;
    /**
     * 
     * @type {Array<UsageBreakdownItem>}
     * @memberof OrganizationProgramUsageSchema
     */
    organizationUsageBreakdown: Array<UsageBreakdownItem>;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationProgramUsageSchema
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationProgramUsageSchema
     */
    endDate: Date;
}

/**
 * Check if a given object implements the OrganizationProgramUsageSchema interface.
 */
export function instanceOfOrganizationProgramUsageSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organizationCreditsSpent" in value;
    isInstance = isInstance && "organizationCreditsUsagePercent" in value;
    isInstance = isInstance && "organizationCreditsUserSpent" in value;
    isInstance = isInstance && "organizationUsageBreakdown" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;

    return isInstance;
}

export function OrganizationProgramUsageSchemaFromJSON(json: any): OrganizationProgramUsageSchema {
    return OrganizationProgramUsageSchemaFromJSONTyped(json, false);
}

export function OrganizationProgramUsageSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationProgramUsageSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationCreditsSpent': json['organization_credits_spent'],
        'organizationCreditsUsagePercent': json['organization_credits_usage_percent'],
        'organizationCreditsUserSpent': json['organization_credits_user_spent'],
        'organizationUsageBreakdown': ((json['organization_usage_breakdown'] as Array<any>).map(UsageBreakdownItemFromJSON)),
        'startDate': (new Date(json['start_date'])),
        'endDate': (new Date(json['end_date'])),
    };
}

export function OrganizationProgramUsageSchemaToJSON(value?: OrganizationProgramUsageSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization_credits_spent': value.organizationCreditsSpent,
        'organization_credits_usage_percent': value.organizationCreditsUsagePercent,
        'organization_credits_user_spent': value.organizationCreditsUserSpent,
        'organization_usage_breakdown': ((value.organizationUsageBreakdown as Array<any>).map(UsageBreakdownItemToJSON)),
        'start_date': (value.startDate.toISOString().substring(0,10)),
        'end_date': (value.endDate.toISOString().substring(0,10)),
    };
}

