/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomGroupSchema } from './CustomGroupSchema';
import {
    CustomGroupSchemaFromJSON,
    CustomGroupSchemaFromJSONTyped,
    CustomGroupSchemaToJSON,
} from './CustomGroupSchema';
import type { OrganizationMembershipSchema } from './OrganizationMembershipSchema';
import {
    OrganizationMembershipSchemaFromJSON,
    OrganizationMembershipSchemaFromJSONTyped,
    OrganizationMembershipSchemaToJSON,
} from './OrganizationMembershipSchema';

/**
 * 
 * @export
 * @interface OrganizationSchema
 */
export interface OrganizationSchema {
    /**
     * 
     * @type {number}
     * @memberof OrganizationSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationSchema
     */
    updated: Date;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSchema
     */
    name: string;
    /**
     * 
     * @type {Array<OrganizationMembershipSchema>}
     * @memberof OrganizationSchema
     */
    memberSet: Array<OrganizationMembershipSchema>;
    /**
     * 
     * @type {Array<CustomGroupSchema>}
     * @memberof OrganizationSchema
     */
    customGroups: Array<CustomGroupSchema>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationSchema
     */
    memberLimit: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationSchema
     */
    workspaceLimit: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationSchema
     */
    activeProgramId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSchema
     */
    canUseCustomNetworking: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrganizationSchema
     */
    creditLimit: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationSchema
     */
    coiledCreditValue: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationSchema
     */
    dollarsSpendLimit: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSchema
     */
    spendLimitShouldStopInstances: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSchema
     */
    isPersonal: boolean;
}

/**
 * Check if a given object implements the OrganizationSchema interface.
 */
export function instanceOfOrganizationSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "memberSet" in value;
    isInstance = isInstance && "customGroups" in value;
    isInstance = isInstance && "memberLimit" in value;
    isInstance = isInstance && "workspaceLimit" in value;
    isInstance = isInstance && "canUseCustomNetworking" in value;
    isInstance = isInstance && "creditLimit" in value;
    isInstance = isInstance && "coiledCreditValue" in value;
    isInstance = isInstance && "dollarsSpendLimit" in value;
    isInstance = isInstance && "spendLimitShouldStopInstances" in value;
    isInstance = isInstance && "isPersonal" in value;

    return isInstance;
}

export function OrganizationSchemaFromJSON(json: any): OrganizationSchema {
    return OrganizationSchemaFromJSONTyped(json, false);
}

export function OrganizationSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'name': json['name'],
        'memberSet': ((json['member_set'] as Array<any>).map(OrganizationMembershipSchemaFromJSON)),
        'customGroups': ((json['custom_groups'] as Array<any>).map(CustomGroupSchemaFromJSON)),
        'memberLimit': json['member_limit'],
        'workspaceLimit': json['workspace_limit'],
        'activeProgramId': !exists(json, 'active_program_id') ? undefined : json['active_program_id'],
        'canUseCustomNetworking': json['can_use_custom_networking'],
        'creditLimit': json['credit_limit'],
        'coiledCreditValue': json['coiled_credit_value'],
        'dollarsSpendLimit': json['dollars_spend_limit'],
        'spendLimitShouldStopInstances': json['spend_limit_should_stop_instances'],
        'isPersonal': json['is_personal'],
    };
}

export function OrganizationSchemaToJSON(value?: OrganizationSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'name': value.name,
        'member_set': ((value.memberSet as Array<any>).map(OrganizationMembershipSchemaToJSON)),
        'custom_groups': ((value.customGroups as Array<any>).map(CustomGroupSchemaToJSON)),
        'member_limit': value.memberLimit,
        'workspace_limit': value.workspaceLimit,
        'active_program_id': value.activeProgramId,
        'can_use_custom_networking': value.canUseCustomNetworking,
        'credit_limit': value.creditLimit,
        'coiled_credit_value': value.coiledCreditValue,
        'dollars_spend_limit': value.dollarsSpendLimit,
        'spend_limit_should_stop_instances': value.spendLimitShouldStopInstances,
        'is_personal': value.isPersonal,
    };
}

