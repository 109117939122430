/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JobUserFileRefSchema } from './JobUserFileRefSchema';
import {
    JobUserFileRefSchemaFromJSON,
    JobUserFileRefSchemaFromJSONTyped,
    JobUserFileRefSchemaToJSON,
} from './JobUserFileRefSchema';
import type { SupersetRuntimeOptionsAPISchema } from './SupersetRuntimeOptionsAPISchema';
import {
    SupersetRuntimeOptionsAPISchemaFromJSON,
    SupersetRuntimeOptionsAPISchemaFromJSONTyped,
    SupersetRuntimeOptionsAPISchemaToJSON,
} from './SupersetRuntimeOptionsAPISchema';

/**
 * 
 * @export
 * @interface CreateSingleScriptClusterSchema
 */
export interface CreateSingleScriptClusterSchema {
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    script?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    scriptUri?: string;
    /**
     * 
     * @type {Array<JobUserFileRefSchema>}
     * @memberof CreateSingleScriptClusterSchema
     */
    fileRefs?: Array<JobUserFileRefSchema>;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    software?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    container?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSingleScriptClusterSchema
     */
    python?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    workspace?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    vmType?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    workerVmType?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSingleScriptClusterSchema
     */
    workers?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSingleScriptClusterSchema
     */
    workerDiskSize?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSingleScriptClusterSchema
     */
    workerDiskThroughput?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSingleScriptClusterSchema
     */
    schedulerDiskSize?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSingleScriptClusterSchema
     */
    schedulerDiskThroughput?: number;
    /**
     * 
     * @type {SupersetRuntimeOptionsAPISchema}
     * @memberof CreateSingleScriptClusterSchema
     */
    clusterOptions?: SupersetRuntimeOptionsAPISchema;
}

/**
 * Check if a given object implements the CreateSingleScriptClusterSchema interface.
 */
export function instanceOfCreateSingleScriptClusterSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateSingleScriptClusterSchemaFromJSON(json: any): CreateSingleScriptClusterSchema {
    return CreateSingleScriptClusterSchemaFromJSONTyped(json, false);
}

export function CreateSingleScriptClusterSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSingleScriptClusterSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'script': !exists(json, 'script') ? undefined : json['script'],
        'scriptUri': !exists(json, 'script_uri') ? undefined : json['script_uri'],
        'fileRefs': !exists(json, 'file_refs') ? undefined : ((json['file_refs'] as Array<any>).map(JobUserFileRefSchemaFromJSON)),
        'software': !exists(json, 'software') ? undefined : json['software'],
        'container': !exists(json, 'container') ? undefined : json['container'],
        'python': !exists(json, 'python') ? undefined : json['python'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'workspace': !exists(json, 'workspace') ? undefined : json['workspace'],
        'vmType': !exists(json, 'vm_type') ? undefined : json['vm_type'],
        'workerVmType': !exists(json, 'worker_vm_type') ? undefined : json['worker_vm_type'],
        'workers': !exists(json, 'workers') ? undefined : json['workers'],
        'workerDiskSize': !exists(json, 'worker_disk_size') ? undefined : json['worker_disk_size'],
        'workerDiskThroughput': !exists(json, 'worker_disk_throughput') ? undefined : json['worker_disk_throughput'],
        'schedulerDiskSize': !exists(json, 'scheduler_disk_size') ? undefined : json['scheduler_disk_size'],
        'schedulerDiskThroughput': !exists(json, 'scheduler_disk_throughput') ? undefined : json['scheduler_disk_throughput'],
        'clusterOptions': !exists(json, 'cluster_options') ? undefined : SupersetRuntimeOptionsAPISchemaFromJSON(json['cluster_options']),
    };
}

export function CreateSingleScriptClusterSchemaToJSON(value?: CreateSingleScriptClusterSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'script': value.script,
        'script_uri': value.scriptUri,
        'file_refs': value.fileRefs === undefined ? undefined : ((value.fileRefs as Array<any>).map(JobUserFileRefSchemaToJSON)),
        'software': value.software,
        'container': value.container,
        'python': value.python,
        'name': value.name,
        'workspace': value.workspace,
        'vm_type': value.vmType,
        'worker_vm_type': value.workerVmType,
        'workers': value.workers,
        'worker_disk_size': value.workerDiskSize,
        'worker_disk_throughput': value.workerDiskThroughput,
        'scheduler_disk_size': value.schedulerDiskSize,
        'scheduler_disk_throughput': value.schedulerDiskThroughput,
        'cluster_options': SupersetRuntimeOptionsAPISchemaToJSON(value.clusterOptions),
    };
}

