import {
  Alert,
  ButtonGroup,
  Collapse,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useAWSRegionalInfra,
  useRequestAWSRegionalSetup,
} from "../../../crud/setup/hooks";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import { AWSRegionalInfra } from "../../../crud/setup/fetch";
import { AppConfigContext } from "../../../crud/config/context";
import { RegionForm } from "./AWSRegionalInfraForm";
import { useQueryClient } from "react-query";
import { TransitionGroup } from "react-transition-group";
import AnimatedButton from "./AnimatedButton";
import { BackendTypeServer } from "../../../domain/people";
import { useIsAccountAdmin } from "../../../crud/account/hooks";
import { analytics } from "../../../analytics";

const REGION_LOCATION_MAP: Record<string, string> = {
  "us-east-1": "US East (N. Virginia)",
  "us-east-2": "US East (Ohio)",
  "us-west-1": "US West (N. California)",
  "us-west-2": "US West (Oregon)",
  "af-south-1": "Africa (Cape Town)",
  "ap-east-1": "Asia Pacific (Hong Kong)",
  "ap-south-1": "Asia Pacific (Mumbai)",
  "ap-northeast-3": "Asia Pacific (Osaka-Local)",
  "ap-northeast-2": "Asia Pacific (Seoul)",
  "ap-southeast-1": "Asia Pacific (Singapore)",
  "ap-southeast-2": "Asia Pacific (Sydney)",
  "ap-northeast-1": "Asia Pacific (Tokyo)",
  "ca-central-1": "Canada (Central)",
  "cn-north-1": "China (Beijing)",
  "cn-northwest-1": "China (Ningxia)",
  "eu-central-1": "Europe (Frankfurt)",
  "eu-west-1": "Europe (Ireland)",
  "eu-west-2": "Europe (London)",
  "eu-south-1": "Europe (Milan)",
  "eu-west-3": "Europe (Paris)",
  "eu-north-1": "Europe (Stockholm)",
  "me-south-1": "Middle East (Bahrain)",
  "sa-east-1": "South America (São Paulo)",
};

type AWSAddRegionForm = {
  region: string;
};
type AWSAddRegionFormProps = {
  disabledRegions: string[];
  disabled: boolean;
  animate?: boolean;
  onSubmit: (result: AWSAddRegionForm) => void;
};
const AddRegionForm = ({
  disabledRegions,
  disabled,
  animate,
  onSubmit,
}: AWSAddRegionFormProps): React.ReactElement => {
  const {
    aws: { supportedRegions },
  } = useContext(AppConfigContext);
  const { reset } = useForm<AWSAddRegionForm>({
    defaultValues: {
      region: supportedRegions.find((r) => !disabledRegions.includes(r)),
    },
  });
  useEffect(
    () =>
      reset({
        region: supportedRegions.find((r) => !disabledRegions.includes(r)),
      }),
    [disabledRegions, supportedRegions, reset],
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <ButtonGroup>
      <AnimatedButton
        animate={animate}
        onClick={handleClick}
        disabled={disabled}
      >
        Add Region
        <ArrowDropDownOutlined />
      </AnimatedButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
      >
        {supportedRegions.map((region) => (
          <MenuItem
            key={region}
            value={region}
            disabled={disabledRegions.includes(region)}
            onClick={() => {
              onSubmit({ region });
              handleClose();
            }}
          >
            {region} - {REGION_LOCATION_MAP[region]}
          </MenuItem>
        ))}
      </Menu>
    </ButtonGroup>
  );
};

type AWSRegionsFormProps = {
  disabled: boolean;
  workspaceSlug: string;
};
export const AWSRegionsForm = ({
  disabled,
  workspaceSlug,
}: AWSRegionsFormProps): React.ReactElement => {
  const { data: current, isLoading } = useAWSRegionalInfra(workspaceSlug);
  const isAdmin = useIsAccountAdmin(workspaceSlug);
  const [localRegions, setLocalRegions] = useState<AWSRegionalInfra[]>([]);
  const occupiedRegions = current?.regions.map((r) => r.region) ?? [];
  const requestSetup = useRequestAWSRegionalSetup(workspaceSlug);
  const client = useQueryClient();

  const onSubmit = (data: AWSRegionalInfra) => {
    requestSetup.mutateAsync(data, {
      onSuccess: (response) => {
        client.invalidateQueries("awsRegionalInfra");
        if (data.id === -1) {
          setLocalRegions([]);
        }
        analytics.track("setup-region-requested", {
          provider: BackendTypeServer.AWS_HOSTED,
          region: data.region,
          managed: data.managed,
        });
      },
    });
  };

  const onRegionAdd = async (result: AWSAddRegionForm) => {
    setLocalRegions([
      {
        region: result.region,
        managed: true,
        subnets: [],
        vpc: {
          id: -1,
          vpcId: "",
        },
        default: true,
        id: -1,
      },
    ]);
  };
  const displayRegions = [
    ...localRegions,
    ...(current?.regions.filter(
      (region) =>
        !localRegions.find((localRegion) => localRegion.id === region.id),
    ) || []),
  ];
  return (
    <Stack spacing={2}>
      <div>
        <AddRegionForm
          animate={displayRegions.length === 0}
          disabled={
            !isAdmin ||
            localRegions.length > 0 ||
            disabled ||
            !!current?.regions.some((r) => !r.managed)
          }
          disabledRegions={occupiedRegions}
          onSubmit={onRegionAdd}
        />
      </div>
      {isLoading && (
        <>
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
        </>
      )}
      {!isLoading && (
        <>
          {displayRegions.length === 0 && (
            <Alert severity="error">{"No regions."}</Alert>
          )}
          <TransitionGroup component={Stack} spacing={2}>
            {displayRegions.map((regInfra) => (
              <Collapse in key={regInfra.region} appear={regInfra.id !== -1}>
                <RegionForm
                  key={regInfra.region}
                  current={regInfra}
                  allowUnmanaged={!current?.regions.some((r) => r.managed)}
                  onSubmit={onSubmit}
                  onCancel={(id) => setLocalRegions([])}
                  workspaceSlug={workspaceSlug}
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </>
      )}
    </Stack>
  );
};
