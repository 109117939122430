import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ky from "ky";
import { datadogLogs } from "@datadog/browser-logs";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  Link,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";

import { LoadState } from "../store";
import { apiErrorToMessage, getStandardHeaders, parseError } from "../apiUtils";
import { Urls } from "../domain/urls";
import { CodeBlock } from "./CodeBlock";
import { LoadingButton } from "@mui/lab";
import { ApiToken } from "../pages/Profile/types";

const StyledForm = styled("form")(({ theme }) => {
  return {
    minWidth: "290px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    "& .MuiTextField-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  };
});

const DialogText = styled(Typography)({
  marginBottom: "20px",
  display: "flex",
  flexWrap: "wrap",
});

const DialogErrorText = styled(DialogText)(({ theme }) => {
  return {
    color: theme.palette.error.main,
  };
});

interface LoginCodeProps {
  token: string;
}

const LoginCode = ({ token }: LoginCodeProps): React.ReactElement => {
  return (
    <React.Fragment>
      <Typography>In a terminal:</Typography>
      <CodeBlock
        isTerminal
        snippet={`coiled login --token ${token}`}
        wrapLongLines
      />
    </React.Fragment>
  );
};

type ApiTokenWithKey = ApiToken & { token: string };

const createToken = async (
  label?: string,
  daysTtl?: string,
): Promise<LoadState<ApiTokenWithKey>> => {
  const daysToExpiry = (days: string): string => {
    const d = new Date();
    d.setDate(d.getDate() + parseInt(days, 10));
    return d.toISOString();
  };

  const expiryObj = daysTtl ? { expiry: daysToExpiry(daysTtl) } : {};
  const labelObj = label ? { label } : {};
  const json = { ...expiryObj, ...labelObj };

  try {
    const response = (await ky
      .post(`/api/v1/api-tokens/`, {
        json,
        headers: getStandardHeaders(),
      })
      .json()) as ApiTokenWithKey;
    return { type: "loaded", data: response };
  } catch (err) {
    datadogLogs.logger.error("Error creating token");
    return { type: "error", error: parseError(err) };
  }
};

type SubmitState =
  | { type: "none" }
  | { type: "done"; token: ApiTokenWithKey }
  | { type: "submitting" }
  | { type: "error"; error: string };

interface CreateTokenFormProps {
  open: boolean;
  handleClose: () => void;
  formTitle: string;
  handleSuccess: (token: ApiToken) => void;
}

interface CreateTokenFormInput {
  label?: string;
  daysTtl?: string;
}

export const CreateTokenForm = ({
  handleClose,
  open,
  formTitle,
  handleSuccess,
}: CreateTokenFormProps): React.ReactElement => {
  const [submissionState, setSubmissionState] = useState<SubmitState>({
    type: "none",
  });

  const { register, handleSubmit } = useForm<CreateTokenFormInput>({
    mode: "onBlur",
  });

  const onSubmit = async (data: CreateTokenFormInput) => {
    setSubmissionState({ type: "submitting" });
    const result = await createToken(
      data.label || undefined,
      data.daysTtl || undefined,
    );
    if (result.type === "error") {
      setSubmissionState({
        type: "error",
        error: apiErrorToMessage(result.error),
      });
    } else if (result.type === "loaded") {
      setSubmissionState((oldState) =>
        // only update to "done" if old state was "submitting"
        // this avoids an update to "done" if e.g. the user closed the dialog box during the request
        // (which would bring the dialog box back up, a bad experience)
        oldState.type === "submitting"
          ? { type: "done", token: result.data }
          : oldState,
      );

      // Add new token (without the secret token) to make sure user sees it right away
      //
      // (throwaway variable "_" is unused; needed for removing the "token" key)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { token: _, ...tokenWithOutSecret } = result.data;
      handleSuccess(tokenWithOutSecret);
    } else {
      datadogLogs.logger.error(
        "Only expected result type 'loaded' or 'error'. Found: " + result.type,
      );
      setSubmissionState({
        type: "error",
        error: apiErrorToMessage("Error creating token."),
      });
    }
  };

  const handleDialogClose = (): void => {
    setSubmissionState({ type: "none" });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="md">
      <DialogTitle>{formTitle}</DialogTitle>
      <DialogContent>
        {submissionState.type === "done" && (
          <ShowGeneratedToken
            handleDialogClose={handleDialogClose}
            token={submissionState.token.token}
          />
        )}
        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          // noValidate
        >
          {submissionState.type !== "done" && (
            <div>
              <DialogText>All fields are optional.</DialogText>
              <FormGroup>
                <TextField
                  label="Label"
                  variant="outlined"
                  {...register("label")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  label="Days to expiration (default: never)"
                  variant="outlined"
                  {...register("daysTtl")}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormGroup>
              <DialogText>
                <p>
                  You will be able to revoke this token at any time from the{" "}
                  <Link
                    component={NavLink}
                    // close dialog since otherwise clicking link does nothing if already on profile page
                    onClick={handleDialogClose}
                    to={`/${Urls.Profile}`}
                    underline="hover"
                  >
                    Profile
                  </Link>{" "}
                  page.
                </p>
              </DialogText>
              {submissionState.type === "error" ? (
                <DialogErrorText>{submissionState.error}</DialogErrorText>
              ) : null}
              <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={handleDialogClose}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={submissionState.type === "submitting"}
                  type="submit"
                  sx={{ midWidth: "110px" }}
                >
                  Create
                </LoadingButton>
              </Stack>
            </div>
          )}
        </StyledForm>
      </DialogContent>
    </Dialog>
  );
};

interface ShowGeneratedTokenProps {
  token: string;
  handleDialogClose: () => void;
}

const ShowGeneratedToken = ({
  token,
  handleDialogClose,
}: ShowGeneratedTokenProps): React.ReactElement => {
  return (
    <Stack spacing={2}>
      <Alert severity="info">
        Make sure to copy your personal access token now. You won’t be able to
        see it again!
      </Alert>
      <Typography>Your token is:</Typography>
      <CodeBlock snippet={token} wrapLongLines />
      <LoginCode token={token} />
      <Typography>
        You can manage your API tokens on the{" "}
        <Link
          component={NavLink}
          // close dialog since otherwise clicking link does nothing if already on profile page
          onClick={handleDialogClose}
          to={`/${Urls.Profile}`}
          underline="hover"
        >
          Profile
        </Link>{" "}
        page.
      </Typography>
    </Stack>
  );
};
