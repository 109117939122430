import React, { useContext } from "react";
import {
  ListItem,
  Link,
  Button,
  Dialog,
  ListItemText,
  CardContent,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useWorkspaceContextSlug } from "../../../utils/hooks";
import { createSearchParams, Link as RouterLink } from "react-router-dom";
import { Urls } from "../../../domain/urls";
import { UserContext } from "../../../crud/user/context";

import { ClusterState } from "../Information/ClusterStateComponent";

import { formatISO } from "date-fns";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import syntaxTheme from "../../../shared-components/syntaxTheme";
import Chip from "@mui/material/Chip";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { ClusterFrontendSchema } from "../../../api-client";
import { backendToCloudProvider } from "../../Setup/Forms/DockerSettingsForm";

interface ClusterInformation {
  cluster?: ClusterFrontendSchema;
  loading?: boolean;
}

export const ClusterInformationDetails = ({
  cluster,
  loading,
}: ClusterInformation): React.ReactElement => {
  const [openDaskConfig, setOpenDaskConfig] = React.useState(false);
  const { user } = useContext(UserContext);
  const account = useWorkspaceContextSlug();

  const desiredZone: string =
    cluster?.clusterOptions?.zoneName ||
    cluster?.clusterOptions?.regionName ||
    "";
  const schedulerZone = cluster?.scheduler.instance?.zoneName
    ? cluster?.scheduler.instance.zoneName
    : "";
  const workerZoneCounts = cluster?.workersExceptUnprovisionable.reduce(
    (accumulator, currentValue) => {
      if (currentValue.instance?.zoneName) {
        accumulator[currentValue.instance?.zoneName] =
          (accumulator[currentValue.instance?.zoneName] || 0) + 1;
      }
      return accumulator;
    },
    Object() as Record<string, number>,
  );
  const workerZones = workerZoneCounts ? Object.keys(workerZoneCounts) : [];
  let clusterZone = <></>;
  if (workerZoneCounts && workerZones) {
    clusterZone = (
      <>
        {schedulerZone}
        {(workerZones.length > 1 || workerZones[0] !== schedulerZone) &&
          " (scheduler)"}
        {(workerZones.length > 1 || workerZones[0] !== schedulerZone) &&
          Object.entries(workerZoneCounts).map(([zone, count]) => (
            <>
              <br />
              {zone} ({count} workers)
            </>
          ))}
      </>
    );
  } else if (schedulerZone) {
    clusterZone = <>{schedulerZone}</>;
  } else if (desiredZone) {
    clusterZone = <>{desiredZone} (requested)</>;
  }

  const firewallDesc = cluster?.clusterOptions?.firewallSpec
    ? cluster?.clusterOptions?.firewallSpec.ingress
        .map((fwRule) => `${fwRule.ports.join()} (${fwRule.cidr})`)
        .join("\n")
    : "";

  const senvLink = cluster?.senvBuildId
    ? `/${Urls.Software}/alias/${cluster.senvAlias?.id}/build/${cluster.senvBuildId}?account=${account}`
    : `/${Urls.Software}/alias/${cluster?.senvAlias?.id}?account=${account}`;
  const grafanaLink = user.isStaff
    ? cluster?.internalGrafanaLink
    : cluster?.externalGrafanaLink;
  const daskConfigYaml = cluster?.daskConfigYaml;
  const clusterTags = user.isStaff
    ? cluster?.tagList
    : cluster?.tagList.filter((value) => !value.systemTag);
  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={4}>
        <Card>
          <CardHeader title="Cluster information" />

          <ListItem>
            <ListItemText>
              {loading && <Skeleton />}
              {cluster && (
                <>
                  Name: {cluster.name}{" "}
                  <ClusterState state={cluster.currentState} />
                </>
              )}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              {loading && <Skeleton />}
              {cluster && <>Created: {formatISO(new Date(cluster.created))}</>}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              {loading && <Skeleton />}
              {cluster && (
                <>
                  Software Environment:{" "}
                  <Link
                    component={RouterLink}
                    sx={{ color: "inherit" }}
                    to={senvLink}
                  >
                    {cluster.senvAlias?.name}
                  </Link>
                </>
              )}
            </ListItemText>
          </ListItem>
          {grafanaLink && (
            <ListItem>
              <ListItemText>
                {loading && <Skeleton />}
                {cluster && (
                  <Typography>
                    {grafanaLink && (
                      <a href={grafanaLink} target="_blank" rel="noreferrer">
                        Grafana
                      </a>
                    )}
                  </Typography>
                )}
              </ListItemText>
            </ListItem>
          )}
          {user.isStaff && (
            <ListItem>
              <Button
                variant="secondary"
                onClick={() => setOpenDaskConfig(true)}
              >
                Dask Config
              </Button>
              <Dialog
                onClose={() => setOpenDaskConfig(false)}
                open={openDaskConfig}
              >
                <SyntaxHighlighter language="yaml" style={syntaxTheme}>
                  {daskConfigYaml}
                </SyntaxHighlighter>
              </Dialog>
            </ListItem>
          )}
        </Card>
      </Grid2>
      <Grid2 xs={4} sx={{ minWidth: "300px" }}>
        <Card sx={{ height: "100%", overflowY: "scroll", maxHeight: "300px" }}>
          <CardHeader title={"Tags"} />
          <CardContent>
            {loading && <Skeleton />}
            {clusterTags && (
              <Grid2 container spacing={1}>
                {clusterTags?.map((tag) => (
                  <Grid2 key={tag.label}>
                    <Chip
                      clickable
                      label={
                        <>
                          {tag.label}: <b>{tag.value}</b>
                        </>
                      }
                      size="small"
                      color={tag.systemTag ? "default" : "info"}
                      component={RouterLink}
                      to={{
                        pathname: "/clusters",
                        search: createSearchParams({
                          tagFilters: `${tag.label}:${tag.value}`,
                        }).toString(),
                      }}
                    />
                  </Grid2>
                ))}
              </Grid2>
            )}
          </CardContent>
        </Card>
      </Grid2>
      <Grid2 xs={4}>
        <Card>
          <CardHeader title={"Infra State"} />
          <ListItem>
            <ListItemText>
              {loading && <Skeleton />}
              {cluster && (
                <>State: {cluster?.clusterInfra?.currentState.state}</>
              )}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              {loading && <Skeleton />}
              {cluster && (
                <>Reason: {cluster?.clusterInfra?.currentState.reason}</>
              )}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              {loading && <Skeleton />}
              <Typography>
                {" "}
                {clusterZone && <span>Zone: {clusterZone}</span>}{" "}
                {cluster && (
                  <span>({backendToCloudProvider[cluster?.backendType]}</span>
                )}
                )
              </Typography>
            </ListItemText>
          </ListItem>
          {user.isStaff && (
            <ListItem>
              <ListItemText>
                {loading && <Skeleton />}
                {firewallDesc && (
                  <Typography>Scheduler Firewall: {firewallDesc}</Typography>
                )}
              </ListItemText>
            </ListItem>
          )}
        </Card>
      </Grid2>
    </Grid2>
  );
};
