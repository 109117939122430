/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskStateEnum } from './TaskStateEnum';
import {
    TaskStateEnumFromJSON,
    TaskStateEnumFromJSONTyped,
    TaskStateEnumToJSON,
} from './TaskStateEnum';

/**
 * 
 * @export
 * @interface RunTaskStatePayload
 */
export interface RunTaskStatePayload {
    /**
     * 
     * @type {TaskStateEnum}
     * @memberof RunTaskStatePayload
     */
    state: TaskStateEnum;
    /**
     * 
     * @type {number}
     * @memberof RunTaskStatePayload
     */
    exitCode?: number;
    /**
     * 
     * @type {number}
     * @memberof RunTaskStatePayload
     */
    startAt?: number;
    /**
     * 
     * @type {number}
     * @memberof RunTaskStatePayload
     */
    transitionAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RunTaskStatePayload
     */
    skipAllDoneCheck?: boolean;
}

/**
 * Check if a given object implements the RunTaskStatePayload interface.
 */
export function instanceOfRunTaskStatePayload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function RunTaskStatePayloadFromJSON(json: any): RunTaskStatePayload {
    return RunTaskStatePayloadFromJSONTyped(json, false);
}

export function RunTaskStatePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): RunTaskStatePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': TaskStateEnumFromJSON(json['state']),
        'exitCode': !exists(json, 'exit_code') ? undefined : json['exit_code'],
        'startAt': !exists(json, 'start_at') ? undefined : json['start_at'],
        'transitionAt': !exists(json, 'transition_at') ? undefined : json['transition_at'],
        'skipAllDoneCheck': !exists(json, 'skip_all_done_check') ? undefined : json['skip_all_done_check'],
    };
}

export function RunTaskStatePayloadToJSON(value?: RunTaskStatePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': TaskStateEnumToJSON(value.state),
        'exit_code': value.exitCode,
        'start_at': value.startAt,
        'transition_at': value.transitionAt,
        'skip_all_done_check': value.skipAllDoneCheck,
    };
}

